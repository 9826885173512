import Ractive from 'ractive';
import _ from 'lodash';
import lunr from 'lunr';
import dayjs from './utilities/dayjs';
import { handleOptionsDown, handleOptionsUp } from './utilities/options';
import DataTableCustomFilter from './types/dataTableCustomFilter';

window.cl = window.cl || {};

window.cl.components = ((cl) => {
    const _storage = {};
    const _components = {};
    const _i = {
        init() {
            _components.mdl = Ractive.extend({
                template: cl.templates.mdl,
                isolated: false,
                append: true,
                onrender() {
                    const self = this;
                    if (_.isUndefined(_storage[self._guid])) {
                        _storage[self._guid] = [];
                    }

                    const classes = ['tooltip', 'js-ripple-effect', 'js-ripple-effect--ignore-events', 'js-button', 'js-checkbox', 'js-icon-toggle', 'js-menu', 'js-progress', 'js-radio', 'js-slider', 'js-spinner', 'js-switch', 'js-tabs', 'js-textfield', 'js-layout', 'js-data-table'];
                    self.mdlTmp = [];
                    _.forEach(classes, ((className) => {
                        const component = self.find('.mdl-' + className);
                        if (!_.isUndefined(component) && !component.classList.contains('is-upgraded')) {
                            try {
                                window.componentHandler.upgradeElement(component);
                                _storage[self._guid].push(component);
                            } catch(e) {
                                _.log(e);
                            }
                        }
                    }))
                },
                onunrender() {
                    try {
                        const storage = _storage[this._guid];
                        for (const key in storage) {
                            window.componentHandler.downgradeElements(storage[key]);
                            storage[key].remove();
                        }
                    } catch (e) {
                        _.log(e);
                    }
                }
            });

            _components.newUser = Ractive.extend({
                template: cl.templates.newUser,
                isolated: false,
            });

            _components.changeUserPassword = Ractive.extend({
                template: cl.templates.changeUserPassword,
                isolated: false,
            });

            _components.changeUserPermissions = Ractive.extend({
                template: cl.templates.changeUserPermissions,
                isolated: false,
            });

            _components.dataTable = Ractive.extend({
                template: cl.templates.dataTable,
                isolated: true,
                twoway: false,
                data: {
                    _, //lodash
                    dayjs,
                    rendered: false,
                    classes(...args) {
                        return args.filter(s => typeof s === 'string' && s !== '').join(' ');
                    },
                    disabled(bool) {
                        return bool ? ' disabled' : '';
                    },
                    data: [],
                    fields: [],
                    perPage: 0,
                    fieldsHash() {
                        return _.keyBy(this.get('fields'), 'src');
                    },
                    getHashedField(key) {
                        return _.get(this.get('fieldsHash')?.() ?? {}, key);
                    },
                    filtered() {
                        const fieldsHash = this.get('fieldsHash')?.();
                        let chain = _(this.get('data'));

                        const selected = this.get('filterSelected');
                        for (const key in selected) {
                            if (!selected[key].length) {
                                continue;
                            }

                            const customFilter = fieldsHash[key]?.customFilter;
                            if (DataTableCustomFilter.isValid(customFilter)) {
                                chain = chain.filter(item => {
                                    const val = item[key];
                                    if (selected[key].indexOf(customFilter.hide) > -1) {
                                        return !customFilter.filter(val);
                                    }

                                    if (selected[key].indexOf(customFilter.show) > -1) {
                                        return customFilter.filter(val);
                                    }

                                    return true;
                                });
                                continue;
                            }

                            chain = chain.filter(i => {
                                return selected[key].some(v => i[key] === v);
                            });
                        }

                        const searchIds = this.get('paging.searchIds');
                        const search = this.get('paging.search').trim();
                        if (search.length > 2) {
                            chain = chain
                                .keyBy('id')
                                .at(searchIds)
                                .filter(i => !!i);
                        }

                        return chain.value();
                    },
                    sorted() {
                        const fields = this.get('fields').map(f => f.src);

                        return _(this.get('filtered')())
                            .orderBy(this.get('paging.sortBy'), this.get('paging.sortAsc') ? 'asc' : 'desc')
                            .map(i=>_.pick(i, fields))
                            .value();
                    },
                    list() {
                        const sorted = this.get('sorted')();
                        const perPage = this.get('paging.perPage');
                        const page = this.get('paging.page');
                        const start = perPage * page - perPage;
                        const end = perPage * page;
                        const maxPage = Math.ceil(sorted.length / perPage);

                        if (sorted.length && page > maxPage) {
                            this.set('paging.page', maxPage);
                        }

                        return _.slice(sorted, start, end);
                    },
                    paging: {
                        page: 1,
                        perPage: 10,
                        perPageOptions: [10, 25, 50, 100],
                        sortBy: '',
                        sortAsc: true,
                        search: '',
                        searchIndex: false,
                        searchIds: [],
                        selected: {},
                    },
                    searchFunc() {
                        const ids = [];
                        const search = this.get('paging.search').trim();
                        const searchIndex = this.get('paging.searchIndex');

                        if (search.length > 2 && _.isFunction(searchIndex.search)) {
                            ids.push(...searchIndex.search(search).map(i => i.ref));
                        }

                        this.set('paging.searchIds', ids);
                    },
                    isNumeric(key) {
                        const field = this.get('fieldsHash')?.()?.[key];

                        return field?.numeric || field?.money || field?.type === 'money';
                    },
                    filterPopup: '',
                    filterSearch: '',
                    filterOptionsSelected() {
                        const popup = this.get('filterPopup');
                        const customFilter = this.get('getHashedField')?.(`${popup}.customFilter`);
                        const selected = this.get(`filterSelected.${popup}`);
                        if (DataTableCustomFilter.isValid(customFilter)) {
                            return customFilter.values.filter(v => (selected?.indexOf?.(v) ?? -1) > -1);
                        }

                        return _(this.get('data'))
                                .map(popup)
                                .uniq()
                                .sort()
                                .filter(e => _.indexOf(selected, e) > -1)
                                .value();
                    },
                    filterOptionsAvailable() {
                        const search = this.get('filterSearch');
                        const popup = this.get('filterPopup');
                        const selected = this.get(`filterSelected.${popup}`);
                        const customFilter = this.get('getHashedField')?.(`${popup}.customFilter`);
                        if (DataTableCustomFilter.isValid(customFilter)) {
                            return (selected?.length ?? 0) === 0 ? customFilter.values : [];
                        }

                        return _(this.get('filtered')())
                                .map(popup)
                                .uniq()
                                .sort()
                                .filter(e => !_.isUndefined(e))
                                .filter(e => search.length > 0 ? e.toLowerCase().includes(search.toLowerCase()) : true)
                                .filter(e => _.indexOf(selected, e) === -1)
                                .value();
                    },
                    filterOptionsRemaining() {
                        const search = this.get('filterSearch');
                        const popup = this.get('filterPopup');
                        const selected = this.get(`filterSelected.${popup}`);
                        const customFilter = this.get('getHashedField')?.(`${popup}.customFilter`);
                        if (DataTableCustomFilter.isValid(customFilter)) {
                            return customFilter.values.filter(v => (selected?.length ?? 0) > 0 && (selected?.indexOf?.(v) ?? -1) === -1);
                        }

                        const available = _.keyBy(this.get('filterOptionsAvailable')());
                        return _(this.get('data'))
                                .map(popup)
                                .uniq()
                                .sort()
                                .filter(e => !_.isUndefined(e))
                                .filter(e => search.length > 0 ? e.toLowerCase().includes(search.toLowerCase()) : true)
                                .filter(e => _.indexOf(selected, e) === -1)
                                .filter(e => _.isUndefined(available[e]))
                                .value();
                    },
                    filterSelected: {},
                    filterInUse() {
                        const filters = this.get('filterSelected');
                        for (const key in filters) {
                            if (filters[key].length) {
                                return true;
                            }
                        }

                        return false;
                    },
                },
                components: cl.components.all,
                oninit() {
                    this.on({
                        prevPage() {
                            if (!this.get('rendered')) {
                                return;
                            }

                            const p = Number(this.get('paging.page')) - 1;
                            this.set('paging.page', p === 0 ? Math.ceil(this.get('filtered')().length / this.get('paging.perPage')) : p);
                        },
                        nextPage() {
                            if (!this.get('rendered')) {
                                return;
                            }

                            const l = Math.ceil(this.get('filtered')().length / this.get('paging.perPage'));
                            const p = Number(this.get('paging.page')) + 1;
                            this.set('paging.page', p > l ? 1 : p);
                        },
                        perPage(e) {
                            const val = e.node.value;
                            const len = Math.ceil(this.get('filtered')().length / val);
                            const page = this.get('paging.page');

                            if (page > len) {
                                this.set('paging.page', len);
                            }

                            this.set('paging.perPage', val);
                        },
                        pageInput(e) {
                            const len = Math.ceil(this.get('filtered')().length / this.get('paging.perPage'));
                            if (/[^0-9]/.test(e.node.value)) {
                                e.node.value = this.get('paging.page');
                            }

                            if (e.node.value < 1) {
                                e.node.value = 1;
                            }

                            if (e.node.value > len) {
                                e.node.value = len;
                            }

                            this.set('paging.page', Number(e.node.value).toFixed());
                        },
                        checkLink(e, key, val) {
                            e?.original?.preventDefault?.();
                            if (!val) {
                                return;
                            }

                            const link = this.get('fieldsHash')()[key].link;
                            if (link === 'productFromSku') {
                                const p = cl.exec('product.fromSku', val);

                                if (p && p.id) {
                                    cl.routes.go(`/product/${p.id}/details`);
                                }
                                return;
                            }

                            if (link === 'openInShopify') {
                                window.open(`${cl.shopifyBaseUrl()}/admin/products/${val.replace(/\D/g, '')}`, '_blank');
                                return;
                            }

                            if (link === 'goToSale') {
                                cl.routes.go(`/reports/review-sale/${val}`);
                                return;
                            }
                        },
                        checkRowLink(e, data) {
                            e?.original?.preventDefault?.();
                            const rowLink = this.get('rowLink');

                            if (rowLink === 'orderFromId') {
                                cl.routes.go(`/orders/${data.id}/details`);
                            } else if (rowLink === 'shipmentFromId') {
                                cl.routes.go(`/shipments/${data.id}/details`);
                            } else if (rowLink === 'openInShopify') {
                                window.open(`${cl.shopifyBaseUrl()}/admin/products/${data.id.replace(/\D/g, '')}`, '_blank');
                            }
                        },
                        sortBy(e, src) {
                            if (!src.sort) {
                                return;
                            }

                            const cur = this.get('paging.sortBy');

                            if (cur === src.src) {
                                this.toggle('paging.sortAsc');
                            } else {
                                this.set('paging.sortBy', src.src);
                            }
                        },
                        clearFilters() {
                            this.set('filterSelected', {});
                        },
                        openFilter(e, name) {
                            this.set('filterPopup', name);
                            this.set('filterSearch', '');
                        },
                        popupClose(e) {
                            if (_.isUndefined(e) || e.original.target.classList.contains('modal-wrapper')) {
                                this.set('filterPopup', '');
                            }
                        },
                        filterSearch(e) {
                            this.set('filterSearch', e.node.value);
                        },
                        filterSelect(e, name) {
                            const popup = this.get('filterPopup');
                            const key = `filterSelected.${popup}`;
                            const index = this.get(key)?.indexOf?.(name) ?? -1;
                            if (index > -1) {
                                this.splice(key, index, 1);
                                return;
                            }

                            const customFilter = this.get('getHashedField')?.(`${popup}.customFilter`);
                            if (DataTableCustomFilter.isValid(customFilter)) {
                                this.set(key, [name]);
                                return;
                            }

                            this.push(key, name);
                        },
                        search (e) {
                            this.set('paging.search', e.node.value);
                        },
                        clearSearch() {
                            this.set('paging.search', '');
                        },
                        csv() {
                            const selected = this.get('paging.selected');
                            const data = this.get('sorted')();

                            if (_.keys(selected).length) {
                                for (const k in data) {
                                    if (!(data[k].sku in selected)) {
                                        delete data[k];
                                    }
                                }
                            }

                            data.unshift(this.get('fields').map(f=>_.capitalizeAllSplit(f.src)));

                            cl.csv(`export-${_.fileDate()}.csv`, data.map(v => {
                                for (const k in v) {
                                    if (_.isNumber(v[k])) {
                                        v[k] = Number(v[k].toFixed(2));
                                    }
                                }

                                return v;
                            }));
                        },
                        selectRow(e, identifier) {
                            const key = `paging.selected.${identifier}`;
                            if (this.get(key)) {
                                this.unset(key);
                            } else {
                                this.set(key, true);
                            }
                        },
                        selectAll() {
                            const data = this.get('filtered')();
                            const keys = _.keys(this.get('paging.selected'));
                            const identifier = this.get('identifier');
                            const selected = _(data)
                                .map(identifier)
                                .union(keys)
                                .uniq()
                                .reduce((a, key) => {
                                    a[key] = true;
                                    return a;
                                }, {});

                            this.set('paging.selected', selected);
                        },
                        unselectAll() {
                            this.set('paging.selected', {});
                        },
                        selectVisible() {
                            const selected = this.get('paging.selected');
                            const identifier = this.get('identifier');
                            const data = this.get('list')();

                            _(data).map(identifier).value().forEach(key => {
                                selected[key] = true;
                            });

                            this.set('paging.selected', selected);
                        },
                        unselectVisible() {
                            const selected = this.get('paging.selected');
                            const identifier = this.get('identifier');
                            const data = this.get('list')();

                            _(data).map(identifier).value().forEach(key => {
                                delete selected[key];
                            });

                            this.set('paging.selected', selected);
                        },
                        selectableAction() {
                            const action = cl.get(this.get('selectableAction'));

                            if (!_.isFunction(action)) {
                                return;
                            }

                            const selected = _.keys(this.get('paging.selected'));
                            if (selected.length) {
                                action(selected);
                            } else {
                                const identifier = this.get('identifier');
                                action(this.get('data').map(e => e[identifier]));
                            }
                        },
                        refresh() {
                            const refresh = cl.get(this.get('refresh'));
                            if (!_.isFunction(refresh)) {
                                return;
                            }

                            refresh();
                        },
                    })

                    const ds = this.get('defaultSort');
                    if (ds) {
                        this.set('paging.sortBy', ds);

                        if (this.get('defaultSortDirection') === 'desc') {
                            this.set('paging.sortAsc', false);
                        }
                    }

                    const df = this.get('defaultFilter');
                    if (df) {
                        try {
                            for (const key in df) {
                                this.set(`filterSelected.${key}`, df[key]);
                            }
                        } catch (e) {
                            _.log(e);
                        }
                    }

                    this.observe('fields.*', (f)=>{
                        const key = `filterSelected.${f.src}`;

                        if (!_.isArray(this.get(key))) {
                            const arr = [];
                            const customFilter = this.get('getHashedField')?.(`${f.src}.customFilter`);
                            if (DataTableCustomFilter.isValid(customFilter)) {
                                console.log(customFilter.defaultValue);
                                arr.push(...customFilter.defaultValue);
                            }
                            this.set(key, arr);
                        }
                    });

                    let indexedCount = 0
                    this.observe('data', (n) => {
                        if (_.isArray(n) && n.length && n.length !== indexedCount && n[0] !== false) {
                            const self = this;
                            indexedCount = n.length;

                            const index = lunr(function () {
                                this.pipeline.remove(lunr.stemmer)
                                this.pipeline.remove(lunr.stopWordFilter)
                                this.pipeline.remove(lunr.trimmer)

                                for (const field of self.get('fields')) {
                                    if (field.search) {
                                        this.field(field.src, {
                                            boost: field.searchWeight ?? 10,
                                        });
                                    }
                                }
                            });

                            n.forEach(d => {
                                index.add(d);
                            });

                            this.set('paging.searchIndex', index);
                        }
                    })

                    this.observe('paging.search', (n) => {
                        setTimeout(() => {
                            this.get('searchFunc')();
                        }, 0);
                    })
                    document.addEventListener('leftArrow', (e) => {
                        this.fire('prevPage');
                    });

                    document.addEventListener('rightArrow', (e) => {
                        this.fire('nextPage');
                    });

                    const perPage = this.get('perPage');
                    if (perPage) {
                        this.set('paging.perPage', perPage);
                    }
                },
                onrender() {
                    this.set('rendered', true);
                },
                onunrender() {
                    this.set('rendered', false);
                }
            });

            _components.productSelector = Ractive.extend({
                template: cl.templates.productSelector,
                isolated: true,
                append: false,
                twoway: false,
                data: {
                    _, //lodash
                    rendered: false,
                    focus: false,
                    unique: _.uuid(),
                    productSearch: '',
                    productSearchIds: [],
                    searchResults() {
                        const ids = this.get('productSearchIds');
                        const locs = (this.get('from') || 'wh.main').split('.');

                        if (!(this.get('rendered') && this.get('focus') && locs.length === 2 && (ids.length || this.get('productSearch').length > 1))) {
                            return [];
                        }

                        const negs = cl.exec('login.hasPerm', 'transfersNegatives');

                        return _(cl.get('products.all'))
                            .keyBy('id')
                            .at(ids)
                            .filter(e => !!e)
                            .filter(p => negs ? true : p.inventory[locs[0]][locs[1]] > 0)
                            .sortBy('sku')
                            .map(p => {
                                p.qty = p.inventory[locs[0]][locs[1]];
                                return p;
                            })
                            .value();
                    },
                    callback: () => {
                        cl.error('callback not specified')
                    },
                },
                on: {
                    optionsUp(e) {
                        if (!(this.get('rendered') && this.get('focus') && this.get('searchResults')().length > 0)) {
                            return;
                        }

                        handleOptionsUp(document.querySelectorAll(`#search-list-${this.get('unique')} li`));
                    },
                    optionsDown() {
                        if (!(this.get('rendered') && this.get('focus') && this.get('searchResults')().length > 0)) {
                            return;
                        }

                        handleOptionsDown(document.querySelectorAll(`#search-list-${this.get('unique')} li`));
                    },
                    optionsExit() {
                        if (!(this.get('rendered') && this.get('searchResults')().length > 0)) {
                            return;
                        }

                        const el = document.querySelector(`#search-input-${this.get('unique')}`);
                        el.classList.remove('focus');
                        el.blur();
                        this.update('searchResults');
                    },
                    optionsSelect() {
                        if (!(this.get('rendered') && this.get('focus') && this.get('searchResults')().length > 0)) {
                            return;
                        }

                        const opt = document.querySelector(`#search-list-${this.get('unique')} li.active`);

                        if (!opt?.dataset?.id) {
                            return;
                        }

                        this.fire('productSelect', opt.dataset.id);
                        this.fire('optionsExit');
                    },
                    productSelect(e, id) {
                        this.get('callback')(id);
                    },
                    productSearch(e) {
                        this.set('productSearch', e.node.value || '');
                    },
                    search() {
                        const search = this.get('productSearch')
                        if (search.length <= 1) {
                            this.set('productSearchIds', []);
                            this.update();
                            return;
                        }

                        _.defer(() => {
                            const ids = _.map(cl.products.search(search), 'ref');
                            this.set('productSearchIds', ids);
                            this.update('searchResults');
                        });
                    },
                    searchFocus() {
                        this.set('focus', true);
                        if (!this.get('searchResults')().length) {
                            return;
                        }

                        const el = document.querySelector(`#search-list-${this.get('unique')}`);
                        el.classList.remove('hide');
                    },
                    searchBlur(e) {
                        this.set('focus', false);
                    },
                    searchClick(e, id) {
                        this.fire('productSelect', id);
                        const el = document.querySelector(`#search-list-${this.get('unique')}`);
                        el.classList.add('hide');
                    },
                    barcode(e, code) {
                        if (!this.get('rendered')) {
                            return;
                        }

                        cl.products.skuFromBarcode(code, (bc, sku, id) => {
                            this.fire('productSelect', id);
                        });
                    }
                },
                components: cl.components.all,
                oninit() {
                    this.observe('productSearch', () => {
                        this.fire('search');
                    });

                    document.addEventListener("upArrow", e => {
                        this.fire('test');
                        this.fire('optionsUp');
                    });

                    document.addEventListener("downArrow", e => {
                        this.fire('optionsDown');
                    });

                    document.addEventListener("enterFire", e => {
                        this.fire('optionsSelect');
                    });

                    document.addEventListener("escapeFire", e => {
                        this.fire('optionsExit');
                    });

                    document.addEventListener("barcode", e => {
                        this.fire('barcode', e.detail);
                    });
                },
                onrender() {
                    this.set('rendered', true);
                },
                onunrender() {
                    this.set('rendered', false);
                }
            });

            _components.productFieldRow = Ractive.extend({
                template: cl.templates.productFieldRow,
                isolated: true,
                twoway: false,
                data: {
                    _,
                    dayjs,
                    rendered: false,
                    showDateSelect: false,
                    optionsOpen: {},
                    optionsSelected: '',
                    optionsOffset: 0,
                    getOptions() {
                        const val = this.get('value');
                        const filterFunc = (opt) => _.startsWith(opt.toLowerCase(), val.toLowerCase()) && !_.eq(opt.trim(), val);
                        return (this.get('options') || []).filter(filterFunc);
                    }
                },
                components: cl.components.all,
                oninit() {
                    this.on({
                        input(e) {
                            this.set(`optionsOpen.${this.get('src')}`, true);
                            const ev = this.get('inputEvent');
                            if (!ev) return;
                            cl.fire(ev, e);
                        },
                        blur(e) {
                            if ((!_.isNull(e.event.relatedTarget) && !e.event.relatedTarget.classList.contains('options-list-child')) || _.isNull(e.original.relatedTarget)) {
                                this.set(`optionsOpen.${this.get('src')}`, false);
                            }

                            const ev = this.get('blurEvent');
                            if (!ev) return;
                            cl.fire(ev, e);
                        },
                        dateSelectClear(e) {
                            const ev = this.get('clearEvent');
                            if (!ev) return;
                            cl.fire(ev, e);
                        },
                        dateSelectOpen() {
                            this.set('showDateSelect', true);
                        },
                        dateSelectClose(e) {
                            if (_.isUndefined(e) || e.original.target.classList.contains('modal-wrapper')) {
                                this.set('showDateSelect', false);
                            }
                        },
                        dateChange(...args) {
                            const ev = this.get('dateChangeEvent');
                            if (!ev) return;
                            cl.fire(ev, ...args);
                        },
                        optionsClick(e, val) {
                            const ev = this.get('inputEvent');
                            if (!ev) return;
                            this.set('value', val);
                            this.set(`optionsOpen.${this.get('src')}`, false);
                            cl.fire(ev, e);
                        },
                        optionsClose() {
                            if (!this.get('rendered')) return;

                            const open = this.get(`optionsOpen.${this.get('src')}`);
                            if (!open) return;

                            this.set(`optionsOpen.${this.get('src')}`, false);
                        },
                        optionsSelect() {
                            if (!this.get('rendered')) return;

                            const open = this.get(`optionsOpen.${this.get('src')}`);
                            if (!open) return;

                            const selected = this.get('optionsSelected');
                            if (!selected) return;

                            const ev = this.get('inputEvent');
                            if (!ev) return;
                            this.set(`optionsOpen.${this.get('src')}`, false);
                            cl.fire(ev, null, this.get('src'), selected);
                        },
                        optionsUp() {
                            if (!this.get('rendered')) return;

                            const open = this.get(`optionsOpen.${this.get('src')}`);
                            if (!open) return;

                            const selected = this.get('optionsSelected');
                            const options = this.get('getOptions')();
                            if (!options.length) return;
                            if (!selected) {
                                this.set('optionsSelected', _.last(options));
                                return;
                            }

                            const index = _.indexOf(options, selected);
                            if (index === -1) {
                                this.set('optionsSelected', _.last(options));
                                return;
                            }

                            this.set('optionsSelected', _.nth(options, (index - 1) % options.length));
                            this.set('optionsOffset', (index - 1) % options.length);
                        },
                        optionsDown() {
                            if (!this.get('rendered')) return;

                            const open = this.get(`optionsOpen.${this.get('src')}`);
                            if (!open) return;

                            const selected = this.get('optionsSelected');
                            const options = this.get('getOptions')();
                            if (!options.length) return;
                            if (!selected) {
                                this.set('optionsSelected', _.head(options));
                                this.set('optionsOffset', 0);
                                return;
                            }

                            const index = _.indexOf(options, selected);
                            if (index === -1) {
                                this.set('optionsSelected', _.head(options));
                                this.set('optionsOffset', 0);
                                return;
                            }

                            this.set('optionsSelected', _.nth(options, (index + 1) % options.length));
                            this.set('optionsOffset', (index + 1) % options.length);
                        }
                    });

                    document.addEventListener('escapeFire', (e) => {
                        this.fire('optionsClose');
                    });

                    document.addEventListener('enterFire', (e) => {
                        this.fire('optionsSelect');
                    });

                    document.addEventListener('upArrow', (e) => {
                        this.fire('optionsUp');
                    });

                    document.addEventListener('downArrow', (e) => {
                        this.fire('optionsDown');
                    });

                    this.observe('value', (newVal, oldVal) => {
                        if (!newVal) {
                            return;
                        }

                        if (!(this.get('options') || []).length) {
                            return;
                        }

                        this.set('optionsOffset', 0);
                    });
                },
                onrender() {
                    this.set('rendered', true);
                },
                onunrender() {
                    this.set('rendered', false);
                }
            });
        },
    };

    return {
        init: _i.init,
        all: _components,
    };
})(window.cl);