window.cl = window.cl || {}

window.cl.logSearch = ((cl) => {
    const _on = {
        logSearchSearch(e) {
            cl.set('logSearch.searchVal', e.node.value)
        },
        logSearchLimit(e) {
            const v = e.node.value
            if (!isNaN(v)) {
                cl.set('logSearch.limit', Number(v))
            }
        }
    };
    const _set = {
        'logSearch.searchVal': '',
        'logSearch.limit': 10,
        'logSearch.results': [],
        'logSearch.list'() {
            return cl.get('logSearch.results');
        }
    };
    const _i = {
        init() {
            cl.on(_on)
            cl.set(_set)

            document.addEventListener("enterFire", (e) => {
                _.defer(_i.searchFunc, e.detail);
            });
        },
        searchFunc(e) {
            if (cl.routes.get() !== 'logSearch') {
                return;
            }
            if (cl.exec('loading')) {
                return;
            }

            const val = cl.get('logSearch.searchVal')
            if (val.length < 3) {
                cl.error('Search Value Too Short');
                return;
            }

            cl.send('logSearch', {
                search: val,
                limit: cl.get('logSearch.limit'),
            })
        },
    };

    return {
        init: _i.init,
    };
})(window.cl)