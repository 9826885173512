import { isUndefined } from 'lodash';

const handleOptionsUp = els => {
    if (isUndefined(els?.length) || els.length <= 0) {
        return;
    }

    if (els.length === 1) {
        els[0].classList.add('active');
        return;
    }

    const found = [...els].findIndex(el => el.classList.contains('active'));
    els.forEach((el) => el.classList.remove('active'));

    if (found <= -1) {
        els[0].classList.add('active');
        return;
    }

    const offset = (found - 1) % els.length === -1 ? els.length - 1 : (found - 1) % els.length;
    els[offset].classList.add('active');
    if (els.length <= 5) {
        return;
    }

    els.forEach((el, i) => {
        if (i < (offset - 3)) {
            el.classList.add('hide');
            return;
        }

        el.classList.remove('hide');
    });
};

const handleOptionsDown = els => {
    if (isUndefined(els?.length) || els.length <= 0) {
        return;
    }

    if (els.length === 1) {
        els[0].classList.add('active');
        return;
    }

    const i = [...els].findIndex(el => el.classList.contains('active'));
    els.forEach((el) => el.classList.remove('active'));

    if (i <= -1) {
        els[0].classList.add('active');
        return;
    }

    const offset = (i + 1) % els.length;
    els[offset].classList.add('active');
    if (els.length <= 5) {
        return;
    }

    els.forEach((el, i) => {
        if (i < (offset - 3)) {
            el.classList.add('hide');
            return;
        }

        el.classList.remove('hide');
    });
};

export {
    handleOptionsUp,
    handleOptionsDown,
};