import { error } from './dialog';

const autoPrint = (html, title) => {
    const nw = window.open('', title);

    if (!nw) {
        const msg = `<div style="max-width:35ch"><strong>Please Allow Popups From This Site</strong><br><br>There should be an icon with a red x on the right side of the address bar. Click it and select "Always allow pop-ups and redirects from ${window.location.origin}" and click done.<br><br></div>`;
        error(msg);
        return;
    }

    nw.document.write(html);
    nw.document.close();
    nw.focus();
    nw.print();
};

export {
    autoPrint,
};