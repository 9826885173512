window.cl = window.cl || {};

window.cl.shipments = ((cl) => {
    const _on = {
        shipmentsNew(e) {
            e?.original?.preventDefault?.();
            cl.routes.go('/shipments/new');
        },
    };

    const _set = {
        'shipments.all': [],
        'shipments.fields': [{
            src: 'id',
            hide: true,
        },
        {
            src: 'created',
            filter: false,
            sort: true,
            search: false,
            type: 'date',
        },
        {
            src: 'orderId',
            filter: false,
            sort: true,
            search: true,
            type: 'caps',
        },
        {
            src: 'po',
            label: 'PO#',
            filter: false,
            sort: true,
            search: true,
        },
        {
            src: 'arriving',
            filter: false,
            sort: true,
            search: false,
            type: 'date',
        },
        {
            src: 'supplier',
            filter: true,
            sort: true,
            search: true,
            type: 'caps',
        },
        {
            src: 'status',
            filter: true,
            sort: true,
            search: true,
            type: 'caps',
        },
        {
            src: 'units',
            filter: false,
            sort: true,
            search: false,
        }],
        'shipments.productsReceived'(orderId, productId) {
            if (_.isUndefined(orderId) && _.isUndefined(productId)) {
                return 0;
            }

            const s = cl.get('shipments.all').filter(s => s.orderId === orderId);
            return s.reduce((a, v) => {
                a += v.products.reduce((aa, vv) => {
                    if (vv.productId === productId) {
                        aa += vv.qty;
                    }

                    return aa;
                }, 0);

                return a;
            }, 0);
        },
        'shipments.lookup': {},
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);
        },
        async load(shipments = []) {
            await cl.data.allLoaded();
            await cl.orders.loaded();

            const s = shipments.map(_i.addOrderData);
            _i.setLookup(s);
            cl.set('shipments.all', s);
        },
        addOrderData(shipment = {}) {
            shipment.units = (shipment.products || []).reduce((a, p) => {
                a += p.qty;
                return a;
            }, 0);

            const order = cl.exec('order.fromId', shipment.orderId);
            shipment.po = order?.po ?? shipment.po;
            shipment.arriving = order?.arriving ?? shipment.arriving;
            shipment.supplier = order?.supplier ?? shipment.supplier;

            return shipment;
        },
        update(type, data) {
            const shipment = _i.addOrderData(data);

            if (type === 'UPDATE' || type === 'INSERT') {
                const i = cl.exec('shipment.indexFromId', data.id);
                if (i > -1) {
                    cl.splice('shipments.all', i, 1, shipment);
                    return;
                }

                cl.push('shipments.all', shipment);
                _i.updateLookup(shipment);
            }

            if (type === 'DELETE') {
                let i = cl.exec('shipment.indexFromId', shipment.id);
                if (i <= -1) {
                    return;
                }

                _i.deleteLookup(shipment.id);
                cl.splice('shipments.all', i, 1);
            }
        },
        updateData(data) {
            const shipment = _i.addOrderData(data);
            const i = cl.exec('shipment.indexFromId', shipment.id);

            if (i > -1) {
                cl.splice('shipments.all', i, 1, shipment);
            }
        },
        updateClient(data) {
            console.log(data);
            data.products.forEach(p => {
                const index = cl.exec('product.indexFromId', p.id);
                if (index <= -1) {
                    return;
                }

                const product = cl.get(`products.all.${index}`);
                if (!product) {
                    return;
                }

                if (_.bool(product?.track)) {
                    cl.increment(`products.all.${index}.inventory.${data.location}`, p.qty);
                }

                if (Number(product?.price) !== Number(p.price)) {
                    cl.set(`products.all.${index}.price`, p.price);
                }

                if (Number(product?.cost) !== Number(p.cost)) {
                    cl.set(`products.all.${index}.cost`, p.cost);
                }

            });
        },
        setLookup(shipments = []) {
            const lookup = {};

            shipments.forEach((o, i) => {
                lookup[`ref_${o.id}`] = i;
            })

            cl.set('shipments.lookup', lookup);
        },
        updateLookup(shipment) {
            const index = _i.getIndex(shipment);
            cl.set(`shipments.lookup.ref_${shipment.id}`, index);
        },
        deleteLookup(id) {
            cl.unset(`shipments.lookup.ref_${id}`);
        },
        getIndex(id) {
            if (_.isUndefined(id)) {
                return -1;
            }

            const lookup = cl.get(`shipments.lookup.ref_${id}`);
            if (lookup > -1) {
                return lookup;
            }

            const shipments = cl.get('shipments.all');
            if (shipments.length === 0) {
                return -1;
            }

            const index = shipments.findIndex(s => s.id === String(id));
            if (index > -1) {
                cl.set(`shipments.lookup.ref_${id}`, index);
            }

            return index;
        },
    };

    return {
        init: _i.init,
        load: _i.load,
        update: _i.update,
        updateData: _i.updateData,
        updateClient: _i.updateClient,
        getIndex: _i.getIndex,
    };
})(window.cl);