window.cl = window.cl || {};

window.cl.templates = (() => {
    const templateNames = [
        '403',
        '404',
        'blank',
        'changeUserPassword',
        'changeUserPermissions',
        'dataTable',
        'help',
        'home',
        'inventory',
        'inventoryBalance',
        'inventoryBalancePrint',
        'inventoryBalancePrintAll',
        'inventoryList',
        'inventoryNew',
        'inventoryPlannerOrder',
        'inventoryScanList',
        'inventorySnapshot',
        'ipRestriction',
        'layout',
        'logSearch',
        'login',
        'lowInventory',
        'mdl',
        'menuItems',
        'newUser',
        'order',
        'orders',
        'printProducts',
        'printTransferRequests',
        'product',
        'productFieldRow',
        'productUpload',
        'products',
        'productsAddToGroup',
        'productsBulkUpdate',
        'productsAddBarcodes',
        'productsAddWeight',
        'productSelector',
        'rawData',
        'receiving',
        'registerSession',
        'reports',
        'reportsBarcodes',
        'reportsNotFoundSkus',
        'reportsOldProducts',
        'reportsShopNotWeb',
        'reportsWebNotShop',
        'reviewSales',
        'reviewSale',
        'session',
        'sessions',
        'settings',
        'shipment',
        'shipmentNew',
        'shipments',
        'tests',
        'transfer',
        'transferNewRequest',
        'transferNewInstant',
        'transferRequest',
        'transferRequests',
        'transfers',
        'unsold',
        'user',
        'users',
        'websitePrice',
    ];

    return templateNames.reduce((obj, name) => {
        obj[name] = require(`../templates/${name}.html`);
        return obj;
    }, {});
})();