window.cl = window.cl || {};

window.cl.inventoryPlanner = ((cl) => {
    const _on = {
        inventoryPlannerRefreshOrder() {
            _i.getOrder();
        },
    };
    const _set = {
        inventoryPlannerOrder: {},
    };
    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);
        },
        async getOrder() {
            await cl.data.allLoaded();

            if (cl.routes.get() !== 'inventoryPlannerOrder') {
                return;
            }

            const id = cl.get('params.id');
            if (!id) {
                return;
            }

            cl.send('inventoryPlannerGetOrder', id);
        },
        loadOrder(order) {
            if (!order) {
                return;
            }

            order.items = () => {
                return _.sortBy(_.uniq([...Object.keys(order.onOrder ?? {}), ...Object.keys(order.received ?? {})]), i => i.replace(/\D/g, ''));
            }

            cl.set('inventoryPlannerOrder', order);
        },
    };

    return {
        init: _i.init,
        getOrder: _i.getOrder,
        loadOrder: _i.loadOrder,
    };
})(window.cl);