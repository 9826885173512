window.cl = window.cl || {}

window.cl.csv = (filename, rows) => {
    const processRow = row => {
        return row
            .map(e => e === null || _.isUndefined(e) ? '' : e.toString())
            .map(e => e instanceof Date ? e.toLocaleString() : e)
            .map(e => e.replace(/"/g, '""'))
            .map(e => e.search(/("|,|\n)/g) >= 0 ? ('"' + e + '"') : e)
            .join(',');
    };

    if (rows.length > 0) {
        const csvString = rows
            .map(e => Object.values(e))
            .map(processRow).join('\n');

        const blob = new Blob([csvString], { type:'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
            return;
        }

        const link = document.createElement('a')

        if (link.download === undefined) {
            return;
        }
        // feature detection
        // Browsers that support HTML5 download attribute
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', filename);
        link.style = 'visibility:hidden';
        document.body.appendChild(link);
        if (_.isFunction(link.click) && !_.isNull(link.click)) {
            link.click();
        }
        document.body.removeChild(link);
    }
}