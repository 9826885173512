import request from 'superagent';
import isUUID from './utilities/isUUID';

window.cl = window.cl || {};

window.cl.settings = ((cl) => {
    const _barcode = 'barcodeScanningEnabled';
    const _tabs = 'TabWarningEnabled';

    const _set = {
        'settings.barcode'() {
            return cl.ls.get(_barcode);
        },
        'settings.tabs'() {
            return cl.ls.get(_tabs);
        },
        'settings.disableProductCaseCheck': false,
        'settings.registerSession': {},
    };

    const _on = {
        reloadAllClients() {
            cl.send('reloadAll');
        },
        updateAllPrinters() {
            cl.send('updateAllPrinters');
        },
        updatePrinter() {
            _i.updatePrinter();
        },
        toggleBarcode() {
            cl.ls.set(_barcode, !cl.ls.get(_barcode));
            cl.update('settings.barcode');
        },
        toggleTabs() {
            cl.ls.set(_tabs, !cl.ls.get(_tabs));
            cl.update('settings.tabs');
        },
        toggleProductCaseCheck() {
            cl.toggle('settings.disableProductCaseCheck');
            setTimeout(() => {
                cl.set('settings.disableProductCaseCheck', false);
            }, 9e5/* 15 minutes */);
        },
        testPrint() {
            request
                .get('https://receipt.cleanline.ninja/test.php')
                .timeout(7e3)
                .end((err, res) => {
                    if (_.isUndefined(res)) {
                        cl.error('Error Sending Receipt To Printer.');
                        return;
                    }

                    const json = JSON.parse(res.text);
                    if (err || !res.ok || json.failure) {
                        cl.error('Error Sending Receipt To Printer.');
                        _.log('Receipt Error:', err, res);
                        return;
                    }

                    cl.success('Test Print Sent');
                    _.log('Receipt Data:', json.post);
                });
        },
        updateWebsitePrices() {
            cl.send('updateWebsitePrices');
        },
        async getRegisterSession(e) {
            e?.original?.preventDefault?.();
            const res = await cl.prompt('Register Session UUID?');
            if (res.buttonClicked !== 'ok') {
                return;
            }

            const uuid = res.inputValue.trim();
            if (!isUUID(uuid)) {
                cl.error(`Invalid UUID: ${uuid}`);
                return;
            }

            cl.routes.go(`/register-session/${uuid}`);
        },
        debugPOS() {
            cl.send('debugPOS', cl.get('pos.sessions.all'));
        },
        debugAllRegisters() {
            cl.send('debugAllRegisters');
        },
        throwError() {
            throw new Error(`Test Error From ${cl.get('login.user')} @ ${Date.now()}`);
        },
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);

            if (_.isUndefined(cl.ls.get(_barcode))) {
                cl.ls.set(_barcode, true);
                cl.update('settings.barcode');
            }

            if (_.isUndefined(cl.ls.get(_tabs))) {
                cl.ls.set(_tabs, true);
                cl.update('settings.tabs');
            }
        },
        barcode() {
            return cl.ls.get(_barcode);
        },
        updatePrinter() {
            ['http://192.168.0.222', 'http://192.168.0.223', 'http://192.168.86.222', 'https://receipt.cleanline.ninja'].map(v => {
                request
                    .get(`${v}/update.php`)
                    .timeout(7e3)
                    .end((err, res) => {
                        if (_.isUndefined(res)) {
                            cl.error(`Error Sending Receipt To Printer: ${v}`);
                            return;
                        }

                        const json = JSON.parse(res.text);
                        if (err || !res.ok || json.failure) {
                            cl.error(`Error Updating Printer: ${v}`);
                            _.log('Printer Update Error:', err, res);
                            return;
                        }

                        _.log('Printer Update Response:', json.res);
                        cl.log(`${json.res} - ${v}`);
                    });
            });
        },
        getRegisterSession() {
            const uuid = cl.get('params').uuid;
            cl.send('getRegisterSession', { uuid });
        },
        async loadRegisterSession(data) {
            await cl.data.allLoaded();
            cl.set('settings.registerSession', data);
        },
        tabs() {
            cl.ls.set(_tabs, !cl.ls.get(_tabs));
        },
        debugAll() {
            cl.send('debugPOS', cl.get('pos.sessions.all'));
        },
    };

    return {
        init: _i.init,
        barcode: _i.barcode,
        updatePrinter: _i.updatePrinter,
        getRegisterSession: _i.getRegisterSession,
        loadRegisterSession: _i.loadRegisterSession,
        tabs: _i.tabs,
        debugAll: _i.debugAll,
    };
})(window.cl);

window.cl.tabs = cl.settings.tabs;