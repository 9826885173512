const CSVtoArray = (str, delimiter = ',') => {
    const reg = "(\\" + delimiter + "|\\r?\\n|\\r|^)" + // delimiters
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + // quoted fields.
                "([^\"\\" + delimiter + "\\r\\n]*))"; // standard fields.

    const patterns = new RegExp(reg, 'gi');

    let data = [[]];
    let matches = null;

    while (matches = patterns.exec(str)) {
        const matchedDelimiter = matches[1];

        if (matchedDelimiter.length && matchedDelimiter !== delimiter) {
            data.push([]);
        }

        let matchedValue;
        if (matches[2]) {
            matchedValue = matches[2].replace(new RegExp("\"\"", 'g'), "\"");
        } else {
            matchedValue = matches[3];
        }

        data[data.length - 1].push(matchedValue);
    }

    return data;
}


export {
    CSVtoArray,
};