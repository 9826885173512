import { isIP } from 'is-ip';

window.cl = window.cl || {};

window.cl.ipRestriction = ((cl) => {
    const _on = {
        ipRestrictionInput(e, field) {
            cl.set(`ipRestriction.new.${field}`, e.node.value);
        },
        ipRestrictionAdd(e) {
            const ip = cl.get('ipRestriction.new.ip');
            const note = cl.get('ipRestriction.new.note');

            if (!isIP(ip)) {
                cl.error(`Invalid IP Address: ${ip}`);
                return;
            }

            if (!note) {
                cl.error('IP Address Requires Note');
                return;
            }

            cl.send('ipRestrictionAdd', { ip, note });
            cl.set('ipRestriction.new.ip',  '');
            cl.set('ipRestriction.new.note', '');
        },
        ipRestrictionDelete(e, id) {
            cl.send('ipRestrictionDelete', id);
        },
    };

    const _set = {
        'ipRestriction.all': [],
        'ipRestriction.list'() {
            return cl.get('ipRestriction.all');
        },
        'ipRestriction.new.ip': '',
        'ipRestriction.new.note': '',
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);
        },
        get() {
            cl.send('ipRestrictionGet');
        },
        load(data = []) {
            cl.set('ipRestriction.all', data);
        },
        addSuccess(data = []) {
            cl.success('IP Successfully Added');
            cl.set('ipRestriction.all', data);
        },
        deleteSuccess(data = []) {
            cl.success('IP Successfully Deleted');
            cl.set('ipRestriction.all', data);
        },
    };

    return {
        init: _i.init,
        get: _i.get,
        load: _i.load,
        addSuccess: _i.addSuccess,
        deleteSuccess: _i.deleteSuccess,
    };
})(window.cl);