window.cl = window.cl || {};

window.cl.tests = ((cl) => {
    const _on = {
        testsLatency() {
            cl.set('tests.latency', 'sending...');
            cl.send('getDBLatency');
        },
    }

    const _set = {
        'tests.latency': 'results go here',
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);
        }
    };

    return {
        init: _i.init,
    };
})(window.cl);