import SessionHelper from './sessionHelper';
import dayjs from './utilities/dayjs';
import DataTableCustomFilter from './types/dataTableCustomFilter';

window.cl = window.cl || {};

window.cl.reports = ((cl) => {
    const _on = {
        fetchReportReviewSales() {
            _i.loadReviewSales();
        },
        fetchReportWebsitePrice() {
            _i.getWebsitePrice();
        },
        reportReviewSalesTimeSelectOpen() {
            cl.set('reports.reviewSales.timeSelect', true);
        },
        reportReviewSalesTimeSelectClose(e) {
            if (!_.isUndefined(e) && !e.original.target.classList.contains('modal-wrapper')) {
                return;
            }

            cl.set('reports.reviewSales.timeSelect', false);
            _i.loadReviewSales();
        },
        reportReviewSalesTimeChange(e, unit, dir) {
            if (!['add', 'subtract'].includes(dir)) {
                return cl.error(`Invalid direction ${dir}`);
            }

            if (!['day', 'month', 'year'].includes(unit)) {
                return cl.error(`Invalid unit ${unit}`);
            }

            cl.set('reports.reviewSales.time',
                dayjs(cl.get('reports.reviewSales.time')).clone()[dir](1, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            );
        },
        reportReviewSalesLocation(e, loc) {
            e.original.preventDefault();
            cl.set('reports.reviewSales.filter', loc);
        },
        reportReviewSalesSetSelected(e, id) {
            if (e.original.target.innerText === 'print') {
                return;
            }
            const key = 'reports.reviewSales.selected';
            cl.set(key, cl.get(key) === id ? '' : id);
        },
        reportRawDataPoint(e) {
            cl.set('reports.raw.point', e.node.value);
        },
        reviewRawDataFetch() {
            cl.send('reportRawData', {
                point: cl.get('reports.raw.point'),
                from: cl.dayjs(cl.get('reports.raw.from')).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                to: cl.dayjs(cl.get('reports.raw.to')).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            })
        },
        reportRawTimeOpen(e, type) {
            cl.set(`reports.raw.${type}Open`, true);
        },
        reportRawTimeClose(e, type) {
            if (_.isUndefined(e) || e.original.target.classList.contains('modal-wrapper')) {
                cl.set(`reports.raw.${type}Open`, false);
            }
        },
        reportRawTimeChange(e, type, unit, dir) {
            if (!['add', 'subtract'].includes(dir)) {
                return cl.error(`Invalid direction ${dir}`);
            }

            if (!['day', 'month', 'year'].includes(unit)) {
                return cl.error(`Invalid unit ${unit}`);
            }

            cl.set(`reports.raw.${type}`,
                dayjs(cl.get(`reports.raw.${type}`)).clone()[dir](1, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            );
        },
        async reviewSalesReprintReceipt(e, uuid) {
            const res = await cl.confirm({
                message: "<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want to reprint this receipt?</div>",
                ok: 'Reprint',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const sale = _.cloneDeep(_.find(cl.get('reports.reviewSales.all'), ['uuid', uuid]));
            sale.connected = true;
            sale.items = _.filter(sale.items, ['deleted', false]);
            sale.ts = cl.dayjs(sale.completedTime).format('x');

            sale.items.map(i => {
                const prod = cl.exec('product.fromSku', i.sku) || {};
                i.name = prod.name;
                if (prod.color) {
                    i.name += ` - ${prod.color}`;
                }
                if (prod.size) {
                    i.name += ` - ${prod.size}`;
                }
                i.price = Number(_.moneyRaw(i.price || prod.price));
                return i;
            });

            require('superagent')
                .post('https://receipt.cleanline.ninja/index.php')
                .timeout(7000)
                .send(sale || {})
                .end((err, res) => {
                    if (_.isUndefined(res)) {
                        cl.error('Error Sending Receipt To Printer.')
                        return;
                    }

                    const json = JSON.parse(res.text);

                    if (!err && res.ok && !json.failure) {
                        _.log('Receipt Data:', json.post);
                        return;
                    }

                    cl.error('Error Sending Receipt To Printer.');
                    _.log('Receipt Error:', err, res);
                });
        },
        reviewSalesAddNote(e, uuid) {
            e?.original?.preventDefault?.();
            const sale = _.find(cl.get('reports.reviewSales.all'), ['uuid', uuid]);

            cl.prompt({
                message: 'Enter Note:',
                okFunc(val) {
                    const i = _.findIndex(cl.get('reports.reviewSales.all'), ['uuid', uuid]);
                    cl.set(`reports.reviewSales.all.${i}.note`, val.trim());

                    cl.send('reportAddSaleNote', {
                        note: val.trim(),
                        uuid,
                    });
                },
                default: sale.note,
            });
        },
        async reviewSalesReverse(e, uuid) {
            e?.original?.preventDefault?.();

            const sale = _.find(cl.get('reports.reviewSales.all'), ['uuid', uuid]);
            if (!sale) {
                cl.error(`Sale ${uuid} not found`);
                return;
            }

            const res = await cl.confirmError(`Are you sure you want create a reverse of this sale?<br><strong>This action cannot be undone.</strong><br><br>Item Qty: ${sale.items.map(i=>i.qty).reduce((a, v) => a + v, 0)}<br>Unique Items: ${sale.items.length}<br>Total: ${_.money(sale.total)}<br>Cashier: ${sale.cashier}`);
            if (res.buttonClicked !== 'ok') {
                return;
            }

            cl.send('reportReverseSale', uuid);
        },
        reportReviewSalesOpenOrder(e, gid, orderName) {
            if (!gid) {
                return;
            }

            const base =  Math.abs(Number(String(orderName).replace(/[^\d\.]/g, ''))) > 10000 ? window.cl.shopifyProdBaseAdminUrl : window.cl.shopifyDevBaseAdminUrl;
            window.open(`${base}/admin/orders/${gid.replace(/\D/g, '')}`, '_blank');
        },
        reportReviewSalesGoToProduct(e, sku) {
            e?.original?.preventDefault?.();
            const p = cl.exec('product.fromSku', sku);
            if (p?.id) {
                cl.routes.go(`/product/${p.id}/details`);
            }
        },
        refreshReportReviewSale() {
            _i.loadReviewSale();
        },
        reviewSaleGoToProduct(e, sku) {
            e?.original?.preventDefault?.();
            const p = cl.exec('product.fromSku', sku);
            if (p?.id) {
                cl.routes.go(`/product/${p.id}/details`);
            }
        },
        reviewSaleGoToStore(e, id) {
            cl.send('reportsShopifyOrderIDFromNumber', id);
        },
        fetchReportUnsoldProducts() {
            cl.unsold.fetch();
        },
        reportUnsoldChange(e) {
            cl.set('reports.unsold.interval', e.node.value);
        },
        reportInventorySnapshotFetch() {
            const time = cl.get('reports.inventorySnapshot.time');
            const date = cl.dayjs(time).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            cl.send('reportInventorySnapshot', date);
        },
        reportInventorySnapshotTimeChange(e, unit, dir) {
            if (!['add', 'subtract'].includes(dir)) {
                return cl.error(`Invalid direction ${dir}`);
            }

            if (!['day', 'month', 'year'].includes(unit)) {
                return cl.error(`Invalid unit ${unit}`);
            }

            cl.set('reports.inventorySnapshot.time',
                dayjs(cl.get('reports.inventorySnapshot.time')).clone()[dir](1, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
        },
        reportInventorySnapshotTimeClose(e) {
            if (_.isUndefined(e) || e.original.target.classList.contains('modal-wrapper')) {
                cl.set('reports.inventorySnapshot.timeOpen', false);
            }
        },
        reportsWebNotShopSetTab(e, tab) {
            cl.set('reports.webNotShop.tab', tab);
        },
    };

    const _set = {
        'reports.reviewSales.all': [],
        'reports.reviewSales.list'() {
            const locationFilter = s => s.location === cl.get('reports.reviewSales.filter');
            const addTimestamp = s => {
                s.completedTimeStamp = cl.dayjs(s.completedTime).format('x');

                return s;
            };
            const addDiscounts = s => {
                s.discounts = (s.items || []).reduce((a, i) => {
                    const p = _.cloneDeep(cl.exec('product.fromId', i.id));
                    a += SessionHelper.lineDiscount(i, p);

                    return a;
                }, 0);

                return s;
            };

            return _(cl.get('reports.reviewSales.all'))
                .filter(locationFilter)
                .map(addTimestamp)
                .map(addDiscounts)
                .orderBy('completedTimeStamp', 'desc')
                .value();
        },
        'reports.reviewSales.time': dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        'reports.reviewSales.timeSelect': false,
        'reports.reviewSales.filter': 'ss.main',
        'reports.reviewSales.selected': '',
        'reports.reviewSales.paymentLine'(p) {
            return (p||[]).map(p=>`${_.capitalizeAll(p.method.replace('-', ' '))}: ${_.money(p.amount)}`).join(', ')||'No Payment';
        },
        'reports.reviewSales.total'() {
            return cl.exec('reports.reviewSales.list').reduce((t,i)=>t+=i.total, 0);
        },
        'reports.reviewSales.totalFor'(loc) {
            const filtered = cl.get('reports.reviewSales.all').filter(s => s.location === loc);
            const total = filtered.reduce((t,i) => t += i.total, 0);

            const giftCardTotal = filtered
                .reduce((t,i) => t += (i.payments || []).reduce((a,p) => {
                    if (p.method.toLowerCase().includes('gift')) {
                        return a += p.amount;
                    }
                    return a;
                }, 0), 0);

            const tradeInTotal = filtered
                .reduce((t,i) => t += (i.payments || []).reduce((a,p) => {
                    if (p.method.toLowerCase().includes('trade')) {
                        return a += p.amount;
                    }
                    return a;
                }, 0), 0);

            return _.money(total - giftCardTotal, tradeInTotal);
        },
        'reports.reviewSales.qtyFor'(loc) {
            return _(cl.get('reports.reviewSales.all'))
                .filter(s => s.location === loc)
                .value()
                .length;
        },
        'reports.reviewSales.paymentsTotal'() {
            const loc = cl.get('reports.reviewSales.filter');
            const methods = {
                'credit': 0,
                'cash': 0,
                'gift': 0,
                'check': 0,
                'trade': 0,
            };

            return cl.get('reports.reviewSales.all')
                .filter(s => s.location === loc)
                .reduce((a, s) => {
                    let diff = s.total;
                    let cash = 0;
                    s.payments && s.payments.forEach(p => {
                        const method = p.method
                            .replace('-card', '')
                            .replace('-in', '');

                        if (!(method in a)) {
                            return;
                        }
                        a[method] += p.amount;
                        if (p.method !== 'cash') {
                            diff -= p.amount;
                            return;
                        }
                        cash += p.amount;
                    });

                    const change = cash - diff;
                    if (loc !== 'wh.main' && change) {
                        a['cash'] -= change;
                    }

                    return a;
                }, methods);
        },
        'reports.raw.from': dayjs().utc().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        'reports.raw.fromOpen': false,
        'reports.raw.to': dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        'reports.raw.toOpen': false,
        'reports.raw.point': 'sales',
        'reports.websitePrice.all': [],
        'reports.websitePrice.list'() {
            const filters = [];

            filters.push(p => p.wprice != p.pprice);

            if (cl.get('reports.websitePrice.ignoreCents')) {
                filters.push(p => Math.abs(p.wprice - p.pprice) > 0.06);
            }

            if (cl.get('reports.websitePrice.ignoreOutOfStock')) {
                filters.push(p => {
                    const prod = cl.exec('product.fromId', p.id);
                    const ss = _.get(prod, 'inventory.ss.main') || 0;
                    const cb = _.get(prod, 'inventory.cb.main') || 0;
                    const wh = _.get(prod, 'inventory.wh.main') || 0;
                    return !(ss === 0 && cb === 0 && wh === 0);
                })
            }

            return _.filterAll(cl.get('reports.websitePrice.all'), ...filters);
        },
        'reports.websitePrice.ignoreCents': true,
        'reports.websitePrice.ignoreOutOfStock': true,
        'reports.inventorySnapshot.timeOpen': false,
        'reports.inventorySnapshot.time': dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        'reports.oldProducts.all': [],
        'reports.oldProducts.fields': [
            {
                src: 'type',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'brand',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'sku',
                filter: false,
                sort: true,
                search: true,
                link: 'productFromSku',
            },
            {
                src: 'name',
                sort: true,
                search: true,
            },
            {
                src: 'lastReceived',
                sort: true,
                search: false,
                type: 'date',
                customFilter: new DataTableCustomFilter('blanks', val => typeof val === 'string' && val?.trim?.()?.length > 0),
            },
            {
                src: 'lastSold',
                sort: true,
                search: false,
                type: 'date',
                customFilter: new DataTableCustomFilter('blanks', val => typeof val === 'string' && val?.trim?.()?.length > 0),
            },
            {
                src: 'created',
                sort: true,
                search: false,
                type: 'date',
            },
            {
                src: 'parent',
                label: 'Shopify ID',
                link: 'openInShopify',
                customFilter: new DataTableCustomFilter('blanks', val => typeof val === 'string' && val?.trim?.()?.length > 0),
            },
        ],
        async 'reports.oldProducts.refresh'() {
            _i.getOldProducts();
        },
        async 'reports.oldProducts.action'(skus = []) {
            const res = await cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want delete ${skus.length} products?</div>`,
                ok: 'Delete Products',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            cl.send('deleteProducts', { skus });
        },
        'reports.shopNotWeb.all': [],
        'reports.shopNotWeb.fields': [
            {
                src: 'department',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'supplier',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'brand',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'group',
                label: 'Type',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'sku',
                filter: false,
                sort: true,
                search: true,
                link: 'productFromSku',
            },
            {
                src: 'name',
                sort: true,
                search: true,
            },
            {
                src: 'size',
                sort: true,
                search: true,
            },
            {
                src: 'color',
                sort: true,
                search: true,
            },
            {
                src: 'price',
                sort: true,
                search: false,
                type: 'money',
            },
            {
                src: 'cost',
                sort: true,
                search: false,
                type: 'money',
                hide: true,
            },
            {
                src: 'wh',
                label: 'WH',
                sort: true,
                search: false,
            },
            {
                src: 'ss',
                label: 'SS',
                sort: true,
                search: false,
            },
            {
                src: 'cb',
                label: 'CB',
                sort: true,
                search: false,
            },
            {
                src: 'total',
                sort: true,
                search: false,
                filterZeros: true,
                filterZerosDefault: true,
                customFilter: new DataTableCustomFilter('zeros', val => !val, 'hide'),
            },
            {
                src: 'lastReceived',
                sort: true,
                search: false,
                type: 'date',
            },
            {
                src: 'created',
                sort: true,
                type: 'date',
                hide: true,
            },
            {
                src: 'barcodes',
                sort: true,
                search: true,
            }
        ],
        'reports.shopNotWeb.refresh'() {
            _i.getShopNotWeb();
        },
        'reports.webNotShop.tab': 'filtered',
        'reports.webNotShop.filtered.all': [],
        'reports.webNotShop.unfiltered.all': [],
        'reports.webNotShop.fields': [
            {
                src: 'id',
                hide: true,
            },
            {
                src: 'vendor',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'sku',
                filter: false,
                sort: true,
                search: true,
                link: 'productFromSku',
            },
            {
                src: 'name',
                sort: true,
                search: true,
            },
            {
                src: 'size',
                sort: true,
                search: true,
            },
            {
                src: 'color',
                sort: true,
                search: true,
            },
            {
                src: 'price',
                sort: true,
                type: 'money',
            },
            {
                src: 'qty',
                sort: true,
            },
        ],
        'reports.webNotShop.refresh'() {
            _i.getWebNotShop();
        },
        'reports.notFoundSkus.all': [],
        'reports.notFoundSkus.fields': [
            {
                src: 'id',
                hide: true,
            },
            {
                src: 'completed',
                type: 'datetime',
                filter: false,
                sort: true,
                search: false,
            },
            {
                src: 'saleUUID',
                label: 'Sale',
                filter: false,
                sort: false,
                search: true,
                link: 'goToSale',
                type: 'linkIcon',
                icon: 'open_in_new',
            },
            {
                src: 'name',
                filter: false,
                sort: true,
                search: true,
            },
            {
                src: 'qty',
                filter: false,
                sort: true,
                search: false,
            },
            {
                src: 'price',
                sort: true,
                type: 'money',
            },
        ],
        'reports.notFoundSkus.refresh'() {
            _i.getNotFoundSkus();
        },
        'reports.barcodes.all': [],
        'reports.barcodes.fields': [
            {
                src: 'id',
                hide: true,
            },
            {
                src: 'department',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'group',
                label: 'Type',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'brand',
                filter: true,
                sort: true,
                search: false,
            },
            {
                src: 'sku',
                filter: false,
                sort: true,
                search: true,
                link: 'productFromSku',
            },
            {
                src: 'name',
                filter: false,
                sort: true,
                search: true,
            },
            {
                src: 'qty',
                filter: false,
                sort: true,
                search: false,
            },
            {
                src: 'shopifyBarcode',
                label: 'Shopify Barcode',
                sort: true,
                search: true,
                customFilter: new DataTableCustomFilter('blanks', val => typeof val === 'string' && val?.trim?.()?.length > 0),
            },
            {
                src: 'barcodes',
                label: 'IMS Barcodes',
                sort: true,
                search: true,
                truncate: 30,
                truncateSeperator: ',',
            },
        ],
        'reports.barcodes.refresh'() {
            _i.getBarcodes();
        },
    };

    const _i = {
        init() {
            cl.on(_on)
            cl.set(_set)

            document.addEventListener("leftArrow", (e) => {
                _.defer(_i.reviewSalesPreviousDay);
            });

            document.addEventListener("rightArrow", (e) => {
                _.defer(_i.reviewSalesNextDay);
            });
        },
        async loadReviewSales() {
            await cl.data.allLoaded();

            cl.send('reportReviewSales', {
                from:cl.dayjs(cl.get('reports.reviewSales.time')).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                to:cl.dayjs(cl.get('reports.reviewSales.time')).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            });
        },
        reviewSalesPreviousDay() {
            if (cl.routes.get() === 'reviewSales' && !cl.exec('loading')) {
                _i.reviewSalesChangeDay(false);
            }
        },
        reviewSalesNextDay() {
            if (cl.routes.get() === 'reviewSales' && !cl.exec('loading')) {
                _i.reviewSalesChangeDay(true);
            }
        },
        reviewSalesChangeDay(dir) {
            const t = dayjs(cl.get('reports.reviewSales.time'))
                .clone()[dir ? 'add' : 'subtract'](1, 'day')
                .utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

            cl.set('reports.reviewSales.time', t);
            _i.loadReviewSales();
        },
        async getWebsitePrice() {
            await cl.data.allLoaded();

            cl.send('reportWebsitePrice');
        },
        async getBarcodes() {
            await cl.data.allLoaded();
            cl.send('reportBarcodes');
        },
        async loadBarcodes(data) {
            await cl.data.allLoaded();

            cl.set('reports.barcodes.all', data.map(d => {
                const p = cl.exec('product.fromSku', d.sku);
                if (!p) return;

                p.shopifyBarcode = d.shopifyBarcode;
                p.qty = (p?.inventory?.ss?.main ?? 0) +
                    (p?.inventory?.cb?.main ?? 0) +
                    (p?.inventory?.wh?.main ?? 0);

                return p;
            }) || []);
        },
        async getShopNotWeb() {
            await cl.data.allLoaded();

            cl.send('reportShopNotWeb');
        },
        async loadShopNotWeb(data) {
            await cl.data.allLoaded();

            cl.set('reports.shopNotWeb.all', data.map(d => {
                const p = cl.exec('product.fromSku', d.sku);
                if (!p) {
                    const lastReceived = _.isNull(d.lastReceived) ? null : cl.dayjs(d.lastReceived).format();
                    const sku = d.sku;

                    return {
                        department: '',
                        supplier: '',
                        brand: '',
                        group: '',
                        sku,
                        name: '',
                        size: '',
                        color: '',
                        price: 0,
                        wh: 0,
                        ss: 0,
                        cb: 0,
                        total: 0,
                        lastReceived,
                        barcodes: '',
                        created: '',
                    };
                }
                p.lastReceived = _.isNull(d.lastReceived) ? null : cl.dayjs(d.lastReceived).format();
                p.created = _.isNull(d.created) ? null : cl.dayjs(d.created).format();
                p.wh = _.get(p, 'inventory.wh.main') || 0;
                p.ss = _.get(p, 'inventory.ss.main') || 0;
                p.cb = _.get(p, 'inventory.cb.main') || 0;
                p.total = p.wh + p.ss + p.cb;

                return p;
            }) || []);
        },
        async getWebNotShop() {
            await cl.data.allLoaded();

            cl.send('reportWebNotShop');
        },
        loadWebNotShop(data) {
            cl.success('Web Not Shop Data Loaded');
            cl.set('reports.webNotShop.filtered.all', data.filter(p => p.qty > 0) || []);
            cl.set('reports.webNotShop.unfiltered.all', data || []);
        },
        loadWebsitePrice(data = []) {
            cl.set('reports.websitePrice.all', data);
        },
        async loadReviewSale() {
            await cl.data.allLoaded();

            cl.set('reports.reviewSale', {})
            cl.send('reportReviewSale', cl.get('params.id'))
        },
        raw(data) {
            cl.csv(`${data.point}-data-${cl.dayjs().format('YYYY-MM-DD-hh-mm-A')}.csv`, data.data);
        },
        inventorySnapshot(data) {
            const today = cl.dayjs().format('L');
            const date = cl.dayjs(cl.get('reports.inventorySnapshot.time')).format('L');
            const products = [];

            const header = [
                'Type',
                'Department',
                'Brand',
                'Sku',
                'Name',
                'Size',
                'Color',
                `WH ${date}`,
                `SS ${date}`,
                `CB ${date}`,
                `TT ${date}`,
                'Price',
                'Cost',
                'Consignment',
                `WH ${today}`,
                `SS ${today}`,
                `CB ${today}`,
                `TT ${today}`,
            ];

            data.forEach(d => {
                const p = cl.exec('product.fromSku', d.sku);
                const wh = p?.inventory?.wh?.main ?? 0;
                const ss = p?.inventory?.ss?.main ?? 0;
                const cb = p?.inventory?.cb?.main ?? 0;
                const tt = wh + ss + cb;

                products.push([
                    d.group,
                    d.department,
                    d.brand,
                    d.sku,
                    d.name,
                    d.size,
                    d.color,
                    d.wh,
                    d.ss,
                    d.cb,
                    (d.wh + d.ss + d.cb),
                    d.price,
                    d.cost,
                    d.consignment,
                    wh,
                    ss,
                    cb,
                    tt,
                ]);
            });

            const skus = _.keyBy(data, 'sku');
            cl.get('products.all').forEach(p => {
                if (p.sku in skus) {
                    return;
                }

                products.push([
                    p.group,
                    p.department,
                    p.brand,
                    p.sku,
                    p.name,
                    p.size,
                    p.color,
                    '',
                    '',
                    '',
                    '',
                    p.price,
                    p.cost,
                    p.consignment,
                    p.inventory.wh.main,
                    p.inventory.ss.main,
                    p.inventory.cb.main,
                    p.inventory.wh.main + p.inventory.ss.main + p.inventory.cb.main,
                ]);
            });

            const sortFunc = (a, b) => {
                const c = a[3];
                const d = b[3];
                if (c < d) {
                    return -1;
                }
                if (c > d) {
                    return 1;
                }
                return 0;
            }
            const rows = [header].concat(products.sort(sortFunc));

            cl.csv(`inventory-snapshot-${date}--${today}.csv`, rows);
        },
        async getOldProducts() {
            await cl.data.allLoaded();

            cl.send('reportOldProducts');
        },
        loadOldProducts(products = []) {
            if (products.length === 0) {
                cl.log('Zero Old Products Found')
                return;
            }

            cl.set('reports.oldProducts.all', products.map(op => {
                const p = cl.exec('product.fromSku', op.sku);
                op.name = p.name;
                if (p.color) {
                    op.name += ` - ${p.color}`;
                }

                if (p.size) {
                    op.name += ` - ${p.size}`;
                }

                op.type = p.group;
                op.brand = p.brand;
                op.created = p.created;

                return op;
            }));
        },
        openShopifyOrder(data) {
            if (!data.id) {
                return;
            }

            const base =  Math.abs(Number(data.number.replace(/[^\d\.]/g, ''))) > 10000 ? window.cl.shopifyProdBaseAdminUrl : window.cl.shopifyDevBaseAdminUrl;
            window.open(`${base}/orders/${data.id.replace(/\D/g, '')}`, '_blank');
        },
        async getNotFoundSkus() {
            await cl.data.allLoaded();

            cl.send('reportNotFoundSkus');
        },
        loadNotFoundSkus(data = []) {
            cl.set('reports.notFoundSkus.all', data);
        },
        saleReversed() {
            cl.success('Sale Reversed');
            if (cl.routes.get() !== 'reviewSales') {
                return;
            }

            _i.loadReviewSales();
        },
    };

    return {
        init: _i.init,
        loadReviewSales: _i.loadReviewSales,
        loadReviewSale: _i.loadReviewSale,
        raw: _i.raw,
        getWebsitePrice: _i.getWebsitePrice,
        getBarcodes: _i.getBarcodes,
        loadBarcodes: _i.loadBarcodes,
        getShopNotWeb: _i.getShopNotWeb,
        loadShopNotWeb: _i.loadShopNotWeb,
        getWebNotShop: _i.getWebNotShop,
        loadWebNotShop: _i.loadWebNotShop,
        loadWebsitePrice: _i.loadWebsitePrice,
        inventorySnapshot: _i.inventorySnapshot,
        getOldProducts: _i.getOldProducts,
        loadOldProducts: _i.loadOldProducts,
        openShopifyOrder: _i.openShopifyOrder,
        getNotFoundSkus: _i.getNotFoundSkus,
        loadNotFoundSkus: _i.loadNotFoundSkus,
        saleReversed: _i.saleReversed
    };
})(window.cl);