window.cl = window.cl || {};

window.cl.orders = ((cl) => {
    let loadingResolver;
    const loadingPromise = new Promise((resolve) => {
        loadingResolver = resolve;
    });

    const _on = {
        ordersNew() {
            cl.send('createOrder');
            cl.set('orders.waitingForCreation', true);
        },
    };
    const _set = {
        'orders.all': [],
        'orders.fields': [
            {
                src: 'id',
                hide: true,
            },
            {
                src: 'arriving',
                filter: false,
                sort: true,
                search: false,
                type: 'date',
                searchWeight: 0,
            },
            {
                src: 'supplier',
                filter: true,
                sort: true,
                search: true,
                type: 'caps',
                searchWeight: 100,
            },
            {
                src: 'po',
                label: 'PO#',
                filter: false,
                sort: true,
                search: true,
                searchWeight: 100,
            },
            {
                src: 'status',
                filter: true,
                sort: true,
                search: true,
                type: 'caps',
                searchWeight: 0,
            },
            {
                src: 'type',
                sort: true,
                search: true,
                type: 'caps',
                searchWeight: 0,
            },
            {
                src: 'units',
                filter: false,
                sort: true,
                search: false,
                searchWeight: 0,
            }, {
                src: 'cost',
                filter: false,
                sort: true,
                search: false,
                type: 'money',
                searchWeight: 0,
            }
        ],
        'orders.waitingForCreation': false,
        'orders.open'() {
            const shipments = cl.get('shipments.all');
            const func = o => {
                const found = shipments.some(s => s.orderId === o.id && s.status !== 'complete');

                return !found && o.status !== 'received' && o.status !== 'canceled';
            };

            return _(cl.get('orders.all'))
                .filter(func)
                .orderBy('arriving', 'desc')
                .value();
        },
        'orders.hashes': {},
        'orders.lookup': {},
    };
    const _i = {
        init() {
            cl.set(_set);
            cl.on(_on);
        },
        async load(data) {
            await cl.data.allLoaded();

            if (_.isNull(data)) {
                return;
            }

            const orders = data.map(_i.addUnitsAndCost);
            cl.set('orders.all', orders);
            _i.setLookup(orders);
            loadingResolver();
            _i.hash(orders);
        },
        async update(type, data) {
            await cl.data.allLoaded();

            if (type === 'UPDATE' || type === 'INSERT') {
                _i.addUnitsAndCost(data);
                const index = _i.getIndex(data.id);
                if (index > -1) {
                    cl.splice('orders.all', index, 1, data);
                } else {
                    cl.push('orders.all', data);
                    _i.updateLookup(data);
                }

                _i.setHash(data);
            }

            if (type === 'DELETE') {
                const index = _i.getIndex(data.id);
                if (index <= -1) {
                    return;
                }

                cl.splice('orders.all', index, 1);
                setTimeout(() => {
                    _i.deleteLookup(data.id);
                    _i.removeHash(data.id);
                }, 0);
            }
        },
        addUnitsAndCost(order) {
            order.units = order.products.reduce((a, p) => {
                a += p.qty;
                return a;
            }, 0);

            order.cost = order.products.reduce((a, p) => {
                const pr = cl.exec('product.fromId', p.id);
                if (pr && pr.cost) {
                    a += pr.cost * p.qty;
                }
                return a;
            }, 0);

            return order;
        },
        hash(orders = []) {
            const start = performance.now();
            const hashes = {};

            orders.map((o) => {
                hashes[`ref_${o.id}`] = cl.hash(JSON.stringify(o));
            });

            cl.set('orders.hashes', hashes);
            cl.order.changes();
            _.log(`Order Hashing Complete: ${orders.length} Orders in ${performance.now() - start}ms`);
            _.logOnly('=== orders hashed ===');
        },
        setHash(order) {
            const hash = cl.hash(JSON.stringify(order));
            cl.set(`orders.hashes.ref_${order.id}`, hash);
            cl.order.changes();
        },
        removeHash(id) {
            cl.unset(`orders.hashes.ref_${id}`);
        },
        setLookup(orders = []) {
            const lookup = {};

            orders.forEach((o, i) => {
                lookup[`ref_${o.id}`] = i;
            })

            cl.set('orders.lookup', lookup);
        },
        updateLookup(order) {
            const index = _i.getIndex(order);
            cl.set(`orders.lookup.ref_${order.id}`, index);
        },
        deleteLookup(id) {
            cl.unset(`orders.lookup.ref_${id}`);
        },
        getIndex(id) {
            if (_.isUndefined(id)) {
                return -1;
            }

            const lookup = cl.get(`orders.lookup.ref_${id}`);
            if (lookup > -1) {
                return lookup;
            }

            const orders = cl.get('orders.all');
            if (orders.length === 0) {
                return -1;
            }

            const index = _.findIndex(orders, ['id', String(id)]);
            if (index > -1) {
                cl.set(`orders.lookup.ref_${id}`, index);
            }
            return index;
        },
        loaded() {
            return loadingPromise;
        }
    }

    return {
        init: _i.init,
        load: _i.load,
        update: _i.update,
        setHash: _i.setHash,
        getIndex: _i.getIndex,
        loaded: _i.loaded,
    };
})(window.cl);