import { handleOptionsDown, handleOptionsUp } from "./utilities/options";
import dayjs from "./utilities/dayjs";

window.cl = window.cl || {};

window.cl.product = ((cl) => {
    const _set = {
        tab() {
            return cl.get('params.tab');
        },
        product: {
            blank: {},
            src() {
                const id = cl.get('params.product');
                const index = cl.get(`products.lookup.indices.ref_${id}`);

                return cl.get(`products.all.${index}`) || cl.get('product.blank');
            },
            srcInventory(base = '', sub = 'main') {
                const src = cl.exec('product.src');
                if (!src) {
                    return;
                }

                const locs = base.split('.');
                if (locs.length === 2) {
                    return cl.exec('product.srcInventory', ...locs);
                }

                return _.get(src, `inventory.${base}.${sub}`);
            },
            isNew() {
                const id = cl.get('product.tmp.id');
                return id === '0' || id === '' || id === 0 || _.isUndefined(id);
            },
            reset: false,
            save: false,
            errors: {},
            changes: {},
            options(option) {
                const el = document.querySelector(`#options-list-input-${option}`);
                if (_.isNull(el) || !el.classList.contains('focus')) {
                    return [];
                }

                const val = document.querySelector(`#options-list-input-${option}`).value.trim();
                return _(cl.get('products.all'))
                    .map(option)
                    .uniq()
                    .pull('')
                    .filter((opt) => {
                        return _.startsWith(opt.toLowerCase(), val.toLowerCase()) && !_.eq(opt.trim(), val);
                    })
                    .sort()
                    .value();
            },
            barcodes: {
                all: [],
            },
            fromId(id) {
                const index = cl.get(`products.lookup.indices.ref_${id}`);

                return cl.get(`products.all.${index}`);
            },
            skuFromId(id) {
                return cl.exec('product.fromId', id)?.sku;
            },
            indexFromId(id) {
                const index = cl.get(`products.lookup.indices.ref_${id}`);

                return index ?? -1;
            },
            fromSku(sku) {
                const id = cl.get(`products.lookup.skus.ref_${sku}`);
                const index = cl.get(`products.lookup.indices.ref_${id}`);

                return cl.get(`products.all.${index}`) || false;
            },
            fromBarcode(bc) {
                const ref = cl.get(`products.lookup.barcodes.ref_${bc}`);

                if (!ref) {
                    return false;
                }

                const index = cl.get(`products.lookup.indices.ref_${ref.id}`);
                return cl.get(`products.all.${index}`) ?? false;
            },
            getQty(id) {
                const p = cl.exec('product.fromId', id);

                if (_.isUndefined(p) || _.isUndefined(p?.inventory)) {
                    return 0;
                }

                return Object.keys(p.inventory).reduce((a, k) => {
                    if (k === 'vr' || k === 'ls') {
                        return a;
                    }
                    a += p.inventory[k].main ?? 0;

                    return a;
                }, 0);
            },
            duplicateFrom: '',
            discontinuedSelect: false,
            orderId: '',
            historyPendingTransfers() {
                return cl.exec('product.getHistory').filter(v => {
                    return v.ref_type === 'pending_transfer';
                });
            },
            historyPendingTransferCount() {
                return cl.exec('product.historyPendingTransfers').length;
            },
            getHistory() {
                const loc = cl.get('product.historyFilterLocation');
                return (cl.exec('product.src').history ?? [])
                    .filter((e) => {
                        if (String(loc).trim() === '') {
                            return true;
                        }
                        const f = _i.getBaseLocation(e.from_loc);
                        const t = _i.getBaseLocation(e.to_loc);
                        return f == loc || t == loc;
                    })
                    .sort((a, b) => {
                        return cl.dayjs(b.time).format('x') - cl.dayjs(a.time).format('x');
                    });
            },
            historyFilterLocation: '',
            getPastLevelLocation: '',
            getPastLevelDate: dayjs().format(),
            getPastLevelDateSelect: false,
            pastLevels: [],
            getPastLevels(id, loc) {
                return cl.get('product.pastLevels')?.filter?.(l => l.id === Number(id) && l.loc === loc);
            }
        },
    };
    const _on = {
        productFieldChange(e, i) {
            if (e.node.dataset.options) {
                e.node.classList.add('focus');
                cl.update('product.options');
            }

            if (!_.isUndefined(e.node.dataset.multi)) {
                if (e?.node?.value) {
                    cl.set(`product.tmp.${e.node.dataset.src}.${i}`, e.node.value);
                }
                return;
            }

            const go = (e.node.nodeName === "INPUT" && e.node.type !== "checkbox");
            const val = e.node.value;
            const src = e.node.dataset.src;

            const selDir = go ? e.node.selectionDirection : 0;
            const selEnd = go ? e.node.selectionEnd : 0;
            const selSta = go ? e.node.selectionStart : 0;

            if (!_.isUndefined(e?.node?.dataset?.validate) && e.node.dataset.validate === "bool") {
                cl.set(`product.tmp.${src}`, String(!_.bool(cl.get(`product.tmp.${src}`))));
            } else {
                cl.set(`product.tmp.${src}`, val);
            }

            if (go) {
                e.node.selectionDirection = selDir;
                e.node.selectionEnd = selEnd;
                e.node.selectionStart = selSta;
            }

            setTimeout(() => _i.validate(false), 0);
        },
        productFieldFocus(e) {
            if (!e.node.dataset.options) {
                return;
            }

            cl.find(`#options-list-input-${e.node.dataset.options}`).classList.add('focus');
            cl.update('product.options');
        },
        productFieldBlur(e) {
            if (e.node.dataset.options && ((!_.isNull(e.original.relatedTarget) && !e.original.relatedTarget.classList.contains('options-list-child')) || _.isNull(e.original.relatedTarget))) {
                cl.find(`#options-list-input-${e.node.dataset.options}`).classList.remove('focus');
                cl.update('product.options');
            }

            setTimeout(() => _i.validate(true), 0);
        },
        productOptionsClick(e, option) {
            const val = e.node.innerText.trim();
            const el = cl.find(`#options-list-input-${option}`);
            el.value = val;
            el.classList.remove('focus');
            cl.update('product.options');
            cl.set(`product.tmp.${option}`, val);
            setTimeout(() => _i.validate(false), 0);
        },
        productDelete() {
            _i.delete();
        },
        productReset() {
            _i.reset();
        },
        productSave() {
            _i.save();
        },
        async productNewBarcode(e) {
            if ((cl.get('product.tmp.barcodes') ?? []).length >= 1) {
                const res = await cl.confirm({
                    message: `<div class='error-alert'><i class='material-icons'>warning</i>Products with multiple barcodes not work in Shopify.<br>Are you shure you want to add another barcode?</div>`,
                });

                if (res.buttonClicked !== 'ok') {
                    return;
                }
            }

            cl.push('product.tmp.barcodes', _i.newBarcode());
        },
        productDeleteBarcode(e, i) {
            cl.splice('product.tmp.barcodes', i, 1);
            setTimeout(() => _i.validate(false), 0);
        },
        productPrintBarcode(e, barcode) {
            cl.prompt({
                message: 'Quantity:',
                okFunc(val) {
                    if (!/^[\d]+$/.test(val.trim())) {
                        val = '1';
                    }

                    const printed = 'ABCDEFGHIJKL'.split('')[cl.dayjs().month()] + cl.dayjs().year().toString().slice(-1);
                    const src = cl.exec('product.src');
                    const name = `${src.name}${src.size !== '' ? ` - ${src.size}` : ''}`;

                    cl.send('productPrintBarcodes', [{
                        barcode,
                        name,
                        sku: src.sku,
                        color: src.color || 'N/A',
                        price: String(src.price),
                        qty: val.trim(),
                        printed,
                    }]);
                },
                default: '1',
            })
        },
        productPrintTestBarcode(e, barcode, product) {
            const data = {
                barcode,
                sku: product.sku,
                name: product.name,
            };

            require('superagent')
                .post('https://receipt.cleanline.ninja/barcode.php')
                .timeout(7000)
                .send(data)
                .end((err, res) => {
                    if (_.isUndefined(res)) {
                        cl.error('Error Test Printing Barcode.');
                        return;
                    }

                    const json = JSON.parse(res.text);
                    if (err || !res.ok || json.failure) {
                        cl.error('Error Test Printing Barcode.');
                        _.log('Barcode Error:', err, res);
                        return;
                    }

                    _.log('Barcode Data:', json.post);
                });
        },
        productFetchHistory() {
            _i.getHistory();
        },
        productFetchAdjustments() {
            _i.getAdjustments();
        },
        productFetchLow() {
            _i.getLow();
        },
        productLowChange(e) {
            const val = e.node.value;
            const src = e.node.dataset.src;
            const num = Number(val);
            const lit = cl.exec('product.src').lit;

            if (lit && e.event.type === 'blur' && val === '') {
                e.node.value = lit[src];
                return;
            }

            cl.set(`product.tmp.lit.${src}`, Number.isNaN(num) ? 0 : num);
        },
        productUpdateLow() {
            cl.users.getPin(async pin => {
                const user = cl.users.fromPin(pin);
                if (_.isUndefined(user)) {
                    cl.error(`No User Found For PIN: ${pin}`);
                    return;
                }

                cl.send('productUpdateLow', {
                    low: cl.get('product.tmp.lit'),
                    user: user.id,
                });
            });
        },
        productDuplicate(e) {
            e?.original?.preventDefault?.();
            cl.set('product.duplicateFrom', cl.get('product.id'));
            cl.routes.go('/product/new/details');
        },
        productHistoryInspect(e, type, id) {
            e?.original?.preventDefault?.();
            if (type === 'transfer') {
                cl.routes.go(`/transfers/${id}`);
                return;
            }

            if (type.includes('transfer')) {
                cl.routes.go(`/transfer-request/${id}`);
                return;
            }

            if (type.includes('sale')) {
                cl.routes.go(`/reports/review-sale/${id}`);
                return;
            }

            if (type === 'shipment') {
                cl.routes.go(`/shipments/${id}/products`);
                return;
            }

            if (type === 'inventory_adjustment') {
                cl.routes.go(`/inventory-adjustment/${id}`);
                return;
            }

            if (type === 'inventoryplanner') {
                cl.routes.go(`/inventoryplanner/${id}`);
                return;
            }

            cl.error(`Ref type ${type} not handled`);
        },
        productDiscontinuedSelectOpen() {
            cl.set('product.discontinuedSelect', true);

            if (!cl.get('product.tmp.discontinued')) {
                cl.set('product.tmp.discontinued', cl.dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
            }
        },
        productDiscontinuedSelectClose(e) {
            if (_.isUndefined(e) || e.original.target.classList.contains('modal-wrapper')) {
                cl.set('product.discontinuedSelect', false);
                setTimeout(() => _i.validate(true), 0);
            }
        },
        productDiscontinuedTimeChange(e, unit, dir) {
            e?.original?.preventDefault?.();
            if (!['add', 'subtract'].includes(dir)) {
                return cl.error(`Invalid direction ${dir}`);
            }

            if (!['day', 'month', 'year'].includes(unit)) {
                return cl.error(`Invalid unit ${unit}`);
            }

            cl.set('product.tmp.discontinued',
                cl.dayjs(cl.get('product.tmp.discontinued')).clone()[dir](1, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
            setTimeout(() => _i.validate(true), 0);
        },
        productDiscontinuedClear() {
            cl.set('product.tmp.discontinued', '');
            setTimeout(() => _i.validate(true), 0);
        },
        productOpenInFrontend(e, sku, handle) {
            e?.original?.preventDefault?.();
            if (!handle) {
                return;
            }

            window.open(`${cl.shopifyBaseUrl()}/products/${handle}`, '_blank');
        },
        productOpenInBackend(e, sku, parent) {
            e?.original?.preventDefault?.();
            if (!parent) {
                return;
            }

            if (parent.startsWith('gid://')) {
                window.open(`${cl.shopifyBaseAdminUrl()}/products/${parent.replace(/\D/g, '')}`, '_blank');
                return;
            }

            cl.error(`Invalid Shopify Parent Identifier: ${parent}`);
        },
        productGoTo(e, id) {
            e?.original?.preventDefault?.();
            if (id) {
                cl.routes.go(`/product/${id}/details`);
            }
        },
        productRequest(e) {
            e?.original?.preventDefault?.();
            cl.transferRequestCreate.fromProduct(cl.exec('product.src'));
        },
        productSetHistoryFilter(e, loc) {
            e?.original?.preventDefault?.();
            const key = 'product.historyFilterLocation';
            const orig = cl.get(key);
            const val = loc === orig ? '' : loc;
            cl.set(key, val);
        },
        productHistoryShopifyLink(e, shopifyRef, shopifyOrder) {
            e?.original?.preventDefault?.();
            if (!shopifyRef) {
                return;
            }

            const base =  Math.abs(Number(String(shopifyOrder).replace(/[^\d\.]/g, ''))) > 10000 ? window.cl.shopifyProdBaseAdminUrl : window.cl.shopifyDevBaseAdminUrl;
            window.open(`${base}/admin/orders/${shopifyRef.replace(/\D/g, '')}`, '_blank');
        },
        productHistoryInventoryPlannerLink(e, id) {
            e?.original?.preventDefault?.();
            if (!id) {
                return;
            }

            const account = cl.get('inventoryPlannerInfo.account');
            const baseURL = cl.get('inventoryPlannerInfo.baseURL');
            if (!account || !baseURL) {
                return;
            }

            window.open(`${baseURL}#/po/view/${id}?a=${account}`, '_blank');
        },
        productGetPastLevel(e, loc) {
            e?.original?.preventDefault?.();
            if (!loc || _.isUndefined(loc)) {
                cl.error('Invalid location for past level');
                return;
            }
            cl.set('product.getPastLevelDateSelect', true);
            cl.set('product.getPastLevelLocation', loc);
        },
        productGetPastLevelDateSelectClose(e) {
            e?.original?.preventDefault?.();
            if (!e?.original?.target?.classList?.contains?.('modal-wrapper')) {
                return;
            }

            cl.set('product.getPastLevelDateSelect', false);
            const id = Number(cl.exec('product.src')?.id);
            if (!Number.isFinite(id) || id == 0) {
                cl.error('Invalid product id');
                return;
            }
            const date = cl.get('product.getPastLevelDate');
            const location = cl.get('product.getPastLevelLocation');
            cl.send('productPastLevel', {
                id,
                location,
                date,
            });
        },
        productGetPastLevelTimeChange(e, unit, dir) {
            e?.original?.preventDefault?.();
            if (!['add', 'subtract'].includes(dir)) {
                return cl.error(`Invalid direction ${dir}`);
            }

            if (!['day', 'month', 'year'].includes(unit)) {
                return cl.error(`Invalid unit ${unit}`);
            }

            cl.set('product.getPastLevelDate',
                dayjs(cl.get('product.getPastLevelDate')).clone()[dir](1, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
        },
    };
    const _i = {
        init() {
            cl.set(_set);
            cl.on(_on);

            document.addEventListener('upArrow', (e) => {
                _i.optionsUp(e.detail);
            });

            document.addEventListener('downArrow', (e) => {
                _i.optionsDown(e.detail);
            });

            document.addEventListener('leftArrow', (e) => {
                setTimeout(_i.lastProduct, 0);
            });

            document.addEventListener('rightArrow', (e) => {
                setTimeout(_i.nextProduct, 0);
            });

            document.addEventListener('enterFire', (e) => {
                setTimeout(() => _i.optionsSelect(e.detail), 0);
            });

            document.addEventListener('escapeFire', (e) => {
                setTimeout(() => _i.optionsExit(e.detail), 0);
            });

            document.addEventListener('save', (e) => {
                if (cl.routes.get() === 'product') {
                    setTimeout(_i.save, 0);
                }
            });
        },
        getBaseLocation(loc = '') {
            if (typeof loc !== 'string') {
                return '';
            }

            const locs = loc.split('.');
            if (locs.length !== 2) {
                return '';
            }

            return locs[0];
        },
        load(id) {
            if (cl.exec('loading') || cl.get('products.all').length === 0 || _.isUndefined(cl.get(`products.lookup.indices.ref_${id}`))) {
                _.delay(_i.load, 20, id);
                return false;
            }

            const orderId = cl.get('product.orderId');
            if (orderId) {
                cl.order.addProduct(orderId, id);
                cl.set('product.orderId', '');
            }

            cl.set('product.id', id);
            cl.set('title', `Edit Product: ${cl.exec('product.src').sku}`);
            cl.set('product.reset', false);
            cl.set('product.save', false);
            cl.set('product.errors', {});
            cl.set('product.changes', {});

            setTimeout(() => {
                const ids = _.map(cl.exec('products.sorted'), 'id');
                const index = _.indexOf(ids, cl.get('product.id'));
                const page = cl.get('products.paging.page');
                const perPage = cl.get('products.paging.perPage');
                const newPage = _.ceil((index + 1) / perPage);

                if (Number(page) !== newPage && newPage > 0) {
                    cl.set('products.paging.page', newPage);
                }
            }, 0);

            cl.set('product.tmp', _.cloneDeep(cl.exec('product.src')));
        },
        async new() {
            await cl.data.allLoaded();

            cl.set('title', 'New Product: ');
            cl.set('product.id', '');
            cl.set('product.reset', false);
            cl.set('product.save', false);
            cl.set('product.errors', {});
            cl.set('product.changes', {});

            const dupId = cl.get('product.duplicateFrom');
            if (!dupId) {
                cl.set('product.tmp', _.merge({}, cl.get('product.blank')));
                return;
            }

            const now = cl.dayjs().format();
            const p = cl.exec('product.fromId', dupId);
            cl.set('product.tmp', _.cloneDeep(p));
            cl.set('product.tmp.id', '');
            cl.set('product.tmp.created', now);
            cl.set('product.tmp.updated', now);
            cl.set('product.tmp.inventory', {});
            cl.set('product.tmp.websitePrice', "0");
            cl.set('product.tmp.barcodes', [_i.newBarcode()]);
            cl.set('product.tmp.parent', '');
            cl.set('product.duplicateFrom', '');
            if (p.parentGroup.includes('shopify_gid')) {
                cl.set('product.tmp.parentGroup', '');
            }
        },
        get() {
            const p = {};
            const src = cl.exec('product.src');

            if (!_.isUndefined(src)) {
                p.id = src.id;
            }

            cl.findAll('.product-field').forEach((v) => {
                if (!_.isUndefined(v.dataset.multi)) {
                    if (_.isUndefined(p[v.dataset.src])) {
                        p[v.dataset.src] = [];
                    }
                    p[v.dataset.src].push(v.value);
                    return;
                }

                if (v.type === 'checkbox') {
                    p[v.dataset.src] = String(v.checked);
                } else if (v.dataset.src === 'discontinued') {
                    p[v.dataset.src] = cl.get('product.tmp.discontinued') || undefined;
                } else {
                    p[v.dataset.src] = v.value;
                }
            });

            return p;
        },
        save() {
            if (!cl.get('product.save') || !_i.validate(true)) {
                return;
            }

            if (cl.get('product.id') === '') {
                cl.send('createProduct', _i.get());
            } else {
                cl.send('updateProduct', _i.get());
            }
        },
        reset() {
            cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want to reset ${cl.exec('product.src').sku}?<br>You will lose any changes.</div>`,
                ok: 'Reset',
                okFunc() {
                    _i.load(cl.get('product.id'));
                },
            })
        },
        delete() {
            const src = cl.exec('product.src');
            if (_.isUndefined(src)) {
                cl.error('Cannot delete unsaved product.');
                return;
            }

            cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want to delete ${src.sku}?<br>There is no undo.</div>`,
                ok: 'Delete',
                okFunc() {
                    cl.send('deleteProduct', {
                        id: src.id,
                        sku: src.sku
                    });
                }
            });
        },
        validate(blur) {
            const caseCheckDisabled = cl.get('settings.disableProductCaseCheck');
            const tmp = _.cloneDeep(cl.get('product.tmp'));
            if (!_.isObject(tmp)) {
                return false;
            }

            const uniqueVals = {};
            const caseChecks = {
                name: true,
                brand: true,
                supplier: true,
                group: true, // product type
                department: true,
                color: true,
                size: true,
                condition: true,
            };

            if (blur) {
                cl.get('products.all').forEach((p) => {
                    for (const key in caseChecks) {
                        if (!(key in uniqueVals)) {
                            uniqueVals[key] = {};
                        }
                        uniqueVals[key][p[key]?.toLowerCase()] = p[key];
                    }
                });
            }

            const skip = {
                adjustments: true,
                costQty: true,
                img: true,
                handle: true,
                history: true,
                inventory: true,
                parent: true,
                parentGroup: true,
                ref: true,
                score: true,
                uuid: true,
                variation: true,
                websitePrice: true,
            };

            for (const key in cl.exec('product.src')) {
                const newVal = tmp[key];
                const srcVal = cl.exec('product.src')[key];
                const newUnique = _.isString(newVal) ? _.get(uniqueVals, `${key}.${newVal.toLowerCase()}`) : '';
                if (
                    blur &&
                    !caseCheckDisabled &&
                    key in caseChecks &&
                    newVal &&
                    newUnique &&
                    newUnique !== newVal
                ) {
                    cl.set(`product.tmp.${key}`, newUnique);
                    if (tmp.id) {
                        return _i.validate(true);
                    }
                }

                if (['id', 'created', 'updated', 'qty'].includes(key)) {
                    continue;
                }

                cl.set(`product.changes.${key}`, false);
                cl.set(`product.errors.${key}`, []);
                const addError = err => cl.push(`product.errors.${key}`, err);
                const isRequeired = () => addError('*Required');
                const hasChanges = () => {
                    cl.set(`product.changes.${key}`, true);
                };

                if (key in skip) {
                    continue;
                }

                const bools = {
                    track: true,
                    quick: true,
                    consignment: true,
                };

                if (key in bools) {
                    if (String(srcVal).trim() !== String(newVal).trim()) {
                        hasChanges();
                    }

                    continue;
                }

                if (key === 'barcodes') {
                    let nv = newVal;
                    let sv = srcVal;

                    if (_.isNull(srcVal) || _.isUndefined(srcVal) || !_.isArray(srcVal)) {
                        nv = [];
                    }

                    if (_.isNull(newVal) || _.isUndefined(newVal) || !_.isArray(newVal)) {
                        sv = [];
                    }

                    if (nv && (sv?.length !== nv?.length || !!nv && !sv)) {
                        hasChanges();
                    }

                    for (const i in sv) {
                        if (sv[i] !== nv[i]) {
                            hasChanges();
                        }
                    }

                    continue;
                }

                if (key === 'discontinued') {
                    if (
                        (!_.isNull(newVal) && !_.isUndefined(newVal) && cl.dayjs(newVal).unix() !== cl.dayjs(srcVal).unix()) ||
                        (_.isUndefined(newVal) && newVal !== srcVal && !_.isNull(srcVal))
                    ) {
                        hasChanges();
                    }

                    continue;
                }

                // the following fields need to be trimmed
                if (blur) {
                    setTimeout(() => {
                        if (_.isArray(newVal) || _.isObject(newVal)) {
                            return;
                        }

                        const trimmed = String(newVal)?.trim?.();
                        const num = Number(trimmed);

                        if (['price', 'cost'].includes(key)) {
                            const val = (newVal?.split?.('.')?.[1]?.length ?? 0) > 2 ? num.toFixed(2) : num;
                            cl.set(`product.tmp.${key}`, val);
                            return
                        }

                        if (_.isNumber(num) && !Number.isNaN(num) && trimmed !== '') {
                            cl.set(`product.tmp.${key}`, num);
                            setTimeout(() => _i.validate(false), 0);
                            return;
                        }

                        if (newVal === trimmed) {
                            return;
                        }

                        cl.set(`product.tmp.${key}`, trimmed);
                    }, 0);
                }

                if (key === 'sku') {
                    if (!newVal || newVal?.trim?.() === '') {
                        isRequeired();
                    }

                    if (newVal && srcVal?.trim?.() !== newVal?.trim?.()) {
                        hasChanges();
                    }

                    if (/[^a-zA-Z0-9-#+_]/.test(newVal)) {
                        addError('*Invalid Character In Sku');
                    }

                    if (blur && newVal && newVal !== newVal.toUpperCase()) {
                        cl.set('product.tmp.sku', newVal.toUpperCase());
                    }

                    continue;
                }

                const strings = {
                    name: true,
                    brand: true,
                    supplier: true,
                    group: true,
                    department: true,
                    color: true,
                    size: true,
                    condition: true,
                };

                if (key in strings) {
                    if (key === 'name' && String(newVal).trim() === '') {
                        isRequeired();
                    }

                    if (
                        (_.isNull(srcVal) && !_.isNull(newVal)) ||
                        _.trim(srcVal) !== _.trim(newVal) ||
                        (!_.isUndefined(srcVal) && (_.isUndefined(newVal)) ||
                        Number.isNaN(newVal) ||
                        _.isNull(newVal))
                    ) {
                        hasChanges();
                    }

                    continue;
                }

                if (key === 'price' || key === 'cost') {
                    if (String(srcVal).trim() !== String(newVal).trim()) {
                        hasChanges();
                    }

                    const mon = String(newVal).split('.');
                    if (/[^0-9\.]/.test(newVal) || mon.length > 2 || (mon.length == 2 && mon[1].length > 2)) {
                        addError('*Invalid Money Format');
                    }

                    continue;
                }

                if (key === 'weight') {
                    if (String(srcVal).trim() !== String(newVal).trim()) {
                        hasChanges();
                    }
                    if (/[^0-9\.]/.test(newVal)) {
                        addError('*Invalid Number Format');
                    }

                    continue;
                }

                if (key === 'costPc') {
                    if (String(srcVal).trim() !== String(newVal).trim()) {
                        hasChanges();
                    }

                    if (newVal && (isNaN(String(newVal).trim()) || !(newVal >= 0 && newVal <= 100))) {
                        addError('*Invalid Percentage Format');
                    }

                    continue;
                }

                _.log('Product Field Validation Miss:', key);
            }

            const fields = Object.keys(tmp);
            const changes = fields.some(key => {
                return cl.get(`product.changes.${key}`);
            });
            const errors = fields.some(key => {
                return (cl.get(`product.errors.${key}`) || []).length > 0;
            });

            if (changes && !errors) {
                cl.set('product.reset', true);
                cl.set('product.save', true);
                return true;
            }

            cl.set('product.reset', false);
            cl.set('product.save', false);
            return false;
        },
        optionsUp(src) {
            if (cl.routes.get() !== 'product' || _.isUndefined(src?.dataset?.options) || (cl.get('product.options')(src?.dataset?.options) || []).length <= 0) {
                return;
            }

            handleOptionsUp(document.querySelectorAll(`#options-list-${src.dataset.options} li`));
        },
        optionsDown(src) {
            if (cl.routes.get() !== 'product' || _.isUndefined(src?.dataset?.options) || (cl.get('product.options')(src?.dataset?.options) || []).length <= 0) {
                return;
            }

            handleOptionsDown(document.querySelectorAll(`#options-list-${src.dataset.options} li`));
        },
        optionsSelect(src) {
            if (cl.routes.get() !== 'product' || _.isUndefined(src?.dataset?.options) || cl.get('product.options')(src?.dataset?.options).length <= 0) {
                return;
            }

            const el = cl.find(`#options-list-input-${src.dataset.options}`);
            const opt = cl.find(`#options-list-${src.dataset.options} li.active`);

            if (opt?.innerText) {
                cl.set(`product.tmp.${src.dataset.options}`, opt.innerText.trim());
            }
            el.classList.remove('focus');
            cl.update('product.options');
            _i.validate(true);
        },
        optionsExit(src) {
            src.classList.remove('focus');
            cl.update('product.options');
        },
        async nextProduct(e) {
            e?.original?.preventDefault?.();
            if (cl.routes.get() !== 'product') {
                return;
            }

            const next = () => {
                const ids = _.map(cl.exec('products.sorted'), 'id');
                const index = _.indexOf(ids, cl.get('params.product'));
                const tab = cl.exec('tab') || 'details';
                const offset = index + 1 % ids.length;
                const nextId = offset === ids.length ? ids[0] : ids[offset];

                if (index > -1 && !_.isUndefined(nextId)) {
                    cl.routes.go(`/product/${nextId}/${tab}`);
                }
            };

            if (!cl.get('product.reset')) {
                next();
                return;
            }

            cl.confirm({
                message: '<div class="error-alert"><i class="material-icons">warning</i>Are you sure you want switch products?<br>You will lose your changes.</div>',
                ok: 'Discard Changes',
                okFunc() {
                    next();
                },
                cancel: 'Keep Changes',
            });
        },
        async lastProduct(e) {
            e?.original?.preventDefault?.();
            if (cl.routes.get() !== 'product') {
                return;
            }

            const last = () => {
                const ids = _.map(cl.exec('products.sorted'), 'id');
                const index = _.indexOf(ids, cl.get('params.product'));
                const tab = cl.exec('tab') || 'details';
                const offset = index - 1 % ids.length;
                const lastId = offset < 0 ? ids[ids.length - 1] : ids[offset];

                if (index > -1 && !_.isUndefined(lastId)) {
                    cl.routes.go(`/product/${lastId}/${tab}`);
                }
            };

            if (!cl.get('product.reset')) {
                last();
                return;
            }

            cl.confirm({
                message: '<div class="error-alert"><i class="material-icons">warning</i>Are you sure you want switch products?<br>You will lose your changes.</div>',
                ok: 'Discard Changes',
                okFunc() {
                    last();
                },
                cancel: 'Keep Changes'
            });
        },
        gtinChecksum(gtin) {
            const sum = parseInt(gtin, 10)
                .toString()
                .split('')
                .map(n => parseInt(n, 10))
                .reverse().reduce((a, n, i) => {
                    a += (i % 2) === 1 ? n : n * 3;
                    return a;
                }, 0) % 10;

            return (sum === 0) ? 0 : (10 - sum);
        },
        createBarcode() {
            let bc = '2';
            while (bc.length < 11) {
                bc += Math.floor(Math.random() * 10).toString();
            }
            bc += _i.gtinChecksum(bc);

            const p = cl.exec('product.fromBarcode', bc);
            if (p) {
                return _i.createBarcode();
            }

            return bc;
        },
        newBarcode() {
            const str = _i.createBarcode();
            setTimeout(() => _i.validate(false), 0);
            return str;
        },
        getHistory() {
            cl.send('productFetchHistory', cl.get('params.product'));
        },
        getAdjustments() {
            cl.send('productFetchAdjustments', cl.get('params.product'));
        },
        getLow() {
            cl.send('productFetchLow', cl.get('params.product'));
        },
        loadPastLevel(data) {
            const id = data?.id;
            const date = data?.date;
            const loc = data?.loc;
            const start = data?.start;
            const end = data?.end;
            const updated = dayjs().format();

            cl.push('product.pastLevels', {
                id,
                date,
                loc,
                start,
                end,
                updated,
            });
        }
    };

    return {
        init: _i.init,
        load: _i.load,
        new: _i.new,
        getHistory: _i.getHistory,
        getAdjustments: _i.getAdjustments,
        getLow: _i.getLow,
        validate: _i.validate,
        createBarcode: _i.createBarcode,
        loadPastLevel: _i.loadPastLevel,
    };
})(window.cl);