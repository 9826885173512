window.cl = window.cl || {};

window.cl.inventory = ((cl) => {
    const _on = {
        inventoryNew(e) {
            e?.original?.preventDefault?.();
            cl.routes.go('/inventory-adjustment/new');
        },
        inventoryNewHandleFile(e) {
            const file = e.node.files[0];
            const reader = new FileReader();

            reader.onerror = () => {
                cl.error('Cannot Read File');
            };

            reader.onload = e => {
                const lines = e.target.result
                    .split(/\r\n|\n/)
                    .map(l=>l.replace(/[\"\,]/g, ''))
                    .filter(l=>l !== '');

                cl.set('inventory.new.import', lines);
            }

            if (!_.isUndefined(file)) {
                reader.readAsText(file);
            }
        },
        inventoryNewSetLocation(e, loc) {
            cl.set('inventory.new.location', loc);
        },
        inventoryNewFileClick(e) {
            e.node.value = null;
            console.log(e);
        },
        inventoryNewProcess() {
            const notFound = [];
            const imp = cl.get('inventory.new.import');

            imp.forEach((bc, i) => {
                const ref = cl.get(`products.lookup.barcodes.ref_${bc}`);

                if (!_.isUndefined(ref)) {
                    return;
                }

                const lines = [];
                _.range(-10, 11).forEach(ii => {
                    const p = cl.exec('product.fromBarcode', imp[i+ii]);
                    lines.push(`${imp[i+ii]}${p ? ` - ${p.sku} - ${p.name}` : ' --- NOT FOUND ---'}`)
                })

                notFound.push({
                    bc,
                    lines,
                });
            })

            if (!notFound.length) {
                cl.send('newInventoryScan', {
                    'barcodes': cl.get('inventory.new.import'),
                    'location': cl.get('inventory.new.location'),
                });

                return;
            }

            cl.set('inventory.new.notFound', notFound);
            cl.update('inventory.new.notFound');
            cl.error(`${notFound.length} Barcode(s) Not Found`);
        },
        async inventoryNewProcessTest() {
            const getNum = async () => {
                const res = await cl.prompt({
                    message: 'Number Of Discrepancies To Generate',
                    default: '10',
                });

                if (res.buttonClicked === 'cancel') {
                    return;
                }

                const val = Number(res.inputValue.trim());
                if (!_.isInteger(val)) {
                    cl.error('Invalid Number');
                    return await getNum();
                }

                return val;
            }

            const location = cl.get('inventory.new.location');
            const [ locMain, locSub ] = location.split('.');
            const loc = `inventory.${locMain}.${locSub}`;
            const num = await getNum();
            let cnt = 1;

            const barcodes = _.shuffle(cl.get('products.all')).reduce((a, p) => {
                const barcode = p?.barcodes?.[0];
                if (!barcode) {
                    return a;
                }
                const qty = Math.max(_.get(p, loc), 0);
                let n = qty;
                if (cnt < num && n > 0) {
                    Math.random() < 0.5 ? n++ : n--;
                    cnt++;
                }
                a.push(...Array(n).fill(barcode));

                return a;
            }, []);

            cl.send('newInventoryScan', { barcodes, location });
        },
        inventoryProcessEmpty() {
            cl.send('newInventoryScan', {
                'barcodes': cl.get('inventory.new.import'),
                'location': cl.get('inventory.new.location'),
            });
        },
        inventoryNewScanList() {
            const scanList = [];
            const imp = cl.get('inventory.new.import');

            imp.forEach(bc => {
                const ref = cl.get(`products.lookup.barcodes.ref_${bc}`);
                const p = cl.exec('product.fromBarcode', bc);

                scanList.push(`${bc}${_.isUndefined(ref) ? ' --- NOT FOUND ---' : ` - ${p.sku} - ${p.name}`}`);
            })

            cl.set('inventory.new.scanList', scanList);
        },
        inventorySelect(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/inventory-adjustment/${id}`);
        },
        inventorySrcLastPage() {
            _i.lastPage();
        },
        inventorySrcNextPage() {
            _i.nextPage();
        },
        inventorySrcGoToProduct(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/product/${id}/history`);
        },
        inventorySrcSave() {
            _i.save();
        },
        inventorySrcDelete() {
            cl.confirm({
                message: "<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want to delete this scan?<br>There is no undo.</div>",
                ok: 'Delete',
                okFunc() {
                    cl.send('inventoryDeleteScan', cl.get('params.inventory'));
                },
            });
        },
        inventorySrcPerPage(e) {
            cl.set('inventory.src.paging.perPage', e.node.value);
        },
        inventorySrcPageInput(e) {
            const l = _.ceil(cl.exec('inventory.src.itemsFiltered')?.length ?? 0 / cl.get('lowInventory.paging.perPage'));
            if (/[^0-9]/.test(e.node.value)) {
                e.node.value = cl.get('lowInventory.paging.page');
            }
            if (e.node.value < 1) {
                e.node.value = 1;
            }
            if (e.node.value > l) {
                e.node.value = l;
            }
            cl.set('lowInventory.paging.page', Number(e.node.value).toFixed());
        },
        inventorySrcAdjQtyDown(e, id) {
            const i = cl.exec('inventory.srcIndex');

            if (i <= -1) {
                return;
            }

            const inv = cl.get(`inventory.all.${i}`);
            const ii = _.findIndex(inv.items, ['productId', id]);
            if (ii <= -1) {
                return;
            }

            cl.decrement(`inventory.all.${i}.items.${ii}.adjActual`);
            setTimeout(_i.changes, 0);
        },
        inventorySrcAdjQtyUp(e, id) {
            const i = cl.exec('inventory.srcIndex');

            if (i <= -1) {
                return;
            }

            const inv = cl.get(`inventory.all.${i}`);
            const ii = _.findIndex(inv.items, ['productId', id]);

            if (ii <= -1) {
                return;
            }

            cl.increment(`inventory.all.${i}.items.${ii}.adjActual`);
            setTimeout(_i.changes, 0);
        },
        inventorySrcAdjQty(e, id) {
            const i = cl.exec('inventory.srcIndex');
            if (i < 0) {
                return;
            }

            const inv = cl.get(`inventory.all.${i}`);
            const ii = _.findIndex(inv.items, ['productId', id]);
            if (ii < 0) {
                return;
            }

            const val = (e.node.value || '').trim();
            if (!/^[\d-+]+$/.test(val)) {
                return;
            }

            cl.set(`inventory.all.${i}.items.${ii}.adjActual`, Number(val));
            setTimeout(_i.changes, 0);
        },
        inventorySrcReload() {
            _i.getScan();
        },
        inventorySrcExe(e, id) {
            cl.confirm({
                message: "<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want to make this adjustment?<br>There is no undo.</div>",
                ok: 'Adjust',
                okFunc() {
                    if (cl.exec('inventory.getSrc').changes) {
                        cl.error('There are unsaved pending changes');
                        return
                    }

                    cl.send('inventoryScanAdjustment', {
                        scanID: cl.exec('inventory.getSrc').id,
                        productIDs: [id],
                    });
                },
            })
        },
        inventorySrcSort(e, name) {
            const by = cl.get('inventory.src.paging.sortBy');

            if (name === by) {
                const dir = cl.get('inventory.src.paging.sortDir');
                cl.set('inventory.src.paging.sortDir', dir==='asc'?'desc':'asc');
                return;
            }

            cl.set('inventory.src.paging.sortBy', name);
        },
        inventorySrcExport() {
            const src = cl.exec('inventory.getSrc');
            const products = _.orderBy(src.items, ['sku', 'asc'])
                .map(i => {
                    const p = cl.exec('product.fromId', i.productId)
                    return {
                        'sku': p.sku,
                        'name': p.name,
                        'size': p.size,
                        'color': p.color,
                        'brand': p.brand,
                        'supplier': p.supplier,
                        'cost': p.cost,
                        'price': p.price,
                        'department': p.department,
                        'type': p.group,
                        'productId': p.id,
                        'inStock': i.qtyRecord,
                        'scanned': i.qtyActual,
                        'adjustment': i.adjSuggested,
                    }
                })

            const name = `inventory-scan-${src.id}-${src.location.replace('.', '-')}.csv`;
            const data = [Object.keys(products[0]).map(n=>_.capitalizeAllSplit(n))].concat(products);

            cl.csv(name, data);
        },
        inventoryDiffClick(e, diff, sku) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/inventory-adjustment/${diff.scanId}`);
            cl.set('inventory.src.paging.search', sku);
        },
        inventorySrcSearch(e) {
            cl.set('inventory.src.paging.search', e.node.value);
        },
        inventorySrcScanList(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/inventory-scan/${id}`);
        },
    };
    const _set = {
        'inventory.all': [],
        'inventory.list'() {
            return _(cl.get('inventory.all'))
                .orderBy(cl.get('inventory.paging.sortBy'), 'desc')
                .value();
        },
        'inventory.paging.page': 1,
        'inventory.paging.perPage': 10,
        'inventory.paging.perPageOptions': [10, 25, 50, 100],
        'inventory.paging.sortBy': 'created',
        'inventory.paging.sortAsc': true,
        'inventory.new.import': [],
        'inventory.new.notFound': [],
        'inventory.new.location': '',
        'inventory.new.disabled'() {
            const imp = cl.get('inventory.new.import');
            const loc = cl.get('inventory.new.location');

            if (imp.length > 0 && loc !== '') {
                return '';
            }

            return ' disabled';
        },
        'inventory.new.testDisabled'() {
            if (cl.get('inventory.new.location') !== '') {
                return '';
            }

            return ' disabled';
        },
        'inventory.new.scanList': [],
        'inventory.getSrc'() {
            return cl.get(`inventory.all.${cl.exec('inventory.srcIndex')}`);
        },
        'inventory.srcIndex'() {
            return _.findIndex(cl.get('inventory.all'), ['id', cl.get('params.inventory')]);
        },
        'inventory.src.paging.search': '',
        'inventory.src.paging.searchIds': [],
        'inventory.src.paging.page': 1,
        'inventory.src.paging.perPage': 10,
        'inventory.src.paging.perPageOptions': [10, 25, 50, 100],
        'inventory.src.paging.sortBy': 'sku',
        'inventory.src.paging.sortDir': 'asc',
        'inventory.src.paging.arrow'(name) {
            if (cl.get('inventory.src.paging.sortBy') !== name) {
                return '';
            }

            const dir = cl.get('inventory.src.paging.sortDir') === 'asc';
            return `<i class="material-icons">arrow_drop_${dir ? 'up' : 'down'}</i>`;
        },
        'inventory.src.itemsFiltered'() {
            const items = cl.exec('inventory.getSrc').items ?? [];
            const mf = i => {
                const p = cl.exec('product.fromId', i.productId);

                if (_.isUndefined(p)) {
                    return i;
                }

                i.sku = p.sku;
                i.name = p.name;

                return i;
            };

            const searchIds = cl.get('inventory.src.paging.searchIds');
            if (searchIds.length === 0) {
                return items?.map?.(mf);
            }

            const filtered = _(items)
                .keyBy('productId')
                .at(searchIds)
                .filter(i => !!i)
                .map(mf)
                .value();

            const perPage = cl.get('inventory.src.paging.perPage');

            if (cl.get('inventory.src.paging.page') > Math.ceil(filtered.length / perPage)) {
                cl.set('inventory.src.paging.page', 1);
            }

            return filtered;
        },
        'inventory.src.itemsList': () => {
            const items = cl.exec('inventory.src.itemsFiltered');
            const page = cl.get('inventory.src.paging.page');
            const perPage = cl.get('inventory.src.paging.perPage');
            const to = perPage * page;
            const from = to - perPage;
            const sortBy = cl.get('inventory.src.paging.sortBy');
            const sortDir = cl.get('inventory.src.paging.sortDir');
            const maxPage = Math.ceil(items.length / perPage);

            if (items.length && page > maxPage) {
                cl.set('inventory.src.paging.page', maxPage);
            }

            return _(items)
                .orderBy(sortBy, sortDir)
                .slice(from, to)
                .value();
        },
        'inventory.src.formatLocation': (loc) => {
            const locs = loc.split('.');
            if (locs.length === 0) {
                return '';
            } else if (locs.length === 1) {
                return locs[0];
            }

            const main = locs[0];
            const stl = locs[1].toLowerCase();
            const sub = stl === 'main' ? '' : ('.' + stl.substr(0, 1));

            return `${main}${sub}`;
        },
        'inventory.scanList': [],
        'inventory.scanListFields': [
            {
                src: 'scanOrder',
                filter: false,
                sort: true,
                search: false,
            }, {
                src: 'barcode',
                sort: true,
                search: true,
            }, {
                src: 'type',
                filter: true,
                sort: true,
                search: true,
            }, {
                src: 'brand',
                filter: true,
                sort: true,
                search: true,
            }, {
                src: 'sku',
                sort: true,
                link: 'productFromSku',
                search: true,
            }, {
                src: 'name',
                sort: true,
                search: true,
            }
        ],
    };
    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);

            document.addEventListener("leftArrow", (e) => {
                _.defer(_i.lastPage);
            });

            document.addEventListener("rightArrow", (e) => {
                _.defer(_i.nextPage);
            });

            document.addEventListener("save", (e) => {
                if (cl.routes.get() === 'inventory') {
                    _.defer(_i.save);
                }
            });

            cl.observe('inventory.src.paging.search', () => {
                const search = cl.get('inventory.src.paging.search');

                cl.set('inventory.src.paging.searchIds', search.length <= 2 ? [] : cl.products.search(search).map(s => s.ref));
            });
        },
        getScan() {
            _.log(cl.get('params.inventory'), cl.get('params'));
            cl.send('inventoryGetScan', cl.get('params.inventory'));
        },
        loadScan(id, scan) {
            const inv = cl.get('inventory.all') || [];

            if (inv.length === 0 || cl.get('products.all').length === 0) {
                setTimeout(()=>cl.inventory.loadScan(id, scan), 30);
                return;
            }

            const i = _.findIndex(inv, ['id', String(id)]);
            if (i <= -1) {
                return;
            }

            (scan || []).map(i => {
                const p = cl.exec('product.fromId', i.productId);

                if (_.isUndefined(p)) {
                    return i;
                }

                i.sku = p.sku;
                i.name = p.name;

                return i;
            });

            cl.set(`inventory.all.${i}.items`, scan);
            cl.set(`inventory.all.${i}.itemsHash`, cl.hash(JSON.stringify(scan)));
            cl.set(`inventory.all.${i}.changes`, false);
        },
        deleteScan(id) {
            const i = _.findIndex(cl.get('inventory.all'), ['id', String(id)]);
            if (i <= -1) {
                return;
            }

            cl.splice('inventory.all', i, 1);
            cl.routes.go('/inventory-adjustment');
        },
        nextPage() {
            if (cl.routes.get() !== 'inventory') {
                return;
            }

            const pp = Number(cl.get('inventory.src.paging.perPage'));
            const lim = Math.ceil(cl.exec('inventory.src.itemsFiltered')?.length ?? 0 / pp);
            const np = Number(cl.get('inventory.src.paging.page')) + 1

            cl.set('inventory.src.paging.page', np > lim ? 1 : np);
        },
        lastPage() {
            if (cl.routes.get() !== 'inventory') {
                return;
            }

            const page = Number(cl.get('inventory.src.paging.page'));

            const lp = page - 1;
            const np = Math.ceil(cl.exec('inventory.src.itemsFiltered')?.length ?? 0 / cl.get('inventory.src.paging.perPage'));

            cl.set('inventory.src.paging.page', lp === 0 ? np : lp);
        },
        changes() {
            const i = cl.exec('inventory.srcIndex');

            if (i <= -1) {
                return;
            }

            const scan = cl.get(`inventory.all.${i}.items`);
            const oldHash = cl.get(`inventory.all.${i}.itemsHash`);
            const newHash = cl.hash(JSON.stringify(scan));

            cl.set(`inventory.all.${i}.changes`, oldHash !== newHash);
        },
        save() {
            if (cl.exec('inventory.getSrc').changes) {
                cl.send('saveInventoryScan', cl.exec('inventory.getSrc'));
            }
        },
        scanAdjustment(scan) {
            const id = scan.scanID;
            const invs = cl.get('inventory.all');
            const i = _.findIndex(invs, ['id', id]);

            if (i <= -1) {
                return;
            }

            scan.productIDs.forEach(pid => {
                const ii = _.findIndex(invs[i].items, ['productId', pid]);
                if (ii > -1) {
                    cl.set(`inventory.all.${i}.items.${ii}.adjusted`, true);
                }
            })
        },
        async getScanList() {
            await cl.data.allLoaded();
            cl.send('inventoryScanList', cl.get('params.scan'));
        },
        scanList(data) {
            const list = data.map((bc, i) => {
                const p = cl.exec('product.fromBarcode', bc);
                return {
                    scanOrder: i + 1,
                    id: p.id,
                    barcode: bc,
                    type: p.group,
                    brand: p.brand,
                    sku: p.sku,
                    name: p.name,
                }
            });
            cl.set('inventory.scanList', list || []);
        },
    };

    return {
        init: _i.init,
        getScan: _i.getScan,
        loadScan: _i.loadScan,
        deleteScan: _i.deleteScan,
        scanAdjustment: _i.scanAdjustment,
        getScanList: _i.getScanList,
        scanList: _i.scanList,
    };
})(window.cl);