import { successSound, failureSound, loadingSound } from './utilities/sound';

window.cl = window.cl || {};

window.cl.sound = ((cl) => {

    const _i = {
        init() {
            //
        },
    };

    return {
        init: _i.init,
        success: successSound,
        failure: failureSound,
        loading: loadingSound,
    }
})(window.cl);