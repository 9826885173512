import data from './sessionData';
import helper from './sessionHelper';

window.cl = window.cl || {};

window.cl.sessions = (cl => {
    const _dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
    const _label = 'posLabel';
    const _loc = 'posLocation';

    const _set = {
        'pos.sessions.all': cl.ls.get('sessions') || [],
        'pos.sessions.list'() {
            return data.getOpenSessions();
        },
        'pos.sessions.indexFromUUID'(uuid) {
            return data.getSessions()?.findIndex?.(s => s.uuid === uuid) ?? -1;
        },
        'pos.sessions.current'() {
            return data.getCurrentSession();
        },
        'pos.sessions.currentIndex'() {
            return data.getCurrentSessionIndex();
        },
        'pos.sessions.currentSaleIndex'() {
            return data.getCurrentSaleIndex();
        },
        'pos.sessions.currentSale'() {
            return data.getCurrentSale();
        },
        'pos.sessions.currentSaleItems'() {
            return data.getCurrentItems() ?? [];
        },
        'pos.sessions.currentSaleActiveItems'() {
            return data.getCurrentItems()?.filter?.(i => !i.deleted) ?? [];
        },
        'pos.sessions.currentSaleDeletedItems'() {
            return data.getCurrentItems()?.filter?.(i => i.deleted) ?? [];
        },
        'pos.sessions.activeSales'() {
            return data.getOpenSales();
        },
        'pos.sessions.salesTotal'(uuid) {
            const session = data.getSession(uuid);
            return session?.sales?.filter(s => !s.deleted && s.completed)
                .reduce?.((a, s) => {
                    a += s?.items?.filter(i => !i.deleted)
                        .map(i => { i.product = helper.getProductFromId(i.id); return i; })
                        .filter(i => !_.isUndefined(i.product?.price))
                        .reduce((a, i) => {
                            a += helper.lineTotalAndTax(i, i.product);
                            return a;
                        }, 0) ?? 0;

                    return a;
                }, 0) ?? 0;
        },
        'pos.sessions.getLabel'() {
            return cl.ls.get(_label);
        },
        'pos.sessions.getLocation'() {
            const loc = cl.ls.get(_loc);
            if (!loc) {
                return 'Set Location';
            }

            const names = cl.exec('products.mainLoactionNames');
            return `Location: ${names[loc]}`;
        },
        'pos.sessions.getRegister'() {
            const loc = cl.ls.get(_loc);
            if (!loc) {
                return 'Set Register';
            }

            const lab = cl.ls.get(_label);
            if (!lab) {
                return 'Set Register';
            }

            return `Register: ${lab}`;
        },
        'pos.sessions.getRegisterOptions'() {
            const loc = cl.ls.get(_loc);
            if (!loc) {
                return [];
            }

            const names = cl.exec('products.mainLoactionNames');
            const options = _.range(4).map(opt => `${names[loc]} ${opt + 1}`);
            const label = cl.ls.get(_label);
            if (label) {
                return options.filter(opt => opt !== names[loc]);
            }

            return options;
        },
    };

    const _on = {
        posSetRegister(e, register) {
            cl.ls.set(_label, register);
            cl.update();
        },
        posSetLocation(e, loc) {
            if (cl.ls.get(_loc) !== loc) {
                cl.ls.remove(_label);
            }

            cl.ls.set(_loc, loc);
            cl.update();
        },
        posNewSession() {
            _i.newSession();
        },
        posSelectSession(e, uuid) {
            cl.session.load(uuid);
        },
        posDeleteSession(e, uuid) {
            data.deleteSession(uuid);
        },
        posEndOfDay() {
            const location = cl.ls.get(_loc);
            const from = cl.dayjs().startOf('day').utc().format(_dateFormat);
            const to = cl.dayjs().endOf('day').utc().format(_dateFormat);

            cl.send('registerEndOfDay', { location, from, to });
        },
        posEndOfDayPreview() {
            const location = cl.ls.get(_loc);
            const from = cl.dayjs().startOf('day').utc().format(_dateFormat);
            const to = cl.dayjs().endOf('day').utc().format(_dateFormat);

            cl.send('registerEndOfDayPreview', { location, from, to });
        },
        posEndOfDayYesterday() {
            const location = cl.ls.get(_loc);
            const from = cl.dayjs().subtract(1, 'days').startOf('day').utc().format(_dateFormat);
            const to = cl.dayjs().subtract(1, 'days').endOf('day').utc().format(_dateFormat);

            cl.send('registerEndOfDayPreview', { location, from, to });
        },
        async posEndOfDayXDaysAgo() {
            const res = await cl.prompt('Get End Of Day For How Many Days In The Past?');
            if (res.buttonClicked !== 'ok') {
                return;
            }

            const days = Number(res.inputValue.trim());
            if (!Number.isInteger(days)) {
                cl.confirmError('Invalid number of days');
                return;
            }

            if (days < 0) {
                cl.confirmError('Invalid number of days');
                return;
            }

            {
                const res = await cl.confirm(`Confirm end of day for ${cl.dayjs().subtract(days, 'days').startOf('day').format('LL')}?`);
                if (res.buttonClicked !== 'ok') {
                    return;
                }
            }

            const location = cl.ls.get(_loc);
            const from = cl.dayjs().subtract(days, 'days').startOf('day').utc().format(_dateFormat);
            const to = cl.dayjs().subtract(days, 'days').endOf('day').utc().format(_dateFormat);

            cl.send('registerEndOfDay', { location, from, to });
        },
    };

    const _i = {
        init() {
            if (_.isUndefined(cl.ls.get('sessions'))) {
                cl.ls.set('sessions', []);
            }

            // old stored label fix
            (() => {
                const label = cl.ls.get(_label);
                if (_.isString(label) && label.toLowerCase() === 'cb1') {
                    cl.ls.set(_label, 'Cannon Beach 1');
                }

                if (!/^(Cannon Beach|Seaside|Warehouse)\s\d$/.test(cl.ls.get(_label))) {
                    cl.ls.remove(_label);
                }

                if (!/^(ss|cb|wh)\.main$/.test(cl.ls.get(_loc))) {
                    cl.ls.remove(_loc);
                }
            })();

            cl.set(_set);
            cl.on(_on);
            cl.observe('pos.sessions.all', (nv, ov) => {
                if (!_.isUndefined(ov)) {
                    cl.ls.set('sessions', nv);
                }
            });

            cl.observe('pos.sessions.all.*.sales.*.orderRef', (nv, ov, kp) => {
                const session = cl.get(kp.split('.').slice(0, 4).join('.'));
                const sale = cl.get(kp.split('.').slice(0, -1).join('.'))

                if (
                    cl.routes.get() === 'session' &&
                    cl.get('params.session') === session.uuid &&
                    session.status !== 'closed' &&
                    sale.orderRef &&
                    !sale.completed &&
                    !sale.deleted
                ) {
                    console.log('sending sale', sale);
                    _i.getPreviousSalesData(sale.orderRef);
                }
            });

            _i.clean();
            setInterval(_i.clean, 1000 * 60 * 60);
        },
        clean() {
            cl.get('pos.sessions.all')
                .filter(s => s.status === 'closed')
                .filter(s => !_.isUndefined(s.closed))
                .map(s => {
                    const days = cl.dayjs().diff(s.closed, 'days');
                    if (days >= 5) {
                        data.deleteSession(s.uuid);
                    }

                    return s;
                });

            cl.set('pos.sessions.all', cl.get('pos.sessions.all').filter(s => !(_.isUndefined(s) || _.isNull(s))));

            const sessions = cl.get('pos.sessions.all');

            for (const sessionKey in sessions) {
                const session = sessions[sessionKey];
                const sales = session.sales;

                for (const saleKey in sales) {
                    const sale = sales[saleKey];
                    if (sale?.id > 0 && sale?.uuid !== '' && sale?.uuid !== undefined) {
                        continue;
                    }

                    cl.set(`pos.sessions.all.${sessionKey}.sales.${saleKey}.uuid`, _.uuid());
                    cl.set(`pos.sessions.all.${sessionKey}.sales.${saleKey}.id`, _.random(1000000) + 1000);
                    if (sale?.completed !== true) {
                        cl.set(`pos.sessions.all.${sessionKey}.sales.${saleKey}.deleted`, true);
                    }
                    data.clearAllCache();
                }
            }
        },
        update(type, data, table) {
            switch (table) {
                case 'register_sessions':
                    _i.updateSessions(type, data);
                    break;
                case 'register_sales':
                    _i.updateSales(type, data);
                    break;
                default:
                    console.log('pos update', arguments);
            };
        },
        updateSessions(type, data) {
            _.log('register session update', type, data);
        },
        updateSales(type, data) {
            _.log('register sale update', type, data)
        },
        newSession(label, location) {
            label = label || cl.ls.get(_label);
            location = location || cl.ls.get(_loc);

            if (!location) {
                cl.error('You Must Set Location');
                return;
            }

            if (!label) {
                cl.error('You Must Set Register');
                return;
            }

            const previous = _(cl.get('pos.sessions.all'))
                .filter(['status', 'open'])
                .filter(['location', location])
                .filter(['label', label])
                .value()
                .length

            if (previous === 0) {
                cl.session.new(label, location);
            } else {
                cl.error('Please close session before opening a new one');
            }
        },
        close(data) {
            data.begin = (new Date(data.begin).getTime() / 1000);
            data.end = (new Date(data.end).getTime() / 1000);

            require('superagent')
                .post(`https://receipt.cleanline.ninja/close.php`)
                .timeout(7000)
                .send(data || {})
                .end(function(err, res) {
                    if (_.isUndefined(res)) {
                        cl.error('Error Sending Receipt To Printer.');
                        _.log('register close empty response', err, res);
                        return;
                    }

                    const json = JSON.parse(res.text);
                    if (err || !res.ok || json.failure) {
                        cl.error('Error Sending Register Close To Printer.');
                        _.log('register close error:', err, res);
                        return;
                    }

                    _.log('register close data:', json.post);
                });

            _i.finalize(data.uuid);
        },
        finalize(uuid) {
            if (!data.hasSession(uuid)) {
                return;
            }

            data.setSessionValue(uuid, 'status', 'closed');
            data.setSessionValue(uuid, 'closed', cl.dayjs().format());
            cl.log('Session successfully closed.');
        },
        endOfDay(data) {
            data.from = (new Date(data.from).getTime() / 1000);
            data.to = (new Date(data.to).getTime() / 1000);
            // drop rentals only section for now
            delete data.rentals;

            require('superagent')
                .post('https://receipt.cleanline.ninja/eod.php')
                .timeout(7000)
                .send(data || {})
                .end(function(err, res) {
                    if (_.isUndefined(res)) {
                        cl.error('Error Sending Receipt To Printer.');
                        _.log('register end of day empty response', err, res);
                        return;
                    }

                    const json = JSON.parse(res.text);
                    if (err || !res.ok || json.failure) {
                        cl.error('Error Sending End Of Day To Printer.');
                        _.log('End Of Day Error:', err, res);
                        return;
                    }

                    _i.finalize(json.post.uuid);
                    _.log('End Of Day Data:', json.post);
                });
        },
        endOfDayPreview(data) {
            data.from = (new Date(data.from).getTime() / 1000);
            data.to = (new Date(data.to).getTime() / 1000);
            // drop rentals only section for now
            delete data.rentals;

            require('superagent')
                .post('https://receipt.cleanline.ninja/eodp.php')
                .timeout(7000)
                .send(data || {})
                .end(function(err, res) {
                    if (_.isUndefined(res)) {
                        cl.error('Error Sending Receipt To Printer.');
                        _.log('register end of day preview empty response', err, res);
                        return;
                    }

                    const json = JSON.parse(res.text);

                    if (err || !res.ok || json.failure) {
                        cl.error('Error Sending End Of Day To Printer.');
                        _.log('End Of Day Error:', err, res);
                    }

                    _i.finalize(json.post.uuid);
                    _.log('End Of Day Data:', json.post);
                });
        },
        async check() {
            await cl.data.allLoaded();

            const session = data.getCurrentSession();
            if (!session || !session?.sales?.length) {
                return;
            }

            const sale = data.getCurrentSale();
            if (!sale || (!sale?.completed && !sale?.deleted)) {
                return;
            }

            const sales = session?.sales.filter(s => !s.deleted && !s.completed);
            const id = sales[sales.length - 1]?.id;
            if (!id) {
                return;
            }

            cl.routes.go(`/sessions/${helper.getSessionRoute()}/${id}`);
        },
        async getPreviousSalesData(orderNum) {
            await cl.data.allLoaded();

            cl.send('registerGetShopifyOrderDetails', orderNum);
            cl.send('registerPreviousSalesByOrder', orderNum);
        },
        registerSalesByOrderNumber(sales) {
            if (!sales.length) {
                return;
            }

            const orderNumber = sales[0].orderRef;
            if (!orderNumber) {
                return;
            }

            const sessions = data.getSessions();
            const salesWithTotalQty = sales.map(s => {
                s.totalQty = s.items.reduce((a,i) => {
                    a += i.qty;
                    return a;
                }, 0);
                return s;
            });

            sessions?.forEach?.((session) => {
                session?.sales?.forEach?.((sale) => {
                    if (sale?.orderRef === orderNumber) {
                        data.setSaleValue(session.uuid, sale.uuid, 'orderSales', salesWithTotalQty);
                    }
                });
            });
        },
        registerShopifyOrderDetails(order) {
            const sessions = data.getSessions();

            sessions.forEach((session) => {
                session?.sales?.forEach?.((sale) => {
                    if (sale?.orderRef === order.order_number) {
                        data.setSaleValue(session.uuid, sale.uuid, 'order', order);
                    }
                });
            });
        },
    };

    return {
        init: _i.init,
        update: _i.update,
        close: _i.close,
        endOfDay: _i.endOfDay,
        endOfDayPreview: _i.endOfDayPreview,
        loc: _loc,
        label: _label,
        check: _i.check,
        registerSalesByOrderNumber: _i.registerSalesByOrderNumber,
        registerShopifyOrderDetails: _i.registerShopifyOrderDetails,
    };
})(window.cl);