import Alertify from 'alertify.js';

const promptHandler = e => {
    if (e.nodeName === 'INPUT' &&
        e.type === 'text' &&
        e.parentNode &&
        e.parentNode.nodeName === 'DIV' &&
        e.parentNode.parentNode &&
        e.parentNode.parentNode.nodeName === 'DIV' &&
        e.parentNode.parentNode.classList &&
        e.parentNode.parentNode.classList.contains('dialog') &&
        e.parentNode.parentNode.parentNode &&
        e.parentNode.parentNode.parentNode.nodeName === 'DIV' &&
        e.parentNode.parentNode.parentNode.classList &&
        e.parentNode.parentNode.parentNode.classList.contains('alertify')) {
        const ok = document.querySelector('.alertify .dialog .ok')

        if (ok && _.isFunction(ok.click)) {
            try {
                ok.click();
            } catch (e) {
                _.log(e);
            }
        }
    }
}

document.addEventListener('enterFire', (e) => {
    promptHandler(e.detail);
});

const maxItems = 10;
const position = 'bottom right';

const log = (msg, delay = 3000) => {
    return Alertify()
        .reset()
        .logPosition(position)
        .closeLogOnClick(true)
        .maxLogItems(maxItems)
        .delay(delay)
        .log(msg);
};

const success = (msg, delay = 3000) => {
    return Alertify()
        .reset()
        .logPosition(position)
        .closeLogOnClick(true)
        .maxLogItems(maxItems)
        .delay(delay)
        .success(msg);
};

const error = (msg, delay = 30000) => {
    return Alertify()
        .reset()
        .delay(delay)
        .logPosition(position)
        .closeLogOnClick(true)
        .maxLogItems(maxItems)
        .error(msg);
};

const errorFatal = (msg, callback) => {
    return Alertify()
        .reset()
        .delay(0)
        .logPosition(position)
        .closeLogOnClick(false)
        .maxLogItems(maxItems)
        .error(msg, callback);
}

const alert = (msg = '', ok = 'Ok') => {
    return Alertify()
        .reset()
        .okBtn(ok)
        .alert(msg);
};

const prompt = (config = {}) => {
    _.defaults(config, {
        message: '',
        ok: 'Ok',
        okFunc() {},
        cancel: 'cancel',
        cancelFunc() {},
        default: ''
    });

    config.okFunc = _.once(config.okFunc);
    config.cancelFunc = _.once(config.cancelFunc);

    return Alertify()
        .reset()
        .defaultValue(config.default)
        .okBtn(config.ok)
        .cancelBtn(config.cancel)
        .prompt(config.message, config.okFunc, config.cancelFunc);
};

const confirm = (config = {}) => {
    _.defaults(config, {
        message: '',
        ok: 'Ok',
        okFunc() {},
        cancel: 'Cancel',
        cancelFunc() {}
    });

    config.okFunc = _.once(config.okFunc);
    config.cancelFunc = _.once(config.cancelFunc);

    return Alertify()
        .reset()
        .okBtn(config.ok)
        .cancelBtn(config.cancel)
        .confirm(config.message, config.okFunc, config.cancelFunc);
};

const say = (text = '') => {
    if (text) {
        let msg = new SpeechSynthesisUtterance(text);
        window.speechSynthesis.speak(msg);
    }
};



export {
    log,
    success,
    error,
    errorFatal,
    alert,
    prompt,
    confirm,
    say,
}