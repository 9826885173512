import Alertify from 'alertify.js';
// reference https://alertifyjs.org

window.cl = window.cl || {};

window.cl.dialog = ((cl) => {
    const _i = {
        init() {
            document.addEventListener("enterFire", (e) => {
                _.defer(_i.promptHandler, e.detail);
            });
            document.addEventListener("escapeFire", (e) => {
                _.defer(_i.promptHandlerEscape, e.detail);
            });
        },
        log(msg, delay = 3000) {
            return Alertify()
                .reset()
                .logPosition("bottom right")
                .closeLogOnClick(true)
                .maxLogItems(10)
                .delay(delay)
                .log(msg);
        },
        logAll(msg) {
            cl.sendSilent('logAll', msg);
        },
        success(msg, delay = 3000) {
            return Alertify()
                .reset()
                .logPosition("bottom right")
                .closeLogOnClick(true)
                .maxLogItems(10)
                .delay(delay)
                .success(msg);
        },
        error(msg, delay = 30000) {
            return Alertify()
                .reset()
                .delay(delay)
                .logPosition("bottom right")
                .closeLogOnClick(true)
                .maxLogItems(10)
                .error(msg);
        },
        alert(msg = '', ok = "Ok") {
            return Alertify()
                .reset()
                .okBtn(ok)
                .alert(msg);
        },
        prompt(config = {}) {
            if (_.isString(config)) {
                config = { message: config };
            }

            if (_.isFunction(config.okFunc)) {
                config.okFunc = _.once(config.okFunc);
            }

            if (_.isFunction(config.cancelFunc)) {
                config.cancelFunc = _.once(config.cancelFunc);
            }

            _.defaults(config, {
                message: '',
                ok: 'Ok',
                okFunc() {},
                cancel: 'cancel',
                cancelFunc() {},
                default: ''
            });

            return Alertify()
                .reset()
                .defaultValue(config.default)
                .okBtn(config.ok)
                .cancelBtn(config.cancel)
                .prompt(config.message, config.okFunc, config.cancelFunc);

        },
        confirm(config = {}) {
            if (_.isString(config)) {
                config = { message: config };
            }

            if (_.isFunction(config.okFunc)) {
                config.okFunc = _.once(config.okFunc);
            }

            if (_.isFunction(config.cancelFunc)) {
                config.cancelFunc = _.once(config.cancelFunc);
            }

            _.defaults(config, {
                message: '',
                ok: 'Ok',
                okFunc() {},
                cancel: 'Cancel',
                cancelFunc() {}
            });

            return Alertify()
                .reset()
                .okBtn(config.ok)
                .cancelBtn(config.cancel)
                .confirm(config.message, config.okFunc, config.cancelFunc);
        },
        confirmError(config = {}) {
            if (_.isString(config)) {
                config = { message: `<div class='error-alert'><i class='material-icons'>warning</i>${config}</div>` };
            } else if (_.isObject(config) && _.isString(config.message) && config.message.length > 0) {
                config.message = `<div class='error-alert'><i class='material-icons'>warning</i>${config.message}</div>`;
            }

            return _i.confirm(config);
        },
        say(text = '') {
            if (!text) {
                return;
            }

            const msg = new SpeechSynthesisUtterance(text);
            window.speechSynthesis.speak(msg);
        },
        errorFatal(msg, callback) {
            return Alertify()
                .reset()
                .delay(0)
                .logPosition("bottom right")
                .closeLogOnClick(false)
                .maxLogItems(10)
                .error(msg, callback);
        },
        promptHandler(e) {
            if (e.nodeName === 'INPUT' &&
                e.type === 'text' &&
                e.parentNode &&
                e.parentNode.nodeName === 'DIV' &&
                e.parentNode.parentNode &&
                e.parentNode.parentNode.nodeName === 'DIV' &&
                e.parentNode.parentNode.classList &&
                e.parentNode.parentNode.classList.contains('dialog') &&
                e.parentNode.parentNode.parentNode &&
                e.parentNode.parentNode.parentNode.nodeName === 'DIV' &&
                e.parentNode.parentNode.parentNode.classList &&
                e.parentNode.parentNode.parentNode.classList.contains('alertify')) {
                const ok = document.querySelector('.alertify .dialog .ok')

                if (_.isFunction(ok?.click)) {
                    try {
                        ok.click();
                    } catch (e) {
                        _.log(e);
                    }
                }
            }
        },
        promptHandlerEscape(e) {
            const els = document.querySelectorAll('.alertify .dialog .cancel');
            if (els.length === 0) {
                return;
            }

            const el = [...els].pop();
            if (!el || !_.isFunction(el.click)) {
                return;
            }

            try {
                el.click();
            } catch (e) {
                _.log(e);
            }
        },
    };

    return {
        init: _i.init,
        log: _i.log,
        logAll: _i.logAll,
        success: _i.success,
        error: _i.error,
        alert: _i.alert,
        prompt: _i.prompt,
        confirm: _i.confirm,
        confirmError: _i.confirmError,
        src: Alertify(),
        say: _i.say,
        errorFatal: _i.errorFatal,
    };
})(window.cl);

window.cl.log = ((...args) => {
    return cl.dialog.log(...args);
});

window.cl.logAll = ((...args) => {
    return cl.dialog.logAll(...args);
});

window.cl.error = ((...args) => {
    return cl.dialog.error(...args);
});

window.cl.success = ((...args) => {
    return cl.dialog.success(...args);
});

window.cl.alert = ((...args) => {
    return cl.dialog.alert(...args);
});

window.cl.prompt = ((...args) => {
    return cl.dialog.prompt(...args);
});

window.cl.confirm = ((...args) => {
    return cl.dialog.confirm(...args);
});

window.cl.confirmError = ((...args) => {
    return cl.dialog.confirmError(...args);
});

window.cl.say = ((...args) => {
    return cl.dialog.say(...args);
});

window.cl.errorFatal = ((...args) => {
    return cl.dialog.errorFatal(...args)
});