import data from './sessionData';
import helper from './sessionHelper';

window.test = data;

window.cl = window.cl || {};

window.cl.session = (cl => {
    const getLineTotal = (uuid, id, qty = 1) =>{
        const li = data.getItem(uuid);
        const prod = helper.getProductFromId(id);

        return helper.lineTotal(li, prod, qty);
    };

    const _set = {
        'pos.session.getSubtotal'() {
            return data.getCurrentSaleSubtotal();
        },
        'pos.session.getTotal'() {
            return data.getCurrentSaleTotal();
        },
        'pos.session.getTax'() {
            return data.getCurrentSaleTax();
        },
        'pos.session.saved'() {
            return data.getCurrentSaleSaved();
        },
        'pos.session.lineTotalRaw'(uuid, id, qty = 1) {
            const li = data.getItem(uuid);
            const prod = helper.getProductFromId(id);

            return helper.lineTotal(li, prod, qty);
        },
        'pos.session.lineTotal'(uuid, id, qty = 1) {
            return _.money(getLineTotal(uuid, id, qty));
        },
        'pos.session.lineTax'(uuid, id, qty = 1) {
            const li = data.getItem(uuid);

            return _.money(getLineTotal(uuid, id, qty) * ((li.taxPc || 0) / 100))
        },
        'pos.session.qtyLine'(uuid, id, qty = 1) {
            const prod = helper.getProductFromId(id);
            if (!prod) {
                return `Loading Product Data &hellip;`;
            }
            const item = data.getItem(uuid);
            const price = item?.price || prod?.price;



            return `Qty: ${qty} @ ${(item?.discp && Number.isFinite(item?.discp)) > 0 ? `<s>${_.money(price)}</s> ` : ''}${_.money(price * (1 - ((Number.isFinite(item?.discp) ? item?.discp : 0) / 100)))} Each`;
        },
        'pos.session.hasDisc': (uuid) => {
            const item = data.getItem(uuid);

            return item.discd || item.discde || item.discp;
        },
        'pos.session.discLine'(uuid, id) {
            const item = data.getItem(uuid);
            if (!Number.isFinite(item.discp) || !Number.isFinite(item.discd)) {
                return '';
            }

            const prod = cl.exec('product.fromId', id);
            const per = _.round((Number.isFinite(item.discp) ? item.discp : 0), 1);
            const dol = _.round((Number(item.price || prod.price) * ((Number.isFinite(item.discp) ? item.discp : 0) / 100)), 2) * item.qty;
            let line = '';

            switch (true) {
                case item.discp != 0 && item.discd != 0:
                    line += `${per}% (${_.money(dol)}) + ${_.money(item.discd)}`;
                    break;
                case item.discp != 0:
                    line += `${per}% (${_.money(dol)})`;
                    break;
                case item.discd != 0:
                    line += _.money(item.discd);
                    break;
            }

            if (line === '') {
                return '';
            }

            return `Discount: ${line} Off`;
        },
        'pos.session.activeItems'(items) {
            return items?.filter?.(i => !i.deleted);
        },
        'pos.session.searchString': '',
        'pos.session.searchIds': [],
        'pos.session.searchResults'() {
            const products = cl.get('products.all');
            const ids = cl.get('pos.session.searchIds');
            const location = cl.ls.get('posLocation');

            return _(products)
                .keyBy('id')
                .at(ids)
                .orderBy([p => _.at(p, `inventory.${location}`)], ['desc'])
                .take(25)
                .value();
        },
        'pos.session.change'() {
            const total = data.getCurrentSaleTotal() || 0;
            const payments = data.getCurrentSalePaymentsTotal() || 0;
            const change = payments - total;

            return change > 0 ? Number(_.moneyRaw(change)) : 0;
        },
        'pos.session.balance'() {
            const total = cl.exec('pos.session.getTotal') || 0;
            const payments = data.getCurrentSalePaymentsTotal() || 0;
            const balance = total - payments;

            return balance > 0 ? balance : 0;
        },
        'pos.session.paid'() {
            const total = data.getCurrentSaleTotal() || 0;
            const payments = data.getCurrentSalePaymentsTotal() || 0;

            return Math.abs(Number(_.moneyRaw(payments))) >= Math.abs(Number(_.moneyRaw(total)));
        },
        'pos.session.isDotCom'() {
            const sess = data.getCurrentSession();
            return sess?.location === 'wh.main' && (sess?.label?.toLowerCase()?.includes('warehouse') || sess?.label?.toLowerCase() === 'dotcom');
        },
        'pos.session.isOpen'() {
            return data.getCurrentSession()?.status === 'open';
        },
        'pos.session.printReceipt': true,
        'pos.session.itemOutOfStock'(item) {
            const product = cl.exec('product.fromId', item?.id);
            if (product?.track === 'false') {
                return false;
            }

            const sale = data.getCurrentSale();
            const totalQty = sale?.items?.reduce?.((a, i) => {
                if (item.id === i.id) {
                    a += i.qty;
                }
                return a;
            }, 0) ?? 0;
            const sess = data.getCurrentSession();
            const location = sess.location;
            const locs = location.split('.');
            const inStockQty = product?.inventory?.[locs[0]]?.[locs[1]];

            if (totalQty > inStockQty) {
                return true;
            }

            return false;
        },
    };

    const _on = {
        posSessionExit(e) {
            e?.original?.preventDefault?.();
            cl.routes.go('/sessions');
        },
        async posCloseSession(e, id) {
            const res = await cl.confirm('<div class="error-alert"><i class="material-icons">warning</i>Are you sure you want to close this session?</div>');
            if (res.buttonClicked !== 'ok') {
                return;
            }

            _i.syncAndCloseSession(id);
        },
        posSessionGoToSale(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/sessions/${helper.getSessionRoute()}/${id}`);
        },
        posSessionNewSale() {
            _i.newSale();
        },
        async posSessionDeleteSale(e) {
            e?.original?.preventDefault?.();
            if (!data.hasCurrentSale()) {
                return;
            }

            const deleteSale = () => {
                data.setCurrentSaleValue('deleted', true);
                const open = data.getOpenSales();
                const next = open[open.length - 1];
                if (_.isUndefined(next)) {
                    return _i.newSale();
                }

                cl.routes.go(`/sessions/${helper.getSessionRoute()}/${next.id}`);
            };

            const sale = data.getCurrentSale();
            if (_.isUndefined(sale) || (sale?.items?.length ?? []) <= 0) {
                deleteSale();
                return;
            }

            const res = await cl.confirm({
                message: "<div class='error-alert'><i class='material-icons'>warning</i>This sale is not empty.<br> Are you sure you want to delete it.</div>",
                ok: 'Delete',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }
            deleteSale();
        },
        async posSessionCloneDotComSale(e) {
            e?.original?.preventDefault?.();
            if (!data.hasCurrentSale()) {
                return;
            }

            const sale = data.getCurrentSale();
            _i.newSale();
            data.setCurrentSaleValue('orderRef', sale.orderRef);
            data.setCurrentSaleValue('order', sale.order);
            const sessionUUID = data.getCurrentSessionValue('uuid');
            if (sessionUUID && sale.uuid) {
                data.setSaleValue(sessionUUID, sale.uuid, 'deleted', true);
            }
        },
        posSessionAddProduct(e, id) {
            _i.addProduct(id);
        },
        async posSessionAddRental(e, id, sku) {
            const excluded = {
                CLSLEXI: true,
                CLSRNTLSDM: true,
                CLSRNTDLVR: true,
            };

            if (sku in excluded) {
                _i.addProduct(id);
                return;
            }

            const res = await cl.prompt({
                message: 'How Many Days?',
                default: '1',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const qty = Number(res.inputValue.trim());
            if (!Number.isInteger(qty)) {
                cl.confirmError('Invalid number of days');
                return;
            }

            if (qty <= 0) {
                cl.confirmError('Invalid number of days');
                return;
            }

            _i.addRental(id, qty);
        },
        posSessionSelectItem(e, uuid) {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [0]');
                return;
            }

            data.setCurrentSaleValue('itemSelected', uuid);
        },
        async posSessionItemSetQty() {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [1]');
                return;
            }

            if (!data.hasSelectedItem()) {
                cl.confirmError('No Item Selected');
                return;
            }

            const defaultQty = String(data.getSelectedItemValue('qty') || 1);

            const res = await cl.prompt({
                message: 'Set Product Qty:',
                default: defaultQty,
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const qty = Number(res.inputValue.trim());
            if (qty === 0) {
                const res = await cl.confirmError('Quantity cannot be zero.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionItemSetQty'), 200);
                }
                return;
            }

            if (!Number.isInteger(qty)) {
                const res = await cl.confirmError('Quantity must be a whole number.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionItemSetQty'), 200);
                }
                return;
            }

            if (Math.abs(qty) > 1000) {
                const res = await cl.confirmError('Quantity cannot be more than 1000.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionItemSetQty'), 200);
                }
                return;
            }

            try {
                if (!data.hasSelectedItem()) {
                    cl.confirmError('No Item Selected');
                    return;
                }

                const prevQty = data.getSelectedItemValue('qty');
                const prevDiscD = data.getSelectedItemValue('discd');
                if ((prevQty > 0 && qty < 0) || (prevQty < 0 && qty > 0) && prevDiscD !== 0) {
                    data.setSelectedItemValue('discd', prevDiscD * -1);
                }
                data.setSelectedItemValue('qty', qty);
            } catch (e) {
                cl.error(e);
            }
        },
        posSessionDeleteItem() {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [2]');
                return;
            }

            if (!data.hasSelectedItem()) {
                cl.confirmError('No Item Selected');
                return;
            }

            const selectedItem = data.getSelectedItem();
            if (!selectedItem) {
                return;
            }

            const id = selectedItem.id;
            const uuid = selectedItem.uuid;
            const deleted = true;
            const item = _i.newItem({ id, uuid, deleted });
            data.replaceSelectedItem(item);

            const uuids = data.getActiveItems().map(i => i.uuid);
            const nuuid = uuids[uuids.length - 1];

            data.setSelectedItem(nuuid);
        },
        async posSessionDiscDA() {
            if (!data.hasCurrentSale()) {
                cl.confirmError('No current sale');;
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [3]');
                return;
            }

            if (!data.hasSelectedItem()) {
                cl.confirmError('No Item Selected');
                return;
            }

            const item = data.getSelectedItem();
            if (!item) {
                cl.confirmError('Please Select A Product');
                return;
            }

            const discd = _.moneyRaw(data.getSelectedItemValue('discd') ?? 0);
            const prod = helper.getProductFromId(item.id);
            const message = `<span class="alert-discount">$</span><strong>${item.sku || prod.sku} - ${item.name || prod.name}</strong><br>Discount Dollar Amount:`;

            const res = await cl.prompt({
                message,
                default: discd,
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const val = Number(res.inputValue.trim().replace(/[$-]/g, ''));
            if (!Number.isFinite(val)) {
                const res = await cl.confirmError('Invalid Dollar Amount.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionDiscDA'), 200);
                }
                return;
            }

            if (val < 0) {
                const res = await cl.confirmError('Amount cannot be negative.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionDiscDA'), 200);
                }
                return;
            }

            const isNeg = data.getSelectedItemValue('qty') < 0;
            data.setSelectedItemValue('discd', isNeg ? val * -1 : val);
        },
        async posSessionDiscPC() {
            if (!data.hasCurrentSale()) {
                cl.confirmError('No current sale');;
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [4]');
                return;
            }

            if (!data.hasSelectedItem()) {
                cl.confirmError('No Item Selected');
                return;
            }

            const item = data.getSelectedItem();
            if (!item) {
                cl.confirmError('Please Select A Product');
                return;
            }

            const prod = helper.getProductFromId(item.id);
            const message = `<span class="alert-discount">%</span><strong>${item.sku || prod.sku} - ${item.name || prod.name}</strong><br>Discount Percentage:`;
            const discp = String(data.getSelectedItemValue('discp') ?? 0);
            const res = await cl.prompt({
                message,
                default: discp,
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const val = Number(res.inputValue.trim().replace(/[%-]/g, ''));
            if (!Number.isFinite(val)) {
                const res = await cl.confirmError('Invalid Percentage.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionDiscPC'), 200);
                }
                return;
            }

            if(val < 0) {
                const res = await cl.confirmError('Percentage cannot be negative.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionDiscPC'), 200);
                }
                return;
            }

            if (val > 100) {
                const res = await cl.confirmError('Percentage cannot be greater than 100.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionDiscPC'), 200);
                }
                return;
            }

            data.setSelectedItemValue('discp', val);
        },
        async posSessionSetTax() {
            if (!data.hasCurrentSale()) {
                cl.confirmError('No current sale');;
                return;
            }

            const item = data.getSelectedItem();
            if (!item) {
                cl.confirmError('Please Select A Product');
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [5]');
                return;
            }

            if (!data.hasSelectedItem()) {
                cl.confirmError('No Item Selected');
                return;
            }

            const prod = helper.getProductFromId(item.id);
            const message = `<strong>${item.sku || prod.sku} - ${item.name || prod.name}</strong><br>Tax Percentage:`;

            const res = await cl.prompt({
                message,
                default: String(item.taxPc || 0),
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const pc = Number(res.inputValue.trim().replace(/[-%]/g, ''));
            if (!Number.isFinite(pc) || pc < 0 || pc > 100) {
                const res = await cl.confirmError('Invalid Tax Percentage');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionSetTax'), 200);
                }
                return;
            }

            data.setSelectedItemValue('taxPc', pc);
        },
        async posSessionNote() {
            if (!data.hasCurrentSale()) {
                cl.confirmError('No current sale');;
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [6]');
                return;
            }

            if (!data.hasSelectedItem()) {
                cl.confirmError('No Item Selected');
                return;
            }

            const note = String(data.getSelectedItemValue('note')).trim();
            const res = await cl.prompt({
                message: 'Enter Note:',
                default: note ?? '',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const val = res.inputValue.trim();
            if (!note && !val) {
                return;
            }

            data.setSelectedItemValue('note', val);
        },
        posSessionPayment() {
            if (!data.hasCurrentSale()) {
                return;
            }

            data.setCurrentSaleValue('payment', true);
        },
        posSessionProductSearch(e) {
            cl.set('pos.session.searchString', e.node.value);
        },
        posSessionProductSearchClose() {
            cl.set('pos.session.searchString', '');
        },
        posSessionExitPayment() {
            if (!data.hasCurrentSale()) {
                return;
            }

            data.setCurrentSaleValue('payment', false);
        },
        async posSessionNewPayment(e, method, note) {
            if (!data.hasCurrentSale()) {
                cl.confirmError('No current sale');
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [8]');
                return;
            }

            const currentTotal = data.getCurrentSaleTotal();
            const balance = data.getCurrentSalePaymentsTotal();
            const isNeg = ['credit', 'check'].includes(method) || currentTotal < 0;
            const sub = (isNeg && (currentTotal > balance)) ? currentTotal - balance : currentTotal;
            const total = !isNeg ? 0 : (sub || 0);

            if (Math.abs(balance) >= Math.abs(currentTotal)) {
                cl.confirmError('sale is already paid for');
                return;
            }

            if (method === 'trade-in' && !note) {
                const res = await cl.prompt('A Note Is Required For Trade Ins:');
                if (res.buttonClicked !== 'ok') {
                    return;
                }

                const val = res.inputValue.trim();
                if (!val.length) {
                    return;
                }

                if (!data.hasCurrentSale()) {
                    return;
                }

                if (!data.isCurrentSaleOpen()) {
                    cl.error('Invalid sale [7]');
                    return;
                }

                data.setCurrentSaleValue('note', val);
                setTimeout(() => {
                    _on.posSessionNewPayment(e, method, val);
                }, 100);
                return;
            }

            const methodName = _.capitalizeAll(method.split('-').join(' '));

            const res = await cl.prompt({
                message: `${methodName} Payment:`,
                default: _.moneyRaw(total),
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const amount = Number(res.inputValue.trim());
            if (amount === 0) {
                cl.confirmError(`No ${methodName} Amount Specified.`);
                return;
            }

            if (!Number.isFinite(amount)) {
                const res = await cl.confirmError('Invalid Dollar Amount');
                if (res.buttonClicked === 'ok')  {
                    setTimeout(() => _on.posSessionNewPayment(e, method, note), 200);
                }
                return;
            }

            console.log(`balance+amount: ${Number(balance) + amount} sub: ${sub}`);
            if (method === 'gift-card' && Math.abs(Number(balance) + amount) > Math.abs(sub)) {
                cl.confirmError(`Max Gift Card Payment Is ${_.money(Number(currentTotal) - Number(balance))}.`);
                return;
            }

            if (method === 'credit' && Math.abs(Number(balance) + amount) > Math.abs(currentTotal)) {
                cl.confirmError('Credit Card Payment Is Above Total.');
                return;
            }

            data.pushCurrentSaleValue('payments', {
                deleted: false,
                uuid: _.uuid(),
                method,
                amount,
            });
        },
        posSessionRemovePayment(e, index) {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [9]');
                return;
            }

            data.setCurrentSaleValue(`payments.${index}.deleted`, true);
        },
        posSessionReceipt() {
            _i.receipt();
        },
        async posSessionSetItemPrice() {
            if (!data.hasCurrentSale()) {
                cl.confirmError('No current sale');
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [10]');
                return;
            }

            if (!data.hasSelectedItem()) {
                cl.confirmError('No Item Selected');
                return;
            }

            const item = data.getSelectedItem();
            const prod = helper.getProductFromId(item.id);
            const price = _.moneyRaw(item.price || prod.price || 0);
            const res = await cl.prompt({
                message: 'Set Product Price:',
                default: price,
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const val = Number(res.inputValue.trim());
            if (!Number.isFinite(val)) {
                const res = await cl.confirmError('Invalid dollar amount.');
                if (res.buttonClicked === 'ok') {
                    cl.fire('posSessionSetItemPrice');
                }
                return;
            }

            if (val === 0) {
                const res = await cl.confirmError('Price cannot be zero.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionSetItemPrice'), 200);
                }
                return;
            }

            if (val < 0) {
                const res = await cl.confirmError('Price cannot be negative.');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => cl.fire('posSessionSetItemPrice'), 200);
                }
                return;
            }


            data.setSelectedItemValue('price', val);
        },
        async posSessionSetOrderRef() {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [11]');
                return;
            }

            const res = await cl.prompt({
                message: 'Set Order Reference:',
                default: data.getCurrentSaleValue('orderRef') || '',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const val = res.inputValue.trim();
            if (!/\d{4,}/.test(val)) {
                cl.confirmError('Invalid order number.');
                return;
            }

            data.setCurrentSaleValue('orderRef', val);
        },
        posSessionOpenOrder(e, id) {
            const addr = `${cl.shopifyBaseAdminUrl()}/orders/${id.replace('gid://shopify/Order/', '')}/`;
            window.open(addr, '_blank');
        },
        posSessionRefreshOrder(e) {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [12]');
                return;
            }

            const ref = data.getCurrentSaleValue('orderRef');
            data.setCurrentSaleValue('orderRef', '');
            data.setCurrentSaleValue('orderRef', ref);
        },
        posSessionDotComAddProduct(e, sku, price = 0, fullPrice = 0, tax = 0) {
            const id = cl.get(`products.lookup.skus.ref_${sku}`);
            if (_.isUndefined(id)) {
                cl.confirmError(`Product ${sku} not found in inventory`);
                return;
            }

            _i.addProduct(id, -1, price, tax, fullPrice);
        },
        async posSessionChangeNote() {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [13]');
                return;
            }

            const sale = data.getCurrentSale();
            const res = await cl.prompt({
                message: `Change Note:`,
                default: sale.note,
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const val = res.inputValue.trim();
            if (!val.length) {
                return;
            }

            data.setCurrentSaleValue('note', val);
        },
    };

    const _i = {
        syncFunc: _.debounce((newVal) => {
            _i.runSync(newVal);
        }, 5000, {
            'maxWait': 3000,
            'leading': true,
        }),
        init() {
            cl.set(_set);
            cl.on(_on);

            cl.observe({
                'pos.session.searchString'() {
                    _.defer(_i.search);
                },
                'pos.sessions.all.*'(newVal, oldVal, key) {
                    if (!_.isUndefined(oldVal)) {
                        _i.syncFunc(newVal);
                    }
                },
            });

            document.addEventListener('barcode', (e) => {
                _i.barcodeListener(e.detail);
            });
        },
        fixScroll() {
            const wrap = document.getElementById("pos-items-wrap");
            wrap.scrollTop = wrap.scrollHeight;
        },
        async runSync(session) {
            if (_.isUndefined(session)) {
                return;
            }

            // await products data load
            await cl.data.allLoaded();

            // make sure we aren't re-syncing the session
            const lsKey = 'posLastSyncHash';
            const sessionHash = cl.hash(JSON.stringify(session));
            if (!cl.ls.get(lsKey)) {
                cl.ls.set(lsKey, sessionHash);
                return;
            }

            if (sessionHash === cl.ls.get(lsKey)) {
                return;
            }

            cl.ls.set(lsKey, sessionHash);

            session.sales?.forEach?.(sa => {
                sa?.items?.forEach?.(i => {
                    if (_.isUndefined(i.uuid)) {
                        i.uuid = _.uuid();
                    }
                });
            });

            if (_.isUndefined(session)) {
                _.log('undefinded session sync');
                return;
            }

            cl.sendSilent('syncRegisterSession', _i.prepairSession(session));
        },
        async syncAndCloseSession(id) {
            const session = data.getSession(id);
            if (_.isUndefined(session)) {
                return;
            }

            // await products data load
            await cl.data.allLoaded();
            session.sales?.forEach?.(sa => {
                sa?.items?.forEach?.(i => {
                    if (_.isUndefined(i.uuid)) {
                        i.uuid = _.uuid();
                    }
                });
            });

            cl.sendSilent('closeRegisterSession', _i.prepairSession(session));
        },
        prepairSession(session) {
            if (!_.isObject(session)) {
                return;
            }

            const sess = _.cloneDeep(session);
            sess?.sales?.map?.(s => {
                if (_.isUndefined(s) || _.isNull(s)) {
                    return s;
                }

                s?.items?.map?.(i => {
                    const p = helper.getProductFromId(i.id) ?? {};
                    i.name = p.name || i.name;
                    i.sku = p.sku || i.sku;
                    i.price = Number(_.moneyRaw(Number(i.price || p.price)));
                    return i;
                });

                const subTotal = s?.items?.reduce?.((a, i) => {
                    const p = helper.getProductFromId(i.id);
                    if (!p) {
                        return a;
                    }

                    a += helper.lineTotal(i, p);
                    return a;
                }, 0) ?? 0;

                const tax = s?.items?.reduce?.((a, i) => {
                    const p = helper.getProductFromId(i.id);
                    if (!p) {
                        return a;
                    }

                    a += helper.lineTax(i, p);
                    return a;
                }, 0) ?? 0;

                s.subTotal = Number(_.moneyRaw(subTotal));
                s.tax = Number(_.moneyRaw(tax));
                s.total = Number(_.moneyRaw(s.subTotal + s.tax));
                _.unset(s, 'itemSelected', 'payment');
                return s;
            });

            _.log('sync pos session', sess);

            return sess;
        },
        new(label, location) {
            if (!label || !location) {
                cl.confirmError(`Missing session ${!label ? 'label' : ''}${!label && !location ? ' &amp; ' : ''}${!location ? 'location' : ''}.`);
                return;
            }

            if (cl.exec('products.locations').map(l => l.subs.map(s => s.code)).flat().indexOf(location) == -1) {
                cl.confirmError(`Invalid location: ${location}.`);
                return;
            }

            cl.push('pos.sessions.all', {
                uuid: _.uuid(),
                created: cl.dayjs().format(),
                updated: cl.dayjs().format(),
                closed: undefined,
                label: label,
                location: location,
                status: 'open',
                saleCount: 1,
                sales: [{
                    id: 1,
                    uuid: _.uuid(),
                    items: [],
                    itemSelected: '',
                    orderRef: undefined,
                    order: undefined,
                    deleted: false,
                    completed: false,
                    payment: false,
                    payments: [],
                }],
            });
        },
        newSale() {
            cl.set('pos.session.printReceipt', true);

            if (!data.hasCurrentSession()) {
                return;
            }

            data.incrementCurrentSessionValue('saleCount');
            const id = data.getCurrentSessionValue('saleCount');
            data.pushCurrentSessionValue('sales', {
                id,
                uuid: _.uuid(),
                items: [],
                itemSelected: '',
                deleted: false,
                completed: false,
                payment: false,
                payments: [],
            });
            cl.routes.go(`/sessions/${helper.getSessionRoute()}/${id}`);
        },
        load(uuid) {
            const session = data.getSession(uuid);
            if (!session) {
                cl.confirmError(`session ${uuid} not found`);
                return;
            }
            const ids = session.sales?.filter?.(s => !s?.deleted && s?.id > 0).map(s => s.id).sort();
            const next = ids[ids.length - 1] || 1;

            cl.routes.go(`/sessions/${uuid}/${next}`);
        },
        async addMisc(id, name, skipNote = false, note = '') {
            if (!skipNote && !String(note).trim().length) {
                const res = await cl.prompt(`${_.capitalizeAll(name)} Requires Note:`);
                if (res.buttonClicked !== 'ok') {
                    return;
                }

                const val = res.inputValue.trim();
                if (!val.length) {
                    const res = await cl.confirmError('Note cannot be empty');
                    if (res.buttonClicked === 'ok') {
                        setTimeout(() => _i.addMisc(id, name, skipNote), 200);
                    }
                    return;
                }

                note = val;
            }

            const res = await cl.prompt({
                message: `${_.capitalizeAll(name)} Requires Price:`,
                default: '0.00',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const val = res.inputValue.trim();
            const price = Number(val);
            if (!Number.isFinite(price)) {
                const res = await cl.confirmError(`Invalid price ${val}`);
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => _i.addMisc(id, name, skipNote, note), 200);
                }
                return;
            }

            if (price <= 0) {
                const res = await cl.confirmError('Price must be positive');
                if (res.buttonClicked === 'ok') {
                    setTimeout(() => _i.addMisc(id, name, skipNote, note), 200);
                }
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [14]');
                return;
            }

            const uuid = _.uuid();
            const qty = 1;
            data.addItem({ id, qty, uuid, note, price });

            _i.fixScroll();
        },
        async addProduct(id, qty = 1, price = 0, tax = 0, fullPrice = 0) {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [15]');
                return;
            }

            const skipSkus = {
                'CLSLEXI': true,
            }

            const product = helper.getProductFromId(id);
            if (fullPrice > 0) {
                product.price = fullPrice;
            }

            // check for Misc product
            if (Number(product.price) === 0 && price === 0) {
                return _i.addMisc(id, product.name, product.sku in skipSkus);
            }

            // check if item is in holds
            const baseLoc = data.getCurrentSessionBaseLocation();
            if (qty > 0 && product.inventory[baseLoc].holds > 0) {
                const mainQty = product.inventory[baseLoc].main;
                const res = await cl.confirm({
                    message: `<div class='error-alert'>
                                <i class='material-icons'>warning</i>
                                ${product.sku} - ${product.name} <br>
                                is <em><strong>${mainQty < 1 ? 'only':'also'}</strong></em> available in <strong>${baseLoc}.holds</strong><br><br>
                                Would you like to transfer it to <strong>${baseLoc}.main</strong>?</div>`,
                    ok: 'Transfer',
                    cancel: 'Skip',
                });

                if (res.buttonClicked !== 'ok') {
                    return;
                }

                cl.users.getPin(pin => {
                    const user = cl.users.fromPin(pin);
                    if (_.isUndefined(user)) {
                        cl.confirmError(`No User Found For PIN: ${pin}`);
                        return;
                    }

                    cl.send('transferFromHolds', {
                        sku: product.sku,
                        loc: baseLoc,
                        user: user.username,
                        qty,
                    });
                });
            }

            const isSorfboard = product.group.toLowerCase() !== 'surfboard' && product.websitePrice > 0 && product.websitePrice < product.price;
            const discp = (price && price != 0 && product.price != 0) ? 100 - ((price / product.price) * 100) : isSorfboard ? 100 - ((product.websitePrice / product.price) * 100) : 0;

            if ((price ?? 0) !== 0 && (product?.price ?? 0) !== 0) {
                price = product.price;
            }

            if (Number.isNaN(price) || !Number.isFinite(price)) {
                price = 0;
            }

            if (!data.hasSaleItem(id)) {
                const uuid = _.uuid();
                const taxPc = tax;
                const np = _i.newItem({id, uuid, qty, discp, taxPc});

                if (!_.isUndefined(price)) {
                    np.price = Number(price);
                }

                data.addItem(np);
                _i.fixScroll();
                return;
            }

            const deleted = data.getItemValue(id, 'deleted');
            if (deleted) {
                data.setItemValue(id, 'deleted', false);
                data.setItemValue(id, 'qty', qty);
                if (!_.isUndefined(price)) {
                    data.setItemValue(id, 'price', Number(price));
                }
                data.setItemValue(id, 'discp', discp);
                data.setItemValue(id, 'taxPc', tax);
                data.setCurrentSaleValue('itemSelected', data.getItemValue(id, 'uuid'));
            } else {
                const itemQty = data.getItemValue(id, 'qty');
                if (itemQty < 0) {
                    data.decrementItemValue(id, 'qty');
                } else {
                    data.incrementItemValue(id, 'qty');
                }
                if (!_.isUndefined(price)) {
                    data.setSelectedItemValue(id, 'price', Number(price));
                }
                data.setSelectedItem(data.getItemValue(id, 'uuid'));
            }
        },
        addRental(id, days) {
            if (!data.hasCurrentSale()) {
                return;
            }

            if (!data.isCurrentSaleOpen()) {
                cl.error('Invalid sale [16]');
                return;
            }

            const prod = helper.getProductFromId(id);
            if (days > 2) {
                const uuid = _.uuid();
                const item1 = _i.newItem({
                    id,
                    uuid,
                    qty: 2,
                    note: `${days} Day ${prod.name}`,
                });
                const item2 = _i.newItem({
                    id,
                    uuid: _.uuid(),
                    qty: days - 2,
                    discp: 50,
                    note: `${days} Day ${prod.name} Discounted`,
                });

                data.addItem(item1);
                data.addItem(item2);
                data.setSelectedItem(uuid);
            } else {
                const uuid = _.uuid();
                const note = `${days} Day ${prod.name}`;
                const qty = days;
                const item = _i.newItem({ id, uuid, qty, note });
                data.addItem(item);
            }

            _i.fixScroll();
        },
        barcodeListener(bc) {
            if (cl.routes.get() !== 'session') {
                return;
            }

            cl.products.skuFromBarcode(bc, (bc, sku, id) => {
                const sale = data.getCurrentSale();

                if (cl.exec('pos.session.isDotCom') && sale.orderRef) {
                    const itemsBySku = _.keyBy(sale?.order?.items, 'sku');
                    const found = sku in itemsBySku;
                    const tax = found ? itemsBySku[sku].tax_percent : 0;

                    if (!found) {
                        cl.log('Warning:<br>Item Scanned Not<br>In Original Sale', 5e3);
                        _i.addProduct(id);
                        return;
                    }

                    _i.addProduct(id, -1, undefined, tax);
                    return;
                }
                _i.addProduct(id);
            });
        },
        search() {
            const search = cl.get('pos.session.searchString');

            if (search.length <= 2) {
                cl.set('pos.session.searchIds', []);
            }

            _.defer(() => {
                cl.set('pos.session.searchIds', cl.products.search(search).map(s => s.ref));
            });
        },
        async receipt(pin) {
            if (_.isUndefined(pin)) {
                cl.users.getPin(pin => cl.session.receipt(pin));
                return;
            }

            const user = cl.users.fromPin(pin);
            if (_.isUndefined(user)) {
                const res = await cl.confirmError(`No User Found For PIN: ${pin}`);
                if (res.buttonClicked === 'ok') {
                    _i.receipt();
                }
                return;
            }

            const sale = _.pick(data.getCurrentSale(), ['id', 'uuid', 'items', 'payments', 'cashier', 'ts', 'orderRef']);

            if (data.hasCurrentSale()) {
                if (!data.getCurrentSaleValue('ts')) {
                    const ts = Date.now();
                    data.setCurrentSaleValue('ts', ts);
                    sale.ts = ts;
                }

                if (!data.getCurrentSaleValue('cashier')) {
                    const cashier = user.username;
                    data.setCurrentSaleValue('cashier', cashier);
                    sale.cashier = cashier;
                }
            }

            const session = data.getCurrentSession();
            sale.location = session.location;
            sale.label = session.label;
            sale.connected = !!cl.get('connected');
            sale.items = sale.items.filter(i => !i.deleted);
            sale.saved = data.getCurrentSaleSaved();
            sale.tax = data.getCurrentSaleTax();

            const total = sale.items.reduce((a, i) => {
                const p = helper.getProductFromId || {};
                a += helper.lineTotal(i, p);
                return a;
            }, 0);

            sale.items.map(i => {
                const p = helper.getProductFromId(i.id) || {};
                i.name = `${p.name}${p.color ? ` - ${p.color}` : ''}${p.size ? ` - ${p.size}` : ''}`;
                i.sku = p.sku;
                i.price = Number(_.moneyRaw(i.price || p.price));
                return i;
            });

            if (!sale.payments.filter(p => !p.deleted).length && _.round(total, 2) !== 0) {
                cl.confirmError('Sale Has No Payments');
                return;
            }

            if (sale.items.length === 0 && total === 0) {
                cl.confirmError('Sale Has No Items');
                return;
            }

            _i.syncFunc.cancel();

            const print = cl.get('pos.session.printReceipt');
            if (!print) {
                cl.success('Sale Complete');
                _i.finalize(sale.uuid);
                return;
            }


            require('superagent')
                .post('https://receipt.cleanline.ninja/index.php')
                .timeout(7000)
                .send(sale || {})
                .end((err, res) => {
                    if (_.isUndefined(res)) {
                        cl.error('Error Sending Receipt To Printer.');
                        return;
                    }

                    const json = JSON.parse(res.text);
                    if (err || !res.ok || json.failure) {
                        cl.error('Error Sending Receipt To Printer.');
                        _.log('Receipt Error:', err, res);
                    } else {
                        _i.finalize(json.post.uuid);
                        _.log('Receipt Data:', json.post);
                    }
                });
        },
        finalize(uuid) {
            cl.ls.set('posLastSale', uuid);
            if (!data.hasCurrentSale()) {
                return;
            }
            data.setCurrentSaleValue('completed', true);
            data.setCurrentSaleValue('completedTime', cl.dayjs().format());

            const open = data.getOpenSales();
            const next = open[open.length - 1];

            if (_.isUndefined(next)) {
                return _i.newSale();
            }

            cl.routes.go(`/sessions/${helper.getSessionRoute()}/${next.id}`);
        },
        newItem(opts = {}) {
            const item = {
                deleted: false,
                discd: 0,
                discde: 0,
                discpe: 0,
                discp: 0,
                qty: 0,
                note: '',
                uuid : '',
                id: '',
            };

            if (_.isObject(opts)) {
                for (const prop in opts) {
                    item[prop] = opts[prop];
                }
            }

            return item;
        },
    };

    return {
        init: _i.init,
        new: _i.new,
        load: _i.load,
        receipt: _i.receipt,
        manualSync: _i.runSync,
        newItem: _i.newItem,
    };
})(window.cl);