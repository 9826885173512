window.cl = window.cl || {};

window.cl.users = ((cl) => {
    const _set = {
        'users.all': [],
        'users.skip': ['cannonbeach', 'seaside', 'dotcom'],
        'users.list'() {
            return _.orderBy(cl.get('users.all'), u => u.username.toLowerCase());
        },
        'users.current'() {
            const id = cl.get('params.user');
            if (_.isUndefined(id)) {
                return false;
            }

            const user = cl.get('users.all').find(u => u.id === id);
            if (!_.isUndefined(user)) {
                return user;
            }

            return false;
        },
        'users.nameFromId'(id) {
            const user = cl.get('users.all').find(u => u.id === String(id));
            if (!_.isUndefined(user)) {
                return user.username;
            }
        },
        'users.errors': {},
        'users.errors.username'() {
            const errors = [];
            const current = cl.exec('users.current');
            const names = _(cl.get('users.all')).reject('id', current.id).map('username').value();

            if (_.indexOf(names, current.username) > -1) {
                errors.push('*Username Taken');
            }

            if (errors.length > 0) {
                return errors.join('<br>');
            }

            return false;
        },
        'users.errors.password'() {
            const errors = [];
            const current = cl.exec('users.current');

            if (!_.isUndefined(current.password) && current.password !== '') {
                if (current.password.length < 10) {
                    errors.push('*Min 10 characters');
                }

                if (!/\d/.test(current.password)) {
                    errors.push('*Min 1 number');
                }
            }

            if (errors.length > 0) {
                return errors.join('<br>');
            }

            return false;
        },
        'users.errors.passwordConfirm'() {
            const errors = [];
            const current = cl.exec('users.current');

            if (!_.isUndefined(current.password) && !_.isUndefined(current.passwordConfirm) && current.password !== current.passwordConfirm) {
                errors.push('*Passwords do not match');
            }

            if (errors.length > 0) {
                return errors.join('<br>');
            }

            return false;
        },
        'users.errors.pin'() {
            const errors = [];
            const current = cl.exec('users.current');

            if (!_.isUndefined(current.pin) && !/^\d{3}$/.test(current.pin)) {
                errors.push('*Pin must be 3 digits');
            }

            if (errors.length > 0) {
                return errors.join('<br>');
            }

            return false;
        },
    };

    const _on = {
        usersNew() {
            cl.modal.new({
                title: 'New User',
                content: cl.components.all.newUser().toHTML(),
                button: 'Create User',
                action() {
                    const name = cl.find('.users-new-name');
                    const password = cl.find('.users-new-password');
                    const repeat = cl.find('.users-new-password-repeat');

                    if (!_.isObject(name) || !_.isObject(password) || !_.isObject(repeat)) {
                        return;
                    }

                    if (name.value === '') {
                        cl.error('Missing username.');
                        return false;
                    }
                    if (password.value === '') {
                        cl.error('Missing password.');
                        return false;
                    }
                    if (password.value !== repeat.value) {
                        cl.error('Passwords do not match.');
                        return false;
                    }
                    if (password.value.length < 10) {
                        cl.error('Password must be at least 10 characters.');
                        return false;
                    }
                    if (!/\d/.test(password.value)) {
                        cl.error('Password must contain at least one number.');
                        return false;
                    }
                    cl.send('createUser', {
                        username: name.value,
                        password: password.value
                    });

                    cl.fire('modalCloseTop');
                },
            });
        },
        usersEdit(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/users/${id}`);
        },
        userSave() {
            _i.save();
        },
        userDelete() {
            cl.prompt({
                message: '<strong>Are you sure you want to delete this user?</strong><br>Type <em>&quot;delete&quot;</em> below to confirm.',
                ok: 'Delete User',
                okFunc(v) {
                    if (v.trim().toLowerCase() !== 'delete') {
                        cl.error('Confirm Error: Delete canceled.');
                        return;
                    }

                    const user = cl.exec('users.current');
                    cl.send('deleteUser', {
                        id: user.id,
                        name: user.name,
                    });
                }
            });
        },
        usersPermissionsChange(e) {
            const users = cl.get('users.all');
            const id = cl.exec('users.current').id;
            const index = users.findIndex(u => u.id === id);

            if (index > -1) {
                cl.set(`users.all${index}.${e.node.dataset.src}`, e.node.checked);
            }
        },
        usersFieldChange(e, toggle) {
            const users = cl.get('users.all');
            const id = cl.exec('users.current').id;
            const index = users.findIndex(u => u.id === id);
            const src = e.node.dataset.src;

            if (index <= -1) {
                return;
            }

            if (!_.isUndefined(toggle) && toggle) {
                cl.toggle(`users.all.${index}.${src}`);
            } else {
                const start = e.node.selectionStart;
                const end = e.node.selectionEnd;

                cl.set(`users.all.${index}.${src}`, e.node.value);
                e.node.selectionStart = start;
                e.node.selectionEnd = end;
            }

            const key = `users.all.${index}.changed`;
            const changed = cl.get(key);
            const field = src.split('.')[0];
            if (_.isUndefined(changed)) {
                cl.set(key, [field]);
                return;
            }

            if (_.indexOf(cl.get(key), field) === -1) {
                cl.push(key, field);
            }
        }
    };

    const _i = {
        init() {
            cl.set(_set);
            cl.on(_on);

            document.addEventListener('save', (e) => {
                if (cl.routes.get() === 'user') {
                    _i.save();
                }
            });
        },
        save() {
            const user = cl.exec('users.current');
            let errors = false;

            _.forIn(cl.get('users.errors'), (v, k) => {
                if (_.isFunction(v) && v()) {
                    errors = true;
                    return false;
                }
            });

            if (!user || errors) {
                cl.error('Fix errors before saving user.');
                return false;
            }

            cl.send('updateUser', user);
        },
        update(type, data) {
            if (type === 'UPDATE' || type === 'INSERT') {
                const index = cl.get('users.all').findIndex(u => u.id === String(data.id));
                if (index > -1) {
                    cl.set(`users.all.${index}`, data);
                } else {
                    cl.push('users.all', data);
                }
            }

            if (type === 'DELETE') {
                const index = cl.get('users.all').findIndex(u => u.id === String(data.id));
                if (index > -1) {
                    cl.splice('users.all', index, 1);
                }
            }
        },
        async getPin(func = (() => {}), tries = 0) {
            const res = await cl.prompt({
                message: 'Enter PIN#:',
                okFunc(val) {
                    if (/^[0-9]{3}$/.test(val)) {
                        func(val);
                        return;
                    }

                    cl.error(`Invalid PIN: ${val}`);
                    if (tries < 2) {
                        tries++;
                        _.defer(_i.getPin, func, tries);
                    }
                },
                default: '',
            });
        },
        async getPinUser(tries = 0) {
            const currentUser = _i.fromName(cl.login.getUser());
            if (!cl.login.isPublic() && currentUser) {
                return currentUser;
            }

            const pin = await cl.prompt({
                message: 'Enter PIN#:',
            });

            if (pin.buttonClicked === 'cancel') {
                return false;
            }

            if (!(/^[0-9]{3}$/.test(pin.inputValue))) {
                cl.error(`Invalid PIN: ${pin.inputValue}`);
                if (tries < 2) {
                    tries++;
                    setTimeout(() => {
                        _i.getPinUser(tries);
                    }, 250);
                }
                return;
            }

            const user = cl.users.fromPin(pin.inputValue);
            if (_.isUndefined(user)) {
                cl.error(`No User Found For PIN: ${pin.inputValue}`);
                return;
            }

            return user;
        },
        fromPin(pin) {
            const users = cl.get('users.all').filter(u => u.pin === pin) || [];
            if (users.length === 1) {
                return users[0];
            }
        },
        fromName(name) {
            const users = cl.get('users.all').filter(u => u.username.toLowerCase() === name.toLowerCase()) || [];
            if (users.length === 1) {
                return users[0];
            }
        },
        current() {
            return _i.fromName(cl.login.getUser());
        },
    };

    return {
        init: _i.init,
        update: _i.update,
        getPin: _i.getPin,
        getPinUser: _i.getPinUser,
        fromPin: _i.fromPin,
        fromName: _i.fromName,
        current: _i.current,
    };
})(window.cl);