window.cl = window.cl || {};

window.cl.unsold = ((cl) => {
    const _on = {
        fetchReportUnsoldProductsRefresh() {
            _i.fetch();
        },
        reportUnsoldChange(e) {
            const key = 'reports.unsold.interval';
            if (cl.get(key) === e.node.value) {
                return;
            }

            cl.set(key, e.node.value);
            _i.fetch();
        }
    };

    const _set = {
        'reports.unsold.data': [],
        'reports.unsold.fields': [{
            src: 'type',
            filter: true,
            sort: true,
            search: true,
        }, {
            src: 'brand',
            filter: true,
            sort: true,
            search: true,
        }, {
            src: 'sku',
            sort: true,
            link: 'productFromSku',
            search: true,
        }, {
            src: 'name',
            sort: true,
            search: true,
        }, {
            src:'qty',
            numeric: true,
            sort: true,
        }, {
            src: 'cost',
            sort: true,
            type: 'money',
        }, {
            src: 'price',
            sort: true,
            type: 'money',
        }, {
            src: 'websitePrice',
            sort: true,
            type: 'money',
        }],
        'reports.unsold.interval': '3',
    };
    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);
        },
        fetch() {
            cl.send('reportUnsoldProducts', cl.get('reports.unsold.interval'));
        },
        async load(data) {
            await cl.data.allLoaded();

            const products = _(data.skus)
                .map(sku => {
                    const p = _.clone(cl.exec('product.fromSku', sku));

                    if (p.color) {
                        p.name += ` - ${p.color}`;
                    }

                    if (p.size) {
                        p.name += ` - ${p.size}`;
                    }

                    if (p) {
                        p.link = `/product/${p.id}/details`;
                        p.type = p.group;
                    }

                    return p;
                })
                .value();

            cl.set('reports.unsold.data', products);
        }
    };

    return {
        init: _i.init,
        fetch: _i.fetch,
        load: _i.load,
    }
})(window.cl);