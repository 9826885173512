import { isObject, round, throttle } from "lodash";

const errorThrottle = throttle(cl.error, 5000);

const SessionHelper = {
    getSessionRoute() {
        return cl.get('params.session');
    },
    getSaleRoute() {
        return cl.get('params.sale');
    },
    getProductFromId(id) {
        return cl.exec('product.fromId', id);
    },
    lineTotal(line = {}, product = {}, qtyOverride) {
        if (!isObject(line)) {
            return 0;
        }

        const check = (n, ref) => {
            const v = Number(n);
            if (!Number.isNaN(v) && Number.isFinite(v)) {
                return v;
            }
            errorThrottle(`Line Calulation Error: Invalid ${ref}: ${n} - ${product.sku}`);
            return 0;
        }

        const discountDollars = check(line.discd, 'Discount Dollars');
        const discountDollarsEach = check(line.discde, 'Discount Dollars Each');
        const discountPercentage = check(line.discp, 'Discount Percentage');
        const discountPercentageEach = check(line.discpe, 'Discount Percentage Each');
        const discountPercentageMultiplier = (1 - (discountPercentage / 100));
        const discountPercentageEachMultiplier = (1 - (discountPercentageEach / 100));
        const qty = qtyOverride || line.qty || 0;

        let price = line.price || product.price || 0;
        price -= discountDollarsEach;
        price *= discountPercentageEachMultiplier;
        price = round(price, 2);
        price *= qty;
        price *= discountPercentageMultiplier;
        price = round(price, 2);
        price -= discountDollars;
        price = round(price, 2);

        if (qty > 0 && price < 0) {
            return 0;
        }

        return price;
    },
    lineDiscount(line = {}, product = {}, qtyOverride) {
        if (!isObject(line)) {
            return 0;
        }

        const qty = qtyOverride || line.qty || 0;
        const total = this.lineTotal(line, product, qtyOverride);
        const price = line.price || product.price || 0;
        return (price * qty) - total;
    },
    lineTax(line = {}, product = {}, qtyOverride) {
        if (!isObject(line)) {
            return 0;
        }

        const percentage = line.taxPc || 0;
        const percentageMultiplier = percentage / 100;
        const total = this.lineTotal(line, product, qtyOverride);

        return round(total * percentageMultiplier, 2);
    },
    lineTotalAndTax(line = {}, product = {}, qtyOverride) {
        const total = this.lineTotal(line, product, qtyOverride);
        const tax = this.lineTax(line, product, qtyOverride);

        return round((total + tax), 2);
    },
};

export default SessionHelper;