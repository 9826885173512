import { createRouter } from 'routerjs';

window.cl = window.cl || {};

window.cl.routes = ((cl) => {
    const _routes = [
        {
            route: '/',
            key: 'home',
            title: 'Cleanline Surf - Inventory Management System',
        },
        {
            route: '/sessions',
            key: 'sessions',
            title: 'Point Of Sale',
        },
        {
            route: '/sessions/:session/:sale',
            key: 'session',
            title: '',
            func() {
                cl.sessions.check();
            },
        },
        {
            route: '/products',
            key: 'products',
            title: 'Manage Products',
            perm: 'productsRead',
            func() {
                const sb = cl.find('#productsSearch');
                if (!_.isUndefined(sb)) {
                    sb.value = cl.get('products.paging.search');
                }
            },
        },
        {
            route: '/product/new/:tab',
            key: 'product',
            title: 'New Product',
            perm: 'productsRead',
            func() {
                cl.product.new();
            },
        },
        {
            route: '/product/:product/:tab',
            key: 'product',
            title: 'Edit Product',
            perm: 'productsRead',
            func(req, context) {
                const id = req?.params?.product;
                cl.set('title', `Edit Product: Loading Product ID ${id}`);
                const cur = _i.get();
                const tab = req.params.tab;

                if (cur !== 'product' || (cur === 'product' && cl.get('product.id') !== id)) {
                    cl.product.load(id);
                } else {
                    cl.set('title', 'Edit Product: ' + cl.exec('product.src').sku);
                }

                switch(tab) {
                    case 'history':
                        cl.product.getHistory();
                        break;
                    case 'adjustments':
                        cl.product.getAdjustments();
                        break;
                    case 'low':
                        cl.product.getLow();
                        break;
                }
            },
        },
        {
            route: '/products-add-barcodes',
            key: 'productsAddBarcodes',
            title: 'Add Barcodes',
            perm: 'productsEdit',
        },
        {
            route: '/products-add-weight',
            key: 'productsAddWeight',
            title: 'Add Weight',
            perm: 'productsEdit',
        },
        {
            route: '/products-add-to-group',
            key: 'productsAddToGroup',
            title: 'Add Products To Group',
            perm: 'productsEdit',
        },
        {
            route: '/products-bulk-update',
            key: 'productsBulkUpdate',
            title: 'Bulk Update',
            perm: 'productsEdit',
        },
        {
            route: '/product-upload',
            key: 'productUpload',
            title: 'Upload Product',
            perm: 'productsCreate',
        },
        {
            route: '/orders/:order/:tab',
            key: 'order',
            title: 'Edit Order',
            perm: 'ordersRead',
        },
        {
            route: '/orders',
            key: 'orders',
            title: 'Manage Orders',
            perm: 'ordersRead',
        },
        {
            route: '/shipments/new',
            key: 'shipmentNew',
            title: 'New Shipment',
            perm: 'shipmentsRead',
        },
        {
            route: '/shipments/:shipment/:tab',
            key: 'shipment',
            title: 'Edit Shipment',
            perm: 'shipmentsRead',
        },
        {
            route: '/shipments',
            key: 'shipments',
            title: 'Manage Shipments',
            perm: 'shipmentsRead',
        },
        {
            route: '/transfers',
            key: 'transfers',
            title: 'Manage Transfers',
            func() {
                cl.transfers.getTransfers();
            },
        },
        {
            route: '/transfers/new-request/:tab',
            key: 'transferNewRequest',
            title: 'New Transfer Request',
            perm: 'transfersRequest',
        },
        {
            route: '/transfers/new-instant/:tab',
            key: 'transferNewInstant',
            title: 'New Instant Transfer',
            perm: 'transfersInstant',
        },
        {
            route: '/transfers/:transfer',
            key: 'transfer',
            title: 'Review Transfer',
            perm: 'transfersProcess',
            func() {
                cl.transfers.loadTransfer();
            }
        },
        {
            route: '/transfer-requests',
            key: 'transferRequests',
            title: 'Transfer Requests',
            perm: 'transferRequestsRead',
        },
        {
            route: '/transfer-request/:request',
            key: 'transferRequest',
            title: 'Review Transfer Request',
            perm: 'transferRequestsRead',
            func() {
                cl.transfers.loadTransferRequest();
            },
        },
        {
            route: '/inventory-balance',
            key: 'inventoryBalance',
            title: 'Inventory Balance',
            perm: 'inventoryBalance',
        },
        {
            route: '/settings',
            key: 'settings',
            title: 'Settings',
        },
        {
            route: '/users',
            key: 'users',
            title: 'Manage Users',
            perm: 'usersEdit',
        },
        {
            route: '/users/:user',
            key: 'user',
            title: 'Edit User',
            perm: 'usersEdit',
        },
        {
            route: '/reports',
            key: 'reports',
            title: 'Reports',
            perm: 'reportsRead',
        },
        {
            route: '/reports/review-sales',
            key: 'reviewSales',
            title: 'Review Sales',
            perm: 'reportsSales',
            func() {
                cl.reports.loadReviewSales();
            },
        },
        {
            route: '/reports/review-sale/:id',
            key: 'reviewSale',
            title: 'Review Sale',
            perm: 'reportsSales',
            func() {
                cl.reports.loadReviewSale();
            }
        },
        {
            route: '/reports/raw-data',
            key: 'rawData',
            title: 'Raw Data',
            perm: 'reportsRaw',
        },
        {
            route: '/reports/website-price',
            key: 'websitePrice',
            title: 'Website Price Discrepancies',
            perm: 'reportsWebsitePrice',
            func() {
                cl.reports.getWebsitePrice();
            },
        },
        {
            route: '/reports/barcodes',
            key: 'reportsBarcodes',
            title: 'Shopify Barcode Discrepancies',
            perm: 'reportsWebsitePrice',
            func() {
                cl.reports.getBarcodes();
            },
        },
        {
            route: '/reports/shop-not-web',
            key: 'reportsShopNotWeb',
            title: 'Shop Not Web',
            perm: 'reportsWebsitePrice',
            func() {
                cl.reports.getShopNotWeb();
            },
        },
        {
            route: '/reports/web-not-shop',
            key: 'reportsWebNotShop',
            title: 'Web Not Shop',
            perm: 'reportsWebsitePrice',
            func() {
                cl.reports.getWebNotShop();
            },
        },
        {
            route: '/reports/inventory-snapshot',
            key: 'inventorySnapshot',
            title: 'Inventory Snapshot',
            perm: 'reportsInventorySnapshot',
        },
        {
            route: '/reports/low-inventory-tool',
            key: 'lowInventory',
            title: 'Low Inventory Tool',
            perm: 'reportsLowInventory',
            func() {
                cl.lowInventory.load();
            },
        },
        {
            route: '/reports/unsold',
            key: 'unsold',
            title: 'Unsold Products',
            perm: 'reportsUnsold',
            func() {
                cl.unsold.fetch();
            },
        },
        {
            route: '/reports/old-products',
            key: 'reportsOldProducts',
            title: 'Old Products',
            perm: 'reportsOldProducts',
            func() {
                cl.reports.getOldProducts();
            }
        },
        {
            route: '/reports/not-found-skus',
            key: 'reportsNotFoundSkus',
            title: 'Old Products',
            perm: 'reportsNotFoundSkus',
            func() {
                cl.reports.getNotFoundSkus();
            }
        },
        {
            route: '/inventory-adjustment',
            key: 'inventoryList',
            title: 'Inventory Scans',
            perm: 'inventoryRead',
        },
        {
            route: '/inventory-adjustment/new',
            key: 'inventoryNew',
            title: 'New Inventory Scan',
            perm: 'inventoryRead',
        },
        {
            route: '/inventory-adjustment/:inventory',
            key: 'inventory',
            title: 'Inventory Scan',
            perm: 'inventoryRead',
            func() {
                cl.inventory.getScan();
            },
        },
        {
            route: '/inventory-scan/:scan',
            key: 'inventoryScanList',
            title: 'Inventory Scan List',
            perm: 'inventoryRead',
            func() {
                cl.inventory.getScanList();
            },
        },
        {
            route: '/log-search',
            key: 'logSearch',
            title: 'Log Search',
            perm: 'superAdmin',
        },
        {
            route: '/register-session/:uuid',
            key: 'registerSession',
            title: 'Register Session Data',
            perm: 'superAdmin',
            func() {
                cl.settings.getRegisterSession();
            },
        },
        {
            route: '/inventoryplanner/:id',
            key: 'inventoryPlannerOrder',
            title: 'Inventory Planner Order',
            perm: 'productsRead',
            func() {
                cl.inventoryPlanner.getOrder();
            }
        },
        {
            route: '/ip-restriction',
            key: 'ipRestriction',
            title: 'IP Restriction',
            perm: 'superAdmin',
            func() {
                cl.ipRestriction.get();
            },
        },
        {
            route: '/help',
            key: 'help',
            title: 'Help',
        },
        {
            route: '/tests',
            key: 'tests',
            title: 'Tests',
        },
    ];

    const _router = createRouter({ignoreCase: true});

    const _set = {

    };

    const _on = {
        go(e, p) {
            _i.go(p);
        },
        link(e, url) {
            window.open(url, '_blank');
        }
    };

    const _i = {
        init() {
            _i.loadRoutes();
            cl.on(_on);
            cl.set(_set);
            if (_.isUndefined(cl.get('tab'))) {
                cl.set('tab', '');
            }
        },
        loadRoutes() {
            _router.error(404, (err, context) => {
                _i.set('404', '404 - Not Found');
            });

            _router.error(403, (err, context) => {
                _i.set('403', '403 - Not Authorized');
            });

            _router.error(500, (err, context) => {
                console.error(err);
            });

            _router.always(async (context) => {
                cl.update('login.check');
            });

            _routes.map(r => {
                const matchingFunc = async (req, context) => {
                    if (r.route === '/' && window.location.pathname !== '/') {
                        return;
                    }

                    req.stop();

                    if (!_.isUndefined(r.perm) && !cl.login.hasPerm(r.perm)) {
                        const e = new Error('Not authorized');
                        e.statusCode = 403;
                        throw e;
                    }

                    _i.set(r.key, r.title);
                    cl.set('params', req.params);
                    r?.func?.(req, context);
                };

                _router.get(r.route, matchingFunc);

                if (r?.route?.[0] === '/' && !r.route.includes(':')) {
                    _router.get(r.route.slice(1), matchingFunc);
                }
            });

            _router.run();

            cl.on({
                goto(e) {
                    e.original.preventDefault();
                    _i.go(e.node.pathname);
                },
            });
        },
        get() {
            return cl.get('route');
        },
        set(route, title) {
            if (!_.isUndefined(title)) {
                cl.set('title', title);
            }

            cl.set('route', route);
            _i.reset();
        },
        reset() {
            cl.resetPartial('main', cl.templates[_i.get()]);
        },
        go(path) {
            _router.navigate(path);
        }
    };
    return {
        router: _router,
        all: _routes,
        init: _i.init,
        get: _i.get,
        set: _i.set,
        go: _i.go,
        reset: _i.reset,
    };
})(window.cl);