import { handleOptionsDown, handleOptionsUp } from "./utilities/options";

window.cl = window.cl || {};

window.cl.productsAddToGroup = ((cl) => {
    const _on = {
        productsAddToGroupSearch() {
            const search = cl.get('productsAddToGroup.productSearch');
            if (search.length < 3) {
                return;
            }

            const ids = _.map(cl.products.search(search), 'ref');
            cl.set('productsAddToGroup.searchIds', ids || []);
            cl.update('productsAddToGroup.searchResults');
        },
        productsAddToGroupSearchFocus() {
            cl.set('productsAddToGroup.focus', true);
            if (cl.exec('productsAddToGroup.searchResults').length) {
                const el = document.querySelector('#products-add-to-group-search-list');
                if (!el) return;
                el.classList.remove('hide');
            }
        },
        productsAddToGroupSearchBlur() {
            cl.set('productsAddToGroup.focus', false);
        },
        productsAddToGroupSearchClick(e, id) {
            _i.productSelect(id);
            const el = document.querySelector('#products-add-to-group-search-list');
            if (!el) return;
            el.classList.add('hide');
        },
        productsAddToGroupProductSearch(e) {
            cl.set('productsAddToGroup.productSearch', e.node.value || '');
        }
    };
    const _set = {
        'productsAddToGroup.ids': [],
        'productsAddToGroup.searchResults'() {
            const ids = cl.get('productsAddToGroup.searchIds');

            if (!cl.get('productsAddToGroup.focus') || ids.length === 0 || cl.get('productsAddToGroup.productSearch') <= 1) {
                return [];
            }

            return _(cl.get('products.all'))
                .keyBy('id')
                .at(ids)
                .filter(p => !!p && ![null, undefined, '', 'nevergroup'].includes(p.parentGroup))
                .sortBy('sku')
                .value();
        },
        'productsAddToGroup.productSearch': '',
        'productsAddToGroup.searchIds': [],
        'productsAddToGroup.focus': false,
    };
    const _i = {
        async init() {
            cl.on(_on);
            cl.set(_set);

            cl.observe('productsAddToGroup.productSearch', () => {
                cl.fire('productsAddToGroupSearch');
            });

            document.addEventListener("upArrow", e => {
                _i.optionsUp();
            });
            document.addEventListener("downArrow", e => {
                _i.optionsDown();
            });
            document.addEventListener("enterFire", e => {
                _i.optionsSelect();
            });
            document.addEventListener("escapeFire", e => {
                _i.optionsExit();
            });
        },
        setIds(ids = []) {
            if (!_.isArray(ids) || ids.length === 0) {
                return;
            }
            cl.set('productsAddToGroup.ids', ids);
            cl.routes.go('/products-add-to-group');
        },
        async productSelect(id) {
            const p = cl.exec('product.fromId', id);
            if (!p) {
                cl.error(`No Product Found For ID: ${id}`);
                return;
            }

            const g = p.parentGroup;
            if (!g || g === 'nevergroup') {
                cl.error(`Invalid Group '${g}' On ${p.sku}`);
                return;
            }

            const ids = cl.get('productsAddToGroup.ids');
            if (!_.isArray(ids) || ids.length === 0) {
                return;
            }

            const skus = ids.map(id => {
                return cl.exec('product.fromId', id).sku;
            }).filter(sku => !_.isUndefined(sku));

            const res = await cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Add ${p.sku} To Group: ${g} &amp; Skus: ${skus.join(', ')}</div>`,
                ok: 'Group',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const fields = ['parentGroup'];
            const product = cl.get('product.blank');
            _.set(product, 'parentGroup', g);
            cl.set('products.bulk.ids', ids);

            cl.send('productsBulkUpdate', {
                ids,
                product,
                fields,
            });

            cl.confirm({
                message: 'Clear Selected Products?',
                okFunc(val) {
                    cl.set('products.selected', {});
                },
            });
        },
        optionsUp() {
            if (!cl.get('productsAddToGroup.focus') || cl.exec('productsAddToGroup.searchResults').length === 0) {
                return;
            }

            handleOptionsUp(document.querySelectorAll('#products-add-to-group-search-list li'));
        },
        optionsDown() {
            if (!cl.get('productsAddToGroup.focus') || cl.exec('productsAddToGroup.searchResults').length === 0) {
                return;
            }

            handleOptionsDown(document.querySelectorAll('#products-add-to-group-search-list li'));
        },
        optionsExit() {
            if (cl.exec('productsAddToGroup.searchResults').length === 0) {
                return;
            }

            const el = document.querySelector('#products-add-to-group-search-input');
            el.classList.remove('focus');
            el.blur();
            cl.update('productsAddToGroup.searchResults');
        },
        optionsSelect() {
            if (!cl.get('productsAddToGroup.focus') || cl.exec('productsAddToGroup.searchResults').length === 0) {
                return;
            }

            const opt = document.querySelector('#products-add-to-group-search-list > li.active');
            const id = opt?.dataset?.id;
            if (!id) {
                return;
            }

            _i.productSelect(id);
            cl.fire('productsAddToGroupOptionsExit');
        },
    };

    return {
        init: _i.init,
        setIds: _i.setIds,
    };
})(window.cl);