import Ractive from 'ractive';
import { Base64 } from 'js-base64';
import { autoPrint } from './utilities/autoPrint';
import { pullSort } from './utilities/pullSort';

window.cl = window.cl || {};

window.cl.inventoryBalance = ((cl) => {
    const _on = {
        async inventoryBalanceSetFromLoc(e, loc) {
            if (cl.get('inventoryBalance.fromLoc') === loc) {
                return;
            }

            if (cl.exec('inventoryBalance.listTransfer').length) {
                const res = await cl.confirm({
                    message: `<div class='error-alert'><i class='material-icons'>warning</i>Changing Location Will Erase Pending Transfer Data</div>`,
                    ok: 'Proceed',
                });

                if (res.buttonClicked !== 'ok') {
                    return;
                }
            }

            cl.set('inventoryBalance.toLoc', '');
            cl.set('inventoryBalance.fromLoc', loc);
            _i.resetTransfer();
        },
        async inventoryBalanceSetToLoc(e, loc) {
            if (cl.exec('inventoryBalance.listTransfer').length) {
                const res = await cl.confirm({
                    message: `<div class='error-alert'><i class='material-icons'>warning</i>Changing Location Will Erase Pending Transfer Data</div>`,
                    ok: 'Proceed',
                });

                if (res.buttonClicked !== 'ok') {
                    return;
                }
            }

            cl.set('inventoryBalance.toLoc', loc);
            _i.resetTransfer();
        },
        inventoryBalanceFireBarcode(e, id) {
            const p = cl.exec('product.fromId', id);
            if (!p) {
                return;
            }

            if (!_.isArray(p.barcodes) || (_.isArray(p.parcodes) && p.barcodes.length === 0)) {
                return;
            }

            cl.keyboard.fire('barcode', p.barcodes[0]);
        },
        inventoryBalanceTransferRemove(e, id) {
            cl.unset(`inventoryBalance.transfer.${id}`);
        },
        inventoryBalanceGoToProduct(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/product/${id}/details`);
        },
        inventoryBalancePrint() {
            _i.printShopify(_i.print);
        },
        inventoryBalancePrintAll() {
            _i.printShopify(_i.printAll);
        },
        inventoryBalanceCheck(e, id) {
            const selected = cl.get('inventoryBalance.selected');
            if (id in selected) {
                cl.unset(`inventoryBalance.selected.${id}`);
            } else {
                cl.set(`inventoryBalance.selected.${id}`, true);
            }
        },
        inventoryBalanceSelectAll() {
            const ids = cl.exec('inventoryBalance.list')
                .map(b => b.id)
                .reduce((a, b) => {
                    a[b] = true;
                    return a;
                }, {});

            cl.set('inventoryBalance.selected', ids);
        },
        inventoryBalanceUnselectAll() {
            cl.set('inventoryBalance.selected', {});
        },
        inventoryBalanceTransfer() {
            const t = cl.exec('inventoryBalance.listTransfer');

            if (t.length === 0) {
                cl.error('Inventory balance transfer needs products.');
                return;
            }

            const message = `
                <strong>Are you sure you want to make this transfer?</strong><br><br>
                <strong>From:</strong> ${cl.get('inventoryBalance.fromLoc')}.main<br>
                <strong>To:</strong> ${cl.get('inventoryBalance.toLoc')}.main<br>
                <strong>Total Qty:</strong> ${t.reduce((sum, t) => sum += Number(t.qty), 0)}
            `;

            cl.confirm({
                message,
                okFunc() {
                    _i.createTransfer();
                }
            });
        },
        inventoryBalancePull(e, uuid) {
            const bal = _.find(cl.get('inventoryBalance.all'), ['uuid', uuid]);

            if (!bal) {
                return;
            }

            const data = {}
            data.ts = _.now()

            const prod = cl.exec('product.fromId', bal.id) || {};

            data.name = prod.name;
            data.color = prod.color;
            data.brand = prod.brand;
            data.size = prod.size;
            data.sku = prod.sku;
            data.price = prod.price;
            data.to = bal.to.toUpperCase();
            data.stock = prod.inventory[bal.from].main;
            data.qty = bal.qty;

            const request = require('superagent');

            request
                .post('https://receipt.cleanline.ninja/inventoryBalancePull.php')
                .timeout(7000)
                .send(data || {})
                .end((err, res) => {
                    if (!_.isUndefined(res)) {
                        const json = JSON.parse(res.text);

                        if (err || !res.ok || json.failure) {
                            cl.error('Error Sending Receipt To Printer.')
                            _.log('Receipt Error:', err, res)
                        } else {
                            _.log('Receipt Data:', json.post)
                        }
                    } else {
                        cl.error('Error Sending Receipt To Printer.')
                    }
                });
        },
        inventoryBalanceUpdate() {
            cl.send('getInventoryBalanceUpdate');
        }
    };
    const _set = {
        'inventoryBalance.all': [],
        'inventoryBalance.fromLoc': '',
        'inventoryBalance.toLoc': '',
        'inventoryBalance.list'() {
            const fromLoc = cl.get('inventoryBalance.fromLoc');
            const toLoc = cl.get('inventoryBalance.toLoc');

            return cl.get('inventoryBalance.all')
                .filter(i => i.from === fromLoc && i.to === toLoc);
        },
        'inventoryBalance.fromCount'(fromLoc) {
            return cl.get('inventoryBalance.all')
                .filter(i => i.from === fromLoc)
                .length;
        },
        'inventoryBalance.toCount'(toLoc) {
            const fromLoc = cl.get('inventoryBalance.fromLoc');

            if (!fromLoc) {
                return 0;
            }

            return cl.get('inventoryBalance.all')
                .filter(i => i.from === fromLoc && i.to === toLoc)
                .length;
        },
        'inventoryBalance.count'() {
            if (!cl.exec('login.permissions', 'inventoryBalance')) {
                return 0;
            }

            return cl.exec('inventoryBalance.fromCount', cl.get('inventoryBalance.fromLoc')) || 0;
        },
        'inventoryBalance.selected': {},
        'inventoryBalance.transfer': {},
        'inventoryBalance.listTransfer'() {
            return _(cl.get('inventoryBalance.transfer'))
                .values()
                .orderBy(i => Number(i.added), 'desc')
                .value();
        },
        'inventoryBalance.transferToken': _.uuid(),
        'inventoryBalance.dropDown': false,
    };
    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);

            cl.set('inventoryBalance.fromLoc', cl.ls.get('inventoryBalanceFromLocation') || 'wh');

            document.addEventListener('barcode', (e) => {
                _.defer(_i.barcodeListener, e.detail);
            });

            cl.observe('inventoryBalance.fromLoc', (newVal, oldVal, key) => {
                _i.autoSelectToLocation();
                if (newVal != '') {
                    cl.ls.set('inventoryBalanceFromLocation', newVal);
                }
            });

            cl.data.addAfterLoad(_i.initialFetch);
        },
        initialFetch() {
            if (!cl.login.hasPerm('inventoryBalance')) {
                return;
            }

            cl.sendSilent('getInventoryBalance');
        },
        async load(data = []) {
            cl.set('inventoryBalance.all', data.map(b => {b.uuid = _.uuid(); return b}));
            await _i.autoSelectToLocation();
            _.logOnly('=== inventory balance loaded ===');
        },
        async autoSelectToLocation() {
            const toLoc = cl.get('inventoryBalance.toLoc');
            if (toLoc) {
                return cl.data.wait(0);
            }

            const fromLoc = cl.get('inventoryBalance.fromLoc');
            if (!fromLoc) {
                return cl.data.wait(0);
            }

            const locs = ['wh', 'ss', 'cb'].filter(l => l !== fromLoc && cl.get('inventoryBalance.all').filter(i => i.from === fromLoc && i.to === l).length > 0);
            if (locs.length >= 1) {
                return cl.set('inventoryBalance.toLoc', locs[0]);
            }

            return cl.data.wait(0);
        },
        barcodeListener(bc) {
            if (cl.routes.get() !== 'inventoryBalance') {
                return;
            }

            cl.products.skuFromBarcode(bc, (bc, sku, id) => {
                _i.productSelect(id);
            });
        },
        productSelect(id) {
            const src = cl.exec('product.fromId', id);
            if (!src) {
                return;
            }

            const max = _.get(_.find(cl.exec('inventoryBalance.list'), ['id', id]), 'qty') || 0;
            if (max === 0) {
                cl.error(`${src.sku} Not In Inventory Balance`);
                return;
            }

            const qty = cl.get(`inventoryBalance.transfer.${id}.qty`);
            if (qty) {
                const newQty = qty + 1;
                if (newQty > max) {
                    cl.error(`${src.sku} Over Qty`);
                    return;
                }
                cl.set(`inventoryBalance.transfer.${id}.qty`, newQty);
            } else {
                cl.set(`inventoryBalance.transfer.${id}`, { id, qty: 1 });
            }

            cl.set(`inventoryBalance.transfer.${id}.added`, Date.now());
        },
        resetTransfer() {
            cl.set('inventoryBalance.transfer', {});
            cl.set('inventoryBalance.transferToken', _.uuid());
        },
        createTransfer() {
            if (!cl.exec('login.isPublic')) {
                _i.createTransferSend(cl.get('login.user'));
                return;
            }

            setTimeout(() => {
                cl.users.getPin(pin => {
                    const user = cl.users.fromPin(pin);
                    if (_.isUndefined(user)) {
                        cl.error(`No User Found For PIN: ${pin}`);
                        return
                    }

                    _i.createTransferSend(user.username);
                });
            }, 10);
        },
        createTransferSend(username) {
            cl.send('inventoryBalanceTransfer', {
                to: `${cl.get('inventoryBalance.toLoc')}.main`,
                from: `${cl.get('inventoryBalance.fromLoc')}.main`,
                products: _.values(cl.get('inventoryBalance.transfer')),
                note: 'Inventory_Balance',
                token: cl.get('transfer.transferToken'),
                user: username,
            });
        },
        transferComplete(data) {
            data.ts = _.now();
            data.balance = true;

            data.products.map(p => {
                const prod = cl.exec('product.fromId', p.id) || {};
                p.name = '';
                if (prod.brand) {
                    p.name += `${prod.brand} - `;
                }
                p.name += prod.name;
                if (prod.color) {
                    p.name += ` - ${prod.color}`;
                }
                if (prod.size) {
                    p.name += ` - ${prod.size}`;
                }
                p.sku = prod.sku;
                return p;
            });

            if (
                !['anVzdGluIHA=','c2hhbiBi'].map(u=>Base64.decode(u)).includes(cl.get('login.user'))
            ) {

                require('superagent')
                    .post('https://receipt.cleanline.ninja/transfer.php')
                    .timeout(7000)
                    .send(data || {})
                    .end((err, res) => {
                        if (!_.isUndefined(res)) {
                            const json = JSON.parse(res.text);

                            if (err || !res.ok || json.failure) {
                                cl.error('Error Sending Receipt To Printer.');
                                _.log('Receipt Error:', err, res);
                            } else {
                                _.log('Receipt Data:', json.post);
                            }
                        } else {
                            cl.error('Error Sending Receipt To Printer.');
                        }
                    });
            }

            cl.routes.go('/transfers');
            _i.resetTransfer();
        },
        async printShopify(func) {
            if (!_.isFunction(func)) {
                return;
            }

            const cachedSortingData = cl.transfers.getCachedWetsuitSortingData();
            if (cachedSortingData) {
                func(cachedSortingData);
                return;
            }

            const ref = _.uuid();
            cl.data.addAwait(ref, func);
            cl.sendWithRef('getWetsuitSortingData', ref);
        },
        async print(sortingData) {
            const all = cl.exec('inventoryBalance.list')
                .map(b => b.id)
                .reduce((a, b) => {
                    a[b] = true;
                    return a;
                }, {});

            const sel = cl.get('inventoryBalance.selected');
            const selected = Object.keys(sel).length === 0 ? all : sel;

            const products = cl.exec('inventoryBalance.list')
                .filter(p => {
                    if (p.id in selected) {
                        return true;
                    }
                    return false;
                });

            products.map(p => {
                const prod = cl.exec('product.fromId', p.id);
                p.name = prod.name;

                p.thickness = 10;
                if (p.sku in sortingData) {
                    p.thickness -= sortingData[p.sku];
                }

                if (p.name.includes('Youth')) {
                    p.gender = 2;
                } else if (p.name.includes('Wmns')) {
                    p.gender = 1;
                } else {
                    p.gender = 0;
                }

                p.nameFiltered = p.name
                    .replace('Wmns ', '')
                    .replace('Youth ', '');

                if (prod.color) {
                    p.name += ` - ${prod.color}`;
                }
                if (prod.size) {
                    p.name += ` - ${prod.size}`;
                }
                p.sku = prod.sku;
                p.price = prod.price;
                p.group = prod.group;
                p.ss = prod.inventory.ss.main;
                p.cb = prod.inventory.cb.main;
                p.wh = prod.inventory.wh.main;

                return p;
            });

            const totalUnits = products.reduce((a, p) => { a += p.qty; return a; }, 0);

            const rac = new Ractive({
                template: cl.templates.inventoryBalancePrint,
                data: {
                    products: pullSort(products),
                    totalUnits,
                    time: new Date().toLocaleString(),
                    fromLoc: cl.get('inventoryBalance.fromLoc'),
                    toLoc: cl.get('inventoryBalance.toLoc'),
                    _, // lodash
                }
            });

            const html = rac.toHTML();
            autoPrint(html, 'PRINT INVENTORY BALANCE');
        },
        async printAll(sortingData) {
            const products = cl.get('inventoryBalance.all');
            const chunks = {
                'wh': [],
                'ss': [],
                'cb': [],
            };

            products.map(p => {
                const prod = cl.exec('product.fromId', p.id);
                p.name = prod.name;

                p.thickness = 10;
                if (p.sku in sortingData) {
                    p.thickness -= sortingData[p.sku];
                }

                if (p.name.includes('Youth')) {
                    p.gender = 2;
                } else if (p.name.includes('Wmns')) {
                    p.gender = 1;
                } else {
                    p.gender = 0;
                }

                p.nameFiltered = p.name
                    .replace('Wmns ', '')
                    .replace('Youth ', '');

                if (prod.color) {
                    p.name += ` - ${prod.color}`;
                }
                if (prod.size) {
                    p.name += ` - ${prod.size}`;
                }
                p.sku = prod.sku;
                p.price = prod.price;
                p.group = prod.group;
                p.ss = prod.inventory.ss.main;
                p.cb = prod.inventory.cb.main;
                p.wh = prod.inventory.wh.main;

                chunks[p.from].push(p);

                return p;
            });

            const totalUnits = products.reduce((a, p) => { if (p.track === 'true') a += p.qty; return a; }, 0);

            chunks['ss'] = pullSort(chunks['ss']);
            chunks['wh'] = pullSort(chunks['wh']);
            chunks['cb'] = pullSort(chunks['cb']);

            const rac = new Ractive({
                template: cl.templates.inventoryBalancePrintAll,
                data: {
                    chunks,
                    totalUnits,
                    time: new Date().toLocaleString(),
                    _, // lodash
                }
            });

            const html = rac.toHTML();
            autoPrint(html, 'PRINT INVENTORY BALANCE');
        },
    };

    return {
        init: _i.init,
        load: _i.load,
        transferComplete: _i.transferComplete,
    };
})(window.cl);