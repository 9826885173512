import onScan from 'onscan.js';


window.cl = window.cl || {};
window.cl.onScan = onScan;

window.cl.keyboard = (() => {
    const _i = {
        init() {
            _i.attach();
        },
        fireEvent(name, detail) {
            const customEvent = new CustomEvent(name, { detail });
            window.document.dispatchEvent(customEvent);
        },
        attach() {
            onScan.attachTo(window.document, {
                ignoreIfFocusOn: ['input', 'textarea', 'select'],
                onScan(barcode) {
                    if (!(cl?.settings?.barcode?.() ?? false)) {
                        return;
                    }

                    _i.fireEvent('barcode', barcode);
                },
                onKeyDetect(key, e) {
                    if (!e.shiftKey && (e.ctrlKey || e.metaKey) && key === 83) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        _i.fireEvent('save');
                        return;
                    }

                    const isInput = ['INPUT', 'TEXTAREA', 'SELECT'].includes(e.target.tagName);
                    const isModifier = e.shiftKey || e.ctrlKey || e.altKey || e.metaKey;
                    const events = {
                        Escape() {
                            _i.fireEvent('esc', e.target);
                        },
                        ArrowLeft() {
                            if (isInput || isModifier) {
                                return;
                            }

                            _i.fireEvent('leftArrow', false);
                        },
                        ArrowRight() {
                            if (isInput || isModifier) {
                                return;
                            }

                            _i.fireEvent('rightArrow', false);
                        },
                        ArrowUp() {
                            _i.fireEvent('upArrow', e.target);

                            if (isInput) {
                                e.preventDefault();
                                e.stopImmediatePropagation();
                            }
                        },
                        ArrowDown() {
                            _i.fireEvent('downArrow', e.target);

                            if (isInput) {
                                e.preventDefault();
                                e.stopImmediatePropagation();
                            }
                        },
                        Enter() {
                            _i.fireEvent('enterFire', e.target);
                        },
                    };

                    events[e.code]?.();
                }
            });
        },
        detach() {
            onScan.detachFrom(document);
        },
        isScanning() {
            return onScan.isScanInProgressFor(window.document);
        },
    }

    return {
        init: _i.init,
        fire: _i.fireEvent,
        attach: _i.attach,
        detach: _i.detach,
        isScanning: _i.isScanning,
    };
})(window.cl);