import { Base64 } from 'js-base64';
import { memoize } from 'lodash';

window.cl = window.cl || {};

window.cl.login = ((cl) => {
    const _set = {
        'login.check'() {
            return _i.check();
        },
        'login.remove'() {
            _.deleteToken();
            cl.update('login.check');
            clearCaches();
            return false;
        },
        'login.permissions'(key) {
            return cl.login.perm()?.[key] ?? false;
        },
        'login.pending': false,
        'login.isPublic'() {
            return isPublicCache();
        },
        'login.isRetail'() {
            return isRetailCache();
        },
        'login.isSeaside'() {
            return isSeasideCache();
        },
        'login.isCannonBeach'() {
            return isCannonBeachCache();
        },
        'login.isOwner'() {
            return isOwnerCache();
        },
        'login.isDev'() {
            return isDevCache();
        },
        'login.isTest'() {
            return isTestCache();
        },
        'user'() {
            return cl.login.getUser();
        },
    };
    const _on = {
        loginSubmit() {
            _i.login();
        },
        loginSetUser(e, v) {
            if (e.original.clientX > 0 && e.original.clientY > 0) {
                cl.set('login.user', v.toLowerCase());
            }
        },
        logout() {
            _i.deleteToken();
            cl.update('login.check');
        }
    };
    const _i = {
        init() {
            cl.set(_set);
            cl.on(_on);
            cl.set('login.user', (() => {
                return (_i.getLastUser() || 'seaside').toLowerCase();
            })());
            cl.set('login.hasPerm', _i.hasPerm);

            document.addEventListener("enterFire", (e) => {
                _.defer(_i.submit, e.detail);
            });

            setInterval(() => {
                cl.update('login.check');
            }, 30000)

            require('superagent')
                .get('https://cleanline.ninja/ip')
                .timeout(5000)
                .end((err, res) => {
                    if (!_.isUndefined(res)) {
                        const ip = res.text.trim();
                        const valid = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip);

                        if (valid) {
                            cl.ls.set('myip', ip);
                        }
                    } else {
                        cl.error('Error checking ip.');
                        _.log(err);
                    }
                });
        },
        response(data) {
            cl.set('login.pending', false);
            if (data.type === "authFailure") {
                _i.deleteToken();
                cl.error('Incorrect Username and/or Password');
            }

            if (data.type === "authFailureIP") {
                _i.deleteToken();
                cl.error('Login location is unauthorized');
            }

            if (data.type === "authSuccess") {
                _i.setToken(data.data);
                _i.setPermissions(data.data.permissions);
                cl.update('login.check');
                cl.update();
                cl.routes.go(window.location.pathname);
                // mdl fix for layout issues after logging out then back in
                componentHandler.downgradeElements(cl.find('.pos-layout'));
                componentHandler.upgradeElement(cl.find('.pos-layout'));

                const name = data.data.user;
                switch (name) {
                    case 'seaside':
                        if (cl.ls.get(cl.sessions.loc) !== 'ss.main') {
                            cl.ls.set(cl.sessions.loc, 'ss.main');
                            cl.ls.remove(cl.sessions.label);
                        }
                        break;
                    case 'cannonbeach':
                        if (cl.ls.get(cl.sessions.loc) !== 'cb.main') {
                            cl.ls.set(cl.sessions.loc, 'cb.main');
                            cl.ls.remove(cl.sessions.label);
                        }
                        break;
                    case 'dotcom':
                        if (cl.ls.get(cl.sessions.loc) !== 'wh.main') {
                            cl.ls.set(cl.sessions.loc, 'wh.main');
                            cl.ls.remove(cl.sessions.label);
                        }
                        break;
                }
                cl.data.restart();
                cl.data.loadUp(cl.login.reads());
            }

            if (data.type === "authCancel") {
                _i.deleteToken();
                cl.update('login.check');
            }
        },
        getToken() {
            return cl.ls.get('token');
        },
        getTokenCreated() {
            return cl.ls.get('tokenCreated');
        },
        getTokenExpires() {
            return cl.ls.get('tokenExpires');
        },
        getLastUser() {
            return cl.ls.get('lastUser');
        },
        setPermissions(perm) {
            cl.ls.set('perm', perm);
        },
        getPermissions() {
            return cl.ls.get('perm');
        },
        setToken(data) {
            cl.ls.set('token', data.token);
            cl.ls.set('tokenCreated', data.created);
            cl.ls.set('tokenExpires', data.expires);
            cl.ls.set('lastUser', data.user);
        },
        deleteToken() {
            cl.send('close');
            _i.deleteTokenOnly();
        },
        deleteTokenOnly() {
            cl.ls.set('token');
            cl.ls.set('tokenCreated');
            cl.ls.set('tokenExpires');
        },
        login() {
            if (!cl.get('connected')) {
                if (cl.find('.login-user').value === 'offline') {
                    _i.offline();
                } else {
                    _.delay(_i.login, 20);
                }
                return;
            }

            const user = cl.find('.login-user').value?.toLowerCase?.();
            const pass = cl.find('.login-pass').value;
            const ip = cl.ls.get('myip');

            if (!cl.exec('login.check') && cl.get('connected')) {
                cl.set('login.user', user);
                cl.send('auth', {
                    user,
                    pass,
                    ip,
                });
                cl.set('login.pending', true);

                setTimeout(()=>{
                    if (cl.get('login.pending')) {
                        cl.set('login.pending', false);
                        cl.error('Login timed out');
                    }
                }, 2000);
            }
        },
        check() {
            const created = _i.getTokenCreated();
            const expires = _i.getTokenExpires();
            if (!created || !expires) {
                _i.deleteTokenOnly();
                return false;
            }

            if ((_.nowUnix() - expires) < (expires - created)) {
                return true;
            }
            return false;
        },
        offline() {
            const lastUser = cl.ls.get('lastUser');
            cl.set('login.user', lastUser.toLowerCase());
            _i.setToken('');
            cl.update('login.check');
            cl.update();
            cl.ls.product.load();
            cl.ls.users.load();
        },
        auth() {
            if (!_.isNull(_i.getToken())) {
                cl.send('checkAuth');
            }
        },
        submit(e) {
            if (e.classList.contains('login-pass') || e.classList.contains('login-user')) {
                _i.login();
            }
        },
        reads() {
            return _(_i.getPermissions())
                .pickBy(v => !!v)
                .keys()
                .filter(v => v.includes('Read'))
                .tap(a => {
                    if (_.includes(a, 'productsRead')) {
                        a.push('productsBlank');
                    }
                })
                .difference(['transfersRead', 'salesRead', 'timesheetRead', 'reportsRead', 'dotComOrdersRead'])
                .value()
                .length + 3; // +3 for build, app id & inventory planner info sent regardless of permissions
        },
        hasPerm(perm) {
            const perms = _i.getPermissions();

            if (_.isUndefined(perms)) {
                return false;
            }

            if (perms.superAdmin === true) {
                return true;
            }

            if (perms[perm] === true) {
                return true;
            }
            return false;
        },
        updatePermissions(perms) {
            _i.setPermissions(perms);
            cl.update();
        },
        user() {
            if (!_i.check()) {
                return '';
            }
            return cl.ls.get('lastUser').toLowerCase();
        },
        isPublic() {
            return [
                'c3BlbmNlciBs', // spencer l
                'c2Vhc2lkZQ==', // seaside
                'Y2Fubm9uYmVhY2g=', // cannonbeach
                'ZG90Y29t' // dotcom
            ]
            .map(Base64.decode)
            .includes(_i.user());
        },
        isRetail() {
            return [
                'c2Vhc2lkZQ==', // seaside
                'Y2Fubm9uYmVhY2g=', // cannonbeach
            ]
            .map(Base64.decode)
            .includes(_i.user());
        },
        isSeaside() {
            return [
                'c2Vhc2lkZQ==', // seaside
            ]
            .map(Base64.decode)
            .includes(_i.user());
        },
        isCannonBeach() {
            return [
                'Y2Fubm9uYmVhY2g=', // cannonbeach
            ]
            .map(Base64.decode)
            .includes(_i.user());
        },
        isOwner() {
            return [
                'ZWwgamVmZQ==', // el jefe
                'bmFuY3k=' // nancy
            ]
            .map(Base64.decode)
            .includes(_i.user());
        },
        isDev() {
            return [
                'anVzdGluIHA=', // justin p
            ]
            .map(Base64.decode)
            .includes(_i.user());
        },
        isTest() {
            return [
                'dGVzdA==', // test
            ]
            .map(Base64.decode)
            .includes(_i.user());
        },
    };

    const caches = [];
    const clearCaches = () => caches.forEach(f => f.cache.clear());
    const isPublicCache = memoize(_i.isPublic);
    caches.push(isPublicCache);
    const isSeasideCache = memoize(_i.isSeaside);
    caches.push(isSeasideCache);
    const isCannonBeachCache = memoize(_i.isCannonBeach);
    caches.push(isCannonBeachCache);
    const isOwnerCache = memoize(_i.isOwner);
    caches.push(isOwnerCache);
    const isRetailCache = memoize(_i.isRetail);
    caches.push(isRetailCache);
    const isDevCache = memoize(_i.isDev);
    caches.push(isDevCache);
    const isTestCache = memoize(_i.isTest);
    caches.push(isTestCache);

    setInterval(clearCaches, 1000 * 60 * 5);

    return {
        init: _i.init,
        getToken: _i.getToken,
        response: _i.response,
        logOff: _i.deleteToken,
        getLastUser: _i.getLastUser,
        getUser: _i.getLastUser,
        auth: _i.auth,
        perm: _i.getPermissions,
        hasPerm: _i.hasPerm,
        reads: _i.reads,
        updatePermissions: _i.updatePermissions,
        isPublic: _i.isPublic,
        isRetail: _i.isRetail,
        isOwner: _i.isOwner,
        isDev: _i.isDev,
    };
})(window.cl);