import { handleOptionsUp, handleOptionsDown } from './utilities/options';
import dayjs from './utilities/dayjs';

window.cl = window.cl || {};

window.cl.order = ((cl) => {
    const _on = {
        orderTab(e, tab) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/orders/${cl.get('params.order')}/${tab}`);
        },
        orderDelete(e, id) {
            cl.prompt({
                message: '<strong>Are you sure you want to delete this order?</strong><br>Type <em>&quot;delete&quot;</em> below to confirm.',
                ok: 'Delete Order',
                okFunc(v) {
                    if (v.trim().toLowerCase() === 'delete') {
                        cl.send('deleteOrder', id);
                    } else {
                        cl.error('Confirm Error: Delete canceled.');
                    }
                }
            });
        },
        orderProductSearch(e) {
            cl.set('order.productSearch', e.node.value);
            cl.find('#order-product-search-input').classList.add('focus');
        },
        orderSearchFocus(e) {
            cl.set('order.productSearch', e.node.value);
            cl.find('#order-product-search-input').classList.add('focus');
            _i.search();
            cl.update('order.productSearchResults');
        },
        orderProductSearchClick(e, id) {
            _i.productSelect(id);
        },
        orderProductQty(e) {
            const index = cl.exec('order.productIndex', e.node.dataset.id);
            if (index < 0) {
                return;
            }

            const key = `orders.all.${cl.exec('order.srcIndex')}.products.${index}.qty`;
            const val = e.node.value;

            if (val < 0 || !/[0-9]/.test(val)) {
                cl.set(key, 0);
            } else {
                cl.set(key, Number(val));
            }
            _.defer(_i.changes);
        },
        orderProductQtyUp(e, id) {
            const index = cl.exec('order.productIndex', id);
            if (index <= -1) {
                return;
            }

            const key = `orders.all.${cl.exec('order.srcIndex')}.products.${index}.qty`;
            cl.set(key, Number(cl.get(key) + 1));
            _.defer(_i.changes);
        },
        orderProductQtyDown(e, id) {
            const index = cl.exec('order.productIndex', id);
            if (index <= -1) {
                return;
            }

            const key = `orders.all.${cl.exec('order.srcIndex')}.products.${index}.qty`;
            const val = cl.get(key);
            const rec = cl.exec('order.received', id) || 0;
            if (!(val > 0 && val > rec)) {
                return;
            }

            cl.set(key, Number(val - 1));
            _.defer(_i.changes);
        },
        orderProductRemove(e, id) {
            const products = cl.exec('order.src').products;
            const srcIndex = cl.exec('order.srcIndex');
            const index = _.findIndex(products, ['id', id]);

            cl.splice('orders.all[' + srcIndex + '].products', index, 1);
            _.defer(_i.changes);
        },
        orderSave(e) {
            if (!e.node.disabled) {
                _.defer(_i.save);
            }
        },
        orderReset(e) {
            if (!e.node.disabled) {
                cl.send('getOrder', cl.exec('order.src').id);
            }
        },
        orderArrivingSelectOpen() {
            cl.set('order.arrivingSelect', true);
        },
        orderArrivingSelectClose(e) {
            if (_.isUndefined(e) || e.original.target.classList.contains('modal-wrapper')) {
                cl.set('order.arrivingSelect', false);
            }
        },
        orderArrivingDayUp() {
            cl.set(`orders.all.${cl.exec('order.srcIndex')}.arriving`,
                dayjs(cl.exec('order.src').arriving).clone().add(1, 'days').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
            _.defer(_i.changes);
        },
        orderArrivingDayDown() {
            cl.set(`orders.all.${cl.exec('order.srcIndex')}.arriving`,
                dayjs(cl.exec('order.src').arriving).clone().subtract(1, 'days').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
            _.defer(_i.changes);
        },
        orderArrivingMonthUp() {
            cl.set(`orders.all.${cl.exec('order.srcIndex')}.arriving`,
                dayjs(cl.exec('order.src').arriving).clone().add(1, 'months').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
            _.defer(_i.changes);
        },
        orderArrivingMonthDown() {
            cl.set(`orders.all.${cl.exec('order.srcIndex')}.arriving`,
                dayjs(cl.exec('order.src').arriving).clone().subtract(1, 'months').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
            _.defer(_i.changes);
        },
        orderArrivingYearUp() {
            cl.set(`orders.all.${cl.exec('order.srcIndex')}.arriving`,
                dayjs(cl.exec('order.src').arriving).clone().add(1, 'years').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
            _.defer(_i.changes);
        },
        orderArrivingYearDown() {
            cl.set(`orders.all.${cl.exec('order.srcIndex')}.arriving`,
                dayjs(cl.exec('order.src').arriving).clone().subtract(1, 'years').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            );
            _.defer(_i.changes);
        },
        orderSupplierSelect(e) {
            const v = e.node.value;
            if (v === 'addNewSupplier') {
                cl.prompt({
                    message: 'Add New Supplier',
                    ok: 'Add',
                    okFunc(v, e) {
                        e.preventDefault();
                        const nv = v.toLowerCase().trim().replace(/\s\s+/g, ' ').split(' ').join('-');
                        cl.push('order.suppliersTmp', nv);
                        cl.push('order.suppliersTmp', cl.exec('order.src').supplier);
                        cl.set(`orders.all.${cl.exec('order.srcIndex')}.supplier`, nv);
                        _.defer(_i.changes);
                        cl.update('order.suppliers');
                    }
                });

                return;
            }

            if (v !== '') {
                cl.set(`orders.all.${cl.exec('order.srcIndex')}.supplier`, v.toLowerCase());
                _.defer(_i.changes);
            }
        },
        orderStatusSelect(e) {
            const v = e.node.value;
            if (v === '') {
                return;
            }

            cl.set(`orders.all.${cl.exec('order.srcIndex')}.status`, v);
            _.defer(_i.changes);
        },
        orderTypeSelect(e) {
            const v = e.node.value;
            if (v === '') {
                return;
            }

            cl.set(`orders.all.${cl.exec('order.srcIndex')}.type`, v);
            _.defer(_i.changes);
        },
        orderGoToProduct(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/product/${id}/details`);
        },
        orderFieldChange(e) {
            const key = e.node.dataset.src;
            if (_.isUndefined(key)) {
                return;
            }

            const val = e.node.value;
            const i = cl.exec('order.srcIndex');
            const start = e.node.selectionStart;
            const end = e.node.selectionEnd;

            cl.set(`orders.all.${i}.${key}`, val);
            try {
                e.node.setSelectionRange(start, end);
            } catch (e) {
                _.log(e);
            }
            _.defer(_i.changes);
        },
        orderAddNote() {
            const node = cl.find('#order-note-area');
            const note = node.value;
            const i = cl.exec('order.srcIndex');
            const key = `orders.all.${i}.notes`;

            if (i <= -1) {
                return;
            }

            if (!_.isArray(cl.get(key))) {
                cl.set(key, [])
            }

            cl.prompt({
                message: 'Please type your name.',
                ok: 'Add',
                okFunc(v, e) {
                    e.preventDefault();
                    if (v === '') {
                        cl.error('Name required.');
                        return;
                    }

                    cl.push(key, {
                        note,
                        name: v.trim(),
                        created: new Date(),
                    });
                    node.value = '';
                    _.defer(_i.changes);
                }
            });
        },
        orderNewProduct(e) {
            e?.original?.preventDefault?.();
            const orderId = cl.exec('order.src').id;
            cl.set('product.orderId', orderId);
            cl.routes.go('/product/new/details');
        },
    };
    const _set = {
        'order.save': false,
        'order.src'() {
            const id = cl.get('params.order');
            if (!id) {
                return false;
            }
            const i = cl.orders.getIndex(id);
            if (i > -1) {
                return cl.get(`orders.all.${i}`);
            }

            return false;
        },
        'order.indexFromId'(id) {
            return cl.orders.getIndex(id);
        },
        'order.fromId'(id) {
            const i = cl.orders.getIndex(id);
            if (i > -1) {
                return cl.get(`orders.all.${i}`);
            }

            return false;
        },
        'order.srcIndex'() {
            return cl.exec('order.indexFromId', cl.get('params.order'));
        },
        'order.productSearch': '',
        'order.productSearchIds': [],
        'order.productSearchResults'() {
            const ids = cl.get('order.productSearchIds');
            const el = cl.find('#order-product-search-input');

            if (!(!_.isNull(el) && el.classList.contains('focus') && (ids.length || cl.get('order.productSearch').length > 1))) {
                return [];
            }

            return _(cl.get('products.all'))
                .keyBy('id')
                .at(ids)
                .sortBy('sku')
                .value();
        },
        'order.productIndex'(id) {
            return _.findIndex(cl.exec('order.src').products, ['id', id]);
        },
        'order.arrivingSelect': false,
        'order.suppliersTmp': [],
        'order.suppliers'() {
            return _(cl.get('orders.all'))
                .map('supplier')
                .concat(cl.get('order.suppliersTmp'))
                .filter(v => !_.isNull(v) && !_.isUndefined(v))
                .map(v => v.toLowerCase())
                .uniq()
                .filter(v => v !== "")
                .sort()
                .value();
        },
        'order.received'(id) {
            return cl.exec('shipments.productsReceived', cl.get('params.order'), id);
        },
        'order.errors'() {
            const products = cl.exec('order.src').products;
            for (const product of products) {
                if (product.qty < cl.exec('order.received', product.id)) {
                    return true;
                }
            }

            return false;
        },
        'order.displayUPC'(barcodes = []) {
            return barcodes.filter(bc => String(bc).length >= 12).join(', ');
        },
    };
    const _i = {
        init() {
            cl.set(_set);
            cl.on(_on);

            cl.observe('order.productSearch', () => {
                _.defer(_i.search);
            });

            document.addEventListener("upArrow", (e) => {
                _.defer(_i.optionsUp, e.detail);
            });
            document.addEventListener("downArrow", (e) => {
                _.defer(_i.optionsDown, e.detail);
            });
            document.addEventListener("enterFire", (e) => {
                _.defer(_i.optionsSelect, e.detail);
            });
            document.addEventListener("escapeFire", (e) => {
                _.defer(_i.optionsExit, e.detail);
            });

            document.addEventListener("save", (e) => {
                if (cl.routes.get() === 'order') {
                    _.defer(_i.save);
                }
            });

            document.addEventListener("barcode", (e) => {
                _i.barcodeListener(e.detail);
            });
        },
        load(order) {
            const index = cl.orders.getIndex(order.id);

            if (index <= -1) {
                return;
            }

            cl.set(`orders.all.${index}`, order);
            _.defer(cl.orders.setHash, order);
            _.defer(_i.changes);
        },
        save() {
            if (!cl.get('order.save')) {
                return;
            }

            const order = cl.exec('order.src');
            order.products = order?.products?.map?.(p => ({ id: String(p.id), qty: Number(p.qty) })) ?? [];

            cl.send('updateOrder', order);
        },
        search() {
            const search = cl.get('order.productSearch');
            if (search.length <= 1) {
                cl.set('order.productSearchIds', []);
                cl.update();
                return;
            }

            cl.set('order.productSearchIds', _(cl.products.search(search)).map('ref').value());
            cl.update('order.productSearchResults');
        },
        changes() {
            const id = cl.get('params.order');
            if (id < 0) {
                return;
            }

            const src = cl.exec('order.src');
            const orderHash = cl.get(`orders.hashes.ref_${src.id}`);

            if (_.isUndefined(orderHash)) {
                return false;
            }

            if (cl.hash(JSON.stringify(src)) === orderHash) {
                cl.set('order.save', false);
                return false;
            } else {
                cl.set('order.save', true);
                return true;
            }
        },
        optionsUp() {
            if (cl.routes.get() !== 'order' || cl.exec('order.productSearchResults').length <= 0) {
                return;
            }

            handleOptionsUp(document.querySelectorAll('#order-product-search-list li'));
        },
        optionsDown() {
            if (cl.routes.get() !== 'order' || cl.exec('order.productSearchResults').length <= 0) {
                return;
            }

            handleOptionsDown(document.querySelectorAll('#order-product-search-list li'));
        },
        optionsSelect() {
            if (cl.routes.get() !== 'order' || cl.exec('order.productSearchResults').length <= 0) {
                return;
            }

            const opt = cl.find('#order-product-search-list li.active');
            if (opt?.dataset?.id) {
                _i.productSelect(opt.dataset.id);
            }
        },
        optionsExit() {
            if (cl.routes.get() !== 'order') {
                return;
            }

            const el = cl.find('#order-product-search-input');
            el.classList.remove('focus');
            el.blur();
            cl.update('order.productSearchResults');
        },
        productSelect(id) {
            const key = `orders.all.${cl.exec('order.srcIndex')}.products`;
            const products = cl.get(key);
            const index = _.findIndex(products, ['id', id]);

            if (index > -1) {
                cl.increment(`orders.all.${cl.exec('order.srcIndex')}.products.${index}.qty`)
            } else {
                cl.unshift(key, {
                    id: String(id),
                    qty: 1,
                    received: 0
                });
            }

            cl.update('order.src');
            _.defer(_i.changes);
        },
        barcodeListener(bc) {
            if (cl.routes.get() !== 'order' || cl.exec('tab') !== 'products') {
                return;
            }

            cl.products.skuFromBarcode(bc, (bc, sku, id) => {
                _i.productSelect(id);
            });
        },
        addProduct(orderId, productId) {
            const i = cl.orders.getIndex(orderId);

            if (i < 0) {
                return;
            }

            cl.unshift(`orders.all.${i}.products`, { id: String(productId), qty: 1, received: 0 });
            cl.routes.go(`/orders/${orderId}/products`);
            _.defer(_i.changes);
        },
    };

    return {
        init: _i.init,
        changes: _i.changes,
        load: _i.load,
        addProduct: _i.addProduct,
    };
})(window.cl);