import { sortBy } from 'lodash';

const pullSort = (products = []) => {
    const funcs = [
        p => p.group,
        p => p.brand,
        p => p.gender,
        p => p.thickness,
        p => p.nameFiltered,
        p => p.sku
    ];

    return sortBy(products.filter(p=>!!p), funcs);
};

const nestedPullSort = (products = []) => {
    const funcs = [
        p => p.product.group,
        p => p.product.brand,
        p => p.product.gender,
        p => p.product.thickness,
        p => p.product.nameFiltered,
        p => p.product.sku
    ];

    return sortBy(products.filter(p=>!!p), funcs);
}

export {
    pullSort,
    nestedPullSort,
}