import _ from "lodash";

const defaultGain = 0.9;

let initialized = false;
let context;
let gain;

const init = () => {
    if (initialized) {
        return;
    }
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    context = new AudioContext();
    gain = context.createGain();
    gain.gain.value = defaultGain;
    gain.connect(context.destination);
    initialized = true;
    document.removeEventListener('click', init);
    document.removeEventListener('touchstart', init);
};

document.addEventListener('click', init);
document.addEventListener('touchstart', init);

const createOscillator = (freq, type = 'sine') => {
    const osc = context.createOscillator();
    osc.type = type;
    osc.frequency.value = freq;
    osc.connect(gain);

    return osc;
};

const getTime = (offset = 0) => {
    return context.currentTime + offset;
};

const successSound = () => {
    try {
        init();
        const osc1 = createOscillator(1000, 'square');
        const osc2 = createOscillator(2000, 'square');
        const osc3 = createOscillator(1000, 'square');

        osc1.start(getTime());
        osc2.start(getTime(0.05));
        osc3.start(getTime(0.1));

        osc1.stop(getTime(0.025));
        osc2.stop(getTime(0.075));
        osc3.stop(getTime(0.125));
    } catch (e) {
        _.log(e);
    }
};

const failureSound = () => {
    try {
        init();
        gain = context.createGain();
        gain.gain.value = 0.1;
        gain.connect(context.destination);
        const osc1 = createOscillator(400, 'sawtooth');
        const osc2 = createOscillator(100, 'sawtooth');

        osc1.start(getTime());
        osc2.start(getTime(0.2));

        osc1.stop(getTime(0.2));
        osc2.stop(getTime(0.4));
        gain = context.createGain();
        gain.gain.value = defaultGain;
        gain.connect(context.destination);
    } catch (e) {
        _.log(e);
    }
};

const loadingSound = () => {
    try {
        init();
        gain = context.createGain();
        gain.gain.value = 0.1;
        gain.connect(context.destination);
        const convolver = context.createConvolver();
        const noiseBuffer = context.createBuffer(2, 0.5 * context.sampleRate, context.sampleRate);
        const left = noiseBuffer.getChannelData(0);
        const right = noiseBuffer.getChannelData(1);

        for (let i = 0; i < noiseBuffer.length; i++) {
            left[i] = Math.random() * 2 - 1;
            right[i] = Math.random() * 2 - 1;
        }
        convolver.buffer = noiseBuffer;

        let tm = 0;

        for (let i = 0; i < 75; ++i) {
            if (Math.random() > 0.15) {
                const osc = createOscillator(Math.ceil((Math.random() * 5950) + 50), 'triangle');
                osc.connect(convolver);
                convolver.connect(context.destination);
                osc.start(getTime(tm));
                tm += 0.05;
                osc.stop(getTime(tm));
            } else {
                tm += 0.1;
            }
        }
        gain = context.createGain();
        gain.gain.value = defaultGain;
        gain.connect(context.destination);
    } catch (e) {
        _.log(e);
    }
}

export {
    successSound,
    failureSound,
    loadingSound,
}