import _ from 'lodash';
import dayjs from './utilities/dayjs';

window.cl = window.cl || {};

window.cl.productsBulkUpdate = ((cl) => {
    const _on = {
        productsBulkUpdate(e) {
            e?.original?.preventDefault?.();
            const ids = _.keys(cl.get('products.selected'));
            if (!ids.length) {
                cl.error('No Products Selected');
                return;
            }

            if (ids.length > 0 && ids.length === cl.get('products.all').length) {
                cl.error('All Products Selected');
                return;
            }

            const defaults = {};

            const skip = {
                id: true,
                inventory: true,
                barcodes: true,
                created: true,
                updated: true,
                img: true,
                qty: true,
                websitePrice: true,
                sku: true,
            }

            for (const id of ids) {
                const p = cl.exec('product.fromId', id);
                if (!p) {
                    continue;
                }

                for (const k in p) {
                    if (k in skip || !p[k]) {
                        continue;
                    }
                    if (!(k in defaults)) {
                        defaults[k] = {};
                    }

                    defaults[k][p[k]] = true;
                }
            };

            for (const k in defaults) {
                const res = _.keys(defaults[k]);
                if (res.length !== 1) {
                    delete defaults[k];
                } else {
                    defaults[k] = res[0];
                }
            }

            cl.set('products.bulk.defaults', defaults);
            cl.set('products.bulk.values', _.clone(defaults));
            cl.set('products.bulk.ids', ids);
            cl.routes.go('/products-bulk-update');
            cl.update('products.bulk.errors');
            cl.update('products.bulk.changes');
        },
        productsBulkReset() {
            const defaults = _.clone(cl.get('products.bulk.defaults'));
            cl.set('products.bulk.values', defaults);
        },
        productsBulkFieldChange(e, src, val) {
            if (!src) {
                try {
                    src = e.node.dataset.src;
                } catch(err) {
                    _.log(err);
                }
            }

            if (!src) return;

            if (!val) {
                try {
                    val = e.node.value;
                } catch(err) {
                    _.log(err);
                }
            }
            if (!val && !(_.isString(val) && val === '')) return;

            cl.set(`products.bulk.values.${src}`, val);
        },
        productsBulkFieldChangeBool(e, src) {
            if (!src) {
                try {
                    src = e.node.dataset.src;
                } catch (e) { }
            }

            if (!src) return;
            const val = cl.get(`products.bulk.values.${src}`);
            const newVal = val === 'true' ? 'false' : 'true';
            cl.set(`products.bulk.values.${src}`, newVal);
        },
        productsBulkFieldBlur(e) {
            const src = e.node.dataset.src;
            const defaultVal = cl.get(`products.bulk.defaults.${src}`);
            let val = e.node.value;

            if (_.isString(val) && val !== val.trim()) {
                val = val.trim();
                cl.set(`products.bulk.values.${src}`, val);
            }

            if (cl.get('settings.disableProductCaseCheck')) {
                return;
            }

            const caseChecks = {
                name: true,
                brand: true,
                supplier: true,
                group: true, // product type
                department: true,
                color: true,
                size: true,
                condition: true,
                parentGroup: false,
            }
            const uniqueVals = cl.get('products.all').reduce((a, p) => {
                for (const key in caseChecks) {
                    if (!(key in a)) {
                        a[key] = {};
                    }
                    a[key][p[key].toLowerCase()] = p[key];
                }
                return a;
            }, {});

            if (
                _.isString(val) &&
                val !== defaultVal &&
                src in caseChecks &&
                uniqueVals[src][val.toLowerCase()] &&
                uniqueVals[src][val.toLowerCase()] !== val
            ) {
                cl.set(`products.bulk.values.${src}`, uniqueVals[src][val.toLowerCase()]);
            }
        },
        productsBulkFieldClear(e) {
            const src = e.node.dataset.src;
            cl.set(`products.bulk.values.${src}`, '');
        },
        productsBulkDateChange(e, src, unit, dir) {
            if (!['add', 'subtract'].includes(dir)) {
                return cl.error(`Invalid direction ${dir}`);
            }

            if (!['day', 'month', 'year'].includes(unit)) {
                return cl.error(`Invalid unit ${unit}`);
            }

            const key = `products.bulk.values.${src}`;
            const newDate = dayjs(cl.get(key)).clone()[dir](1, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            cl.set(key, newDate);
        },
        productsBulkUpdateSend() {
            if (cl.exec('products.bulk.hasErrors')) {
                cl.error('Please Fix Errors Before Updating');
                return;
            }

            if (!cl.exec('products.bulk.hasChanges')) {
                cl.error('No Changes To Update');
                return;
            }

            const ids = cl.get('products.bulk.ids').map(id => parseInt(id));
            const changes = cl.exec('products.bulk.changes');
            const values = cl.get('products.bulk.values');
            const defaults = cl.get('products.bulk.defaults');
            const vals = {};
            let str = `Are you sure you want to bulk update ${ids.length} products.<br><strong>There is no undo</strong>.<br><br>Changes:<br><br>`;

            for (const key in changes) {
                if (changes[key]) {
                    if (key === 'discontinued' && values[key] === '') {
                        vals[key] = undefined;
                    } else {
                        vals[key] = values[key];
                    }
                    str += `${_.capitalizeAll(key)}: ${defaults[key] || '<em>null</em>'} -> ${values[key] || '<em>null</em>'}<br>`;
                }
            }

            cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>${str}</div>`,
                ok: `Update ${ids.length} Products`,
                okFunc() {
                    cl.send('productsBulkUpdate', {
                        ids,
                        product: vals,
                        fields: _.keys(vals),
                    });
                }
            });
        },
    };
    const _set = {
        'products.bulk.ids': {},
        'products.bulk.defaults': {},
        'products.bulk.values': {},
        'products.bulk.errors'() {
            const values = cl.get('products.bulk.values');
            const errors = {};

            for (const key in values) {
                errors[key] = _i.errorCheck(key, values[key]);
            }

            return errors;
        },
        'products.bulk.hasErrors'() {
            const errs = cl.exec('products.bulk.errors');
            return Object.keys(errs).some(key => errs[key].length);
        },
        'products.bulk.changes'() {
            const defaults = cl.get('products.bulk.defaults');
            const values = cl.get('products.bulk.values');

            return Object.keys(values).reduce((a, key) => {
                a[key] = key in defaults && values[key] !== defaults[key];
                return a;
            }, {});
        },
        'products.bulk.hasChanges'() {
            const changes = cl.exec('products.bulk.changes');
            return Object.keys(changes).some(key => changes[key]);
        },
        'products.bulk.canUpdate'() {
            return cl.exec('products.bulk.hasChanges') && !cl.exec('products.bulk.hasErrors');
        },
        'products.bulk.options'(option) {
            return _(cl.get('products.all'))
                .map(option)
                .uniq()
                .pull('')
                .sort()
                .value();
        },
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);
        },
        errorCheck(key, val) {
            const errors = [];
            let required = false;

            const moneyCheck = n => {
                const mon = String(n).split('.');
                if (/[^0-9\.]/.test(n) || mon.length > 2 || (mon.length === 2 && mon[1].length > 2)) {
                    return true;
                }
                return false;
            }
            const numberCheck = n => /[^0-9\.]/.test(n) || n == '';

            switch (key) {
                case 'id': // skip
                    break;
                case 'sku': // skip
                    break;
                case 'created': // skip
                    break;
                case 'updated': // skip
                    break;
                case 'qty': // skip
                    break;
                case 'name':
                    if (!val.trim()) {
                        required = true;
                    }
                    if (/[\t\n\r\v]/i.test(val.trim())) {
                        errors.push('*Invalid Character');
                    }
                    break;
                case 'price':
                    if (moneyCheck(val)) {
                        errors.push('*Invalid Money Format');
                    }
                    break;
                case 'cost':
                    if (moneyCheck(val)) {
                        errors.push('*Invalid Money Format');
                    }
                    break;
                case 'costPc':
                    if (isNaN(String(val).trim()) || !(val >= 0 && val <= 100) || val == '') {
                        errors.push('*Invalid Percentage Format');
                    }
                    break;
                case 'weight':
                    if (numberCheck(val)) {
                        errors.push('*Invalid Number Format');
                    }
                    break;
                case 'color': // skip
                case 'size': // skip
                case 'brand': // skip
                case 'group': // skip
                case 'supplier': // skip
                case 'department': // skip
                case 'consignment': // skip
                case 'condition': // skip
                case 'quick': // skip
                case 'track': // skip
                case 'parent': // skip
                case 'handle': // skip
                case 'parentGroup': //skip
                    break;
                case 'discontinued':
                    if (val !== '' && !dayjs(val).isValid()) {
                        errors.push('*Invalid Date');
                    }
                    break;
                default:
                    _.log(`Error Check: Unhandled Product Field "${key}"`);
                    errors.push('*Unable To Validate');
                    break;
            }

            if (required) {
                errors.push('*Required');
            }

            return errors;
        },
        success() {
            cl.success(`${cl.get('products.bulk.ids').length} Products Updated`);
            cl.set('products.bulk.ids', {});
            cl.set('products.bulk.defaults', {});
            cl.set('products.bulk.values', {});
            cl.routes.go('/products');
        },
        clientUpdate(data) {
            const fields = data.fields;
            const product = data.product;
            const ids = data.ids || [];
            const idsKey = _.keyBy(ids);
            const products = cl.get('products.all');

            const updateProducts = p => {
                if (p.id in idsKey) {
                    for (const field of fields) {
                        p[field] = product[field];
                    }
                }

                return p;
            };

            cl.set('products.all', products.map(updateProducts));
            cl.products.updateIndices();
        },
    };

    return {
        init: _i.init,
        success: _i.success,
        clientUpdate: _i.clientUpdate,
    };
})(window.cl);