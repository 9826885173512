import { Base64 } from 'js-base64';
import { v4 as uuidv4 } from 'uuid';
import isUUID from './utilities/isUUID';
import dayjs from './utilities/dayjs';

_.mixin({
    money(money = 0) {
        const str = Number(money).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        const neg = Math.abs(money) !== Number(money) && Number(money) !== 0;

        return `${neg ? '-' : ''}$${neg ? str.replace('-', '') : str}`;
    },
    moneyRaw(money = 0) {
        return Number(money).toFixed(2);
    },
    bool(string) {
        if (string === 'true' || string === true) {
            return true;
        }
        return false;
    },
    randomString(len) {
        return Math
            .round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, len)))
            .toString(36)
            .slice(1);
    },
    capitalizeAll(s) {
        return String(s).split(' ').map(_.capitalize).join(' ');
    },
    capitalizeAllSplit(s) {
        return String(s).split(' ').map(s=>s.split(/(?=[A-Z])/).map(_.capitalize).join(' '));
    },
    camelCase(s = '') {
        return s.replace(/(?:^\w|[A-Z]|\b\w)/g, (v, k) => {
            return k === 0 ? v.toLowerCase() : v.toUpperCase();
        }).replace(/\s+/g, '');
    },
    uuid() {
        return uuidv4();
    },
    isUUID(s) {
        return isUUID(s);
    },
    log(...args) {
        _.logOnly.apply(this, [new Date().toLocaleString(), ...args]);
    },
    logVerbose(...args) {
        if (!cl.logVerbose) {
            return;
        }
        _.log.apply(this, args);
    },
    logOnly(...args) {
        window[Base64.decode('Y29uc29sZQ==')][Base64.decode('bG9n')].apply(this, args);
    },
    sortByKeys(obj) {
        const keys = Object.keys(obj);
        const sortedKeys = _.sortBy(keys);

        return _.fromPairs(
            _.map(sortedKeys, key => [key, obj[key]]),
        )
    },
    pullName(p) {
        let s = `${p.sku} - ${p.name}`;

        if (!_.isUndefined(p.variations)) {
            for (const k in p.variations) {
                s += ` - ${k}: ${p.variations[k]} `;
            }
        }

        return _.truncate(s, { length: 90 });
    },
    addSign(n) {
        if (n > 0) {
            return `+${n}`;
        }
        return `${n}`;
    },
    formatPhone(num) {
        const cleaned = ('' + num).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }

        return null;
    },
    nowUnix() {
        return dayjs().unix();
    },
    filterAll(collection, ...fs) {
        for (const f of fs) {
            collection = _.filter(collection, f);
        }
        return collection;
    },
    orderNumberToNumber(on = '') {
        return Number(on.replace(/\D/g, ''));
    },
    inArray(val, ...vals) {
        return vals.includes(val);
    },
    fileDate() {
        return cl.dayjs().format('YYYY-MM-DD-hh-mm-A');
    },
    arrayReplace(a, b) {
        if (!_.isArray(a) || !_.isArray(b)) {
            console.error('arrayReplace() expects 2 arrays');
            return [];
        }

        const copy = [...b];
        a.length = 0;
        a.push(...copy);
        return a;
    },
    objectDiff(a, b) {
        return _.fromPairs(_.differenceWith(_.toPairs(a), _.toPairs(b), _.isEqual));
    },
});