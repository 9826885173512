import _ from 'lodash';
import lunr from 'lunr';
import Ractive from 'ractive';
import { CSVtoArray } from './utilities/CSVtoArray';
import { failureSound } from './utilities/sound';

window.cl = window.cl || {};

window.cl.products = ((cl) => {
    const _indexer = {
        index: undefined,
        delay: 0,
        start: _.now(),
        set(index) {
            this.index = index;
        },
        get() {
            if (!this.index) {
                this.init();
            }

            return this.index;
        },
        getDelay() {
            return this.delay;
        },
        addDelay(n = 0) {
            this.delay += n;
        },
        resetDelay() {
            this.delay = 0;
        },
        getStart() {
            return this.start;
        },
        resetStart() {
            this.start = _.now();
        },
        init() {
            const self = this;
            self.index = lunr(function() {
                this.pipeline.remove(lunr.stemmer);
                this.pipeline.remove(lunr.stopWordFilter);
                this.pipeline.remove(lunr.trimmer);

                this.field('name', {
                    boost: 20
                });
                this.field('sku', {
                    boost: 10
                });
                this.field('brand', {
                    boost: 15
                });
                this.field('supplier', {
                    boost: 15
                });
                this.field('group', {
                    boost: 5
                });
                this.field('department', {
                    boost: 5
                });
                this.field('color', {
                    boost: 5
                });
                this.field('size', {
                    boost: 5
                });
                this.field('parent', {
                    boost: 3
                });
                this.field('id', {
                    boost: 1
                });
            });

            self.index.pipeline.remove(lunr.stemmer);
            self.index.pipeline.remove(lunr.stopWordFilter);
            self.index.pipeline.remove(lunr.trimmer);
        },
    };

    const _productChunks = [];
    const _bySize = [
            '0', '1', '2', '3', '4', '5', '6', '6T', '7', '8S', '8', '8T',
            '9', '10S', '10', '10T', '11','12S', '12', '12M', '12T', '13',
            '14', '15', '16', '17', '18', '18M', '19', '20', 'XXXS', 'XXS',
            'XS', 'S', 'ST', 'MS', 'M', 'ML', 'MT', 'LS', 'L', 'LT', 'XLS',
            'XL', 'XLT', 'XXLS', 'XXL', 'XXXL', 'XXXXL'
        ].reduce((a, v, k) => {
            a[v] = 1e6 + k;
            return a;
        }, {});
    const _oosKey = 'productsHideOutOfStock';
    const _set = {
        'products.paging.page': 1,
        'products.paging.perPage': 10,
        'products.paging.perPageOptions': [10, 15, 25, 50, 100],
        'products.paging.sortBy': 'group',
        'products.paging.sortAsc': true,
        'products.paging.search': '',
        'products.paging.searchIds': [],
        async 'products.paging.fireSearch'() {
            await cl.data.allLoaded();
            _i.search();
        },
        'products.paging.sortDisplay'(attr) {
            const paging = cl.get('products.paging');
            if (paging.sortBy === attr) {
                return `<i class="material-icons">${paging.sortAsc ? 'arrow_drop_up' : 'arrow_drop_down'}</i>`;
            }
            return '';
        },
        'products.indexed': false,
        'products.preFiltered'(exclude) {
            let chain = _(cl.get('products.all')).filter(p => !_.isUndefined(p) && !_.isUndefined(p.id));

            if (cl.get('products.paging.searchIds').length || cl.get('products.paging.search').length > 1) {
                chain = chain.keyBy('id').at(cl.get('products.paging.searchIds'));
            }

            const selected = cl.get('products.filter.selected');
            for (const id in selected) {
                if (selected[id].length && id !== exclude) {
                    chain = chain.filter(p => selected[id].some(v => p[id] === v));
                }
            }

            return chain.value();
        },
        'products.filtered'(exclude) {
            const hideOutOfStock = cl.exec('products.hideOutOfStock');
            const hideOutOfStockFunc = p => hideOutOfStock ? !(
                p &&
                _.bool(p.track) &&
                p.inventory.wh.main === 0 &&
                p.inventory.wh.holds === 0 &&
                p.inventory.ss.main === 0 &&
                p.inventory.ss.holds === 0 &&
                p.inventory.cb.main === 0 &&
                p.inventory.cb.holds === 0
            ) : true;

            const onHold = cl.get('products.onHold');
            const onHoldFunc = p => onHold ? (
                p.inventory.wh.holds > 0
                || p.inventory.ss.holds > 0
                || p.inventory.cb.holds
            ) > 0 : true;

            const missing = cl.get('products.missing');
            const missingFunc = p => missing ? (
                p.inventory.wh.missing > 0
                || p.inventory.ss.missing > 0
                || p.inventory.cb.missing > 0
            ) : true;

            const onlineOnly = cl.get('products.onlineOnly');
            const onlineOnlyFunc = p => onlineOnly ? (p.websitePrice > 0 && !_.isNull(p?.parent) && !_.isUndefined(p?.parent) && _.isString(p?.parent) && p?.parent?.trim?.() !== '') : true;

            const storeOnly = cl.get('products.storeOnly');
            const storeOnlyFunc = p => storeOnly ? (!p?.websitePrice || p.websitePrice <= 0 || _.isUndefined(p?.parent) || _.isNull(p?.parent) || (_.isString(p?.parent) && p?.parent?.trim?.() === '')) : true;

            const zeroWeight = cl.get('products.zeroWeight');
            const zeroWeightFunc = p => zeroWeight ? Number(p.weight) === 0 : true;

            const notTracked = cl.get('products.notTracked');
            const notTrackedFunc = p => notTracked ? p.track === 'false' : true;

            const onlyTracked = cl.get('products.onlyTracked');
            const onlyTrackedFunc = p => onlyTracked ? p.track === 'true' : true;

            const vrReturns = cl.get('products.vrReturns');
            const vrReturnsFunc = p => vrReturns ? p.inventory.vr.returns > 0 : true;

            const ungrouped = cl.get('products.ungrouped');
            const ungroupedFunc = p => ungrouped ? p.parentGroup === '' : true;

            const grouped = cl.get('products.grouped');
            const groupedFunc = p => grouped ? p.parentGroup !== '' : true;

            const neverGroup = cl.get('products.neverGroup');
            const neverGroupFunc = p => neverGroup ? p.parentGroup === 'nevergroup' : true;

            const negativeQty = cl.get('products.negativeQty');
            const negativeQtyFunc = p => negativeQty ? (
                p.inventory.wh.main < 0
                || p.inventory.ss.main < 0
                || p.inventory.cb.main < 0
            ) : true;

            const hasUPC = cl.get('products.hasUPC');
            const hasUPCFunc = p => {
                if (!hasUPC) {
                    return true;
                }

                if (_.isUndefined(p) || (_.isObject(p) && ((p.barcodes || []).length === 0))) {
                    return false;
                }

                for (const bc of p.barcodes) {
                    if (String(bc).length >= 12 && String(bc).at(0) !== '2') {
                        return true;
                    }
                }

                return false;
            };

            const missingUPC = cl.get('products.missingUPC');
            const missingUPCFunc = p => {
                if (!missingUPC) {
                    return true;
                }

                if (!_.isArray(p?.barcodes) || _.isNull(p?.barcodes) || p?.barcodes?.length === 0) {
                    return true;
                }

                let found = false;
                for (const bc of p.barcodes) {
                    if (String(bc).length >= 12 && String(bc).at(0) !== '2') {
                        found = true;
                    }
                }

                return !found;
            };

            const localUPC = cl.get('products.localUPC');
            const localUPCFunc = p => {
                if (!localUPC) {
                    return true;
                }

                if (!_.isArray(p?.barcodes) || _.isNull(p?.barcodes) || p?.barcodes?.length === 0) {
                    return false;
                }

                for (const bc of p.barcodes) {
                    if (String(bc).length >= 12 && String(bc).at(0) === '2') {
                        return true;
                    }
                }

                return false;
            };

            const hasMultipleBarcodes = cl.get('products.hasMultipleBarcodes');
            const hasMultipleBarcodesFunc = p => hasMultipleBarcodes ? (p.barcodes ?? []).length > 1 : true;

            return _(cl.exec('products.preFiltered'))
                .filter(hideOutOfStockFunc)
                .filter(onHoldFunc)
                .filter(missingFunc)
                .filter(onlineOnlyFunc)
                .filter(storeOnlyFunc)
                .filter(zeroWeightFunc)
                .filter(notTrackedFunc)
                .filter(onlyTrackedFunc)
                .filter(vrReturnsFunc)
                .filter(ungroupedFunc)
                .filter(groupedFunc)
                .filter(neverGroupFunc)
                .filter(negativeQtyFunc)
                .filter(hasUPCFunc)
                .filter(missingUPCFunc)
                .filter(localUPCFunc)
                .filter(hasMultipleBarcodesFunc)
                .value();
        },
        'products.hidden'() {
            if (cl.exec('products.hideOutOfStock')) {
                return cl.exec('products.preFiltered').length - cl.exec('products.filtered').length;
            }

            return 0;
        },
        'products.sorted'() {
            if (!cl.routes.get().includes('product')) {
                return [];
            }

            const numeric = {
                price: true,
                cost: true,
            };

            const fields = {
                group: true,
                brand: true,
                name: true,
                size: true,
                color: true,
                sku: true,
            };

            const sortBy = cl.get('products.paging.sortBy');
            const list = _(Object.keys(fields))
                .pull(sortBy)
                .unshift(sortBy)
                .map($sortBy => {
                    if ($sortBy === 'size') {
                        return p => {
                            const key = p?.size?.trim?.();
                            if (key in _bySize && !_.isUndefined(_bySize[key])) {
                                return _bySize[key];
                            }

                            const num = Number(p?.size);
                            if (!Number.isNaN(num)) {
                                return num;
                            }

                            return p.size;
                        };
                    }

                    if ($sortBy in numeric) {
                        return p => Number(p[$sortBy]);
                    }

                    return $sortBy;
                })
                .value();

            return _.orderBy(cl.exec('products.filtered'), list, cl.get('products.paging.sortAsc') ? 'asc' : 'desc');
        },
        'products.visible'() {
            if (cl.routes.get() !== 'products') {
                return [];
            }

            return _(cl.exec('products.sorted'))
                .map((v) => {
                    v.qty = Number(v.qty);
                    v.price = Number(v.price);
                    return v;
                })
                .slice(cl.get('products.paging.perPage') * cl.get('products.paging.page') - cl.get('products.paging.perPage'), cl.get('products.paging.perPage') * cl.get('products.paging.page'))
                .value();
        },
        'products.quick'() {
            return _(cl.get('products.all'))
                .filter(['quick', 'true'])
                .filter(p => !p.discontinued)
                .sort((a, b) => {
                    let _a = 0;
                    let _b = 0;

                    _a += Math.abs(a.inventory.ss.main);
                    _a += Math.abs(a.inventory.cb.main);
                    _b += Math.abs(b.inventory.ss.main);
                    _b += Math.abs(b.inventory.cb.main);
                    return _b -_a;
                })
                .value();
        },
        'products.units'() {
            return cl.exec('products.filtered')
                .reduce((a, p) => {
                    if (p.track === 'false') {
                        return a;
                    }

                    a.wh += p.inventory.wh.main + p.inventory.wh.holds + p.inventory.wh.missing;
                    a.whCost += Number(p.cost) * (p.inventory.wh.main + p.inventory.wh.holds + p.inventory.wh.missing);
                    a.ss += p.inventory.ss.main + p.inventory.ss.holds + p.inventory.ss.missing;
                    a.ssCost += Number(p.cost) * (p.inventory.ss.main + p.inventory.ss.holds + p.inventory.ss.missing);
                    a.cb += p.inventory.cb.main + p.inventory.cb.holds + p.inventory.cb.missing;
                    a.cbCost += Number(p.cost) * (p.inventory.cb.main + p.inventory.cb.holds + p.inventory.cb.missing);
                    a.all += p.inventory.wh.main
                        + p.inventory.wh.holds
                        + p.inventory.wh.missing
                        + p.inventory.ss.main
                        + p.inventory.ss.holds
                        + p.inventory.ss.missing
                        + p.inventory.cb.main
                        + p.inventory.cb.holds
                        + p.inventory.cb.missing;
                    a.allCost += Number(p.cost) * (
                        p.inventory.wh.main + p.inventory.wh.holds + p.inventory.wh.missing
                        + p.inventory.ss.main + p.inventory.ss.holds + p.inventory.ss.missing
                        + p.inventory.cb.main + p.inventory.cb.holds + p.inventory.cb.missing
                    );

                    return a;
                }, {
                    all:0,
                    allCost:0,
                    ss:0,
                    ssCost:0,
                    wh:0,
                    whCost:0,
                    cb:0,
                    cbCost:0
                });
        },
        'products.rental'() {
            if (!cl.get('products.all').length) {
                return [];
            }

            const skus = [
                'CLSRNTSURF',
                'CLSRNTWET',
                'CLSRNTBOOT',
                'CLSRNTGLV',
                'CLSRNTHOOD',
                'CLSRNTYWET',
                'CLSRNTDEMO',
                'CLSRNTBB',
                'CLSRNTSKM',
                'CLSRNTSUP',
                'CLSRNTKYK',
                'CLSRNTKITE',
                'CLSRNTMNTB',
                'CLSRNTSRCK',
                'CLSRNTSTRP',
                'CLSRNTBAG',
                'CLSOVRNGHT',
            ]

            const rentals =  _(cl.get('products.all'))
                .filter(['group', 'Rental'])
                .sortBy(p => Math.abs(p.qty))
                .filter(p => !skus.includes(p.sku))
                .value();

            skus.reverse().forEach(s => rentals.unshift(cl.exec('product.fromSku', s)));

            return rentals.filter(p => !p.discontinued);
        },
        'products.selected': {},
        'products.selectedSkus'() {
            const ids = Object.keys(cl.get('products.selected')).map(id => parseInt(id));
            if (!_.isArray(ids) || ids.length === 0) {
                return [];
            }

            return ids.map(id => {
                return cl.exec('product.skuFromId', id);
            }).filter(sku => !_.isUndefined(sku));
        },
        'products.lastSelected': '',
        'products.locations'() {
            return [{
                base: 'WH',
                long: 'Warehouse',
                subs: [{
                    name: 'Main',
                    code: 'wh.main',
                    sub: 'main',
                }, {
                    name: 'Holds',
                    code: 'wh.holds',
                    sub: 'holds',
                }, {
                    name: 'Missing',
                    code: 'wh.missing',
                    sub: 'missing',
                }],
            }, {
                base: 'SS',
                long: 'Seaside',
                subs: [{
                    name: 'Main',
                    code: 'ss.main',
                    sub: 'main',
                }, {
                    name: 'Holds',
                    code: 'ss.holds',
                    sub: 'holds',
                }, {
                    name: 'Missing',
                    code: 'ss.missing',
                    sub: 'missing',
                }],
            }, {
                base: 'CB',
                long: 'Cannon Beach',
                subs: [{
                    name: 'Main',
                    code: 'cb.main',
                    sub: 'main',
                }, {
                    name: 'Holds',
                    code: 'cb.holds',
                    sub: 'holds',
                }, {
                    name: 'Missing',
                    code: 'cb.missing',
                    sub: 'missing',
                }],
            }, {
                base: 'VR',
                long: 'Virtual Inventory',
                subs: [{
                    name: 'Sales',
                    code: 'vr.sales',
                    sub: 'sales',
                }, {
                    name: 'Returns',
                    code: 'vr.returns',
                    sub: 'returns',
                }, {
                    name: 'Promotions',
                    code: 'vr.promotions',
                    sub: 'promotions',
                }, {
                    name: 'Receiving',
                    code: 'vr.receiving',
                    sub: 'receiving',
                }, {
                    name: 'Vendor',
                    code: 'vr.vendor',
                    sub: 'vendor',
                }, {
                    name: 'Adjustments',
                    code: 'vr.adjustments',
                    sub: 'adjustments',
                }, {
                    name: 'Books',
                    code: 'vr.books',
                    sub: 'books',
                }, {
                    name: 'Inventory Planner',
                    code: 'vr.inventoryplanner',
                    sub: 'inventoryPlanner',
                }],
            }, {
                base: 'LS',
                long: 'Loss Inventory',
                subs: [{
                    name: 'Rental',
                    code: 'ls.rental',
                    sub: 'rental',
                }, {
                    name: 'Damage',
                    code: 'ls.damage',
                    sub: 'damage',
                }, {
                    name: 'Theft',
                    code: 'ls.theft',
                    sub: 'theft',
                }],
            }]
        },
        'products.mainLocations'() {
            return _(cl.exec('products.locations'))
                .filter(l => _.head(l.subs).sub === 'main')
                .map(l => {
                    l.subs = l.subs.filter(s => s.sub === 'main');
                    return l;
                })
                .value();
        },
        'products.mainLoactionNames'() {
            return cl.exec('products.mainLocations').reduce((a, l) => {
                a[l.subs[0].code] = l.long;
                return a;
            }, {});
        },
        'products.lookup.barcodes': {},
        'products.lookup.skus': {},
        'products.lookup.indices': {},
        'products.hideOutOfStock'() {
            return cl.ls.get(_oosKey);
        },
        'products.onHold': false,
        'products.missing': false,
        'products.onlineOnly': false,
        'products.hasUPC': false,
        'products.hasMultipleBarcodes': false,
        'products.missingUPC': false,
        'products.zeroWeight': false,
        'products.notTracked': false,
        'products.onlyTracked': false,
        'products.vrReturns': false,
        'products.ungrouped': false,
        'products.grouped': false,
        'products.neverGroup': false,
        'products.negativeQty': false,
        'products.activeFilters'() {
            const filters = [];

            if (cl.exec('products.hideOutOfStock')) {
                filters.push('Hide Out-of-Stock');
            }

            if (cl.get('products.onHold')) {
                filters.push('On Hold');
            }

            if (cl.get('products.missing')) {
                filters.push('Missing');
            }

            if (cl.get('products.onlineOnly')) {
                filters.push('Available Online');
            }

            if (cl.get('products.storeOnly')) {
                filters.push('Store Only');
            }

            if (cl.get('products.hasUPC')) {
                filters.push('Has UPC');
            }

            if (cl.get('products.missingUPC')) {
                filters.push('Missing UPC');
            }

            if (cl.get('products.hasMultipleBarcodes')) {
                filters.push('Multiple Barcodes');
            }

            if (cl.get('products.zeroWeight')) {
                filters.push('Zero Weight');
            }

            if (cl.get('products.notTracked')) {
                filters.push('Not Tracked');
            }

            if (cl.get('products.onlyTracked')) {
                filters.push('Only Tracked');
            }

            if (cl.get('products.vrReturns')) {
                filters.push('VR.RETURNS');
            }

            if (cl.get('products.ungrouped')) {
                filters.push('Ungrouped');
            }

            if (cl.get('products.grouped')) {
                filters.push('Grouped');
            }

            if (cl.get('products.neverGroup')) {
                filters.push('Never Group');
            }

            if (cl.get('products.negativeQty')) {
                filters.push('Negative Qty');
            }

            if (cl.get('products.filter.selected.brand').length > 0) {
                filters.push('Brand');
            }

            if (cl.get('products.filter.selected.group').length > 0) {
                filters.push('Type');
            }

            if (cl.get('products.filter.selected.size').length > 0) {
                filters.push('Size');
            }

            if (cl.get('products.filter.selected.color').length > 0) {
                filters.push('Color');
            }

            return filters;
        },
        'products.filter.popup': '',
        'products.filter.search': '',
        'products.filter.optionsSelected'() {
            const popup = cl.get('products.filter.popup');
            const selected = cl.get(`products.filter.selected.${popup}`);

            return _(cl.get('products.all'))
                    .map(popup)
                    .uniq()
                    .sort()
                    .filter(e => _.indexOf(selected, e) > -1)
                    .value();
        },
        'products.filter.optionsAvailable'() {
            const search = cl.get('products.filter.search');
            const popup = cl.get('products.filter.popup');
            const selected = cl.get(`products.filter.selected.${popup}`);

            return _(cl.exec('products.filtered', popup))
                .map(popup)
                .uniq()
                .sort()
                .filter(e => !_.isUndefined(e))
                .filter(e => e.toLowerCase().includes(search.toLowerCase()))
                .filter(e => _.indexOf(selected, e) === -1)
                .value();
        },
        'products.filter.optionsRemaining'() {
            const search = cl.get('products.filter.search');
            const popup = cl.get('products.filter.popup');
            const selected = cl.get(`products.filter.selected.${popup}`);
            const available = _.keyBy(cl.exec('products.filter.optionsAvailable'));

            return _(cl.get('products.all'))
                .map(popup)
                .uniq()
                .sort()
                .filter(e => !_.isUndefined(e))
                .filter(e => e.toLowerCase().includes(search.toLowerCase()))
                .filter(e => _.indexOf(selected, e) === -1)
                .filter(e => _.isUndefined(available[e]))
                .value();
        },
        'products.filter.selected':{
            brand: [],
            group: [],
            size: [],
            color: [],
        },
        'products.filter.inUse'() {
            const filters = cl.get('products.filter.selected');
            for (const key in filters) {
                if (filters[key].length) {
                    return true;
                }
            }
            return false;
        },
        'products.addBarcodes.errors': [],
        'products.addBarcodes.products': {},
        'products.addWeight.errors': [],
        'products.addWeight.products': {},
        'products.showAdditionalColumns': false,
        'products.additionalColumns': [],
        'products.possibleAdditionalColumns'() {
            const skip = {
                'sku': true,
                'name': true,
                'cost': true,
                'price': true,
                'inventory': true,
                'group': true,
                'brand': true,
                'size': true,
                'color': true,
            };
            return Object.keys(cl.get('product.blank')).filter(c => !(c in skip));
        },
    };

    const _on = {
        productsLastPage() {
            _i.lastPage();
        },
        productsNextPage() {
            _i.nextPage();
        },
        productsPerPage(e) {
            const l = _.ceil(cl.exec('products.filtered').length / e.node.value);
            const page = cl.get('products.paging.page');

            if (page > l) {
                cl.set('products.paging.page', l);
            }

            cl.set('products.paging.perPage', e.node.value);
        },
        productsSortBy(e, v) {
            const o = cl.get('products.paging.sortBy');
            cl.set('products.paging.sortBy', v);
            if (v === o) {
                cl.toggle('products.paging.sortAsc');
            }
        },
        productsPageInput(e) {
            const l = _.ceil(cl.exec('products.filtered').length / cl.get('products.paging.perPage'));
            if (/[^0-9]/.test(e.node.value)) {
                e.node.value = cl.get('products.paging.page');
            }

            if (e.node.value < 1) {
                e.node.value = 1;
            }

            if (e.node.value > l) {
                e.node.value = l;
            }

            cl.set('products.paging.page', Number(e.node.value).toFixed());
        },
        productsSearch(e) {
            cl.set('products.paging.search', e.node.value);
        },
        productsClearSearch(e) {
            cl.find('#productsSearch').value = '';
            cl.set('products.paging.search', '');
        },
        productsSelect(e, id) {
            const key = `products.selected.${id}`;
            cl.get(key) ? cl.unset(key) : cl.set(key, true);

            const last = cl.get('products.lastSelected');
            cl.set(`products.lastSelected`, id);
            const unselected = cl.get(key) ? false : true;

            if (!e?.event?.shiftKey || !last) {
                return;
            }

            e.event.preventDefault();
            e.event.stopPropagation();
            e.event.stopImmediatePropagation?.();
            (window.getSelection ? window.getSelection() : document.selection).empty();

            const selected = cl.get('products.selected');
            const ids = cl.exec('products.visible').map(p => p.id);

            let found = false;
            for (const v of ids) {
                if (!found && (v === id || v === last)) {
                    found = true;
                    continue;
                }

                if (!found) {
                    continue;
                }

                if (v !== id && v !== last) {
                    if (unselected) {
                        delete selected[v];
                    } else {
                        selected[v] = true;
                    }
                } else {
                    break;
                }
            }

            cl.set('products.selected', selected);
        },
        productsSelectAll() {
            const selected = _(cl.exec('products.filtered'))
                .map('id')
                .union(_.keys(cl.get('products.selected')))
                .uniq()
                .reduce((a, id) => {
                    a[id] = true;
                    return a;
                }, {});

            cl.set('products.selected', selected);
        },
        productsUnselectAll() {
            cl.set('products.selected', {});
        },
        productsSelectVisible() {
            const selected = cl.get('products.selected');

            _(cl.exec('products.visible')).map('id').value().forEach((id) => {
                selected[id] = true;
            });

            cl.set('products.selected', selected);
        },
        productsUnselectVisible() {
            const selected = cl.get('products.selected');

            _(cl.exec('products.visible')).map('id').value().forEach((id) => {
                delete selected[id];
            });

            cl.set('products.selected', selected);
        },
        productsNew(e) {
            e?.original?.preventDefault?.();
            cl.routes.go('/product/new/details');
        },
        productGoto(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/product/${id}/details`);
        },
        productTab(e, tab) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/product/${cl.get('params.product') || 'new'}/${tab}`);
        },
        productsShowFilters() {
            cl.toggle('products.showFilters');
        },
        productsToggleFilter(e, name) {
            cl.toggle(`products.filters.${name}`);
        },
        manualBarcode() {
            cl.prompt({
                message: 'Enter Barcode or SKU:',
                okFunc(val) {
                    const p = cl.exec('product.fromSku', val.trim());
                    if ((p?.barcodes?.length || 0) > 0) {
                        cl.keyboard.fire('barcode', p.barcodes[0]);
                        return;
                    }

                    cl.keyboard.fire('barcode', val);
                },
            });
        },
        async productsPrint() {
            const limit = 1000;
            const sel = _.cloneDeep(cl.get('products.selected'));
            const products = [];

            if (Object.keys(sel).length === 0) {
                cl.exec('products.sorted').map(p => sel[p.id] = true);
            }

            Object.keys(sel).forEach(id => {
                products.push(cl.exec('product.fromId', id));
            });

            if (products.length > limit) {
                products.length = limit;
                const res = await cl.confirmError(`Too many products to print. Only the first ${limit} will be printed.`);
                if (res.buttonClicked !== 'ok') {
                    return;
                }
            }

            products.sort((a, b) => a.sku.localeCompare(b.sku));

            const rac = new Ractive({
                template: cl.templates.printProducts,
                data: {
                    products,
                    time: new Date().toLocaleString(),
                    _, // lodash
                }
            });

            const nw = window.open('', 'PRINT PRODUCTS');

            if (!nw) {
                cl.error(`<div style="max-width:35ch"><strong>Please Allow Popups From This Site</strong><br><br>There should be an icon with a red x on the right side of the address bar. Click it and select "Always allow pop-ups and redirects from ${window.location.origin}" and click done.<br><br></div>`);
                return;
            }

            nw.document.write(rac.toHTML());
            nw.document.close();
            nw.focus();
            nw.print();
        },
        productsToggleHideOutOfStock() {
            cl.ls.set(_oosKey, !cl.ls.get(_oosKey))
            cl.update();
        },
        productsToggleUngrouped() {
            cl.toggle('products.ungrouped');
            cl.set('products.neverGroup', false);
            cl.set('products.grouped', false);
        },
        productsToggleGrouped() {
            cl.toggle('products.grouped');
            cl.set('products.neverGroup', false);
            cl.set('products.ungrouped', false);
        },
        productsToggleNeverGroup() {
            cl.toggle('products.neverGroup');
            cl.set('products.ungrouped', false);
            cl.set('products.grouped', false);
        },
        productsToggleNegativeQty() {
            cl.toggle('products.negativeQty');
        },
        productsOpenFilter(e, name) {
            cl.set('products.filter.popup', name);
            cl.set('products.filter.search', '');
        },
        productsPopupClose(e) {
            if (_.isUndefined(e) || e?.original?.target?.classList?.contains('modal-wrapper')) {
                cl.set('products.filter.popup', '');
            }
        },
        productsFilterSelect(e, name) {
            const key = `products.filter.selected.${cl.get('products.filter.popup')}`;
            const i = cl.get(key).indexOf(name);

            if (i > -1) {
                cl.splice(key, i, 1);
            } else {
                cl.push(key, name);
            }
        },
        productsFilterSearch(e) {
            cl.set('products.filter.search', e.node.value);
        },
        productsFilterClear() {
            const filters = cl.get('products.filter.selected');
            for (const key in filters) {
                cl.set(`products.filter.selected.${key}`, []);
            }
        },
        productsExportCSV() {
            const products = [
                ['Type', 'Department', 'Brand', 'Supplier', 'Sku', 'Name', 'Size', 'Color', 'WH', 'SS', 'CB', 'Price', 'Cost', 'Website Price'],
            ];

            const allIds = cl.exec('products.sorted')
                .reduce((a, p) => {
                    a[p.id] = true;
                    return a;
                }, {});

            const sel = Object.keys(cl.get('products.selected')).length > 0 ? cl.get('products.selected') : allIds;

            Object.keys(sel).forEach(id => {
                const p = cl.exec('product.fromId', id);
                products.push([
                    p.group,
                    p.department,
                    p.brand,
                    p.supplier,
                    p.sku,
                    p.name,
                    p.size,
                    p.color,
                    p.inventory.wh.main,
                    p.inventory.ss.main,
                    p.inventory.cb.main,
                    p.price,
                    p.cost,
                    p.websitePrice,
                ]);
            });

            cl.csv(`products-${cl.dayjs().format('YYYY-MM-DD-hh-mm-A')}.csv`, products);
        },
        productsDiscontinue() {
            _i.discontinue();
        },
        productsReprintBarcodes() {
            _i.reprintBarcodes(Object.keys(cl.get('products.selected')));
        },
        productsUndelete() {
            cl.prompt({
                message: 'Undelete Sku',
                okFunc(val) {
                    cl.send('undeleteSku', val.trim());
                },
            });
        },
        productsAddBarcodesHandleFile(e) {
            const file = e.node.files[0];
            const reader = new FileReader();

            reader.onerror = () => {
                cl.error('Cannot Read File');
            }

            reader.onload = e => {
                const lines = e.target.result;
                const data = CSVtoArray(lines);
                _i.addBarcodes(data);
            }

            if (!_.isUndefined(file)) {
                reader.readAsText(file);
            }
        },
        productsAddBarcodesProcess() {
            const data = cl.get('products.addBarcodes.products');
            cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want add ${_.keys(data).length} barcodes?<br>There is no undo.</div>`,
                okFunc() {
                    cl.send('addBarcodes', data);
                }
            });
        },
        productsAddWeightHandleFile(e) {
            const file = e.node.files[0];
            const reader = new FileReader();

            reader.onerror = () => {
                cl.error('Cannot Read File');
            }

            reader.onload = e => {
                const lines = e.target.result;
                const data = CSVtoArray(lines);
                _i.addWeight(data);
            }

            if (!_.isUndefined(file)) {
                reader.readAsText(file);
            }
        },
        productsAddWeightProcess() {
            const data = cl.get('products.addWeight.products');
            cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want add weight to ${Object.keys(data).length} products?<br>There is no undo.</div>`,
                okFunc() {
                    cl.send('addWeight', data);
                }
            });
        },
        async productsGroup() {
            const ids = Object.keys(cl.get('products.selected')).map(id => parseInt(id));
            if (!ids?.length) {
                return;
            }
            const skus = cl.exec('products.selectedSkus');

            const res = await cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Group: ${skus.join(', ')}</div>`,
                ok: 'Group',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const fields = ['parentGroup'];
            const product = cl.get('product.blank');
            _.set(product, 'parentGroup', `ims_${_.uuid()}`);
            cl.set('products.bulk.ids', ids);

            cl.send('productsBulkUpdate', {
                ids,
                product,
                fields,
            });
        },
        async productsAddToGroup() {
            const ids = _.keys(cl.get('products.selected')).map(id => parseInt(id));
            if (!ids?.length) {
                return;
            }

            cl.productsAddToGroup.setIds(ids);
        },
        async productsUngroup() {
            const ids = Object.keys(cl.get('products.selected')).map(id => parseInt(id));
            if (!ids?.length) {
                return;
            }
            const skus = cl.exec('products.selectedSkus');

            const res = await cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Ungroup: ${skus.join(', ')}</div>`,
                ok: 'Ungroup',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const fields = ['parentGroup'];
            const product = cl.get('product.blank');
            _.set(product, 'parentGroup', null);
            cl.set('products.bulk.ids', ids);

            cl.send('productsBulkUpdate', {
                ids,
                product,
                fields,
            });
        },
        async productsNeverGroup() {
            const ids = Object.keys(cl.get('products.selected')).map(id => parseInt(id));
            if (!ids?.length) {
                return;
            }
            const skus = cl.exec('products.selectedSkus');

            const res = await cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Never Group: ${skus.join(', ')}</div>`,
                ok: 'Never Group',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const fields = ['parentGroup'];
            const product = cl.get('product.blank');
            _.set(product, 'parentGroup', 'nevergroup');
            cl.set('products.bulk.ids', ids);

            cl.send('productsBulkUpdate', {
                ids,
                product,
                fields,
            });
        },
        productsAdditionalColumn(e, col) {
            const cols = cl.get('products.additionalColumns');
            if (!cols.includes(col)) {
                cl.push('products.additionalColumns', col);
                cl.set('products.showAdditionalColumns', false);
                return;
            }
            const i = cols.indexOf(col);
            if (i <= -1) {
                return;
            }
            cl.splice('products.additionalColumns', i, 1);
            cl.set('products.showAdditionalColumns', false);
        },
    };

    const _i = {
        init() {
            if (_.isUndefined(cl.get('products.all'))) {
                cl.set('products.all', []);
            }

            cl.set(_set);
            cl.on(_on);

            for (const key in cl.get('products.filters')) {
                const nk = `products.filters.${key}`
                if (_.isUndefined(cl.ls.get(nk))) {
                    cl.ls.set(nk, cl.get(nk));
                } else if (cl.ls.get(nk) !== cl.get(nk)){
                    cl.set(nk, cl.ls.get(nk));
                }
            }

            cl.observe({
                'products.paging.search'(e) {
                    _.defer(_i.search);
                },
                'products.filters.*'(nv, ov, key) {
                    if (!_.isUndefined(ov)) {
                        console.log(nv, key);
                        if (cl.ls.get(key) !== nv) {
                            cl.ls.set(key, nv);
                        }
                    }
                },
            });

            cl.ls.persist('products.paging.perPage');
            cl.ls.persist('products.additionalColumns');
            cl.ls.persist('products.paging.search', 'products.paging.fireSearch');

            document.addEventListener("leftArrow", (e) => {
                _.defer(_i.lastPage);
            });

            document.addEventListener("rightArrow", (e) => {
                _.defer(_i.nextPage);
            });

            document.addEventListener("barcode", (e) => {
                _i.barcodeListener(e.detail);
            });

            document.addEventListener('mousedown', (e) => {
                if (!e.shiftKey) {
                    return;
                }

                if (e.target.tagName !== 'I') {
                    return;
                }

                if (!e.target.classList.contains('material-icons')) {
                    return;
                }

                (window.getSelection?.() ?? document.selection).empty();
            });

            if (_.isUndefined(cl.ls.get(_oosKey))) {
                cl.ls.set(_oosKey, false);
            }

            setInterval(() => {
                const products = cl.get('products.all');
                if (!cl.exec('loading') && products.length && cl.exec('login.check')) {
                    _.log('Running Inventory Check');

                    cl.sendSilent('productInventoryCheck', products.reduce((o, p) => {
                        o[Number(p.id)] = {
                            w:Number(p.inventory.wh.main),
                            s:Number(p.inventory.ss.main),
                            c:Number(p.inventory.cb.main),
                        };

                        return o;
                    }, {}));
                }
            }, 9e5); // once every 15 minutes
        },
        filter() {
            const products = cl.get('products.all').slice(0, -100);
            cl.set('products.filtered', products);
            return cl.get('products.all');
        },
        search() {
            const search = cl.get('products.paging.search');
            if (search.length <= 2) {
                cl.set('products.paging.searchIds', []);
            }

            _.defer(() => {
                const ids = _(_i.searchIndex(search)).map('ref').value();

                _.defer(() => {
                    cl.set('products.paging.searchIds', ids);
                })
                _.defer(_i.checkPage);
            });
        },
        searchIndex(s) {
            return _indexer.get().search(s.replace('onei','o\'nei').split(' ').filter(s => s.length > 1).join(' '));
        },
        checkPage() {
            const pages = _.ceil(cl.exec('products.filtered').length / cl.get('products.paging.perPage'));
            if (cl.get('products.paging.page') > pages && pages !== 0) {
                cl.set('products.paging.page', pages);
            }
        },
        loadChunk(chunk, size, loadTime) {
            _productChunks.push(...chunk);

            const isFinal = _productChunks.length === size;
            if (isFinal) {
                _i.setLookup(_productChunks);
                cl.set('products.all', [..._productChunks]);
                cl.data.loadDown(1, 'getProducts');
                _productChunks.length = 0;
            }

            _i.indexChunk(chunk, isFinal, loadTime);
        },
        chunkReset() {
            _productChunks.length = 0;
            _indexer.resetStart();
            _indexer.resetDelay();
        },
        indexChunk(chunk, isFinal, loadTime) {
            const lastId = chunk[chunk.length - 1].id;

            for (const product of chunk) {
                setTimeout(() => {
                    try {
                        _indexer.get().add(product);
                    } catch(e) {
                        _.log(e);
                    }
                    if (isFinal && product.id === lastId) {
                        _.log(`Products Indexed: ${(_.now() - _indexer.getStart()).toLocaleString()} ms`);
                        _.log(`Products Server Load Time: ${loadTime}`);
                        _.logOnly('=== ims ready ===');
                        cl.set('products.indexed', true);
                    }
                }, _indexer.getDelay());
                _indexer.addDelay(0.25);
            }
        },
        update(type, data) {
            if (type === 'UPDATE') {
                _.delay(_i.upsertProduct, cl.data.delay(), data);
            }

            if (type === 'INSERT') {
                _.delay(_i.upsertProduct, cl.data.delay(), data);
            }

            if (type === 'DELETE') {
                _.delay(_i.deleteProduct, cl.data.delay(), data);
            }
        },
        updateOne(product) {
            _i.upsertProduct(product);
        },
        upsertProduct(product) {
            const index = cl.get('products.all').findIndex(p => String(p.id) === String(product.id));

            if (index > -1) {
                cl.splice('products.all', index, 1, product);
                _indexer.get().update(product, false);
                _i.updateLookup(product, index);
            } else {
                cl.push('products.all', product);
                const index = cl.get('products.all').findIndex(p => String(p.id) === String(product.id));
                _indexer.get().update(product, false);
                _i.updateLookup(product, index);
            }


            if (cl.routes.get() === 'product' && cl.get('params.product') === String(product.id)) {
                cl.product.validate();
                if (cl.get('params.tab') === 'history') {
                    cl.product.getHistory();
                }
            }
        },
        deleteProduct(product) {
            const index = cl.get(`products.lookup.indices.ref_${product.id}`);
            if (index <= -1) {
                return;
            }

            cl.splice('products.all', index, 1);
            _indexer.get().remove(product);
            setTimeout(_i.updateIndices, 0);
            if (cl.routes.get() === 'product' && String(cl.get('product.id')) === String(product.id)) {
                cl.routes.go('/products');
            }
        },
        lastPage() {
            if (cl.routes.get() !== 'products') {
                return;
            }

            const next = Number(cl.get('products.paging.page')) - 1;
            const first = _.ceil(cl.exec('products.filtered').length / cl.get('products.paging.perPage'));
            cl.set('products.paging.page', next === 0 ? first : next);
        },
        nextPage() {
            if (cl.routes.get() !== 'products') {
                return;
            }

            const next = Number(cl.get('products.paging.page')) + 1;
            const last = _.ceil(cl.exec('products.filtered').length / cl.get('products.paging.perPage'));
            cl.set('products.paging.page', next > last ? 1 : next);
        },
        barcodeListener(barcode) {
            if (cl.routes.get() === 'products') {
                _.defer(_i.barcodeSearch, barcode, _i.barcodeFound);
            }
        },
        barcodeSearchFound: false,
        async barcodeSearch(barcode, foundFunc) {
            if (!cl.get('products.all').length) {
                cl.error('Products Not Loaded<br>Please Wait &amp; Rescan');
                return;
            }

            _i.barcodeSearchFound = false;
            const prod = cl.get(`products.lookup.barcodes.ref_${barcode}`)
            if (!_.isUndefined(prod)) {
                setTimeout(() => { foundFunc(barcode, prod.sku, prod.id); }, 0);
                _i.barcodeSearchFound = true;
            }

            setTimeout(() => {
                _i.barcodeSearchFinish(barcode);
            }, 500);
        },
        barcodeSearchFinish(barcode = '') {
            if (!_i.barcodeSearchFound) {
                cl.error(`Barcode Not Found: ${barcode}`);
                if (!cl.exec('login.isRetail')) {
                    failureSound();
                }
            }
        },
        barcodeFound(barcode, sku, id) {
            cl.fire('productGoto', id);
        },
        setLookup(products) {
            const barcodes = {};
            const skus = {};
            const indices = {};

            products.forEach((p, i) => {
                if (!_.isNull(p.barcodes)) {
                    p.barcodes.forEach(b => {
                        barcodes[`ref_${b}`] = {
                            id: p.id,
                            sku: p.sku,
                        };
                    });
                }
                skus[`ref_${p.sku}`] = p.id;
                indices[`ref_${p.id}`] = i;
            });

            cl.set('products.lookup.barcodes', barcodes);
            cl.set('products.lookup.skus', skus);
            cl.set('products.lookup.indices', indices);
        },
        updateIndices() {
            const products = cl.get('products.all');
            const indices = {};

            products.forEach((p, i) => {
                indices[`ref_${p.id}`] = i;
            })

            cl.set('products.lookup.indices', indices);
        },
        updateLookup(product, index = -1) {
            product.barcodes = product.barcodes || [];
            product.barcodes.forEach(b => {
                cl.set(`products.lookup.barcodes.ref_${b}`, { id: product.id, sku: product.sku });
            });

            cl.set(`products.lookup.skus.ref_${product.sku}`, product.id);

            if (index < 0) {
                index = _.findIndex(cl.get('products.all'), ['id', product.id]);
            }

            cl.set(`products.lookup.indices.ref_${product.id}`, index);
        },
        lunr() {
            return _indexer.get();
        },
        async loadHistory(id, data) {
            await cl.data.allLoaded();
            cl.success('Product History Received');
            const index = cl.get(`products.lookup.indices.ref_${id}`);

            if (index < 0) {
                return;
            }

            cl.set(`products.all.${index}.history`, data);
        },
        async loadAdjustments(id, data) {
            await cl.data.allLoaded();

            cl.success('Product Adjustments Received');
            const index = cl.get(`products.lookup.indices.ref_${id}`);

            if (index < 0) {
                return;
            }

            cl.set(`products.all.${index}.adjustments`, data);
        },
        async loadLow(data) {
            await cl.data.allLoaded();

            const index = cl.get(`products.lookup.indices.ref_${data.id}`);
            if (index <= -1) {
                return;
            }

            cl.set(`products.all.${index}.lit`, data);
            cl.set('product.tmp.lit', Object.assign({}, data));
        },
        inventoryAdjustment(data) {
            data.forEach(a => {
                cl.set(`products.all.${cl.exec('product.indexFromId', a.id)}.inventory.${a.loc}`, a.lvl);
            });
        },
        async discontinue() {
            const ids = Object.keys(cl.get('products.selected')).map(Number);
            if (!ids.length) {
                cl.error('No Products Selected');
                return;
            }

            const getDate = async () => {
                const format = 'MM/DD/YYYY';
                const res = await cl.prompt({
                    message: 'Discontinued Product Date i.e. 07/04/1776',
                    default: cl.dayjs().format(format),
                });

                if (res.buttonClicked === 'cancel') {
                    return;
                }

                const date = res.inputValue.trim();
                if (date === '' || cl.dayjs(date, format).format() === 'Invalid date') {
                    return await getDate();
                }

                return cl.dayjs(date, format).format();
            };

            const date = await getDate();
            if (_.isUndefined(getDate)) {
                return;
            }

            const getNote = async () => {
                const res = await cl.prompt('Discontinued Product Note');
                if (res.buttonClicked === 'cancel') {
                    return;
                }

                const note = res.inputValue.trim();
                if (note === '') {
                    return await getNote();
                }

                return note;
            };

            const note = await getNote();
            if (_.isUndefined(getNote)) {
                return;
            }

            cl.send('productDiscontinue', {
                ids,
                date,
                note,
            });
        },
        discontinuedClientUpdate(data) {
            data.ids.forEach(id => {
                const i = cl.exec('product.indexFromId', id);
                cl.set(`products.all.${i}.discontinued`, data.date);
            });
        },
        reprintBarcodes(ids) {
            const code = 'ABCDEFGHIJKL'.split('')[cl.dayjs().month()] + cl.dayjs().year().toString().slice(-1);
            const barcodes = []

            for (const id of ids) {
                const product = cl.exec('product.fromId', id)
                const name = `${product.name}${product.size ? ` - ${product.size}` : ''}`;

                if (!_.isArray(product?.barcodes) || _.isNull(product?.barcodes) || product?.barcodes?.length === 0) {
                    continue;
                }

                barcodes.push({
                    barcode: product.barcodes[0],
                    name,
                    sku: product.sku,
                    color: product.color || 'N/A',
                    price: String(product.price),
                    qty: String(product.inventory.wh.main + product.inventory.ss.main + product.inventory.cb.main),
                    printed: code,
                });
            }

            cl.send('productPrintBarcodes', barcodes);
        },
        addBarcodes(data = []) {
            const errs = [];
            const products = {};

            for (const bcs of data) {
                if (bcs.length !== 2) {
                    errs.push(`Incorrect Number Of Columns: "${bcs.join(', ')}"`);
                    continue;
                }

                if ((bcs.length === 2 && (bcs[0].length === 0 || bcs[1].length === 0))) {
                    errs.push(`Missing Value: "${bcs.join(', ')}"`);
                    continue;
                }

                const p = cl.exec('product.fromBarcode', bcs[0]);

                if (!p || p && !p.sku) {
                    errs.push(`Product Not Found: "${bcs.join(', ')}"`);
                } else {
                    products[p.sku] = bcs;
                }
            }

            cl.set('products.addBarcodes.errors', errs);
            cl.set('products.addBarcodes.products', products);
        },
        addBarcodesClientUpdate(data = {}) {
            for (const sku in data) {
                const p = cl.exec('product.fromSku', sku);
                if (!p) {
                    continue;
                }

                p.barcodes = p.barcodes || [];
                const oldCode = data[sku][0];
                const newCode = data[sku][1];
                const oldFound = p.barcodes.some(bc => bc === oldCode);
                const newFound = p.barcodes.some(bc => bc === newCode);

                if (oldFound && !newFound) {
                    p.barcodes.push(newCode);
                    const i = cl.exec('product.indexFromId', p.id);
                    cl.set(`products.all.${i}.barcodes`, p.barcodes);
                    cl.set(`products.lookup.barcodes.ref_${newCode}`, { id: p.id, sku: p.sku });
                }
            }
        },
        addBarcodesReset() {
            cl.set('products.addBarcodes.errors', []);
            cl.set('products.addBarcodes.products', {});
        },
        addWeight(data = []) {
            const errs = [];
            const products = {};

            for (const row of data) {
                if (row.length !== 2) {
                    errs.push(`Incorrect Number Of Columns: "${row.join(', ')}"`);
                    continue;
                }

                if ((row.length === 2 && (row[0].length === 0 || row[1].length === 0))) {
                    errs.push(`Missing Value: "${row.join(', ')}"`);
                    continue;
                }

                const p = cl.exec('product.fromSku', row[0]);

                if (!p || p && !p.sku) {
                    errs.push(`Product Not Found: "${row.join(', ')}"`);
                } else {
                    products[p.sku] = Number(row[1]);
                }
            }

            cl.set('products.addWeight.errors', errs);
            cl.set('products.addWeight.products', products);
        },
        addWeightClientUpdate(data = {}) {
            for (const sku in data) {
                const p = cl.exec('product.fromSku', sku);
                if (!p) {
                    continue;
                }

                const i = cl.exec('product.indexFromId', p.id);
                cl.set(`products.all.${i}.weight`, data[sku]);
            }
        },
        addWeightReset() {
            cl.set('products.addWeight.errors', []);
            cl.set('products.addWeight.products', {});
        },
        async deleteProductsClientUpdate(skus = []) {
            await cl.data.allLoaded();
            const products = [...cl.get('products.all')];
            const toDelete = {};
            const indices = {};

            products.forEach((val, key) => {
                indices[`ref_${val.sku}`] = key;
            });

            for (const sku of skus) {
                const index = indices[`ref_${sku}`];
                if (!index) {
                    continue;
                }

                const product = products[index];
                _indexer.get().remove(product);
                toDelete[index] = true;
                cl.unset(`products.lookup.indices.ref_${product.id}`);
                cl.unset(`products.lookup.skus.ref_${sku}`);
                for (const barcode of (product.barcodes || [])) {
                    cl.unset(`products.lookup.barcodes.ref_${barcode}`);
                }
            }

            cl.set('products.all', products.filter((val, key) => {
                if (key in toDelete) {
                    return false;
                }
                return true;
            }));
            setTimeout(_i.updateIndices, 0);
        },
        async indexed() {
            let resolver;
            const p = new Promise((resolve) => {
                resolver = resolve;
            });

            const interval = setInterval(() => {
                if (cl.get('products.indexed')) {
                    resolver();
                    clearInterval(interval);
                }
            }, 50);

            return p;
        },
    };

    return {
        init: _i.init,
        search: _i.searchIndex,
        update: _i.update,
        updateOne: _i.updateOne,
        updateIndices: _i.updateIndices,
        skuFromBarcode: _i.barcodeSearch,
        loadChunk: _i.loadChunk,
        chunkReset: _i.chunkReset,
        lunr: _i.lunr,
        loadHistory: _i.loadHistory,
        loadAdjustments: _i.loadAdjustments,
        loadLow: _i.loadLow,
        inventoryAdjustment: _i.inventoryAdjustment,
        discontinuedClientUpdate: _i.discontinuedClientUpdate,
        addBarcodesClientUpdate: _i.addBarcodesClientUpdate,
        addBarcodesReset: _i.addBarcodesReset,
        addWeightClientUpdate: _i.addWeightClientUpdate,
        addWeightReset: _i.addWeightReset,
        deleteProductsClientUpdate: _i.deleteProductsClientUpdate,
        createProduct: _i.upsertProduct,
        indexed: _i.indexed,
    };
})(window.cl);