import isUPC from './utilities/isUPC.js';


window.cl = window.cl || {};

window.cl.isUPC = isUPC;

window.cl.lowInventory = ((cl) => {
    const _join = ['department', 'brand', 'sku', 'group', 'name', 'size', 'color', 'price', 'barcodes'];
    const _core = ['id', 'wh', 'ss', 'cb', 'tt', 'ignore', 'note'];
    const _on = {
        fetchLowInventory() {
            _i.fetch();
        },
        lowInventorySetLevel(e, id, loc) {
            const index = _i.getIndex(id);
            if (index <= -1) {
                return;
            }

            const str = e.node.value.replace(/\D/g, '');
            const n = Number.isFinite(Number(str)) ? Number(str) : 0;
            _i.changes(id);
            cl.set(`lowInventory.all.${index}.${loc}`, n);
            e.node.value = String(n);
            setTimeout(_i.checkChanges, 0);
        },
        lowInventoryLastPage() {
            _i.lastPage();
        },
        lowInventoryNextPage() {
            _i.nextPage();
        },
        lowInventoryPerPage(e) {
            cl.set('lowInventory.paging.perPage', e.node.value);
        },
        lowInventoryPageInput(e) {
            const l = _.ceil(cl.exec('lowInventory.filtered').length / cl.get('lowInventory.paging.perPage'));
            if (/[^0-9]/.test(e.node.value)) {
                e.node.value = cl.get('lowInventory.paging.page');
            }

            if (e.node.value < 1) {
                e.node.value = 1;
            }

            if (e.node.value > l) {
                e.node.value = l;
            }

            cl.set('lowInventory.paging.page', Number(e.node.value).toFixed());
        },
        lowInventoryToggleIgnore(e, id) {
            const index = _i.getIndex(id);
            if (index <= -1) {
                return;
            }

            const key = `lowInventory.all.${index}.ignore`;
            const ignore = cl.get(key);

            if (ignore) {
                _i.changes(id);
                cl.toggle(key);
                setTimeout(_i.checkChanges, 0);
                return;
            }

            const product = cl.exec('product.fromId', id);
            cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want to ignore<br>${product.sku} - ${product.name}?</div>`,
                ok: 'Ignore',
                okFunc() {
                    _i.changes(id);
                    cl.toggle(key);
                    setTimeout(_i.checkChanges, 0);
                },
            });
        },
        lowInventorySort(e, name) {
            const by = cl.get('lowInventory.paging.sortBy');

            if (name !== by) {
                cl.set('lowInventory.paging.sortBy', name);
                return;
            }

            const dir = cl.get('lowInventory.paging.sortDir');
            cl.set('lowInventory.paging.sortDir', dir === 'asc' ? 'desc' : 'asc');
        },
        lowInventoryOpenFilter(e, name) {
            cl.set('lowInventory.filter.popup', name);
            cl.set('lowInventory.filter.search', '');
        },
        lowInventoryPopupClose(e) {
            if (_.isUndefined(e) || e.original.target.classList.contains('modal-wrapper')) {
                cl.set('lowInventory.filter.popup', '');
            }
        },
        lowInventoryFilterSelect(e, name) {
            const key = `lowInventory.filter.selected.${cl.get('lowInventory.filter.popup')}`;
            const i = cl.get(key).indexOf(name);

            if (i > -1) {
                cl.splice(key, i, 1);
            } else {
                cl.push(key, name);
            }
        },
        lowInventoryFilterSearch(e) {
            cl.set('lowInventory.filter.search', e.node.value);
        },
        lowInventoryNoteUpdate(e, id) {
            const index = _i.getIndex(id);
            if (index <= -1) {
                return;
            }

            _i.changes(id);
            const start = e.node.selectionStart;
            const end = e.node.selectionEnd;
            cl.set(`lowInventory.all.${index}.note`, e.node.value);
            setTimeout(_i.checkChanges, 0);

            try {
                e.node.setSelectionRange(start, end);
            } catch (e) {
                _.log(e);
            }
        },
        lowInventorySave() {
            const changes = cl.get('lowInventory.changes');
            const products = [];

            for (const ref in changes) {
                const change = changes[ref];
                const index = _i.getIndex(change.id);
                if (index <= -1) {
                    continue;
                }

                const product = _.pick(cl.get(`lowInventory.all.${index}`), _core);
                const hash = cl.hash(JSON.stringify(product));

                if (hash !== change.hash) {
                    products.push(product);
                }
            }

            if (products.length === 0) {
                cl.error('No Changes Detected');
                return;
            }

            const user = cl.users.current();
            if (!user) {
                cl.error('No Current User Found');
                return;
            }

            cl.send('reportLowInventoryToolSave', {
                products,
                user: user.id,
            });
        },
        lowInventoryGenerate() {
            const products = [];
            const errs = [];

            cl.exec('lowInventory.sorted').forEach(product => {
                const prod = cl.exec('product.fromId', product.id);

                if (_.isUndefined(prod)) {
                    errs.push(product.id);
                }

                const inv = prod.inventory;
                const whQty = Number(inv.wh.main);
                const ssQty = Number(inv.ss.main);
                const cbQty = Number(inv.cb.main);
                const ttQty = whQty + ssQty + cbQty;
                const ttLow = product.tt;

                if (ttQty >= ttLow) {
                    return;
                }

                products.push({
                    'Department': product.department,
                    'Brand': product.brand,
                    'Group': product.group,
                    'SKU': product.sku,
                    'Name': product.name,
                    'Size': product.size,
                    'Color': product.color,
                    'WH': whQty,
                    'SS': ssQty,
                    'CB': cbQty,
                    'Combined': ttQty,
                    'LOW': ttLow,
                    'Suggested': ttLow - ttQty,
                    'Order': '',
                    '3M': product.v3,
                    'DO3': product.o3,
                    'SM3': _.round(((product.v3 / (90 - product.o3)) || 0) * product.o3, 1) || 0,
                    '6M': product.v6,
                    'DO6': product.o6,
                    'SM6':_.round(((product.v6 / (180 - product.o6)) || 0) * product.o6, 1) || 0,
                    'F3M': product.f3,
                    'F6M': product.f6,
                    'Price': _.money(product.price),
                    'Notes': product.note,
                    'Discontinued': prod.discontinued ? cl.dayjs(prod.discontinued).format('YYYY-MM-DD-hh-mm-A') : '',
                    'UPCs': (prod.barcodes?.filter?.(isUPC) ?? []).join(', '),
                });
            });

            if (!(products && products.length && _.isObject(products[0]))) {
                cl.error('Low Inventory Data Not Loaded');
                return;
            }

            products.unshift(Object.keys(products[0]));

            cl.csv(`low-inventory-report-${cl.dayjs().format('YYYY-MM-DD-hh-mm-A')}.csv`, products.map(p => Object.values(p)));

            _.log('Orphaned Products', errs);
        },
        lowInventoryFilterZerosToggle() {
            cl.toggle('lowInventory.filterZeros');
        },
        lowInventoryFilterLowToggle() {
            cl.toggle('lowInventory.filterLow');
        },
        lowInventoryFilterNeverReceivedToggle() {
            cl.toggle('lowInventory.filterNeverReceived');
        },
        lowInventoryFilterLowCBToggle() {
            cl.toggle('lowInventory.filterLowCB');
        },
        lowInventoryFilterIgnoreToggle() {
            cl.toggle('lowInventory.filterIgnore');
        },
        lowInventoryFilterClear() {
            const filters = cl.get('lowInventory.filter.selected');
            for (const key in filters) {
                cl.set(`lowInventory.filter.selected.${key}`, []);
            }
        },
        lowInventorySearch(e) {
            cl.set('lowInventory.search', e.node.value);
        },
        lowInventoryGoToProduct(e, sku) {
            e?.original?.preventDefault?.();
            const prod = cl.exec('product.fromSku', sku);
            if (!prod) {
                return;
            }

            cl.routes.go(`/product/${prod.id}/details`);
        },
        exportLowInventory() {
            const products = [];
            const errs = [];

            cl.exec('lowInventory.sorted').forEach(product => {
                const prod = cl.exec('product.fromId', product.id);

                if (_.isUndefined(prod)) {
                    errs.push(product.id);
                    return;
                }

                const inv = prod.inventory;
                const whQty = Number(inv.wh.main);
                const ssQty = Number(inv.ss.main);
                const cbQty = Number(inv.cb.main);
                const ttQty = whQty + ssQty + cbQty;
                const ttLow = product.tt;

                products.push({
                    'Department': product.department,
                    'Brand': product.brand,
                    'Group': product.group,
                    'SKU': product.sku,
                    'Name': product.name,
                    'Size': product.size,
                    'Color': product.color,
                    'WH': whQty,
                    'SS': ssQty,
                    'CB': cbQty,
                    'Combined': ttQty,
                    'LOW': ttLow,
                    'Suggested': ttLow - ttQty,
                    'Order': '',
                    '3M': product.v3,
                    'DO3': product.o3,
                    'SM3': _.round(((product.v3 / (90 - product.o3)) || 0) * product.o3, 1) || 0,
                    '6M': product.v6,
                    'DO6': product.o6,
                    'SM6': _.round(((product.v6 / (180 - product.o6)) || 0) * product.o6, 1) || 0,
                    'F3M': product.f3,
                    'F6M': product.f6,
                    'Price': _.money(product.price),
                    'Notes': product.note,
                    'Discontinued': prod.discontinued ? cl.dayjs(prod.discontinued).format('YYYY-MM-DD-hh-mm-A') : '',
                });
            });

            products.unshift(Object.keys(products[0]));

            cl.csv(`low-inventory-all-${cl.dayjs().format('YYYY-MM-DD-hh-mm-A')}.csv`, products.map(p => Object.values(p)));

            _.log('Orphaned Products', errs);
        },
        async lowInventoryIgnoreAll() {
            const ids = cl.exec('lowInventory.filtered')
                .map(p => p.id)
                .reduce((a, id) => {
                    a[id] = true;
                    return a;
                }, {});

            const count = Object.keys(ids).length;
            if (count === 0) {
                return;
            }

            const res = await cl.confirm({
                message: `<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want to ignore ${count} products?</div>`,
                ok: `Ignore ${count}`,
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const products = cl.get('lowInventory.all');
            const changes = cl.get('lowInventory.changes');
            for (const product of products) {
                if (!(product.id in ids)) {
                    continue;
                }

                if (_.isUndefined(changes[`ref_${product.id}`])) {
                    const p = _.pick(product, _core);
                    const id = product.id;
                    const hash = cl.hash(JSON.stringify(p));
                    changes[`ref_${product.id}`] = { id, hash };
                }

                product.ignore = true;
            }

            cl.set('lowInventory.all', products);
            cl.set('lowInventory.changes', changes);
            setTimeout(_i.checkChanges, 0);
        },
    };
    const _set = {
        'lowInventory.all': [],
        'lowInventory.filtered'() {
            const selected = cl.get('lowInventory.filter.selected');
            let chain = _(cl.get('lowInventory.all'));

            for (const key in selected) {
                if (selected[key].length) {
                    chain = chain.filter(p => selected[key].some(v => p[key]===v));
                }
            }

            if (cl.get('lowInventory.filterZeros')) {
                chain = chain.filter(p => (p.ss === 0 || p.cb === 0 || p.wh === 0));
            }

            if (cl.get('lowInventory.searchIds').length || cl.get('lowInventory.search').length > 1) {
                chain = chain.filter(p => !_.isUndefined(p) && !_.isUndefined(p.id))
                    .keyBy('id')
                    .at(cl.get('lowInventory.searchIds'))
                    .filter(p => !!p);
            }

            if (cl.get('lowInventory.filterLow')) {
                chain = chain.filter(p => {
                    if (p.ss != 999 && p.ssQty < p.ss) {
                        return true;
                    }

                    if (p.cb != 999 && p.cbQty < p.cb) {
                        return true;
                    }

                    if (p.wh != 999 && p.whQty < p.wh) {
                        return true;
                    }

                    return false;
                });
            }


            if (cl.get('lowInventory.filterNeverReceived')) {
                chain = chain.filter(p => {
                    return p.rec === 0 && p.sales === 0 && p.ttQty === 0;
                });
            }

            if (cl.get('lowInventory.filterLowCB')) {
                chain = chain.filter(p => {
                    if (p.cb != 999 && p.cbQty < p.cb) {
                        return true;
                    }

                    return false;
                });
            }

            if (cl.get('lowInventory.filterIgnore')) {
                chain = chain.filter(p => !p.ignore);
            }

            return chain.value();
        },
        'lowInventory.sorted': ()=>{
            const products = cl.exec('lowInventory.filtered');
            const sortDir = cl.get('lowInventory.paging.sortDir');
            const sortBy = cl.get('lowInventory.paging.sortBy');
            const sorfFuncs = {
                'missed': p => _.round(((p.v3 / (90 - p.o3)) || 0) * p.o3, 1) || 0,
                'missed180': p => _.round(((p.v6 / (180 - p.o6)) || 0) * p.o6, 1) || 0,
            };
            const sortByVal  = (sortBy.includes('missed') ? sorfFuncs[sortBy] : sortBy) ?? sortBy;

            return _(products)
                .map(p => {
                    p.price = Number(p.price);
                    return p;
                })
                .orderBy(sortByVal, sortDir)
                .value();
        },
        'lowInventory.list'() {
            const products = cl.exec('lowInventory.filtered');
            const page = cl.get('lowInventory.paging.page');
            const perPage = cl.get('lowInventory.paging.perPage');
            const to = perPage * page;
            const from = to - perPage;
            const sortDir = cl.get('lowInventory.paging.sortDir');
            const sortBy = cl.get('lowInventory.paging.sortBy');
            const sorfFuncs = {
                'missed': p => _.round(((p.v3 / (90 - p.o3)) || 0) * p.o3, 1) || 0,
                'missed180': p => _.round(((p.v6 / (180 - p.o6)) || 0) * p.o6, 1) || 0,
            };
            const sortByVal = (sortBy.includes('missed') ? sorfFuncs[sortBy] : sortBy) ?? sortBy;

            if (page > Math.ceil(products.length / perPage)) {
                cl.set('lowInventory.paging.page', 1);
                return [];
            }

            return _(products)
                .map(p => {
                    p.price = Number(p.price);

                    return p;
                })
                .orderBy(sortByVal, sortDir)
                .slice(from, to)
                .value();
        },
        'lowInventory.sum'(f) {
            const reduceFunc = (a, i) => {
                a += i[f] || 0;
                return a;
            };

            return cl.exec('lowInventory.filtered').reduce(reduceFunc, 0) || 0;
        },
        'lowInventory.paging.page': 1,
        'lowInventory.paging.perPage': 25,
        'lowInventory.paging.perPageOptions': [10, 25, 50, 100, 250],
        'lowInventory.paging.sortBy': 'sku',
        'lowInventory.paging.sortDir': 'asc',
        'lowInventory.paging.arrow'(name) {
            if (cl.get('lowInventory.paging.sortBy') !== name) {
                return '';
            }

            return `<i class="material-icons">arrow_drop_${cl.get('lowInventory.paging.sortDir') === 'asc' ? 'up' : 'down'}</i>`;
        },
        'lowInventory.filter.popup': '',
        'lowInventory.filter.search': '',
        'lowInventory.filter.optionsSelected'() {
            const popup = cl.get('lowInventory.filter.popup');
            const selected = cl.get(`lowInventory.filter.selected.${popup}`);

            return _(cl.get('lowInventory.all'))
                    .map(popup)
                    .uniq()
                    .sort()
                    .filter(e => _.indexOf(selected, e) > -1)
                    .value();
        },
        'lowInventory.filter.optionsAvailable'() {
            const search = cl.get('lowInventory.filter.search');
            const popup = cl.get('lowInventory.filter.popup');
            const selected = cl.get(`lowInventory.filter.selected.${popup}`);

            return _(cl.exec('lowInventory.filtered'))
                .map(popup)
                .uniq()
                .sort()
                .filter(e => !_.isUndefined(e))
                .filter(e => e.toLowerCase().includes(search.toLowerCase()))
                .filter(e => _.indexOf(selected, e) === -1)
                .value();
        },
        'lowInventory.filter.optionsRemaining'() {
            const search = cl.get('lowInventory.filter.search');
            const popup = cl.get('lowInventory.filter.popup');
            const selected = cl.get(`lowInventory.filter.selected.${popup}`);
            const available = _.keyBy(cl.exec('lowInventory.filter.optionsAvailable'));

            return _(cl.get('lowInventory.all'))
                    .map(popup)
                    .uniq()
                    .sort()
                    .filter(e => !_.isUndefined(e))
                    .filter(e => e.toLowerCase().includes(search.toLowerCase()))
                    .filter(e => _.indexOf(selected, e) === -1)
                    .filter(e => _.isUndefined(available[e]))
                    .value();
        },
        'lowInventory.filter.selected': {
            brand: [],
            department: [],
            group: [],
            size: [],
            color: [],
        },
        'lowInventory.filter.inUse'() {
            const filters = cl.get('lowInventory.filter.selected');
            for (const key in filters) {
                if (filters[key].length) {
                    return true;
                }
            }
            return false;
        },
        'lowInventory.lookup.id': {},
        'lowInventory.changes': {},
        'lowInventory.hasChanges': false,
        'lowInventory.filterZeros': false,
        'lowInventory.filterLow': false,
        'lowInventory.filterLowCB': false,
        'lowInventory.filterIgnore': true,
        'lowInventory.filterNeverReceived': false,
        'lowInventory.search': '',
        'lowInventory.searchIds': [],
    },
    _i = {
        init() {
            cl.on(_on);
            cl.set(_set);

            document.addEventListener("leftArrow", (e) => {
                _.defer(_i.lastPage);
            });

            document.addEventListener("rightArrow", (e) => {
                _.defer(_i.nextPage);
            });

            cl.observe({
                'lowInventory.search'(e) {
                    _.defer(_i.search);
                },
            });
        },
        getIndex(id) {
            return cl.get(`lowInventory.lookup.id.ref_${id}`);
        },
        async load() {
            await cl.data.allLoaded();
            _i.fetch();
        },
        async data(data) {
            await cl.data.allLoaded();
            if (!_.isArray(data)) {
                return;
            }

            const lookup = {};
            const products = data.map((p, i) => {
                const prod = cl.exec('product.fromId', p.id);
                const n = _.assign(p, _.pick(prod, _join));

                n.ssQty = prod?.inventory?.ss?.main ?? 0;
                n.cbQty = prod?.inventory?.cb?.main ?? 0;
                n.whQty = prod?.inventory?.wh?.main ?? 0;
                n.ttQty = n.ssQty + n.cbQty + n.whQty;
                n.rec = prod?.inventory?.vr?.receiving ?? 0;
                n.sales = prod?.inventory?.vr?.sales ?? 0;
                n.upcs = prod?.barcodes?.filter?.(isUPC) ?? [];

                lookup[`ref_${p.id}`] = i;
                return n;
            });

            cl.set('lowInventory.all', products ?? []);
            cl.set('lowInventory.lookup.id', lookup);
            cl.set('lowInventory.changes', {});
            _i.checkChanges();
        },
        fetch() {
            cl.send('reportLowInventoryTool');
        },
        nextPage() {
            if (cl.routes.get() !== 'lowInventory') {
                return;
            }

            const pp = Number(cl.get('lowInventory.paging.perPage'));
            const lim = Math.ceil(cl.exec('lowInventory.filtered').length / pp);
            const np = Number(cl.get('lowInventory.paging.page')) + 1

            cl.set('lowInventory.paging.page', np > lim ? 1 : np);

        },
        lastPage() {
            if (cl.routes.get() !== 'lowInventory') {
                return;
            }

            const page = Number(cl.get('lowInventory.paging.page'));
            const max = Math.ceil(cl.exec('lowInventory.filtered').length / cl.get('lowInventory.paging.perPage'));
            const lp = page - 1;

            cl.set('lowInventory.paging.page', lp === 0 ? max : lp);
        },
        changes(id) {
            const key = `lowInventory.changes.ref_${id}`;
            if (!_.isUndefined(cl.get(key))) {
                return;
            }

            const index = _i.getIndex(id);
            if (index <= -1) {
                return;
            }

            const product = _.pick(cl.get(`lowInventory.all.${index}`), _core);
            const hash = cl.hash(JSON.stringify(product));

            cl.set(key, { id, hash });
        },
        checkChanges() {
            const changes = cl.get('lowInventory.changes');

            for (const ref in changes) {
                const change = changes[ref];
                const index = _i.getIndex(change.id);
                if (index <= -1) {
                    continue;
                }

                const product = _.pick(cl.get(`lowInventory.all.${index}`), _core);
                const hash = cl.hash(JSON.stringify(product));

                if (hash !== change.hash) {
                    cl.set('lowInventory.hasChanges', true);
                    return;
                }
            }

            cl.set('lowInventory.hasChanges', false);
        },
        saved(products) {
            products.forEach(product => {
                cl.unset(`lowInventory.changes.ref_${product.id}`);
                const index = _i.getIndex(product.id);
                if (index <= -1) {
                    return;
                }

                const prod = cl.exec('product.fromId', product.id);
                const p = _.assign(product, _.pick(prod, _join));

                p.whQty = prod?.inventory?.wh?.main ?? 0;
                p.ssQty = prod?.inventory?.ss?.main ?? 0;
                p.cbQty = prod?.inventory?.cb?.main ?? 0;
                p.ttQty = p.whQty + p.ssQty + p.cbQty;
                cl.set(`lowInventory.all.${index}`, p);
            });

            _i.checkChanges();
        },
        search() {
            const search = cl.get('lowInventory.search');
            if (search.length <= 2) {
                cl.set('lowInventory.searchIds', []);
                return;
            }

            const res = cl.products.search(search);
            const ids = _(res).map('ref').value();

            cl.set('lowInventory.searchIds', ids);
        },
    };

    return {
        init: _i.init,
        load: _i.load,
        data: _i.data,
        saved: _i.saved,
    };
})(window.cl);