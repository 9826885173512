window.cl = window.cl || {};

window.cl.sockets = ((cl, loc) => {
    const connection = {
        connection: null,
        reconnect: true,
        delay: 1e3,
        disconnected: false,
        get() {
            return this.connection;
        },
        set(connection) {
            this.connection = connection;
        },
        send(data) {
            this.connection.send(JSON.stringify(data));
        },
        close() {
            this.connection.close();
        }
    };

    const _on = {
        killConnection() {
            cl.sockets.kill();
        },
        reconnect() {
            cl.sockets.connect();
        }
    };

    const _i = {
        init() {
            _i.connect();
            cl.on(_on);
        },
        connect() {
            connection.reconnect = true
            const proto = loc.protocol === 'http:' ? 'ws://' : 'wss://';
            const c = new WebSocket(proto + loc.host + "/ws");
            c.onopen = () => {
                cl.set('connected', true);
                cl.login.auth();
                cl.products.chunkReset();
                if (cl.exec('login.check')) {
                    cl.data.restart();
                    cl.data.loadUp(cl.login.reads());
                }
                connection.delay = 1e3;
                if (connection.disconnected) {
                    connection.disconnected = false;
                    if (cl.devMode) {
                        cl.log('websocket reconnected');
                    }
                }
            };

            c.onmessage = (e) => {
                _.defer(() => {
                    cl.data.update(JSON.parse(e.data));
                });
            };

            c.onerror = (e) => {
                _.log('websockets error', e);
            };

            c.onclose = (e) => {
                connection.disconnected = true;
                if (cl.devMode) {
                    cl.log('websocket disconnected');
                }

                _.log('websockets closed', e);
                cl.set('connected', false);
                cl.products.chunkReset();
                _.delay(() => {
                    if (connection.reconnect) {
                        if (connection.delay < 6e4) {
                            connection.delay *= 1.25;
                        }
                        _.attempt(cl.sockets.connect);
                    }
                }, connection.delay);
            };

            connection.set(c);
        },
        send(action, data, appId = '', ref) {
            if (!cl.get('connected')) {
                _.delay(_i.send, 100, action, data, cl.get('appId'));
                return false;
            }

            if (!cl.exec('login.check') && action !== 'auth') {
                return false;
            }

            if (appId && appId !== cl.get('appId') && action !== 'getInventoryBalance') {
                cl.error(`Error Sending ${action}<br>Please Try Again`);
                return false;
            }

            const update = {
                id: _.uniqueId(),
                action,
                token: cl.login.getToken(),
                app: cl.get('appId'),
                ref,
                data,
            };

            if (cl.exec('login.hasPerm', 'logData')) {
                _.log('action', action, data);
            }

            cl.data.que(update);
            connection.send(update);
        },
        sendWithRef(action, ref, data, appId) {
            _i.send(action, data, appId, ref);
        },
        sendSilent(action, data, appId = '') {
            if (!cl.get('connected')) {
                _.delay(_i.send, 100, action, data, cl.get('appId'));
                return false;
            }

            if (!cl.exec('login.check') && action !== 'auth') {
                return false;
            }

            if (appId && appId !== cl.get('appId') && action !== 'getInventoryBalance') {
                cl.error(`Error Sending ${action}<br>Please Try Again`);
                return false;
            }

            const update = {
                id: _.uniqueId(),
                action,
                token: cl.login.getToken(),
                app: cl.get('appId'),
                data,
            };

            connection.send(update);
        },
        kill() {
            cl.set('connected', false);
            connection.close();
            connection.reconnect = false;
        }
    };

    return {
        init: _i.init,
        connect: _i.connect,
        send: _i.send,
        sendWithRef: _i.sendWithRef,
        sendSilent: _i.sendSilent,
        kill: _i.kill,
        connection,
    };
})(window.cl, window.location);

window.cl.send = ((...args) => {
    return cl.sockets.send(...args);
});

window.cl.sendWithRef = ((...args) => {
    return cl.sockets.sendWithRef(...args);
});

window.cl.sendSilent = ((...args) => {
    return cl.sockets.sendSilent(...args);
});