import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import isUUID from './utilities/isUUID';
import _ from 'lodash';
import helper from './sessionHelper';
import dayjs from './utilities/dayjs';

// Ractive data handling
const baseDataKey = 'pos.sessions.all';

const getData = (key = '') => {
    return cl.get(`${baseDataKey}${key ? `.${key}` : ''}`);
};

const setData = (key, value) => {
    cl.set(`${baseDataKey}.${key}`, value);
    if (key?.split?.('.')?.pop?.() !== 'updated') {
        SessionData.updateCurrentSession();
    }
};

const pushData = (key, value) => {
    cl.push(`${baseDataKey}.${key}`, value);
    SessionData.updateCurrentSession();
};

const incrementData = (key) => {
    cl.increment(`${baseDataKey}.${key}`);
    SessionData.updateCurrentSession();
};

const decrementData = (key) => {
    cl.decrement(`${baseDataKey}.${key}`);
    SessionData.updateCurrentSession();
};

const spliceData = (start, deleteCount, ...newValues) => {
    cl.splice(baseDataKey, start, deleteCount, ...newValues);
    for (const key in cache) {
        delete(cache[key]);
    }
};

// session data handling
const setSessionData = (key, value) => {
    if (!SessionData.hasCurrentSession()) return;
    setData(`${SessionData.getCurrentSessionIndex()}.${key}`, value);
};

const pushSessionData = (key, value) => {
    if (!SessionData.hasCurrentSession()) return;
    pushData(`${SessionData.getCurrentSessionIndex()}.${key}`, value);
};

const incrementSessionData = (key, value) => {
    if (!SessionData.hasCurrentSession()) return;
    incrementData(`${SessionData.getCurrentSessionIndex()}.${key}`, value);
};

const decrementSessionData = (key, value) => {
    if (!SessionData.hasCurrentSession()) return;
    decrementData(`${SessionData.getCurrentSessionIndex()}.${key}`, value);
};

// sale data handling
const setSaleData = (key, value) => {
    if (!SessionData.hasCurrentSale()) return;
    setSessionData(`sales.${SessionData.getCurrentSaleIndex()}.${key}`, value);
};

const pushSaleData = (key, value) => {
    if (!SessionData.hasCurrentSale()) return;
    pushSessionData(`sales.${SessionData.getCurrentSaleIndex()}.${key}`, value);
};

const incrementSaleData = (key, value) => {
    if (!SessionData.hasCurrentSale()) return;
    incrementSessionData(`sales.${SessionData.getCurrentSaleIndex()}.${key}`, value);
};

const decrementSaleData = (key, value) => {
    if (!SessionData.hasCurrentSale()) return;
    decrementSessionData(`sales.${SessionData.getCurrentSaleIndex()}.${key}`, value);
};

// item data handling
const setItemData = (key, value) => {
    if (!SessionData.hasSelectedItem()) return;
    setSaleData(`items.${SessionData.getSelectedItemIndex()}.${key}`, value);
};

const pushItemData = (key, value) => {
    if (!SessionData.hasSelectedItem()) return;
    pushSaleData(`items.${SessionData.getSelectedItemIndex()}.${key}`, value);
};

const incrementItemData = (key, value) => {
    if (!SessionData.hasSelectedItem()) return;
    incrementSaleData(`items.${SessionData.getSelectedItemIndex()}.${key}`, value);
};

const decrementItemData = (key, value) => {
    if (!SessionData.hasSelectedItem()) return;
    decrementSaleData(`items.${SessionData.getSelectedItemIndex()}.${key}`, value);
};

const cache = {};

const getSaleCacheKeyPrefix = () => {
    const sessionIndex = helper.getSessionRoute();
    if (!sessionIndex) {
        return;
    }

    return `sale__${sessionIndex}__`;
};

const getItemCacheKeyPrefix = () => {
    const sessionIndex = helper.getSessionRoute();
    const saleIndex = helper.getSaleRoute();

    if (!sessionIndex || !saleIndex) {
        return;
    }

    return `item__${sessionIndex}__${saleIndex}__`;
};

const SessionData = {
    getCache() {
        return cache;
    },
    clearCache(val) {
        for (const k in cache) {
            if (k.contains(val)) {
                delete(cache[k]);
            }
        }
    },
    clearAllCache() {
        for (const k in cache) {
            delete(cache[k]);
        }
    },
// sessions
    getSessions() {
        return getData();
    },
    getOpenSessions() {
        return this.getSessions()?.filter?.(s => s?.status !== 'closed');
    },
    getSessionIndex(uuid = '') {
        if (!uuid?.trim?.()) {
            return;
        }

        const key = `session__${uuid}`;
        const cacheVal = cache[key];
        if (cacheVal > -1) {
            if (this.getSessions()?.[cacheVal]?.uuid === uuid) {
                return cacheVal;
            }

            delete(cache[key]);
        }

        const index = this.getSessions()?.findIndex(s => s.uuid === uuid) ?? -1;
        if (index > -1) {
            cache[key] = index;
        }

        return index;
    },
    deleteSession(uuid = '') {
        const index = this.getSessionIndex(uuid);
        if (index < 0) {
            return;
        }

        spliceData(index, 1);
    },
// session
    getSession(uuid = '') {
        const index = this.getSessionIndex(uuid);
        if (index < 0) {
            return;
        }

        return this.getSessions()[index];
    },
    hasSession(uuid = '') {
        return this.getSessionIndex(uuid) > -1;
    },
    getCurrentSession() {
        return this.getSession(helper.getSessionRoute());
    },
    getCurrentSessionIndex() {
        return this.getSessionIndex(helper.getSessionRoute());
    },
    hasCurrentSession() {
        return this.getSessionIndex(helper.getSessionRoute()) > -1;
    },
    getCurrentSessionValue(key) {
        return get(this.getCurrentSession(), key);
    },
    setCurrentSessionValue(key, value) {
        setSessionData(key, value);
        return value;
    },
    pushCurrentSessionValue(key, value) {
        pushSessionData(key, value);
        return value;
    },
    incrementCurrentSessionValue(key) {
        const index = this.getCurrentSessionIndex();
        if (index < 0) {
            return;
        }

        incrementData(`${index}.${key}`);
    },
    decrementCurrentSessionValue(key) {
        const index = this.getCurrentSessionIndex();
        if (index < 0) {
            return;
        }

        decrementData(`${index}.${key}`);
    },
    updateCurrentSession() {
        if (!this.hasCurrentSession()) {
            return;
        }

        this.setCurrentSessionValue('updated', dayjs().format());
    },
    getCurrentSessionLocation() {
        return this.getCurrentSessionValue('location');
    },
    getCurrentSessionLocations() {
        return this.getCurrentSessionLocation()?.split?.('.');
    },
    getCurrentSessionBaseLocation() {
        return this.getCurrentSessionLocations()?.[0];
    },
    getCurrentSessionSubLocation() {
        return this.getCurrentSessionLocations()?.[1];
    },
    setSessionValue(uuid, key, value) {
        const index = this.getSessionIndex(uuid);
        if (index < 0) {
            return;
        }

        setData(`${index}.${key}`, value);
    },
// sales
    getSales() {
        if (!this.hasCurrentSession()) {
            return [];
        }

        return this.getCurrentSessionValue('sales') ?? [];
    },
    getOpenSales() {
        if (!this.hasCurrentSession()) {
            return [];
        }

        return this.getSales()?.filter?.(s => !s.deleted && !s.completed);
    },
// sale
    getSaleIndex(ref = '') {
        if (isUUID(ref)) {
            return this.getSaleIndexByUUID(ref);
        }

        return this.getSaleIndexById(ref);
    },
    getSaleIndexById(id) {
        if (!this.hasCurrentSession()) {
            return -1;
        }

        const key = `${getSaleCacheKeyPrefix()}${id}`;
        const cached = cache[key] ?? -1;
        if (cached > -1) {
            return cached;
        }

        const index = this.getSales()?.findIndex(s => s?.id === Number(id));
        if (index > -1) {
            cache[key] = index;
        }

        return index;
    },
    getSaleIndexByUUID(uuid) {
        if (!this.hasCurrentSession()) {
            return -1;
        }

        const key = `${getSaleCacheKeyPrefix()}${uuid}`;
        const cached = cache[key] ?? -1;
        if (cached > -1) {
            return cached;
        }

        const index = this.getSales()?.findIndex(s => s.uuid === uuid);
        if (index > -1) {
            cache[key] = index;
        }

        return index;
    },
    getSale(ref = '') {
        if (!this.hasCurrentSession()) {
            return;
        }

        const index = this.getSaleIndex(ref);
        if (index < 0) {
            return;
        }

        return this.getSales()?.[index];
    },
    getCurrentSale() {
        return this.getSale(helper.getSaleRoute());
    },
    getCurrentSaleIndex() {
        return this.getSaleIndex(helper.getSaleRoute());
    },
    hasCurrentSale() {
        return this.getSaleIndex(helper.getSaleRoute()) > -1;
    },
    getCurrentSaleValue(key) {
        return get(this.getCurrentSale(), key);
    },
    setCurrentSaleValue(key, value) {
        setSaleData(key, value);
    },
    pushCurrentSaleValue(key, value) {
        pushSaleData(key, value);
    },
    incrementCurrentSaleValue(key) {
        incrementSaleData(key);
    },
    decrementCurrentSaleValue(key) {
        decrementSaleData(key);
    },
    isCurrentSaleOpen() {
        return this.getCurrentSaleIndex() > -1 && !this.getCurrentSaleValue('deleted') && !this.getCurrentSaleValue('completed');
    },
    getCurrentSalePaymentsTotal() {
        if (!this.hasCurrentSale()) {
            return 0;
        }

        const payments = this.getCurrentSaleValue('payments')
            ?.filter?.(p => !p.deleted)
            .map(p => Number(p.amount))
            .reduce((a, p) => { a += p; return a; }, 0);

        return Number(_.moneyRaw(payments)) || 0;
    },
    getCurrentSaleSubotal() {
        if (!this.hasCurrentSale()) {
            return 0;
        }

        const subtotal = this.getCurrentItems()
            .filter(i => !i.deleted)
            .map(i => ({ ...i, product: helper.getProductFromId(i.id) }))
            .filter(i => !_.isUndefined(i?.product?.price))
            .reduce((a, i) => {
                a += helper.lineTotal(i, i?.product);
                return a;
            }, 0);

        return Number(_.moneyRaw(subtotal));
    },
    getCurrentSaleTax() {
        if (!this.hasCurrentSale()) {
            return 0;
        }

        const tax = this.getActiveItems()
            .reduce((a, i) => {
                a += helper.lineTax(i, i.product);
                return a;
            }, 0);

        return _.round(tax, 2);
    },
    getCurrentSaleTotal() {
        if (!this.hasCurrentSale()) {
            return 0;
        }

        const total = this.getCurrentSaleSubotal() + this.getCurrentSaleTax();

        return Number(_.moneyRaw(total));
    },
    getCurrentSaleSaved() {
        if (!this.hasCurrentSale()) {
            return 0;
        }

        const saved = this.getCurrentItems()
            ?.filter?.(i => !i.deleted)
            .reduce((a, i) => {
                if (!_.isUndefined(i?.price)) {
                    const p = _.cloneDeep(helper.getProductFromId(i.id));
                    a += helper.lineDiscount(i, p);
                }

                return a;
            }, 0);

        return Number(_.moneyRaw(saved));
    },
    setSaleValue(sessionUUID, saleUUID, key, value) {
        const sessionIndex = this.getSessionIndex(sessionUUID);
        if (sessionIndex < 0) {
            return;
        }

        const saleIndex = this.getSaleIndex(saleUUID);
        if (saleIndex < 0) {
            return;
        }

        setData(`${sessionIndex}.sales.${saleIndex}.${key}`, value);
    },
// items
    getCurrentItems() {
        if (!this.hasCurrentSale()) {
            return [];
        }

        return this.getCurrentSaleValue('items') ?? [];
    },
    getActiveItems() {
        if (!this.hasCurrentSale()) {
            return [];
        }

        return this.getCurrentItems()
            .filter(i => !i.deleted)
            .map(i => ({ ...i, product: helper.getProductFromId(i.id) }))
            .filter(i => !_.isUndefined(i?.product?.price));
    },
// item
    getItemIndex(ref) {
        if (!this.hasCurrentSession() || !this.hasCurrentSale()) {
            return -1;
        }

        if (isUUID(ref)) {
            return this.getItemIndexByUUID(ref);
        }

        return this.getItemIndexById(ref);
    },
    getItemIndexById(id) {
        if (!this.hasCurrentSession() || !this.hasCurrentSale()) {
            return -1;
        }

        const key = `${getItemCacheKeyPrefix()}${id}`;
        const cached = cache[key] ?? -1;
        if (cached > -1) {
            return cached;
        }

        const index = this.getCurrentItems()?.findIndex(i => i.id === id);
        if (index > -1) {
            cache[key] = index;
        }

        return index;
    },
    getItemIndexByUUID(uuid) {
        if (!this.hasCurrentSession() || !this.hasCurrentSale()) {
            return -1;
        }

        const key = `${getItemCacheKeyPrefix()}${uuid}`;
        const cached = cache[key] ?? -1;
        if (cached > -1) {
            return cached;
        }

        const index = this.getCurrentItems()?.findIndex(i => i.uuid === uuid);
        if (index > -1) {
            cache[key] = index;
        }

        return index;
    },
    getItem(ref = '') {
        const index = this.getItemIndex(ref);
        if (index < 0) {
            return;
        }

        return this.getCurrentSaleValue(`items.${index}`);
    },
    getSelectedItem() {
        return this.getItem(this.getCurrentSaleValue('itemSelected'));
    },
    getSelectedItemIndex() {
        return this.getItemIndex(this.getCurrentSaleValue('itemSelected')) ?? -1;
    },
    hasSelectedItem() {
        return this.hasCurrentSale() && this.getCurrentSaleValue('itemSelected') !== undefined && this.getCurrentSaleValue('itemSelected') !== '';
    },
    hasSaleItem(ref) {
        return this.getItemIndex(ref) > -1;
    },
    setSelectedItem(uuid) {
        if (!this.hasCurrentSale()) {
            return;
        }

        this.setCurrentSaleValue('itemSelected', uuid);
    },
    replaceSelectedItem(item) {
        if (!this.hasCurrentSale() || !this.hasSelectedItem()) {
            return;
        }

        const index = this.getSelectedItemIndex();
        if (index < 0) {
            throw new Error('Item not found');
        }
        this.setCurrentSaleValue(`items.${index}`, item);
    },
    setSelectedItemValue(key, value) {
        setItemData(key, value);
    },
    addItem(item) {
        if (!this.hasCurrentSale()) {
            throw new Error('No sale selected');
        }

        if (!item.uuid) {
            item.uuid = uuidv4();
        }

        if (_.isUndefined(item.discd)) {
            item.discd = 0;
        }

        if (_.isUndefined(item.discp)) {
            item.discp = 0;
        }

        if (_.isUndefined(item.discde)) {
            item.discde = 0;
        }

        if (_.isUndefined(item.discpe)) {
            item.discpe = 0;
        }

        this.pushCurrentSaleValue('items', item);
        this.setCurrentSaleValue('itemSelected', item.uuid);
    },
    getItemValue(ref, key) {
        return get(this.getItem(ref), key);
    },
    setItemValue(ref, key, value) {
        const index = this.getItemIndex(ref);
        if (index < 0) {
            throw new Error('Item not found');
        }

        this.setCurrentSaleValue(`items.${index}.${key}`, value);
    },
    incrementItemValue(ref, key) {
        const index = this.getItemIndex(ref);
        if (index < 0) {
            throw new Error('Item not found');
        }

        this.incrementCurrentSaleValue(`items.${index}.${key}`);
    },
    decrementItemValue(ref, key) {
        const index = this.getItemIndex(ref);
        if (index < 0) {
            throw new Error('Item not found');
        }

        this.decrementCurrentSaleValue(`items.${index}.${key}`);
    },
    getSelectedItemValue(key) {
        const index = this.getSelectedItemIndex();
        if (index < 0) {
            throw new Error('Item not found');
        }

        return get(this.getSelectedItem(), key);
    },
    setSelectedItemValue(key, value) {
        const index = this.getSelectedItemIndex();
        if (index < 0) {
            throw new Error('Item not found');
        }

        this.setCurrentSaleValue(`items.${index}.${key}`, value);
    },
};

export default SessionData;