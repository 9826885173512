window.cl = window.cl || {};

window.cl.time = ((cl) => {
    const getTime = () => {
        const options = {
            hour12: true,
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };

        return new Date().toLocaleTimeString('en-us', options);
    }

    const _set = {
        time: getTime(),
    }

    const _i = {
        init() {
            cl.set(_set);

            setInterval(() => {
                if (cl.get('time') !== getTime()) {
                    cl.set('time', getTime());
                };
            }, 50);
        },
        timer(name) {
            const start = new Date();
            return {
                stop() {
                    const time = new Date().getTime() - start.getTime();
                    _.log(`Timer: ${name} finished in ${time}ms`);
                }
            }
        },
    };

    return {
        init: _i.init,
        timer: _i.timer
    }
})(window.cl);