window.cl = window.cl || {};

window.cl.data = ((cl) => {
    const _updateFuncs = {
        productUpdate(data) {
            if (!(cl.routes.get() === 'product' && cl.get('product.id') === String(data.identifier))) {
                return;
            }

            cl.products.updateOne(data.data);
            cl.product.load(data.identifier);
        },
        productDelete(data) {
            // do nothing
        },
        productCreate(data) {
            if (!(cl.routes.get() === 'product' && cl.get('product.id') === '')) {
                return;
            }

            cl.routes.go(`/product/${data.identifier}/${cl.get('params.tab')}`);
            cl.product.load(data.identifier);
        },
        productsLoad(data) {
            if (!_.isNull(data.data)) {
                cl.products.loadChunk(data.data, data.data.length);
                return;
            }
            _.log('null products from server', data);
        },
        productsLoadChunk(data) {
            if (!_.isNull(data.data)) {
                cl.products.loadChunk(data.data, data.dataItemCount, data.dataLoadTime);
                cl.ls.product.cancel();
            } else {
                _.log('null products from server', data);
            }
        },
        productBlank(data) {
            cl.set('product.blank', data.data);
        },
        productPrintBarcodesSuccess(data) {
            cl.success('Barcodes Sent To Bartender', 1e4);
        },
        productHistory(data) {
            cl.products.loadHistory(data.identifier, data.data || []);
        },
        productAdjustments(data) {
            cl.products.loadAdjustments(data.identifier, data.data || []);
        },
        productLowLoad(data) {
            cl.success('Product Low Inventory Data Received');
            cl.products.loadLow(data.data);
        },
        productLowUpdate(data) {
            cl.success('Low Inventory Data Updated');
            cl.products.loadLow(data.data);
        },
        productInventoryAdjustment(data) {
            cl.products.inventoryAdjustment(data.data);
        },
        productDiscontinuedSuccess(data) {
            cl.success('Product(s) Updated');
        },
        productDiscontinuedClientUpdate(data) {
            cl.products.discontinuedClientUpdate(data.data);
        },
        productUndeleteSku(data) {
            cl.success(`"${data.data}" Undeleted`);
        },
        addBarcodesSuccess(data) {
            cl.success("Barcodes Successfully Added");
            cl.products.addBarcodesReset();
        },
        addBarcodesClientUpdate(data) {
            cl.products.addBarcodesClientUpdate(data.data);
        },
        addWeightSuccess(data) {
            cl.success("Weight Successfully Added");
            cl.products.addWeightReset();
        },
        addWeightClientUpdate(data) {
            cl.products.addWeightClientUpdate(data.data);
        },
        deleteProductsSuccess(data) {
            cl.success("Products Successfully Deleted");
            cl.set('reports.oldProducts.all', []);
        },
        deleteProductsClientUpdate(data) {
            cl.products.deleteProductsClientUpdate(data.data);
        },
        productsBulkUpdateSuccess(data) {
            cl.productsBulkUpdate.success();
        },
        productsBulkUpdateClientUpdate(data) {
            cl.productsBulkUpdate.clientUpdate(data.data);
        },
        productUploadSkuCheck(data) {
            cl.productUpload.skuCheck(data.data);
        },
        productUploadSuccess(data) {
            cl.productUpload.success();
        },
        productUploadClientUpdate(data) {
            cl.productUpload.clientUpdate(data.data);
        },
        productPastLevel(data) {
            cl.product.loadPastLevel(data.data);
        },
        // user data
        usersLoad(data) {
            cl.set('users.all', data.data || []);
        },
        userCreated(data) {
            cl.success(`User ${data.identifier} successfully created.`);
        },
        userDeleted(data) {
            cl.success(`User ${data.identifier} successfully deleted.`);
            cl.routes.go('/users/');
        },
        userUpdated(data) {
            cl.success(`User ${cl.exec('users.nameFromId', data.identifier)} successfully updated.`);
        },
        userChangePassword(data) {
            cl.success(`User ${data.data}'s password successfully changed.`);
        },
        userChangeActive(data) {
            cl.success(`User ${data.data}'s active status changed.`);
        },
        userChangeName(data) {
            // do nothing
        },
        // shipment data
        shipmentCreated(data) {
            cl.routes.go(`/shipments/${data.identifier}/details`);
            cl.success('Shipment successfully created.');
        },
        shipmentsLoad(data) {
            cl.shipments.load(data.data || []);
        },
        shipmentDelete(data) {
            cl.success('Shipment successfully deleted.');
            cl.routes.go('/shipments');
        },
        shipmentUpdate(data) {
            cl.success('Shipment successfully updated.');
        },
        shipmentUpdateProduct(data) {
            _.log('Shipment Product Updated: ', data.data);
        },
        shipmentUpdateMaxAll(data) {
            _.log('Shipment Max All: ', data.data);
        },
        shipmentReceive(data) {
            cl.success('Shipment successfully received.');
            cl.routes.go(`/shipments/${data.identifier}/details`);
            cl.shipments.updateData(data.data);
        },
        shipmentNoProducts(data) {
            cl.error('Shipment has no products.');
            cl.routes.go(`/shipments/${data.identifier}/products`);
        },
        shipmentUpdateClient(data) {
            cl.shipments.updateClient(data.data);
        },
        // order data
        ordersLoad(data) {
            cl.orders.load(data.data || []);
        },
        orderLoad(data) {
            cl.order.load(data.data);
        },
        orderCreated(data) {
            cl.success('Order successfully created.');
            if (!cl.get('orders.waitingForCreation')) {
                return;
            }

            cl.routes.go(`/orders/${data.identifier}/details`);
            cl.set('orders.waitingForCreation', false);
        },
        orderDeleted(data) {
            cl.success('Order successfully deleted.');
            cl.routes.go('/orders');
        },
        orderUpdate(data) {
            cl.success('Order successfully updated.');
        },
        // transfers
        instantTransferComplete(data) {
            cl.success('Transfer successfully executed.');
            cl.transfers.instantComplete(data.data);
        },
        inventoryBalanceTransferComplete(data) {
            cl.inventoryBalance.transferComplete(data.data);
            cl.success('Transfer successfully executed.');
        },
        transferUpdateClient(data) {
            cl.transfers.updateClient(data.data);
        },
        getTransfer(data) {
            cl.transfers.loadCurrent(data.data);
        },
        getTransferRequest(data) {
            cl.transfers.loadCurrentRequest(data.data);
        },
        getTransferRequests(data) {
            cl.transfers.loadRequests(data.data);
        },
        canceTransferRequest(data) {
            cl.success('Transfer Request Canceled');
        },
        processTransferRequestMissing(data) {
            cl.success('Transfer Request Missing');
        },
        processTransferRequest(data) {
            cl.success('Transfer Success');
            cl.transfers.requestComplete(data.data);
        },
        getTransfers(data) {
            cl.transfers.loadTransfers(data.data);
        },
        manualTransferRequest(data) {
            cl.transferRequestCreate.complete(data.data);
        },
        transferFromHolds(data) {
            cl.success(`${data.data.sku} Transfer Success`);
        },
        inventoryBalanceForceUpdate(data) {
            cl.inventoryBalance.load(data.data);
        },
        wetsuitSortingData(data) {
            cl.transfers.setCachedWetsuitSortingData(data.data);
        },
        processTransferRequestVerifyInventory(data) {
            cl.success(`Processed Verify Inventory<br> Request #${data.data}`);
        },
        // register
        registerSessionsLoad(data) {
            _.log('register sessions from server', data.data);
        },
        registerSessionClosed(data) {
            cl.sessions.close(data.data);
        },
        registerEndOfDay(data) {
            cl.sessions.endOfDay(data.data);
        },
        registerEndOfDayPreview(data) {
            cl.sessions.endOfDayPreview(data.data);
        },
        registerSalesByOrderNumber(data) {
            cl.sessions.registerSalesByOrderNumber(data.data || []);
        },
        getRegisterSession(data) {
            cl.settings.loadRegisterSession(data.data ?? {});
        },
        inStorePickupForceUpdate(data) {
            cl.pickup.load(data.data);
        },
        inStorePickupUpdateOrderStatusSuccess(data) {
            cl.pickup.updateStatusSuccess(data.data);
        },
        registerShopifyOrderDetails(data) {
            cl.sessions.registerShopifyOrderDetails(data.data || {});
        },
        // reports
        reportReviewSales(data) {
            cl.set('reports.reviewSales.all', data.data || []);
        },
        reportReviewSale(data) {
            cl.set('reports.reviewSale', data.data || {});
        },
        reportReverseSale() {
            cl.reports.saleReversed();
        },
        reportLowInventoryTool(data) {
            cl.lowInventory.data(data.data.products);
            if (data.data.new > 0) {
                cl.success(`${data.data.new} New Products`);
            };
        },
        reportLowInventoryToolSaveSuccess(data) {
            cl.success('Saved');
            cl.lowInventory.saved(data.data);
        },
        reportRawData(data) {
            cl.reports.raw(data.data);
        },
        reportInventoryBalance(data) {
            cl.reports.inventoryBalance(data.data);
        },
        reportAddSaleNoteSuccess(data) {
            cl.success('Note saved', 500);
        },
        reportWebsitePrice(data) {
            cl.reports.loadWebsitePrice(data.data);
            cl.success('Price Data Received');
        },
        reportUnsoldProducts(data) {
            cl.unsold.load(data.data);
        },
        reportInventorySnapshot(data) {
            cl.reports.inventorySnapshot(data.data);
        },
        reportOldProducts(data) {
            cl.reports.loadOldProducts(data.data);
        },
        reportsShopifyOrderIDFromNumber(data) {
            cl.reports.openShopifyOrder(data.data);
        },
        reportShopNotWeb(data) {
            cl.reports.loadShopNotWeb(data.data);
        },
        reportWebNotShop(data) {
            cl.reports.loadWebNotShop(data.data);
        },
        reportNotFoundSkus(data) {
            cl.reports.loadNotFoundSkus(data.data);
        },
        reportBarcodes(data) {
            cl.reports.loadBarcodes(data.data);
        },
        // inventory
        newInventoryScan(data) {
            cl.push('inventory.all', data.data);
            cl.set('inventory.new.import', []);
            cl.set('inventory.new.notFound', []);
            cl.routes.go(`/inventory-adjustment/${data.identifier}`);
        },
        inventoryScansLoad(data) {
            cl.set('inventory.all', data.data || []);
        },
        inventoryGetScan(data) {
            cl.inventory.loadScan(data.identifier, data.data);
        },
        inventoryDeleteScan(data) {
            cl.inventory.deleteScan(data.identifier);
        },
        inventorySaveScan(data) {
            cl.inventory.loadScan(data.data.id, data.data.items);
        },
        inventoryScanAdjustment(data) {
            cl.inventory.scanAdjustment(data.data);
        },
        inventoryScanList(data) {
            cl.inventory.scanList(data.data);
        },
        // utility cases
        authFailure(data) {
            cl.login.response(data);
        },
        authSuccess(data) {
            cl.login.response(data);
        },
        authFailureIP(data) {
            cl.login.response(data);
        },
        authCancel(data) {
            cl.login.response(data);
        },
        userPermissionsUpdate(data) {
            cl.login.updatePermissions(data.data);
        },
        reloadAll(data) {
            location.reload();
        },
        updateAllPrinters(data) {
            cl.settings.updatePrinter();
        },
        updateWebsitePrices(data) {
            cl.success('Prices Successfully Updated');
        },
        latency(data) {
            cl.set('tests.latency', data.data);
        },
        ipRestrictionAddSuccess(data) {
            cl.ipRestriction.addSuccess(data.data);
        },
        ipRestrictionLoad(data) {
            cl.ipRestriction.load(data.data);
        },
        ipRestrictionDeleteSuccess(data) {
            cl.ipRestriction.deleteSuccess(data.data);
        },
        logSearch(data) {
            cl.set('logSearch.results', data.data || []);
        },
        debugAll() {
            cl.settings.debugAll();
        },
        error(data) {
            cl.error(data.data.replace('\n', '<br>'));
        },
        invalidAction(data) {
            cl.error(`Invalid request: ${data.data}`);
        },
        build(data) {
            _i.build(data.data);
        },
        appId(data) {
            _i.appId(data.data);
        },
        inventoryPlannerInfo(data) {
            _i.inventoryPlannerInfo(data.data);
        },
        inventoryPlannerGetOrder(data) {
            cl.inventoryPlanner.loadOrder(data.data);
        },
        logAll(data) {
            cl.log(data.data, 3e4);
        },
        success(data) {
            cl.success(data.data, 5e4);
        },
    };

    const _afterLoadFuncs = [];
    const _await = {};
    const _que = [];
    const _failed = [];
    let _delayLastRun = _.now();
    let _delayOffset = 0;
    let _start = _.now();
    let _firstLoad = false;

    const _set = {
        'loadQue': 0,
        'loading'() {
            return cl.get('loadQue') > 0;
        },
        'loadingPercent'() {
            if (_firstLoad) {
                return '';
            }

            const reads = cl.login.reads();
            const que = cl.get('loadQue');
            const per = 100 - Math.ceil((que / reads) * 100);

            if (per < 0) {
                return '0%';
            }

            return `${per}%`;
        },
        allLoaded() {
            if (!cl.exec('loading') && cl.initFinished && cl.exec('login.check') && (cl.get('products.all') || []).length > 0) {
                return true;
            }

            return false;
        },
    };
    const _on = {
        upDate() {
            cl.update();
        },
        reload() {
            window.location.reload()
        },
    };
    const _i = {
        init() {
            cl.r.observe('loadQue', (n, o, k) => {
                if (!_.isUndefined(n) && !_.isUndefined(o)) {
                    // console.log(k + ' changed from ' + o + ' to ' + n);
                }
            });

            cl.set(_set);
            cl.on(_on);
        },
        update(data) {
            if (cl.exec('login.hasPerm', 'logData')) {
                const dataType = _.get(data, 'update.type');
                const loadTime = _.get(data, 'update.dataLoadTime');
                if (dataType) {
                    if (loadTime) {
                        _.log(`update: ${dataType} @ ${loadTime}`, { data: _.get(data, 'update.data')});
                    } else {
                        _.log(`update: ${dataType}`, { data: _.get(data, 'update.data')});
                    }
                } else {
                    _.log(data);
                }
            }
            if (!_.isUndefined(data.update)) {
                _.defer(_i.messages, data.update);
            } else {
                const table = data.table;

                if (!_.isUndefined(_.get(data, 'data.deleted')) && data.data.deleted === true) {
                    data.action = 'DELETE';
                }

                const module = cl[table];
                if (!_.isUndefined(module) && _.isFunction(module.update)) {
                    _.defer(module.update, data.action, data.data, data.table);
                }
            }
            if (data.ref) {
                _i.checkAwait(data.ref, _.get(data, 'update.data'));
            }
        },
        messages(data) {
            switch (data.type) {
                case 'inventoryBalanceUpdate':
                    cl.inventoryBalance.load(data.data);
                    return;
                case 'inStorePickupUpdate':
                    cl.pickup.load(data.data);
                    return;
            };

            _i.loadDown(1, data.type);

            const func = _updateFuncs[data.type];
            if (_.isUndefined(func)) {
                _.log('Update Message Miss', data);
            }

            func?.(data);
        },
        // delay() used to space out websockets data updates
        delay() {
            if ((_.now() - _delayLastRun) < 10) {
                _delayOffset += 40;
                _delayLastRun = _.now();
            } else {
                _delayOffset = 1;
                _delayLastRun = _.now();
            }
            return _delayOffset;
        },
        que(update) {
            if (update.action === 'checkAuth') {
                return;
            }

            _i.loadUp();
            _que.push(update);
            _.delay(cl.data.checkQue, 1.2e5, update);
        },
        checkQue(update) {
            let index = _.indexOf(_que, update);
            if (index <= -1) {
                return;
            }

            _que.splice(index, 1);
            _failed.push(update);
            if (_que.length === 0) {
                _i.loadDown(1, 'queCheck');
            }
        },
        queIndex(id) {
            const index = _.findIndex(_que, ['data.id', id]);
            _que.splice(index, 1);
            if (_que.length === 0) {
                _i.loadDown(1, 'queCorrection');
            }
            return index;
        },
        loadUp(i = 1) {
            cl.increment('loadQue', i);
            _.delay(() => {
                _i.loadDown(i, 'timeOut');
            }, 90 * 1000);
        },
        loadDown(i = 1, key) {
            if (key === 'productsLoadChunk') {
                return;
            }

            const que = cl.get('loadQue');
            const to = que - i;

            if (que <= 0) {
                return;
            }

            if (to < 0) {
                cl.set('loadQue', 0);
                return;
            }

            cl.set('loadQue', to);
            if (to === 0 && !_firstLoad) {
                _.log(`Time To Load: ${(_.now() - _start).toLocaleString()} ms`);
                _firstLoad = true;
            }
        },
        restart() {
            _start = _.now();
            _firstLoad = false;
            setTimeout(_i.afterLoad, 1000);
        },
        build(id) {
            cl.set('build.server', id.trim());
            cl.set('build.app', window.clBuild? window.clBuild.trim():'');

            if (cl.get('build.app') && cl.get('build.app') !== cl.get('build.server')) {
                cl.errorFatal('<center>App Out Of Date<br>Please Refresh</center>', () => {
                    window.onbeforeunload = null;
                    window.location.reload();
                });

                setTimeout(() => {
                    window.onbeforeunload = null;

                    cl.errorFatal('<center>App Reloading<br>In 60 Seconds&hellip;</center>', () => {
                        window.location.reload();
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 6e4/* 1 minute */);


                }, 144e5/* 4 hours */);
            }
        },
        appId(id) {
            cl.set('appId', id);
        },
        inventoryPlannerInfo(data) {
            cl.set('inventoryPlannerInfo', data);
        },
        addAfterLoad(func) {
            if (_.isFunction(func)) {
                _afterLoadFuncs.push(func);
            }
        },
        afterLoad() {
            if (cl.exec('loading') || !cl.initFinished || !cl.exec('login.check')) {
                setTimeout(_i.afterLoad, 500);
                return;
            }

            _afterLoadFuncs.forEach(f => {
                if (!_.isFunction(f)) {
                    return;
                }

                try {
                    f();
                } catch (e) {
                    _.log(e);
                }
            });
        },
        addAwait(ref = '', f = () => {}) {
            if (ref === '' || !_.isString(ref) || !_.isFunction(f)) {
                return;
            }

            _await[ref] = f;
            setTimeout(() => {
                if (ref in _await) {
                    delete _await[ref];
                    _.log(`Await ${ref} Timed Out`);
                }
            },  300 * 1000);
        },
        checkAwait(ref, data) {
            const f = _await[ref];
            if (_.isFunction(f)) {
                f(data);
                delete _await[ref];
            }
        },
        async allLoaded() {
            let resolver;
            const p = new Promise((resolve) => {
                resolver = resolve;
            });

            const interval = setInterval(() => {
                if (cl.exec('allLoaded')) {
                    resolver();
                    clearInterval(interval);
                }
            }, 50);

            return p;
        },
        async wait(timeOut = 1000) {
            return new Promise((resolve) => {
                setTimeout(resolve, timeOut);
            });
        },
        async emptyPromise() {
            return new Promise((resolve) => {
                resolve();
            });
        },
    };

    return {
        init: _i.init,
        update: _i.update,
        delay: _i.delay,
        que: _i.que,
        checkQue: _i.checkQue,
        failed: _failed,
        loadUp: _i.loadUp,
        loadDown: _i.loadDown,
        restart: _i.restart,
        addAfterLoad: _i.addAfterLoad,
        addAwait: _i.addAwait,
        allLoaded: _i.allLoaded,
        wait: _i.wait,
        emptyPromise: _i.emptyPromise,
    };
})(window.cl);

window.cl.get = ((...args) => {
    if (args.length === 1 && _.isUndefined(args[0])) {
        return;
    }
    return window.cl.r.get(...args);
});

window.cl.set = ((...args) => {
    return window.cl.r.set(...args);
});

window.cl.unset = ((...args) => {
    return window.cl.r.unset(...args);
});

window.cl.toggle = ((...args) => {
    return window.cl.r.toggle(...args);
});

window.cl.splice = ((...args) => {
    return window.cl.r.splice(...args);
});

window.cl.push = ((...args) => {
    if (args.length === 2 && _.isNull(window.cl.get(args[0]))) {
        window.cl.set(args[0], []);
    }
    return window.cl.r.push(...args);
});

window.cl.unshift = ((...args)=>{
    return window.cl.r.unshift(...args)
});

window.cl.update = ((...args) => {
    return window.cl.r.update(...args);
});

window.cl.find = ((...args) => {
    return window.cl.r.find(...args);
});

window.cl.findAll = ((...args) => {
    return window.cl.r.findAll(...args);
});

window.cl.on = ((...args) => {
    return window.cl.r.on(...args);
});

window.cl.observe = ((...args) => {
    return window.cl.r.observe(...args);
});

window.cl.fire = ((...args) => {
    return window.cl.r.fire(...args);
});

window.cl.resetPartial = ((...args) => {
    return window.cl.r.resetPartial(...args);
});

window.cl.exec = ((...args) => {
    const func = window.cl.get(args[0]);

    if (!_.isFunction(func)) {
        return;
    }

    return func(...args.splice(1));
});

window.cl.increment = ((key, int = 1) => {
    return window.cl.set(key, window.cl.get(key) + int);
})

window.cl.decrement = ((key, int = 1) => {
    return window.cl.set(key, window.cl.get(key) - int);
})