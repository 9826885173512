import Ractive from 'ractive';
import { nestedPullSort } from './utilities/pullSort';
import { handleOptionsDown, handleOptionsUp } from './utilities/options';
import dayjs from './utilities/dayjs';

window.cl = window.cl || {};

window.cl.isExpedited = (s = '') => {
    const n = s.toLowerCase();
    return n.includes('next day') ||
        n.includes('overnight') ||
        n.includes('express') ||
        n.includes('three-day') ||
        n.includes('3-day') ||
        n.includes('3 day') ||
        n.includes('second day') ||
        n.includes('2-day') ||
        n.includes('2 day') ||
        n.includes('air') ||
        n.includes('expedited');
}

window.cl.isInStore = (s = '') => s.toLowerCase().includes('store');

window.cl.transfers = ((cl) => {
    const _packetSize = 50;

    const _on = {
        transfersNewRequest(e) {
            e?.original?.preventDefault?.();
            cl.routes.go('/transfers/new-request/from');
            cl.transferRequestCreate.new();
        },
        transfersNewInstant(e) {
            e?.original?.preventDefault?.();
            cl.set({
                'transfer.from': '',
                'transfer.to': '',
                'transfer.products': [],
                'transfer.token': _.uuid(),
            })
            cl.routes.go('/transfers/new-instant/from');
        },
        transfersNewInstantTab(e, tab) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/transfers/new-instant/${tab}`);
        },
        transfersFromLocationSelect(e, from) {
            if (!cl.exec('transfer.retailFromCheck', from?.split?.('.')?.[0] ?? '')) {
                return;
            }

            const keyBase = 'transfer';
            const type = 'Transfer';
            _i.locationCheck({
                from,
                keyBase,
                type,
            });
        },
        transfersToLocationSelect(e, to) {
            const keyBase = 'transfer';
            const type = 'Transfer';
            _i.locationCheck({
                to,
                keyBase,
                type,
            });
        },
        transferProductSearch(e) {
            cl.set('transfer.productSearch', e.node.value);
            cl.find('#transfer-product-search-input').classList.add('focus');
        },
        transferProductSearchClick(e, id) {
            _i.instantProductSelect(id);
        },
        transferSearchFocus(e) {
            cl.set('transfer.productSearch', e.node.value);
            cl.find('#transfer-product-search-input').classList.add('focus');
            cl.update('transfer.productSearchResults');
        },
        transferProductQty(e, id) {
            const index = cl.get('transfer.products').findIndex(p => p.id === id);
            const locs = cl.get('transfer.from').split('.') || [];
            const src = cl.exec('product.fromId', id);
            const negPerm = cl.exec('login.hasPerm', 'transfersNegatives');
            const isVr = locs[0] == 'vr';
            const max = src.inventory?.[locs[0]]?.[locs[1]] ?? 1;
            const val = e.node.value.trim();
            const isNumber = /^\d+$/.test(val);

            if (isNumber && index > -1 && val <= max) {
                cl.set(`transfer.products.${index}.qty`, Number(val));
                return;
            }

            if (isNumber && index > -1 && negPerm && isVr) {
                cl.set(`transfer.products.${index}.qty`, Number(val));
                return;
            }

            cl.error(`${cl.exec('product.fromId', id).sku} only has ${max} in stock`);
            e.node.value = '0';
            cl.set(`transfer.products.${index}.qty`, 0);
        },
        transferProductQtyUp(e, id) {
            const index = cl.get('transfer.products').findIndex(p => p.id === id);
            const locs = cl.get('transfer.from').split('.') || [];
            const src = cl.exec('product.fromId', id);
            const max = src.inventory?.[locs[0]]?.[locs[1]] ?? 1;
            const key = `transfer.products.${index}.qty`;
            const negPerm = cl.exec('login.hasPerm', 'transfersNegatives');
            const isVr = locs[0] == 'vr';

            if (index > -1 && cl.get(key) < max) {
                cl.increment(key);
                return;
            }

            if (!negPerm || (negPerm && !isVr)) {
                cl.alert(`You don't have that many ${src.name} in inventory.`);
            }
            if (negPerm) {
                cl.increment(key);
            }
        },
        transferProductQtyDown(e, id) {
            const index = cl.get('transfer.products').findIndex(p => p.id === id);
            const key = `transfer.products.${index}.qty`;

            if (index > -1 && cl.get(key) > 1) {
                cl.decrement(key);
            }
        },
        transferProductRemove(e, id) {
            const index = cl.get('transfer.products').findIndex(p => p.id === id);

            if (index > -1) {
                cl.splice('transfer.products', index, 1);
            }
        },
        async transferCreateInstant(e) {
            e?.original?.preventDefault?.();
            const t = cl.get('transfer');

            if (t.from.length === 0) {
                cl.error('Transfer needs "from" location.');
                cl.routes.go('/transfers/new-instant/from');
                return;
            }

            if (t.to.length === 0) {
                cl.error('Transfer needs "to" location.');
                cl.routes.go('/transfers/new-instant/to');
                return;
            }

            if (t.products.length === 0) {
                cl.error('Transfers need products.');
                cl.routes.go('/transfers/new-instant/products');
                return;
            }

            if (t.from === t.to) {
                cl.error('Transfer "to" and "from" are same location');
                return;
            }

            if (!t.note.trim()) {
                const resNote = await cl.prompt({
                    message: 'Transfer Note Required',
                    okFunc(v) {
                        cl.set('transfer.note', v.trim());
                    }
                });

                if (resNote.buttonClicked !== 'cancel') {
                    cl.fire('transferCreateInstant');
                }

                return;
            }

            const message = `
                <strong>Are you sure you want to make this transfer?</strong><br><br>
                <strong>From:</strong> ${t.from}<br>
                <strong>To:</strong> ${t.to}<br>
                <strong>Total Qty:</strong> ${t.products.reduce((sum, t) => sum += Number(t.qty), 0)}<br>
                <strong>Note:</strong> ${t.note}
            `;

            _i.optionsExit();

            cl.confirm({
                message,
                okFunc() {
                    _i.createTransfer();
                }
            });
        },
        transferInstantNote(e) {
            cl.set('transfer.note', e.node.value);
        },
        transfersRefresh() {
            _i.getTransfers();
        },
        transferGoToProduct(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/product/${id}/details`);
        },
        transferGoToRequest(e, id) {
            e?.original?.preventDefault?.();
            if (!id) {
                return;
            }
            cl.routes.go(`/transfer-request/${id}`);
        },
        refreshCurrentTransfer() {
            _i.loadTransfer();
        },
        refreshCurrentTransferRequest() {
            _i.loadTransferRequest();
        },
        transferRequestGoToTransfer(e, id) {
            e?.original?.preventDefault?.();
            if (!id) {
                return;
            }
            cl.routes.go(`/transfers/${id}`);
        },
        printCurrentTransfer() {
            cl.confirm({
                message: `<div class="error-alert"><i class="material-icons">warning</i>Are you sure you want to reprint this transfer's receipt?</div>`,
                okFunc() {
                    const data = cl.get('transfers.current');

                    data.ts = _.now();
                    data.packet = cl.exec('transfers.requests.getPacketNumber', data.orderNumber);

                    data.products.map(p => {
                        const prod = cl.exec('product.fromId', p.id) || {};
                        p.name = '';
                        if (prod.brand) {
                            p.name += `${prod.brand} - `;
                        }
                        p.name += prod.name;
                        if (prod.color) {
                            p.name += ` - ${prod.color}`;
                        }

                        if (prod.size) {
                            p.name += ` - ${prod.size}`;
                        }

                        p.sku = prod.sku;

                        return p;
                    });

                    require('superagent')
                        .post('https://receipt.cleanline.ninja/transfer.php')
                        .timeout(7000)
                        .send(data || {})
                        .end((err, res) => {
                            if (_.isUndefined(res)) {
                                cl.error('Error Sending Receipt To Printer.');
                                return;
                            }

                            const json = JSON.parse(res.text);

                            if (err || !res.ok || json.failure) {
                                cl.error('Error Sending Receipt To Printer.');
                                _.log('Receipt Error:', err, res);
                                return;
                            }

                            _.log('Receipt Data:', json.post)
                        });
                },
            });
        },
        async transferRequestCancel(e, id) {
            const res = await cl.confirm({
                message: '<strong>Are you sure you want to cancel this transfer?</strong><br>There is no undo</em>.',
            });

            if (res.buttonClicked !== 'ok') {
                return;
            }

            const timeOut = 250;
            await cl.data.wait(timeOut);

            const getNote = async () => {
                const message = 'Note required for cancelling a transfer request';
                const note = await cl.prompt({
                    message,
                });

                if (note.buttonClicked === 'cancel') {
                    return;
                }

                if (note.inputValue.trim().length === 0) {
                    cl.error(message);
                    return await getNote();
                }

                return note.inputValue.trim();
            };

            const note = await getNote();
            if (typeof note === 'undefined') {
                return;
            }

            const getPin = async (tries = 0) => {
                tries++;
                const user = await cl.users.getPinUser();
                if (!user) {
                    return;
                }

                if (_.isUndefined(user) && tries > 2) {
                    return;
                }

                if (_.isUndefined(user)) {
                    setTimeout(() => {
                        getPin(tries)
                    }, timeOut);
                    return;
                }

                cl.send('cancelTransferRequest', {
                    id: Number(id),
                    user: user.username,
                    note,
                });
            };

            await cl.data.wait(timeOut);
            getPin();
        },
        async transferRequestMissing(e, req) {
            const getNote = async () => {
                const message = 'Note required for missing product';
                const note = await cl.prompt({
                    message,
                });

                if (note.buttonClicked === 'cancel') {
                    return;
                }

                if (note.inputValue.trim().length === 0) {
                    cl.error(message);
                    return await getNote();
                }

                return note.inputValue.trim();
            };

            const note = await getNote();
            if (typeof note === 'undefined') {
                return;
            }

            const qty = req.qty;
            const timeOut = 250;
            const getPin = async (tries = 0) => {
                tries++;

                const user = await cl.users.getPinUser();
                if (user === false) {
                    return;
                }

                if (_.isUndefined(user) && tries > 2) {
                    return;
                }

                if (_.isUndefined(user)) {
                    setTimeout(() => {
                        getPin(tries);
                    }, timeOut);
                    return;
                }

                cl.send('transferRequestMissing', {
                    id: String(req.id),
                    user: user.username,
                    qty,
                    note,
                });
            };

            if (qty === 1) {
                setTimeout(getPin, timeOut);
                return;
            }

            const func = async () => {
                const name = cl.get(`products.all.${cl.exec('product.indexFromId', req.product_id)}.name`) || 'products';
                const amount = await cl.prompt({
                    message: `There are ${req.qty}X ${name} requested.<br>Enter the amount you can't find.`,
                    default: '1',
                });

                if (amount.buttonClicked === 'cancel') {
                    return;
                }

                qty = Number(amount.inputValue);
                if (qty < 1 || qty > req.qty || _.isNaN(qty)) {
                    cl.error(`Missing quantity must be a number greater than 0 and less than ${req.qty + 1}`);
                    setTimeout(func, timeOut);
                    return;
                }

                setTimeout(getPin, timeOut);
            };

            // wait a little to avoid enter key from previous prompt confirming the next one
            setTimeout(func, timeOut);
        },
        transfersRequestsAltLocation(e) {
            cl.set('transfers.requests.altLoc', e.node.value);
            cl.ls.set('transferRequestLocation', e.node.value);
            cl.update('transfers.requests.list');
        },
        transfersRequestsRange(e) {
            const vals = e.node.value.split('-');

            if (vals.length === 2) {
                cl.set('transfers.requests.rangeFrom', vals[0]);
                cl.set('transfers.requests.rangeTo', vals[1]);
            } else {
                cl.set('transfers.requests.rangeFrom', '');
                cl.set('transfers.requests.rangeTo', '');
            }
        },
        async printTransferRequests() {
            _i.printTransferRequestsShopify();
        },
        transferRequestCheck(e, id) {
            const selected = cl.get('transfers.requests.selected');
            const index = _.indexOf(selected, id);

            if (index > -1) {
                cl.splice('transfers.requests.selected', index, 1);
                return;
            }

            cl.push('transfers.requests.selected', id);
        },
        transferRequestPullPrint(e, id) {
            const req = cl.get('transfers.requests.all').find(r => r.id === id);

            if (!req) {
                return;
            }

            const data = {};
            data.ts = _.now();
            data.packet = cl.exec('transfers.requests.getPacketNumber', req.order_number);

            const prod = cl.exec('product.fromId', req.product_id) || {};
            const fromLoc = req.from.split('.');

            data.name = prod.name;
            data.color = prod.color;
            data.brand = prod.brand;
            data.size = prod.size;
            data.sku = prod.sku;
            data.price = prod.price;
            data.from = req.from.toUpperCase();
            data.stock = prod.inventory[fromLoc[0]][fromLoc[1]];
            data.qty = req.qty;
            data.user = req.user;
            data.shipping = req.shipping;
            data.isExpedited = cl.isExpedited(data.shipping);
            data.isInStore = cl.isInStore(data.shipping);
            if (data.isInStore) {
                data.storeLocation = data.shipping.toLowerCase().includes('seaside') ? 'SS' : 'CB';
            }

            require('superagent')
                .post('https://receipt.cleanline.ninja/transferRequestPull.php')
                .timeout(7000)
                .send(data || {})
                .end((err, res) => {
                    if (_.isUndefined(res)) {
                        cl.error('Error Sending Receipt To Printer.');
                        return;
                    }

                    const json = JSON.parse(res.text);
                    if (err || !res.ok || json.failure) {
                        cl.error('Error Sending Receipt To Printer.');
                        _.log('Receipt Error:', err, res);
                        return;
                    }

                    _.log('Receipt Data:', json.post);
                });
        },
        transferRequestSelectAll() {
            cl.set('transfers.requests.selected', cl.exec('transfers.requests.list').map(r => r.id));
        },
        transferRequestUnselectAll() {
            cl.set('transfers.requests.selected', []);
        },
        transferRequestAdminProcess(e, id) {
            cl.send('processTransferRequest', {
                id: Number(id),
                user: cl.get('login.user'),
                qty: 1,
            });
        },
        transferRequestFireBarcode(e, id) {
            const product = cl.exec('product.fromId', id);
            if (!product || (product && (product.barcodes || []).length === 0)) {
                return;
            }

            _i.requestBarcodeListener(_.last(product.barcodes));
        },
        transfersTimeSelectOpen() {
            cl.set('transfers.timeSelect', true);
        },
        transfersTimeSelectClose(e) {
            if (!_.isUndefined(e) && !e?.original?.target?.classList?.contains?.('modal-wrapper')) {
                return;
            }

            cl.set('transfers.timeSelect', false);
            _i.getTransfers();
        },
        transfersTimeChange(e, unit, dir) {
            if (!['add', 'subtract'].includes(dir)) {
                return cl.error(`Invalid direction ${dir}`);
            }

            if (!['day', 'month', 'year'].includes(unit)) {
                return cl.error(`Invalid unit ${unit}`);
            }

            const time = dayjs(cl.get('transfers.time')).clone()[dir](1, unit).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            cl.set('transfers.time', time);
        },
        transferReview(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/transfers/${id}`);
        },
        transferGoToProductBySku(e, sku) {
            e?.original?.preventDefault?.();
            const id = cl.exec('product.fromSku', sku).id;
            if (!id) {
                cl.error('Invalid Product Sku')
            }

            cl.routes.go(`/product/${id}/details`);
        },
        async transferRequestVerifyInventory(e, req) {
            const sku = cl.exec('product.fromId', req.product_id).sku;

            if ((req.recentTransfers || []).length > 0) {
                let table = '';

                for (const t of req.recentTransfers) {
                    table += `
                        <tr>
                            <td>${t.id}</td>
                            <td>${dayjs(t.created).format('L LT')}</td>
                            <td>${t.from}</td>
                            <td>${t.qty}</td>
                            <td>${t.note}</td>
                        </td>
                    `;
                }

                await cl.alert(`<div class='error-alert'>
                    <i class='material-icons'>warning</i>
                    <p class="msg">
                    There are ${req.recentTransfers.length} recent transfers
                    of <span class="sku">${sku}</span> that may not be at your location yet.
                    </p>
                    <br>
                    <table class="thin" style="table-layout: fixed;">
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>Created</td>
                                <td>From</td>
                                <td>Qty</td>
                                <td>Note</td>
                            </tr>
                        </thead>
                        <tbody>${table}</tbody>
                    </table></div>`);
                await cl.data.wait(250);
            }

            const res = await cl.prompt({
                message: `Enter The Qty Of <span class="sku">${sku}</span> You Were Able To Locate:`,
            });

            if (res.buttonClicked === 'cancel') {
                return;
            }

            const qty = res.inputValue.trim();
            if (!(/^\d+$/.test(qty))) {
                cl.error(`Invalid Qty: ${qty}`);
                return;
            }

            const getPin = async () => {
                const user = await cl.users.getPinUser();
                if (_.isUndefined(user)) {
                    return;
                }

                cl.send('transferRequestVerifyInventory', {
                    id: Number(req.id),
                    qty: Number(qty),
                    user: user.username,
                });
            }

            await cl.data.wait(250);
            getPin();
        },
        transferRequestOpenInShopify(e, shopifyOrderID, orderNumber) {
            e?.original?.preventDefault?.();
            if (!shopifyOrderID) {
                return;
            }

            const base =  Math.abs(Number(String(orderNumber).replace(/[^\d\.]/g, ''))) > 10000 ? window.cl.shopifyProdBaseAdminUrl : window.cl.shopifyDevBaseAdminUrl;
            window.open(`${base}/admin/orders/${shopifyOrderID.replace(/\D/g, '')}`, '_blank');
        }
    };

    const _set = {
        'transfer.products': [],
        'transfer.from': '',
        'transfer.to': '',
        'transfer.note': '',
        'transfer.token': _.uuid(),
        'transfer.productSearch': '',
        'transfer.productSearchIds': [],
        'transfer.productSearchResults'() {
            const ids = cl.get('transfer.productSearchIds');
            const el = cl.find('#transfer-product-search-input');
            const locs = cl.get('transfer.from').split('.') || [];

            if (locs.length !== 2 || _.isNull(el) || _.isUndefined(el) || !el?.classList?.contains?.('focus') || !(ids.length || cl.get('transfer.productSearch').length > 1)) {
                return [];
            }

            const filter = !cl.exec('login.hasPerm', 'transfersNegatives');

            return _(cl.get('products.all'))
                .filter(p => filter ? p.inventory?.[locs[0]]?.[locs[1]] > 0 : true)
                .keyBy('id')
                .at(ids)
                .filter(e => !!e)
                .sortBy('sku')
                .map(p => {
                    p.qty = p.inventory?.[locs[0]]?.[locs[1]];
                    return p;
                })
                .value();
        },
        'transfer.retailFromCheck'(baseLoc) {
            const base = baseLoc.toLowerCase();
            const others = ['vr', 'ls'];
            const isSeaside = cl.exec('login.isSeaside');
            const isCannonBeach = cl.exec('login.isCannonBeach');

            if (isSeaside && base !== 'ss' && !others.includes(base)) {
                return false;
            }

            if (isCannonBeach && base !== 'cb' && !others.includes(base)) {
                return false;
            }

            return true;
        },
        'transfers.all': [],
        'transfers.time': dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        'transfers.timeSelect': false,
        'transfers.current': {},
        'transfers.currentRequest': {},
        'transfers.requests.all': [],
        'transfers.requests.byLoc'() {
            const filter = cl.get('transfers.requests.expedited');

            return _(cl.get('transfers.requests.all'))
                .filter(['from', cl.exec('transfers.requests.loc')])
                .filter(['complete', false])
                .filter(t => filter ? cl.isExpedited(t.shipping) || cl.isInStore(t.shipping) : true)
                .value();
        },
        'transfers.requests.list'() {
            let chain = _(cl.exec('transfers.requests.byLoc'));

            const to = _.orderNumberToNumber(cl.get('transfers.requests.rangeTo'));
            const from = _.orderNumberToNumber(cl.get('transfers.requests.rangeFrom'));

            if (to > 0 && from > 0) {
                const internal = cl.get('transfers.requests.includeInternal');
                chain = chain.filter(r => {
                    const on = _.orderNumberToNumber(r.orderNumber);
                    if (internal) {
                        return (on >= from && on <= to) || r.orderNumber === 'N/A';
                    }

                    return on >= from && on <= to;
                });
            }

            return chain
                .orderBy('updated', 'asc')
                .uniqBy('id')
                .value();
        },
        'transfers.requests.count'() {
            if (!cl.exec('login.permissions', 'transferRequestsRead')) {
                return 0;
            }

            return cl.exec('transfers.requests.list').length || 0;
        },
        'transfers.requests.altLoc': '',
        'transfers.requests.loc'() {
            const ls = cl.ls.get('transferRequestLocation');
            if (ls) {
                return ls.length === 2 ? `${ls}.main` : ls;
            }

            const alt = cl.get('transfers.requests.altLoc');
            if (alt) {
                return alt.length === 2 ? `${alt}.main` : alt;
            }

            const loc = cl.ls.get('posLocation');
            const base = loc?.split?.('.')?.[0];
            if (!loc || !base) {
                return '';
            }

            if (base.length === 2) {
                return `${base}.main`;
            }

            return base;
        },
        'transfers.requests.dropDown': false,
        'transfers.requests.selected': [],
        'transfers.requests.range'() {
            const range = cl.exec('transfers.requests.byLoc')
                .filter(r => r.orderNumber !== 'N/A')
                .map(r => r.orderNumber)
                .sort()
                .reduce((a, o) => {
                    const key = Math.floor(_.orderNumberToNumber(o) / _packetSize);
                    if (!a[key]) {
                        a[key] = new Set();
                    }

                    a[key].add(o);
                    return a;
                }, {});

            return _.forIn(range, (v, k, o) => o[k] = [...v]);
        },
        'transfers.requests.rangeFrom': '',
        'transfers.requests.rangeTo': '',
        'transfers.requests.hasRangeSet'() {
            if (cl.get('transfers.requests.rangeFrom') && cl.get('transfers.requests.rangeTo')){
                return true;
            }

            return false;
        },
        'transfers.requests.getPacketNumber'(orderNumber) {
            const n = _.orderNumberToNumber(orderNumber);
            if (n === 0) {
                return '';
            }

            const key = Math.floor(n / _packetSize);
            return (key % 99) + 1;
        },
        'transfers.requests.expedited': false,
        'transfers.requests.includeInternal': true,
        'transfers.requests.pauseScanning': false,
        'transfers.changeToMissing'(loc) {
            const locs = String(loc).split('.');
            if (locs.length != 2) {
                return loc;
            }

            locs[1] = 'missing';
            return locs.join('.');
        },
        'wetsuitSorting.data': null,
        'wetsuitSorting.time': null,
        'isExpedited': cl.isExpedited,
        'isInStore': cl.isInStore,
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);
            cl.observe('transfer.productSearch', () => {
                _.defer(_i.search);
            });

            document.addEventListener('upArrow', (e) => {
                _i.optionsUp(e.detail);
            });

            document.addEventListener('downArrow', (e) => {
                _i.optionsDown(e.detail);
            });

            document.addEventListener("enterFire", (e) => {
                _i.optionsSelect(e.detail);
            });

            document.addEventListener("escapeFire", (e) => {
                _i.optionsExit(e.detail);
            });

            document.addEventListener("barcode", (e) => {
                _i.instantBarcodeListener(e.detail);
                _i.requestBarcodeListener(e.detail);
            });

            const loc = cl.exec('transfers.requests.loc');
            const lsLoc = cl.ls.get('transferRequestLocation');

            cl.set('transfers.requests.altLoc', loc || lsLoc || 'wh');

            cl.observe('transfer.products.*', _i.holdsCheck);
            cl.ls.persist('transfers.requests.includeInternal');
        },
        search() {
            const search = cl.get('transfer.productSearch');
            if (search.length <= 1) {
                cl.set('transfer.productSearchIds', []);
                cl.update();
                return;
            }

            const ids = _.map(cl.products.search(search), 'ref');
            cl.set('transfer.productSearchIds', ids);
            cl.update('transfer.productSearchResults');
        },
        optionsUp(src) {
            if (cl.routes.get() !== 'transferNewInstant' || cl.exec('transfer.productSearchResults').length <= 0) {
                return;
            }

            handleOptionsUp(document.querySelectorAll('#transfer-product-search-list li'));
        },
        optionsDown(src) {
            if (cl.routes.get() !== 'transferNewInstant' || cl.exec('transfer.productSearchResults').length <= 0) {
                return;
            }

            handleOptionsDown(document.querySelectorAll('#transfer-product-search-list li'));
        },
        optionsSelect(src) {
            if (cl.routes.get() !== 'transferNewInstant' || cl.exec('transfer.productSearchResults').length <= 0) {
                return;
            }

            const opt = cl.find('#transfer-product-search-list li.active');
            if (opt && opt.dataset && opt.dataset.id) {
                _i.instantProductSelect(opt.dataset.id);
            }
        },
        optionsExit(src) {
            if (cl.routes.get() !== 'transferNewInstant' || cl.exec('transfer.productSearchResults').length <= 0) {
                return;
            }

            const el = cl.find('#transfer-product-search-input');
            el.classList.remove('focus');
            el.blur();
            cl.update('transfer.productSearchResults');
        },
        instantProductSelect(id) {
            const src = cl.exec('product.fromId', id);
            if (!src) {
                return;
            }

            const products = cl.get('transfer.products');
            const index = products.findIndex(p => p.id === id);
            const locs = cl.get('transfer.from').split('.') || [];
            if (locs.length !== 2) {
                return;
            }

            const qty = src.inventory?.[locs[0]]?.[locs[1]];
            const max = qty || 1;
            const key = `transfer.products.${index}.qty`;
            const negPerm = cl.exec('login.hasPerm', 'transfersNegatives');
            const isVr = locs[0] == 'vr';

            setTimeout(() => {
                cl.update('transfer');
            }, 50);

            if (index <= -1) {
                cl.push('transfer.products', {
                    id: id,
                    qty: 1
                });

                if (qty <= 0 && !negPerm) {
                    cl.alert(`You don't have that many ${src.name} in inventory.`);
                }
                return;
            }

            if (cl.get(key) < max) {
                cl.increment(key);
                return;
            }

            if (!negPerm || (negPerm && !isVr)) {
                cl.alert(`You don't have that many ${src.name} in inventory.`);
            }

            if (negPerm) {
                cl.increment(key);
            }
        },
        instantBarcodeListener(bc) {
            if (cl.routes.get() !== 'transferNewInstant' || cl.exec('tab') !== 'products') {
                return;
            }

            cl.products.skuFromBarcode(bc, (bc, sku, id) => {
                _i.instantProductSelect(id);
            });
        },
        async requestBarcodeListener(bc) {
            if (cl.routes.get() !== 'transferRequests') {
                return;
            }

            if (cl.get('transfers.requests.pauseScanning')) {
                cl.error('Scanning Disabled<br>Until Prompt Answered');
                return;
            }

            const func = async (bc, sku, id) => {
                const expedited = cl.get('transfers.requests.expedited');
                const strip = _.orderNumberToNumber;
                const to = strip(cl.get('transfers.requests.rangeTo'));
                const from = strip(cl.get('transfers.requests.rangeFrom'));
                const rangeActive = to > 0 && from > 0;

                const transfer = _(cl.get('transfers.requests.all'))
                    .filter(['from', cl.exec('transfers.requests.loc')])
                    .filter(['complete', false])
                    .filter(r => expedited ? (cl.isExpedited(r.shipping) || cl.isInStore(r.shipping)) : true)
                    .filter(r => rangeActive ? (strip(r.orderNumber) >= from &&  strip(r.orderNumber) <= to) : true)
                    .orderBy('updated', 'asc')
                    .uniqBy('id')
                    .find(['product_id', Number(id)]);

                if (_.isUndefined(transfer)) {
                    const p = cl.exec('product.fromId', id);
                    cl.confirm({
                        message: `<div class='error-alert'><i class='material-icons'>warning</i>There are no active transfer requests<br>for ${p.sku} - ${p.name}.</div>`,
                    });
                    return;
                }

                if (transfer.qty <= 1) {
                    _i.processTransferRequest(Number(transfer.id));
                    return;
                }

                cl.set('transfers.requests.pauseScanning', true);

                const res = await cl.confirm({
                    message: `<div class='error-alert'><i class='material-icons'>warning</i>There are ${transfer.qty} &times; ${sku}'s on this transfer request.<br>Would you like to process them all at once?</div>`,
                    ok: 'Process One',
                    okFunc() {
                        _i.processTransferRequest(Number(transfer.id));
                    },
                    cancel: 'Process All',
                    cancelFunc() {
                        _i.processTransferRequest(Number(transfer.id), transfer.qty);
                    },
                    buttonFocus: 'cancel',
                });

                cl.set('transfers.requests.pauseScanning', false);
            };

            cl.products.skuFromBarcode(bc, func);
        },
        processTransferRequest(id, qty = 1) {
            cl.send('processTransferRequest', {
                id,
                qty,
                user: cl.get('login.user'),
                token: _.uuid(),
            })
        },
        clear() {
            cl.set({
                'transfer.from': '',
                'transfer.to': '',
                'transfer.products': [],
                'transfer.note': '',
            })
        },
        instantComplete(data) {
            data.ts = _.now();

            data.products.map(p => {
                const prod = cl.exec('product.fromId', p.id) || {};
                p.name = '';
                if (prod.brand) {
                    p.name += `${prod.brand} - `;
                }
                p.name += prod.name
                if (prod.color) {
                    p.name += ` - ${prod.color}`;
                }
                if (prod.size) {
                    p.name += ` - ${prod.size}`;
                }
                p.sku = prod.sku;

                return p;
            });

            if (!data.from.includes('vr') && !data.to.includes('vr')) {
                require('superagent')
                    .post('https://receipt.cleanline.ninja/transfer.php')
                    .timeout(7000)
                    .send(data || {})
                    .end((err, res) => {
                        if (_.isUndefined(res)) {
                            cl.error('Error Sending Receipt To Printer.');
                            return;
                        }

                        const json = JSON.parse(res.text);

                        if (err || !res.ok || json.failure) {
                            cl.error('Error Sending Receipt To Printer.');
                            _.log('Receipt Error:', err, res);
                            return;
                        }

                        _.log('Receipt Data:', json.post);
                    });
            }


            cl.routes.go('/transfers');
            cl.transfers.clear();
        },
        createTransfer() {
            if (!cl.exec('login.isPublic')) {
                _i.createTransferSend(cl.get('login.user'));
                return;
            }

            setTimeout(()=>{
                cl.users.getPin(pin => {
                    const user = cl.users.fromPin(pin);
                    if (_.isUndefined(user)) {
                        cl.error(`No User Found For PIN: ${pin}`);
                        return;
                    }

                    _i.createTransferSend(user.username);
                });
            }, 10);
        },
        createTransferSend(username) {
            cl.send('createInstantTransfer', {
                to: cl.get('transfer.to'),
                from: cl.get('transfer.from'),
                products: cl.get('transfer.products'),
                note: cl.get('transfer.note'),
                token: cl.get('transfer.token'),
                user: username,
            });
        },
        updateClient(data) {
            data.products.forEach(p => {
                const index = cl.exec('product.indexFromId', p.id);

                if (index <= -1) {
                    return;
                }

                const fromKey = `products.all.${index}.inventory.${data.from}`;
                const toKey = `products.all.${index}.inventory.${data.to}`;

                if (!_.bool(cl.get(`products.all.${index}.track`))) {
                    return;
                }

                cl.set(fromKey, cl.get(fromKey) - p.qty);
                cl.set(toKey, cl.get(toKey) + p.qty);
            });
        },
        async loadTransfer() {
            await cl.data.allLoaded();
            cl.set('transfers.current', {});
            cl.send('getTransfer', cl.get('params.transfer'));
        },
        loadCurrent(transfer){
            cl.set('transfers.current', transfer);
        },
        async loadTransferRequest() {
            await cl.data.allLoaded();
            cl.set('transfers.currentRequest', {});
            cl.send('getTransferRequest', cl.get('params.request'));
        },
        loadCurrentRequest(request) {
            cl.set('transfers.currentRequest', request);
        },
        loadRequests(data) {
            cl.set('transfers.requests.all', data || []);
        },
        update(type, data) {
            if (type === 'UPDATE' || type === 'INSERT') {
                _.delay(_i.upsertRequest, cl.data.delay(), data);
            }

            if (type === 'DELETE') {
                _.delay(_i.deleteRequest, cl.data.delay(), data);
            }
        },
        upsertRequest(data) {
            if (data.deleted) {
                _i.deleteRequest(data);
                return;
            }

            const index = cl.get('transfers.requests.all').findIndex(r => r.id === String(data.id));
            if (index > -1) {
                cl.splice('transfers.requests.all', index, 1, data);
            } else {
                cl.push('transfers.requests.all', data);
            }
        },
        deleteRequest(data) {
            const index = cl.get('transfers.requests.all').findIndex(r => r.id === String(data.id));

            if (index > -1) {
                cl.splice('transfers.requests.all', index, 1);
            }
        },
        requestComplete(data) {
            data.ts = _.now();
            data.packet = cl.exec('transfers.requests.getPacketNumber', data.orderNumber);

            const prod = cl.exec('product.fromId', data.product_id) || {};
            data.name = '';
            if (prod.brand) {
                data.name += `${prod.brand} - `;
            }
            data.name += prod.name;
            if (prod.color) {
                data.name += ` - ${prod.color}`;
            }
            if (prod.size) {
                data.name += ` - ${prod.size}`;
            }
            data.sku = prod.sku;

            data.isExpedited = cl.isExpedited(data.shipping);
            data.isInStore = cl.isInStore(data.shipping);
            if (data.isInStore) {
                data.storeLocation = data.shipping.toLowerCase().includes('seaside') ? 'SS' : 'CB';
            }

            require('superagent')
                .post('https://receipt.cleanline.ninja/transferRequest.php')
                .timeout(7000)
                .send(data || {})
                .end((err, res) => {
                    if (_.isUndefined(res)) {
                        cl.error('Error Sending Receipt To Printer.');
                        return;
                    }

                    const json = JSON.parse(res.text);
                    if (err || !res.ok || json.failure) {
                        cl.error('Error Sending Receipt To Printer.');
                        _.log('Receipt Error:', err, res);
                        return;
                    }

                    _.log('Receipt Data:', json.post);
                });

            setTimeout(() => {
                if (cl.get('transfers.requests.expedited') && cl.exec('transfers.requests.list').length === 0) {
                    cl.set('transfers.requests.expedited', false);
                }
            }, 1e3);
        },
        async getTransfers() {
            await cl.data.allLoaded();

            cl.send('getTransfers', {
                from: cl.dayjs(cl.get('transfers.time')).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                to: cl.dayjs(cl.get('transfers.time')).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            });
        },
        loadTransfers(data) {
            cl.set('transfers.all', data || []);
        },
        holdsCheck(v, ov) {
            if (!cl.get('products.all').length) {
                setTimeout(() => {
                    _i.holdsCheck(v, ov);
                }, 500);
                return;
            }

            if (_.isUndefined(ov) && v) {
                const p = cl.exec('product.fromId', v.id);
                const from = cl.get('transfer.from').split('.');

                if (from.length > 1) {
                    const fromBase = from[0];
                    const fromSub = from[1];
                    const holdQty = p.inventory[fromBase].holds;
                    const mainQty = p.inventory[fromBase].main;

                    if (fromSub === 'main' && holdQty > 0) {
                        cl.confirm({
                            message: `<div class='error-alert'>
                                <i class='material-icons'>warning</i>
                                ${p.sku} - ${p.name} <br>
                                is <em><strong>${mainQty < 1 ? 'only':'also'}</strong></em> available in <strong>${fromBase}.holds</strong><br><br>
                                Would you like to transfer it to <strong>${fromBase}.main</strong>?</div>`,
                            ok: 'Transfer',
                            cancel: 'Skip',
                            okFunc() {
                                cl.users.getPin(pin => {
                                    const user = cl.users.fromPin(pin);
                                    if (_.isUndefined(user)) {
                                        cl.error(`No User Found For PIN: ${pin}`);
                                        return;
                                    }

                                    cl.send('transferFromHolds', {
                                        sku: p.sku,
                                        loc: fromBase,
                                        user: user.username,
                                        qty: v.qty,
                                    });
                                });
                            }
                        });
                    }
                }
            }
        },
        getCachedWetsuitSortingData() {
            if (_.nowUnix() - cl.get('wetsuitSorting.time') > (60 * 60 * 8)) {
                cl.unset('wetsuitSorting.data');
                return;
            }

            return cl.get('wetsuitSorting.data');
        },
        setCachedWetsuitSortingData(data) {
            cl.set('wetsuitSorting.data', data);
            cl.set('wetsuitSorting.time', _.nowUnix());
        },
        async printTransferRequestsShopify() {
            const cachedSortingData = _i.getCachedWetsuitSortingData();
            if (cachedSortingData) {
                _i.printTransferRequests(cachedSortingData);
                return;
            }

            const ref = _.uuid();
            cl.data.addAwait(ref, _i.printTransferRequests);
            cl.sendWithRef('getWetsuitSortingData', ref);
        },
        async printTransferRequests(sortingData) {
            if (!sortingData) {
                cl.error('Transfer Request Sorting Data Missing');
                return;
            }
            const selected = cl.get('transfers.requests.selected') || []

            if (!selected.length) {
                selected.push(...cl.exec('transfers.requests.list').map(r => r.id));
            }

            const transfers = [];
            selected.forEach(id => {
                const t = _(cl.exec('transfers.requests.list')).keyBy('id').at(id).head();
                if (!t) {
                    return;
                }

                const p = cl.exec('product.fromId', t.product_id);
                p.thickness = 10;

                if (p.sku in sortingData) {
                    p.thickness -= sortingData[p.sku];
                }

                if (p.name.includes('Youth')) {
                    p.gender = 2;
                } else if (p.name.includes('Wmns')) {
                    p.gender = 1;
                } else {
                    p.gender = 0;
                }

                p.nameFiltered = p.name
                    .replace('Wmns ', '')
                    .replace('Youth ', '');

                transfers.push(Object.assign(t, { product: p }));
            });

            /*
                for testing wetsuit sorting:

                const atts = ['name', 'brand', 'thickness', 'gender', 'nameFiltered', 'sku'];
                const filter = t => "\n" + t.filter(t => t.product.group.toLowerCase() === 'wetsuit')
                .map((t) => {
                    atts.forEach(v => {
                        if (String(t.product[v]).length > (t[`${v}Length`] ?? 0)) {
                            t[`${v}Length`] = String(t.product[v]).length;
                        };
                    });

                    return t;
                })
                .map(t => {
                    atts.forEach(v => {
                        console.log(`${v}: ${t[`${v}Length`]}`);
                    });

                    return t;
                })
                .reduce((a, v, k, arr) => {
                    a += '|';
                    if (k === 0) {
                        a += atts.map(at => {
                            return ' ' + at.padEnd(Math.max(...arr.map(t => t[`${at}Length`])) + 1) + '|';
                        }).join('');
                        a += "\n|";
                    }

                    a += atts.map(at => {
                        return ' ' + String(v.product[at]).padEnd?.(Math.max(...[at.length + 1, ...arr.map(t => t[`${at}Length`])]) + 1) + '|';
                    }).join('');

                    a += "\n";

                    return a;
                }, '');

            */

            _.arrayReplace(transfers, nestedPullSort(transfers.filter(t => !!t.product)));

            const rac = new Ractive({
                template: cl.templates.printTransferRequests,
                data: {
                    transfers,
                    loc: cl.exec('transfers.requests.loc').split('.')[0],
                    time: new Date().toLocaleString(),
                    isExpedited: cl.isExpedited,
                    isInStore: cl.isInStore,
                    _, // lodash
                }
            });

            const html = rac.toHTML();
            const nw = window.open('', 'PRINT PULL SHEET');

            if (!nw) {
                cl.error(`<div style="max-width:35ch"><strong>Please Allow Popups From This Site</strong><br><br>There should be an icon with a red x on the right side of the address bar. Click it and select "Always allow pop-ups and redirects from ${window.location.origin}" and click done.<br><br></div>`);
                return;
            }

            nw.document.write(html);
            nw.document.close();
            nw.focus();
            nw.print();
        },
        locationCheck(change) {
            change.from = String(change.from || '').toLowerCase();
            change.to = String(change.to || '').toLowerCase();

            switch(true) {
                case change.from.length > 0:
                    change.location = 'from';
                    change.to = cl.get(`${change.keyBase}.to`);
                    break;
                case change.to.length > 0:
                    change.location = 'to';
                    change.from = cl.get(`${change.keyBase}.from`);
                    break;
                default:
                    cl.error('No Locations Set');
                    return;
            }

            change.fromLocs = change.from.split('.');
            change.toLocs = change.to.split('.');

            if (change[change.location] && change[`${change.location}Locs`].length !== 2) {
                cl.error('Invalid Location');
                return;
            }

            change.fromBase = change.fromLocs[0];
            change.fromSub = change.fromLocs[1];
            change.toBase = change.toLocs[0];
            change.toSub = change.toLocs[1];

            if (change.from !== '' && change.to !== '' && change.from === change.to) {
                cl.error(`${change.type}s must be be to different locations.`);
                cl.set(`${change.keyBase}.${change.location}`, '');
                return;
            }

            if (change.fromSub !== 'missing' && change.toSub !== 'missing') {
                cl.set(`${change.keyBase}.${change.location}`, change[change.location]);
                return;
            }

            if ((change.location === 'from' && change.toLocs.length !== 2) || (change.location === 'to' && change.fromLocs.length !== 2)) {
                cl.set(`${change.keyBase}.${change.location}`, change[change.location]);
                return;
            }

            if (change.toBase !== change.fromBase) {
                const physicalSubs = ['main', 'holds', 'missing'];
                if (!physicalSubs.includes(change.toSub)) {
                    change.toSub = 'main';
                }

                if (!physicalSubs.includes(change.fromSub)) {
                    change.fromSub = 'main';
                }

                const pysicalBases = ['wh', 'ss', 'cb'];
                if (!pysicalBases.includes(change.fromBase)) {
                    change.fromBase = change.toBase;
                }

                if (!pysicalBases.includes(change.toBase)) {
                    change.toBase = change.fromBase;
                }

                const alt = change.location === 'from' ? 'to' : 'from';
                cl.error(`${change.type}s to missing must be at the same location.<br>Setting ${alt} location to: ${change[`${change.location}Base`]}.${change[`${alt}Sub`]}`);
                cl.set(`${change.keyBase}.${alt}`, `${change.location === 'from' ? change.fromBase : change.toBase}.${change.location === 'from' ? change.toSub : change.fromSub}`);
                cl.set(`${change.keyBase}.${change.location}`, change[change.location]);
                return;
            }

            cl.set(`${change.keyBase}.${change.location}`, change[change.location]);
        },
    };

    return {
        init: _i.init,
        clear: _i.clear,
        instantComplete: _i.instantComplete,
        updateClient: _i.updateClient,
        loadTransfer: _i.loadTransfer,
        loadCurrent: _i.loadCurrent,
        loadTransferRequest: _i.loadTransferRequest,
        loadCurrentRequest: _i.loadCurrentRequest,
        loadRequests: _i.loadRequests,
        requestComplete: _i.requestComplete,
        update: _i.update,
        loadTransfers: _i.loadTransfers,
        getTransfers: _i.getTransfers,
        getCachedWetsuitSortingData: _i.getCachedWetsuitSortingData,
        setCachedWetsuitSortingData: _i.setCachedWetsuitSortingData,
        locationCheck: _i.locationCheck,
    };
})(window.cl);