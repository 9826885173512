import { failureSound } from './utilities/sound';

window.cl = window.cl || {};

window.cl.shipment = ((cl) => {
    const _on = {
        shipmentOrderSelect(e, id) {
            if (id === '') {
                return;
            }

            const s = _.filter(cl.get('shipments.all'), s => s.orderId === id && s.status !== 'complete').length;
            if (s !== 0) {
                cl.error('There is an open shipment associated with this order');
                return;
            }

            cl.confirm({
                message: "<div class='error-alert'><i class='material-icons'>warning</i>Are you sure you want to create a shipment from this order.</div>",
                ok: 'Create',
                okFunc() {
                    cl.send('createShipment', id);
                }
            });
        },
        shipmentDelete(e, id) {
            cl.prompt({
                message: '<strong>Are you sure you want to delete this shipment?</strong><br>Type <em>&quot;delete&quot;</em> below to confirm.',
                ok: 'Delete Shipment',
                okFunc(v) {
                    if (v.trim().toLowerCase() !== 'delete') {
                        cl.error('Confirm Error: Delete canceled.');
                        return;
                    }

                    cl.send('deleteShipment', id);
                }
            });
        },
        shipmentTab(e, tab) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/shipments/${cl.get('params.shipment')}/${tab}`);
        },
        shipmentProductQty(e, pid) {
            const shipment = cl.exec('shipment.src');
            const id = shipment.id;
            const i = cl.exec('shipment.productIndex', pid);
            const product = _.get(shipment, `products.${i}`);
            if (_.isUndefined(product)) {
                return;
            }

            const onOrder = cl.exec('shipment.onOrder', pid);
            product.qty = Number(e.node.value);
            if (_.isNaN(product.qty) || product.qty < 0) {
                product.qty = 0;
            }

            if (product.qty > onOrder) {
                product.qty = onOrder;
            }

            product.cost = Number(product.cost) || 0;
            product.price = Number(product.price) || 0;
            cl.sendSilent('updateShipmentProduct', {
                id,
                product,
            });
        },
        shipmentProductQtyUp(e, pid) {
            const i = cl.exec('shipment.productIndex', pid);
            if (i < 0) {
                return;
            }

            const shipment = cl.exec('shipment.src');
            const id = shipment.id;
            const product = _.get(shipment, `products.${i}`);
            if (_.isUndefined(product)) {
                return;
            }

            const onOrder = cl.exec('shipment.onOrder', pid);
            if ((product.qty + 1) > onOrder) {
                return;
            }

            product.qty += 1;
            product.cost = Number(product.cost) || 0;
            product.price = Number(product.price) || 0;
            cl.sendSilent('updateShipmentProduct', {
                id,
                product,
            });
        },
        shipmentProductQtyMax(e, pid) {
            const i = cl.exec('shipment.productIndex', pid);
            if (i < 0) {
                return;
            }

            const shipment = cl.exec('shipment.src');
            const id = shipment.id;
            const product = _.get(shipment, `products.${i}`);
            if (_.isUndefined(product)) {
                return;
            }

            const onOrder = cl.exec('shipment.onOrder', pid);
            if (product.qty < onOrder) {
                product.qty = onOrder;
            } else {
                product.qty = 0;
            }

            product.cost = Number(product.cost) || 0;
            product.price = Number(product.price) || 0;
            cl.sendSilent('updateShipmentProduct', {
                id,
                product,
            });
        },
        shipmentProductQtyDown(e, pid) {
            const i = cl.exec('shipment.productIndex', pid);
            if (i < 0) {
                return;
            }

            const shipment = cl.exec('shipment.src');
            const id = shipment.id;
            const product = shipment?.products?.[i];
            if (_.isUndefined(product)) {
                return;
            }

            if (product.qty <= 0) {
                return;
            }

            product.qty -= 1;
            product.cost = Number(product.cost) || 0;
            product.price = Number(product.price) || 0;
            cl.sendSilent('updateShipmentProduct', {
                id,
                product,
            });
        },
        shipmentGoToOrder(e) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/orders/${cl.exec('shipment.src').orderId}/${cl.get('params.tab')}`);
        },
        shipmentReceive(e, id) {
            e?.original?.preventDefault?.();
            if (_(cl.exec('shipment.src').products).map('qty').sum() < 1) {
                cl.error('Shipment has no products.');
                return false;
            }

            cl.set('shipment.location', '');
            cl.routes.go(`/shipments/${cl.get('params.shipment')}/receive`);
        },
        shipmentLocationSelect(e, id) {
            const loc = cl.get('shipment.location');
            cl.set('shipment.location', loc === id ? undefined : id);
        },
        shipmentFinal(e) {
            e?.original?.preventDefault?.();
            if (_(cl.exec('shipment.src').products).map('qty').sum() < 1) {
                cl.error('Shipment has no products.');
                cl.routes.go(`/shipments/${cl.get('params.shipment')}/products`);
                return false;
            }

            cl.confirm({
                message: '<strong>Are you sure you want to receive this shipment?</strong><br>There is no undo</em>.',
                ok: 'Receive Shipment',
                okFunc(v) {
                    cl.send('shipmentReceive', {
                        location: cl.get('shipment.location'),
                        shipment: cl.exec('shipment.src'),
                    });
                }
            });
        },
        shipmentPrintBarcodes(e, id) {
            const shipment = cl.get(`shipments.all.${cl.exec('shipment.indexFromId', id)}`);
            const barcodes = [];
            const code = 'ABCDEFGHIJKL'.split('')[cl.dayjs().month()] + cl.dayjs().year().toString().slice(-1);

            const qty = shipment.products.reduce((a, p) => { a += p.qty; return a; }, 0);

            if (qty === 0) {
                cl.error('Shipment has no products.');
                return;
            }

            shipment.products.forEach(p => {
                const src = cl.exec('product.fromId', p.productId);

                if (_.isUndefined(src)) {
                    return;
                }

                const name = `${src.name}${src.size !== '' ? (' - ' + src.size ) : ''}`;
                barcodes.push({
                    id: p.productId,
                    barcode: src.barcodes && src.barcodes[0] || '',
                    name,
                    sku: src.sku,
                    color: src.color || 'N/A',
                    price: String(src.price),
                    qty: String(p.qty),
                    printed: code,
                });
            });

            if (barcodes.length > 0) {
                cl.send('productPrintBarcodes', barcodes);
            } else {
                cl.error('Error printing barcodes');
            }
        },
        shipmentGoToProduct(e, id) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/product/${id}/details`);
        },
        shipmentProductCost(e, pid) {
            const nv = e.node.value.trim() || '0';
            if (!/^\d+\.?\d{0,2}$/.test(nv)) {
                cl.log(`Invalid Cost Value: ${nv}`);
                return;
            }

            const i = cl.exec('shipment.productIndex', pid);
            if (i < 0) {
                return;
            }

            if (nv[nv.length - 1] === '.' || nv === '0') {
                const si = cl.exec('shipment.srcIndex');
                cl.set(`shipments.all.${si}.products.${i}.cost`, nv || 0);
                return;
            }

            const shipment = cl.exec('shipment.src');
            const id = shipment.id;
            const product = shipment?.products?.[i];
            if (_.isUndefined(product)) {
                return;
            }

            product.cost = Number(nv) || 0;
            product.price = Number(product.price) || 0;
            cl.sendSilent('updateShipmentProduct', {
                id,
                product,
            });

            const si = cl.exec('shipment.srcIndex');
            cl.set(`shipments.all.${si}.products.${i}.cost`, product.cost || 0);
        },
        shipmentProductPrice(e, pid) {
            const nv = `${e.node.value.trim()}` || '0';
            if (!/^\d+\.?\d{0,2}$/.test(nv)) {
                cl.log(`Invalid Price Value: ${nv}`);
                return;
            }

            const i = cl.exec('shipment.productIndex', pid);
            if (i < 0) {
                return;
            }

            if (nv[nv.length - 1] === '.' || nv === '0') {
                const si = cl.exec('shipment.srcIndex');
                cl.set(`shipments.all.${si}.products.${i}.price`, nv || 0);
                return;
            }

            const shipment = cl.exec('shipment.src');
            const id = shipment.id;
            const product = shipment?.products?.[i];
            if (_.isUndefined(product)) {
                return;
            }

            product.cost = Number(product.cost) || 0;
            product.price = Number(nv) || 0;
            cl.sendSilent('updateShipmentProduct', {
                id,
                product,
            });
        },
        shipmentMaxAll(e, id) {
            cl.sendSilent('updateShipmentMaxAll', {
                id,
            });
        },
    };

    const _set = {
        'shipment.src'() {
            return cl.get(`shipments.all.${cl.exec('shipment.srcIndex')}`);
        },
        'shipment.srcIndex'() {
            return cl.exec('shipment.indexFromId', cl.get('params.shipment'));
        },
        'shipment.indexFromId'(id) {
            return cl.shipments.getIndex(id);
        },
        'shipments.fromId'(id) {
            return cl.get(`shipments.all.${cl.exec('shipment.indexFromId', id)}`);
        },
        'shipment.onOrder'(id) {
            const src = cl.exec('shipment.src');
            const o = cl.exec('order.fromId', src.orderId);
            const s = _.filter(cl.get('shipments.all'), s => s.status === 'complete' && s.orderId === src.orderId);

            if (_.isUndefined(o)) {
                return 0;
            }

            const p = o.products.find(p => p.id === id);
            if (_.isUndefined(p)) {
                return 0;
            }

            return p.qty - s.reduce((a, v) => {
                const p = v.products.find(p => p.productId === id);
                if (_.isUndefined(p)) {
                    return a;
                }

                a += p.qty;
                return a;
            }, 0);
        },
        'shipment.productIndex'(id) {
            const p = cl.exec('shipment.src').products;
            return p.findIndex(p => p.productId === id);
        },
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);

            _i.check();

            document.addEventListener("barcode", (e) => {
                _i.barcodeListener(e.detail);
            });
        },
        check() {
            if (cl.routes.get() === 'shipment' && cl.exec('tab') === 'receive') {
                cl.routes.go(`/shipments/${cl.get('params.shipment')}/products`);
            }
        },
        barcodeListener(bc) {
            if (cl.routes.get() !== 'shipment' || cl.exec('tab') !== 'products') {
                return;
            }

            cl.products.skuFromBarcode(bc, (bc, sku, id) => {
                _i.productSelect(id, sku);
            });
        },
        productSelect(pid, sku) {
            const i = cl.exec('shipment.productIndex', pid);

            if (i < 0) {
                cl.error(`${sku} is not in this shipment.`);
                failureSound();
                return;
            }

            const onOrder = cl.exec('shipment.onOrder', pid);
            const si = cl.exec('shipment.srcIndex');
            const qty = cl.get(`shipments.all.${si}.products.${i}.qty`) + 1;
            if (qty > onOrder) {
                cl.error(`${sku} is over qty.`);
                failureSound();
                return;
            }

            const shipment = cl.exec('shipment.src');
            const id = shipment.id;
            const product = shipment?.products?.[i];
            if (_.isUndefined(product)) {
                cl.error(`${sku} was not found in shipment.`);
                failureSound();
                return;
            }

            product.qty = qty;
            product.cost = Number(product.cost) || 0;
            product.price = Number(product.price) || 0;
            cl.sendSilent('updateShipmentProduct', {
                id,
                product,
            });
        },
    };

    return {
        init: _i.init,
    };
})(window.cl);