import localforage from 'localforage';
import { Base64 } from 'js-base64';

window.cl = window.cl || {};

window.cl.ls = ((cl, ls) => {
    const _nameSpace = 'clData';
    let _lastId;
    let _products;
    let _users;
    const _uuid = _.uuid();
    const _set = {
        multipleTabs: false,
    };
    const _i = {
        init() {
            _i.productsInit();
            _i.usersInit();

            if (cl.login.isDev()) {
                _set.multipleTabs = false;
            }

            cl.set(_set);

            _i.read();

            window.addEventListener('storage', (e) => {
                if (e.key === _nameSpace && e.newValue !== e.oldValue) {
                    const o = JSON.parse(Base64.decode(e.oldValue));
                    const n = JSON.parse(Base64.decode(e.newValue));

                    if (JSON.stringify(n.sessions) !== JSON.stringify(o.sessions)) {
                        cl.set('pos.sessions.all', n.sessions);
                        cl.update();
                    }
                }
            });

            _i.restorePersist();
            _i.multipleTabs();
        },
        read() {
            if (_.isNull(ls.getItem(_nameSpace))) {
                _i.save({});
            }
            return JSON.parse(Base64.decode(ls.getItem(_nameSpace)));
        },
        save(data) {
            ls.setItem(_nameSpace, Base64.encode(JSON.stringify(data)));
        },
        set(key, value) {
            const data = _i.read();
            _.set(data, key, value);
            _i.save(data);
        },
        get(key) {
            if (_.isUndefined(key)) {
                return _i.read();
            }
            return _.get(_i.read(), key);
        },
        remove(key) {
            const data = _i.read();
            _.unset(data, key);
            _i.save(data);
        },
        push(key, value) {
            const arr = _i.get(key);
            if (_.isUndefined(arr)) {
                _i.set(key, []);
                return _i.push(key, value);
            }
            if (!_.isArray(arr)) {
                return false
            }
            arr.push(value);
            _i.set(key, arr);
        },
        splice(key, index, deleteCount, ...newValues) {
            const arr = _i.get(key);
            if (_.isArray(arr)) {
                arr.splice(index, deleteCount, ...newValues);
                _i.set(key, arr);
            }
        },
        productsInit() {
            cl.set('schedule', {});

            localforage.config({
                driver: localforage.INDEXEDDB,
            });

            _products = localforage.createInstance({
                name: 'clProducts',
            });

            const check = ()=> {
                const now = new Date();
                const year = now.getFullYear();
                const month = now.getMonth();
                const day = now.getDate();
                const today = `schedule.${year}${month}${day}`;

                if (!cl.get(today)){
                    cl.set(today, true);

                    const offset = new Date(year, month, day, 20, 5, 0, 0) - now;
                    if (offset < 0) {
                        return;
                    }
                    setTimeout(() => {
                        _.log(`backup products run ${cl.dayjs().format()}`);

                        let delay = 0;
                        const products = cl.get('products.all');
                        const getDelay = () => {
                            delay += 50;
                            return delay;
                        }

                        if (products.length) {
                            _lastId = products[products.length - 1].id;
                            products.forEach(p => {
                                setTimeout(() => {
                                    cl.ls.product.upsert(p);
                                }, getDelay());
                            });
                        }
                    }, offset);
                }
            }
            setInterval(check, 36e5 /* 1 hour */);
            check();
            
        },
        product: {
            upsert(product) {
                if (product && product.id) {
                    _products.setItem(product.id, product)
                    .then(p => { })
                    .catch(err => {
                        _.log(`LocalForage:clProductsError:${product.id}`, err);
                    });
                }
                if (product.id === _lastId) {
                    _.log(`backup products finished ${cl.dayjs().format()}`);
                }
            },
            load() {
                if (cl.get('products.all').length === 0) {
                    _.log(`loading products from backup ${cl.dayjs().format()}`);

                    const products = [];

                    _products.iterate((p, key, i) => { products.push(p) })
                        .then(function () {
                            cl.set('products.all', products);
                            cl.success('Products loaded from backup');
                        })
                        .catch(function (err) {
                            _.log(`LocalForage:clProductsError:`, err);
                        });
                }
            },
            storage() {
                return _products;
            },
            canceled: false,
            cancel() {
                this.canceled = true;
            }
        },
        usersInit() {
            localforage.config({
                driver: localforage.INDEXEDDB
            });

            _users = localforage.createInstance({
                name: 'clUsers'
            });

            _users.length().then(l => {
                let f;
                if (l === 0) {
                    f = (newVal, oldVal, key) => {
                        _.defer(() => {
                            cl.ls.users.upsert(newVal);
                        });
                    }
                } else {
                    f = (newVal, oldVal, key) => {
                        _.defer(() => {
                            if (!_.isUndefined(oldVal)) {
                                cl.ls.users.upsert(newVal);
                            }
                        });
                    }
                }
                cl.observe('users.all.*', f);
            });
        },
        users: {
            upsert(user) {
                if (user && user.id) {
                    const u = _.pick(user, 'id', 'username', 'pin');

                    _users.setItem(u.id, u)
                    .then(u => { })
                    .catch(err => {
                        _.log(`LocalForage:clUsersError:${user.id}`, err);
                    });
                }
            },
            load() {
                const users = cl.get('users.all');
                if (users && users.length === 0) {
                    _.log('loading users from backup');

                    _users.iterate((u, key, i) => {
                        _.defer(() => {
                            const i = _.findIndex(cl.get('users.all'), ['id', u.id]);
                            if (i === -1) {
                                cl.push('users.all', u);
                            }
                        })
                    })
                    .then(() => { /* iteration complete */ })
                    .catch(function (err) {
                        _.log(`LocalForage:clUsersError:`, err);
                    });
                }
            },
            storage() {
                return _users
            },
        },
        persist(key, func = '') {
            cl.ls.set(`persistKeys.${btoa(key)}`, true);

            const f = (val, old) => {
                if (_.isUndefined(val) || _.isNull(val) || _.isUndefined(old) || (_.isArray(val) && _.isEmpty(val))) {
                    return;
                }
                _.logVerbose(`Local Storage: persist changed: ${key}: ${val}`);
                cl.ls.set(`persist.${key}`, val);
                cl.ls.set(`persistFunc.${key}`, func);
            }

            cl.observe(key, _.debounce(f, 1000));
        },
        restorePersist() {
            setTimeout(() => {
                for (const key in cl.ls.get('persistKeys')) {
                    const val = cl.ls.get(`persist.${atob(key)}`);
                    const funcKey = cl.ls.get(`persistFunc.${atob(key)}`);
                    const pKey = atob(key);

                    if (_.isUndefined(val) || val === '' || _.isNull(val) || (_.isArray(val) && _.isEmpty(val))) {
                        continue;
                    }
                    _.logVerbose(`Local Storage: restoring ${pKey} to ${JSON.stringify(val)}`);
                    cl.set(pKey, val);

                    if (!funcKey) {
                        continue;
                    }

                    const func = cl.get(funcKey);
                    if (_.isFunction(func)) {
                        func();
                    }
                }
            }, 100);
        },
        multipleTabs() {
            if (!window.SharedWorker) {
                _.log(`browser doesn't support web workers`);
                return;
            }
            const tickInterval = 100;
            const worker = new SharedWorker('/build/sharedWorker.js', 'NDN-Worker');

            worker.port.addEventListener('message', (e) => {
                if (!cl.get('products.indexed')) {
                    return;
                }
                const tabs = e.data.multipleTabs;
                cl.set('multipleTabs', tabs);
            }, false);

            worker.port.addEventListener('error', (e) => {
                throw new Error('Shared Worker Error: ', e);
            }, false);

            worker.port.onmessageerror = e => {
                  _.log('service worker message error', e.data);
            };

            worker.port.start();

            const tick = () => {
                const msg = {
                    uuid: _uuid,
                    time: _.now(),
                };
                worker.port.postMessage(msg);
            };

            (async () => {
                await cl.products.indexed();
                const interval = setInterval(tick, tickInterval);

                window.addEventListener("beforeunload", function(e){
                    clearInterval(interval);
                 });
            })();

            window.addEventListener("beforeunload", function(e){
                worker.port.close();
            });
        },
    };

    return {
        init: _i.init,
        set: _i.set,
        get: _i.get,
        remove: _i.remove,
        push: _i.push,
        splice: _i.splice,
        product: _i.product,
        users: _i.users,
        persist: _i.persist,
    };
})(window.cl, window.localStorage);