window.cl = window.cl || {};

window.cl.modal = ((cl) => {
    const _set = {
        'modals.all': [],
        'modals.last'() {
            return _(cl.get('modals.all')).orderBy('id', 'desc').head();
        },
    };
    const _on = {
        modalClose(e, id) {
            let index = _.indexOf(_.map(cl.get('modals.all'), 'id'), id);
            if (index > -1) {
                cl.splice('modals.all', index, 1);
            }
        },
        modalCloseTop(e) {
            if (_.isUndefined(e.original) || e.original.target.classList.contains('modal-wrapper')) {
                let index = _.indexOf(_.map(cl.get('modals.all'), 'id'), _(cl.get('modals.all')).orderBy('id', 'desc').head().id);
                if (index > -1) {
                    cl.splice('modals.all', index, 1);
                }
            }
        },
        exec(e, func) {
            func();
        }
    };
    const _i = {
        init() {
            cl.set(_set);
            cl.on(_on);
        },
        new(obj) {
            cl.push('modals.all', {
                id: _.uniqueId(),
                content: obj.content,
                action: obj.action,
                button: obj.button,
                title: obj.title,
                secondButton: obj.secondButton,
                secondAction: obj.secondAction
            });
        }
    };

    return {
        init: _i.init,
        new: _i.new
    };
})(window.cl);