import Ractive from 'ractive';
import MD5 from 'md5';
import StackTrace from 'stacktrace-js';
import dayjs from './utilities/dayjs';

document.addEventListener("DOMContentLoaded", () => {

    Ractive.DEBUG = false;

    window.cl = window.cl || {};
    window.cl.hash = MD5;
    window.cl.dayjs = dayjs;
    window.cl.devMode = window.location.host.includes('dev') || window.location.host.includes('local');
    window.cl.logVerbose = false;
    if (window.cl.devMode) {
        window.cl.logVerbose = true;
    }
    window.cl.initFinished = false;

    window.cl.shopifyDevBaseUrl = 'https://dev.cleanlinesurf.com';
    window.cl.shopifyProdBaseUrl = 'https://www.cleanlinesurf.com';

    window.cl.shopifyBaseUrl = () => {
        if (window.cl.devMode) {
            return window.cl.shopifyDevBaseUrl;
        }
        return window.cl.shopifyProdBaseUrl;
    }

    window.cl.shopifyDevBaseAdminUrl = 'https://admin.shopify.com/store/dev-cleanline-surf';
    window.cl.shopifyProdBaseAdminUrl = 'https://admin.shopify.com/store/cleanline-surf';

    window.cl.shopifyBaseAdminUrl = () => {
        if (window.cl.devMode) {
            return window.cl.shopifyDevBaseAdminUrl;
        }
        return window.cl.shopifyProdBaseAdminUrl;
    }

    window.cl.ims = ((cl, chrome, storage) => {
        const _data = {};
        const _i = {
            init() {
                window.addEventListener('error', e => {
                    if (e.error && _.isFunction(e.error.toString) && e.error.toString() === 'EvalError: Possible side-effect in debug-evaluate') {
                        return;
                    }

                    cl.error(`<span style="display:block;font-size:2.5rem;line-height:1.5;text-align:center;">Error ☹️️</span>${e.error.message}`)
                    if (cl?.get?.('connected')) {
                        StackTrace.fromError(e.error).then(stackFrames=>{
                            const stack = stackFrames.map(f=>`at ${f.functionName} (${f.fileName}:${f.lineNumber}:${f.columnNumber})`).join("\n")
                            cl.sendSilent('uncaughtClientError', `${window.location.href}\n\n${e.error.message}\n${stack}`)
                        })
                    }
                })

                _data.connected = false;
                _data.year = new Date().getFullYear();
                _data._ = _;
                _data.dayjs = dayjs;
                _data.isDev = () => {
                    return window.cl.devMode;
                };
                _data.classes = (...args) => {
                    return args.filter(s => typeof s === 'string' && s !== '').join(' ');
                };
                _data.disabled = (bool) => {
                    return bool ? ' disabled' : '';
                };

                _i.checkBrowser();
                cl.components.init();

                Ractive.prototype.unset = function(keypath) {
                    const property = keypath;
                    const lastDot = keypath.lastIndexOf('.');
                    const hasLastDot = lastDot !== -1;

                    this.set(keypath);
                    delete this.get(hasLastDot ? keypath.substr(0, lastDot) : '')[hasLastDot ? keypath.substring(lastDot + 1) : property];
                    return this.update(keypath);
                };

                Ractive.prototype.exec = function(...args) {
                    const func = this.get(args[0]);

                    if (!_.isFunction(func)) {
                        return;
                    }

                    return func(...args.splice(1));
                };

                cl.r = new Ractive({
                    el: '#app',
                    template: cl.templates.layout,
                    partials: {
                        main: cl.templates['blank'],
                        login: cl.templates.login,
                        menuItems: cl.templates.menuItems,
                    },
                    data: _data,
                    components: cl.components.all,
                    twoway: false,
                });

                if (cl.devMode) {
                    cl.r.DEBUG = true;
                    cl.r.DEBUG_PROMISES = true;
                }

                cl.data.init();

                _.forIn(cl, (v, k) => {
                    if (_.isFunction(v.init) && !['components', 'data'].includes(k)) {
                        try {
                            v.init();
                        } catch (e) {
                            _.log(e);
                        }
                    }
                });
                (() => {
                    if (!_data.isDev()) {
                        // show asci art in console
                        const n = [1, 43, 1, 2, 6, 1, 2, 6, 7, 2, 5, 2, 3, 4, 2, 1, 1, 1, 2, 6, 2, 6, 2, 6, 2, 3, 2, 1, 4, 3, 2, 1, 1, 1, 2, 6, 2, 6, 5, 3, 7, 1, 2, 1, 2, 2, 2, 1, 1, 1, 2, 6, 2, 6, 2, 6, 2, 3, 2, 1, 2, 2, 2, 1, 2, 1, 1, 2, 6, 1, 7, 1, 7, 1, 2, 3, 2, 1, 2, 3, 4, 1, 1, 43, 1, 7, 2, 6, 2, 1, 3, 4, 2, 1, 7, 8, 1, 7, 2, 6, 2, 1, 4, 3, 2, 1, 2, 13, 1, 7, 2, 6, 2, 1, 2, 1, 2, 2, 2, 1, 5, 10, 1, 7, 2, 6, 2, 1, 2, 2, 2, 1, 2, 1, 2, 13, 1, 7, 7, 1, 2, 1, 2, 3, 4, 1, 7, 8, 1, 43, 1, 5, 7, 1, 2, 4, 2, 1, 6, 2, 7, 6, 1, 5, 2, 6, 2, 4, 2, 1, 2, 3, 2, 1, 2, 11, 1, 5, 7, 1, 2, 4, 2, 1, 6, 2, 5, 8, 1, 10, 2, 1, 2, 4, 2, 1, 2, 3, 2, 1, 2, 11, 1, 5, 7, 2, 6, 2, 2, 3, 2, 1, 2, 11, 1, 43, 2];
                        const c = `0101212121212121012121212121212101212121212121210121212121212121210121212121212121010121212121210121212121210121212121212101212121212121012121212121010121212121210121212121212101212121212101212121212121012121212121010`;

                        _.log(n.reduce((a,v,k) => a += `\n#█`[c[k]].repeat(v), ''));
                    }
                })()

                // scroll fix
                try {
                    const scrollFix = _.debounce((function(e) {
                        const imgs = document.querySelectorAll('.image-hover img');

                        for (const img of imgs) {
                            img.style.marginTop = `${-260 - e.target.scrollTop}px`;
                        }
                    }), 50);
                    const main = document.querySelector('main');
                    if (main) {
                        main.addEventListener('scroll', scrollFix);
                    }
                }
                catch (e) {
                    _.log(e);
                }

                window.cl.initFinished = true;
            },
            checkBrowser() {
                if ((!_.isObject(chrome) || _.isUndefined(storage)) && window.navigator.userAgent !== 'electron') {
                    cl.error("This app is designed to work with Google Chrome.");
                }
            },
        };

        _i.init();

        return {};
    })(window.cl, window.chrome, window.localStorage);
});

if (window.location.host === 'cleanline.ninja') {
    window.onbeforeunload = e => ' ';
}