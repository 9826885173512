import _ from 'lodash';
import { CSVtoArray } from './utilities/CSVtoArray';

window.cl = window.cl || {};

window.cl.productUpload = ((cl) => {
    const _on = {
        productUploadDownloadTemplate() {
            const data = [{
                'Sku': 'ABC123BLKS',
                'Name': 'Example Product',
                'Price': 9.95,
                'Cost': 4.95,
                'Cost Percent': 50,
                'Weight': 2.5,
                'Size': 'Medium',
                'Color': 'Black',
                'Brand': 'Cleanline Surf',
                'Supplier': 'ABC Corp',
                'Product Type': 'Jacket',
                'Department': 'Apparel & Accessories',
                'Condition': 'New',
                'Track Inventory': true,
                'Quick Product': false,
                'Consignment': false,
                'Parent Group': '',
                'Barcode': '036000291452'
            }];

            data.unshift(Object.keys(data[0]));

            cl.csv(`product-template.csv`, data);
        },
        productUploadHandleClick(e) {
            e.node.value = null;
        },
        productUploadHandleFile(e) {
            const file = e.node.files[0];
            const reader = new FileReader();

            reader.onerror = () => {
                cl.error('Cannot Read File');
                cl.set('productUpload.errors', []);
                cl.set('productUpload.all', []);
            }

            reader.onload = e => {
                const lines = e.target.result
                    .replace(/\r\n/g, "\n")
                    .split("\n").filter(l => !!l).join("\n");

                const data = CSVtoArray(lines)
                    .filter(line => {
                        if (!_.isObject(line)) {
                            return false;
                        }

                        for (const key in line) {
                            if (line[key]) {
                                return true;
                            }
                        }

                        return false;
                    });

                _i.process(data);
            }

            reader.onabort = e => {
                cl.set('productUpload.errors', []);
                cl.set('productUpload.all', []);
            }

            if (!_.isUndefined(file)) {
                reader.readAsText(file);
            }
        },
        productUploadSend() {
            if (cl.get('productUpload.errors').length) {
                cl.error('Please Correct Errors Before Uploading');
                return;
            }

            const products = cl.get('productUpload.all');
            if (!products.length) {
                cl.error('No Products To Upload');
                return;
            }

            cl.send('productUpload', { products });
        },
    };

    const _set = {
        'productUpload.all': [],
        'productUpload.hasErrors': false,
        'productUpload.errors': [],
        'productUpload.canUpload'() {
            return cl.get('productUpload.errors').length === 0;
        }
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);
        },
        process(data = []) {
            cl.set('productUpload.errors', []);
            cl.set('productUpload.all', []);

            if (data.length < 2) {
                cl.push('productUpload.errors', 'CSV has insufficient data');
                return;
            }

            // trim whitespace
            data = data.map((v, k) => {
                if (_.isArray(v)) {
                    return v.map((vv, kk) => {
                        if (_.isString(vv)) {
                            return vv.trim();
                        }

                        return vv;
                    });
                }

                return v;
            });

            let headers = data[0].map(v => {
                const s = String(v).toLowerCase().trim();
                switch (s) {
                    case 'product type':
                        return 'group';
                    case 'track inventory':
                        return 'track';
                    case 'quick product':
                        return 'quick';
                    case 'cost percent':
                        return 'costPc';
                    case 'barcode':
                        return 'barcodes';
                    case 'parent group':
                        return 'parentGroup';
                    default:
                        return s;
                }
            });
            data.splice(0, 1);

            const required = [
                'sku',
                'name',
                'cost',
                'price',
                'cost',
                'brand',
                'supplier',
                'group',
                'department',
            ];

            for (const req of required) {
                if (!headers.includes(req)) {
                    cl.push('productUpload.errors', `CSV missing header ${req}`);
                }
            }

            // check for empty values in required fields
            const requiredMap = required.reduce((a, v) => {
                a[v] = true;
                return a;
            }, {});

            data.forEach((v, k) => {
                v.forEach((vv, kk) => {
                    if (headers[kk] in requiredMap && !vv) {
                        cl.push('productUpload.errors', `Required fields can't be empty: ${headers[kk]}, row ${k + 1}`);
                    }
                });
            });

            // remove unneccessary fields
            const colsToRemove = [];
            const blank = cl.get('product.blank');

            headers.forEach((v, k) => {
                if (!(v in blank)) {
                    colsToRemove.push(k);
                }
            });

            if (colsToRemove.length) {
                data.forEach((v, k, arr) => {
                    arr[k] = arr[k].filter((v, k) => !colsToRemove.includes(k));
                });
                headers = headers.filter((v, k) => !colsToRemove.includes(k));
            }

            data.forEach((v, k, arr) => {
                arr[k] = arr[k].reduce((ac, vv, kk) => {
                    ac[headers[kk]] = vv;
                    return ac;
                }, {});
            });

            data.forEach((v, k, arr) => {
                arr[k].sku = (arr[k].sku || '').toUpperCase();
                if (/[^a-zA-Z0-9-#+_]/.test(arr[k].sku)) {
                    cl.push('productUpload.errors', `Invalid Character In SKU: ${arr[k].sku}`);
                }

                if (arr[k].sku === '') {
                    cl.push('productUpload.errors', `SKU Missing On Line ${k + 2}`);
                }
                arr[k] = _i.caseCheck(arr[k]);
            });

            const skuCheck = {};
            const barcodeCheck = {};
            for (const product of data) {
                if (product.sku in skuCheck) {
                    if (product.sku !== '') {
                        cl.push('productUpload.errors', `Duplicate SKU in CSV: ${product.sku}`);
                    }
                } else {
                    skuCheck[product.sku] = true;
                }

                if (product.barcodes in barcodeCheck) {
                    cl.push('productUpload.errors', `Duplicate Barcode in CSV: ${product.barcodes}`);
                } else if (!_.isUndefined(product.barcodes) && !!product.barcodes) {
                    barcodeCheck[product.barcodes] = true;
                }

                if (cl.exec('product.fromSku', product.sku)) {
                    cl.push('productUpload.errors', `SKU Exists: ${product.sku}`);
                }

                if (cl.exec('product.fromBarcode', product.barcodes)) {
                    cl.push('productUpload.errors', `Barcode Exists: ${product.barcodes}`);
                }
            }

            if (cl.get('productUpload.errors').length) {
                return;
            }

            data.forEach((v, k, arr) => {
                if (_.isUndefined(arr[k].barcodes)) {
                    arr[k].barcodes = cl.product.createBarcode();
                }

                arr[k].barcodes = [arr[k].barcodes];
                if (_.isUndefined(arr[k].track)) {
                    arr[k].track = "true";
                }

                for (const key in arr[k]) {
                    if (_.isString(arr[k][key])) {
                        arr[k][key] = arr[k][key]
                            .replace(/^yes$/i, 'true')
                            .replace(/^no$/i, 'false')
                            .replace(/^true$/i, 'true')
                            .replace(/^false$/i, 'false');
                    }
                }
            });

            cl.set('productUpload.all', data);
            const skus = _.map(data, 'sku');
            cl.send('productUploadSkuCheck', { skus });
        },
        skuCheck(skus = {}) {
            for (const sku in skus) {
                if (skus[sku]) {
                    cl.push('productUpload.errors', `SKU From Deleted Product: ${sku}`);
                } else {
                    cl.push('productUpload.errors', `SKU From Existing Product: ${sku}`);
                }
            }
        },
        caseCheckCache: undefined,
        caseChecks: {
            name: true,
            brand: true,
            supplier: true,
            group: true, // product type
            department: true,
            color: true,
            size: true,
            condition: true,
            parentGroup: false,
        },
        getCaseCheckData() {
            if (_i.caseCheckCache) {
                return _i.caseCheckCache;
            }

            _i.caseCheckCache = cl.get('products.all').reduce((a, p) => {
                for (const key in _i.caseChecks) {
                    if (!(key in a)) {
                        a[key] = {};
                    }
                    a[key][p[key].toLowerCase()] = p[key];
                }
                return a;
            }, {});

            return _i.caseCheckCache;
        },
        caseCheck(product = {}) {
            const caseCheckData = _i.getCaseCheckData();

            for (const key in product) {
                if (!(key in _i.caseChecks)) {
                    continue;
                }

                const val = product[key];
                const valLo = val.toLowerCase();
                const defaultVal = cl.get(`products.bulk.defaults.${key}`);

                if (
                    _.isString(val) &&
                    val !== defaultVal &&
                    caseCheckData[key][valLo] &&
                    caseCheckData[key][valLo] !== val
                ) {
                    product[key] = caseCheckData[key][valLo];
                }
            }

            return product;
        },
        success() {
            cl.success('Products successfully created.');
            cl.set('productUpload.errors', []);
            cl.set('productUpload.all', []);
        },
        clientUpdate(data = []) {
            for (const product of data) {
                cl.products.createProduct(product);
            }
        },
    };

    return {
        init: _i.init,
        skuCheck: _i.skuCheck,
        success: _i.success,
        clientUpdate: _i.clientUpdate,
    };
})(window.cl);

/*
Required:
Sku
Name
Cost
Price
Cost
Brand
Supplier
Group/Type
Department

Default Condition To New if not specified
Track Default True
*/