import _ from "lodash";

export default class DataTableCustomFilter {
    constructor(name, filterFunction, defaultFilter = '') {
        this._name = name;
        this._func = filterFunction;
        this._defaultVal = defaultFilter;
    }

    filter(val) {
        return this._func?.(val);
    }

    get defaultValue() {
        if (this._defaultVal === 'show') {
            return [this.show];
        }

        if (this._defaultVal === 'hide') {
            return [this.hide];
        }

        return [];
    }

    get name() {
        return this._name;
    }

    get hide() {
        return `Exclude ${_.capitalizeAll(this.name)}`;
    }

    get show() {
        return `Show Only ${_.capitalizeAll(this.name)}`;
    }

    get values() {
        return  [this.hide, this.show];
    }

    get valid() {
        if (!_.isString(this.name) || (this.name?.length ?? 0) === 0) {
            return false;
        }

        if (!_.isFunction(this._func)) {
            return false;
        }

        return true;
    }

    static isValid(customFilter) {
        if (!(customFilter instanceof DataTableCustomFilter)){
            return false;
        }

        return customFilter.valid;
    }
}