window.cl = window.cl || {}

window.cl.transferRequestCreate = ((cl) => {
    const _on = {
        transferRequestCreateTab(e, tab) {
            e?.original?.preventDefault?.();
            cl.routes.go(`/transfers/new-request/${tab}`);
        },
        transferRequestCreateNote(e) {
            cl.set('transferRequestCreate.note', (e.node.value || '').trim());
        },
        transferRequestCreateProductQtyUp(e, id) {
            const index = _.findIndex(cl.get('transferRequestCreate.products'), ['id', id]);
            if (index < 0) {
                cl.error('Error Locating Product');
                return
            }

            const locs = cl.get('transferRequestCreate.from').split('.') || [];
            const src = cl.exec('product.fromId', id);
            const max = src.inventory?.[locs[0]]?.[locs[1]] || 1;
            const key = `transferRequestCreate.products.${index}.qty`;

            if (index > -1 && cl.get(key) < max) {
                cl.increment(key);
                return;
            }

            cl.alert(`You don't have that many ${src.name} in inventory.`);
            if (cl.exec('login.hasPerm', 'transfersNegatives')) {
                cl.increment(key);
            }
        },
        transferRequestCreateProductQtyDown(e, id) {
            const index = cl.get('transferRequestCreate.products')?.findIndex?.(p => p.id === id);
            if (index < 0) {
                cl.error('Error Locating Product');
                return
            }

            const key = `transferRequestCreate.products.${index}.qty`;
            if (index > -1 && cl.get(key) > 1) {
                cl.decrement(key);
            }
        },
        transferRequestCreateProductQty(e, id) {
            const index = _.findIndex(cl.get('transferRequestCreate.products'), ['id', id]);
            const locs = cl.get('transferRequestCreate.from').split('.') || [];
            const src = cl.exec('product.fromId', id);
            const max = src.inventory?.[locs[0]]?.[locs[1]] || 1;
            const val = e.node.value.trim();

            if (/^\d+$/.test(val) && index > -1 && val <= max) {
                cl.set(`transferRequestCreate.products.${index}.qty`, Number(val));
                return;
            }

            cl.error(`${cl.exec('product.fromId', id).sku} only has ${max} in stock`);
            e.node.value = '0';
            cl.set(`transferRequestCreate.products.${index}.qty`, 0);
        },
        transferRequestCreateProductRemove(e, id) {
            const index = cl.get('transferRequestCreate.products').findIndex(p => p.id === id);
            if (index > -1) {
                cl.splice('transferRequestCreate.products', index, 1);
            }
        },
        transferRequestCreate(e) {
            e?.original?.preventDefault?.();
            if (cl.get('transferRequestCreate.from').length === 0) {
                cl.error('Request needs "from" location.');
                cl.routes.go('/transfers/new-request/from');
                return;
            }

            if (cl.get('transferRequestCreate.to').length === 0) {
                cl.error('Request needs "to" location.');
                cl.routes.go('/transfers/new-request/to');
                return;
            }

            if (cl.get('transferRequestCreate.products').length === 0) {
                cl.error('Request needs products.');
                cl.routes.go('/transfers/new-request/products');
                return;
            }

            if ((cl.get('transferRequestCreate.note') || '').trim() === '') {
                cl.error('Request needs a note.');
                return;
            }

            if (cl.get('transferRequestCreate.from') === cl.get('transferRequestCreate.to')) {
                cl.error('Request "to" and "from" are same location');
                return;
            }

            const message = `
                <strong>Are you sure you want to create this request?</strong><br><br>
                <strong>From:</strong> ${cl.get('transferRequestCreate.from')}<br>
                <strong>To:</strong> ${cl.get('transferRequestCreate.to')}<br>
                <strong>Total Qty:</strong> ${_.reduce(cl.get('transferRequestCreate.products'), (sum, t) => sum += Number(t.qty), 0)}<br>
                <strong>Note:</strong> ${cl.get('transferRequestCreate.note')}
            `;

            cl.confirm({
                message,
                okFunc() {
                    _i.createRequest();
                },
            });
        },
        transferRequestFromSelect(e, from) {
            const keyBase = 'transferRequestCreate';
            const type = 'Transfer request';
            cl.transfers.locationCheck({
                from,
                keyBase,
                type,
            });
        },
        transferRequestToSelect(e, to) {
            const keyBase = 'transferRequestCreate';
            const type = 'Transfer request';
            cl.transfers.locationCheck({
                to,
                keyBase,
                type,
            });
        },
    };

    const _set = {
        'transferRequestCreate.products': [],
        'transferRequestCreate.from': '',
        'transferRequestCreate.to': '',
        'transferRequestCreate.note': '',
        'transferRequestCreate.productSelect'(id) {
            const products = cl.get('transferRequestCreate.products');
            const index = products.findIndex(p => p.id === id);
            const locs = cl.get('transferRequestCreate.from').split('.') || ['', ''];
            const src = cl.exec('product.fromId', id);
            const srcInventory = src.inventory?.[locs[0]]?.[locs[1]];
            const max = srcInventory || 1;
            const key = `transferRequestCreate.products.${index}.qty`;

            setTimeout(() => {
                cl.update('transfer');
            }, 10);

            if (index <= -1) {
                cl.push('transferRequestCreate.products', {
                    id: id,
                    qty: 1
                });

                if (srcInventory <= 0) {
                    cl.alert(`You don't have that many ${src.sku} in inventory.`);
                }
                return;
            }

            if (cl.get(key) < max) {
                cl.increment(key);
                return;
            }

            cl.alert(`You don't have that many ${src.sku} in inventory.`);
            if (cl.exec('login.hasPerm', 'transfersNegatives')) {
                cl.increment(key);
            }
        },
    };

    const _i = {
        init() {
            cl.on(_on);
            cl.set(_set);

            cl.observe('transferRequestCreate.products.*', _i.holdsCheck);
        },
        createRequest() {
            if (!cl.exec('login.isPublic')) {
                _i.createRequestSend(cl.get('login.user'));
                return;
            }

            setTimeout(()=>{
                cl.users.getPin(pin => {
                    const user = cl.users.fromPin(pin);
                    if (_.isUndefined(user)) {
                        cl.error(`No User Found For PIN: ${pin}`);
                        return;
                    }

                    _i.createRequestSend(user.username);
                });
            }, 10);
        },
        createRequestSend(username) {
            cl.send('createTransferRequest', {
                to: cl.get('transferRequestCreate.to'),
                from: cl.get('transferRequestCreate.from'),
                products: cl.get('transferRequestCreate.products'),
                note: cl.get('transferRequestCreate.note'),
                user: username,
            });
        },
        complete() {
            _i.new();
            cl.routes.go('/transfer-requests');
        },
        new() {
            cl.set('transferRequestCreate.products', []);
            cl.set('transferRequestCreate.from', '');
            cl.set('transferRequestCreate.to', '');
            cl.set('transferRequestCreate.note', '');
        },
        fromProduct(product) {
            _i.new();
            cl.push('transferRequestCreate.products', {
                id: product.id,
                qty: 1,
            });
            cl.routes.go('/transfers/new-request/products');
            const loc = cl.ls.get('posLocation');
            if (!loc) {
                return;
            }

            cl.set('transferRequestCreate.to', loc);

            const base = loc.split('.')[0];
            const wh = product.inventory.wh.main;
            const ss = product.inventory.ss.main;
            const cb = product.inventory.cb.main;

            if (base != 'wh' && wh > 0) {
                cl.set('transferRequestCreate.from', 'wh.main');
                return;
            }

            if (base != 'ss' && ss > 0) {
                cl.set('transferRequestCreate.from', 'ss.main');
                return;
            }

            if (base != 'cb' && cb > 0) {
                cl.set('transferRequestCreate.from', 'cb.main');
                return;
            }
        },
        holdsCheck(v, ov) {
            if (!v || !_.isUndefined(ov)) {
                return;
            }

            const p = cl.exec('product.fromId', v.id);
            const from = String(cl.get('transferRequestCreate.from'));
            const fromLocs = from.split('.');

            if (fromLocs.length < 2) {
                return;
            }

            const fromBase = fromLocs[0];
            const fromSub = fromLocs[1];
            const holdQty = p.inventory[fromBase].holds;
            const mainQty = p.inventory[fromBase].main;

            if (fromSub !== 'main' || holdQty <= 0) {
                return;
            }

            cl.alert(`<div class='error-alert'>
                <i class='material-icons'>warning</i>
                ${p.sku} - ${p.name} <br>
                is <em><strong>${mainQty < 1 ? 'only':'also'}</strong></em> available in <strong>${fromBase}.holds</strong><br><br>
                Are you sure you want to request it from <strong>${fromBase}.main</strong>?</div>`);
        },
    };

    return {
        init: _i.init,
        complete: _i.complete,
        new: _i.new,
        fromProduct: _i.fromProduct,
    };
})(window.cl)