const gtinChecksum = (s) => {
    return String(String(s)
        .slice(0, -1)
        .split('')
        .reverse()
        .reduce((a, v, i) => a + v * (i % 2 || 3), 0) * 9 % 10);
}

const isGTIN = (code) => {
    if (typeof code !== 'string') {
        return false
    }

    if (!/^\d{8,14}$/.test(code)) {
        return false
    }

    return code.slice(-1) === gtinChecksum(code);
};

const isUPC = (code) => {
    if (typeof code !== 'string') {
        return false
    }

    if (!/^\d{12}$/.test(code)) {
        return false
    }

    return isGTIN(code);
};

export default isUPC;